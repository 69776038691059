import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsives = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function Slider(props) {
  const { SliderArray, SliderItem, arrows, responsive, showDots } = props;
  const CustomDot = ({ onClick, ...rest }) => {
    const {
      onMove,
      index,
      active,
      carouselState: { currentSlide, deviceType },
    } = rest;
    return (
      <button
        className={active ? "active-slider " : "not-active-slider"}
        onClick={() => onClick()}
      />
    );
  };

  return (
    <Carousel
      customDot={<CustomDot />}
      //swipeable={true}
      arrows={arrows}
      renderDotsOutside
      //removeArrowOnDeviceType={["tablet", "mobile"]}
      responsive={responsive}
      infinite={true}
      //showDots={showDots}
    >
      {SliderArray.map((item, index) => (
        <SliderItem item={item} index={index} />
      ))}
    </Carousel>
  );
}
Slider.defaultProps = {
  SliderArray: [{}, {}, {},{}],
  SliderItem: () => <div />,
  arrows: false,
  responsive: responsives,
  showDots: true,
};
export default Slider;
