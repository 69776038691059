import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import apiEndPoints from "utils/apiEndPoints";
import { apiCall } from "utils/httpClient";
import { useSelector } from "react-redux";
import Select from "react-select";
import { successToast, errorToast } from "utils/httpClient";
import Loader from "common/loader";
import Multiselect from "multiselect-react-dropdown";
import { AutocompletePlace } from "common/autoCompleteAddress";
import moment from "moment";
function PhotoList(props) {
  const wrapperRef = useRef(null);
  const wrapperRefCity = useRef(null);
  const inputFile = useRef(null);
  // const {businessDetails, setBusinessDetail} = props();
  let history = useHistory();
  const userData = useSelector((store) => store.userData);
  const [isLoader, setIsLoader] = useState(false);
  const [proIsLoader, setProIsLoader] = useState(false);

  const [businessDetails, setBusinessDetail] = useState({});
  const [paymentmethods, setPaymentmethods] = useState({});
  const [selectedService, setSelectedService] = useState([]);
  const [photofile, setPhotofile] = useState();
  // console.log("photofile photofile state", photofile)
  const [citiesList, setCitiesList] = useState([]);
  const [selectedCity, setSelectedCity] = useState({});
  const [baseUrl, setBaseUrl] = useState("");
  // const [selectImage, setSelectImage] = useState('images/no_image.png');
  const [selectImage, setSelectImage] = useState("");

  const [searchCityList, setSearchCityList] = useState([]);
  const [cityListShow, setCityListShow] = useState(false);
  const [industryListShow, setIndustryListShow] = useState(false);
  const [industryList, setIndustryList] = useState({});
  const [bucket_Img_url, setBucket_Img_url] = useState("images/no_image.png");
  const [bucketcertificate, setBucketcertificate] = useState("");
  //console.log("PhotoList -> bucketcertificate", bucketcertificate)
  const [imgLoad, setImgLoad] = useState(false);
  var plantype = localStorage.getItem("plan_type");
  
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        //alert("You clicked outside of me!");
        setIndustryListShow(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
  }, [wrapperRef]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRefCity.current &&
        !wrapperRefCity.current.contains(event.target)
      ) {
        setCityListShow(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
  }, [wrapperRefCity]);

  useEffect(() => {
    getBusinessData();
    
  }, []);

  
  useEffect(() => {}, [selectedService]);

  const getBusinessData = async () => {
    // setIsLoader(true);
    const header = {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    };
    const param = {
      profileid: userData.userData.profileid,
      businessid: userData.userData.businessid,
    };

    const { data } = await apiCall(
      "POST",
      apiEndPoints.GETBUSINESSDETAILS,
      param,
      header
    );

    if (data.status == 200) {
      await localStorage.setItem("allinformation", data.data.allinformation);
      setBusinessDetail(data.data);
      data.data?.certificate != ""
        ? getImagecertificate(data.data?.certificate)
        : console.log("");

      data.data?.photofile != ""
        ? getImage(data.data?.photofile)
        : console.log("");

      setBaseUrl(data.base_url);
      setPaymentmethods(JSON.parse(data.data?.payments));
     

      var selArr = [];
      data.data?.naics.length > 0 &&
        data.data.naics.map((x) => {
          const arr = { cat: x.naicsid, key: x.title };
          selArr.push(arr);
        });
      setSelectedService([...selArr]);
      setIsLoader(false);

      history.push("/");
    } else {
      setIsLoader(false);
    }
      
      
  };
  // console.log("selectedService=vv===>", selectedService);
  // console.log('setSelectedService ==>', selectedService)

  // ---------------------------------- get industry List ----------------------------

  //   --------------------------------------------- Get service List ----------------------------
  // service=================
  const [serviceList, setServiceList] = useState({});

  const searchServiceListData = async (item) => {
    if (item != "" && item.length >= 3) {
      const params = { servicename: item };
      const { data } = await apiCall(
        "POST",
        apiEndPoints.GETSERVICELIST,
        params
      );
      if (data.status == 200) {
        setServiceList(data.data);
      } else {
        setServiceList([]);
      }
    } else {
      setServiceList([]);
    }
  };

  const options =
    serviceList.length > 0
      ? serviceList.map((curE) => {
          return { cat: curE.naicsid, key: curE.title };
        })
      : [];

  const [formError, setFormError] = useState({});

  function validation() {
    let error = {};
    let validError = false;
    if (!businessDetails?.fullname) {
      validError = true;
      error["fullname"] = "Business name is required!";
    }
    if (!businessDetails?.address) {
      validError = true;
      error["address"] = "Address is required!";
    }
    if (!businessDetails.cityid) {
      validError = true;
      error["cityid"] = "City is required!";
    }
    if (!businessDetails.city_name) {
      validError = true;
      error["cityid"] = "City is required!";
    }
    console.log("payment", paymentmethods);
    if (paymentmethods == null) {
      validError = true;
      error["payments"] = "Select Any one Payment method!";
    } else if (
      paymentmethods.cash == 0 &&
      paymentmethods.cashapp == 0 &&
      paymentmethods.zelle == 0 &&
      paymentmethods.paypal == 0 &&
      paymentmethods.creditcard == 0
    ) {
      validError = true;
      error["payments"] = "Select Any one Payment method!";
    }
    if (!businessDetails?.phone || businessDetails?.phone.length <= 9 || businessDetails?.phone.length >= 15) {
      validError = true;
      error["phone"] = "Phone is required and length should be 10 to 15 digit!";
    }

    const emailValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (
      !businessDetails.email ||
      emailValid.test(businessDetails.email) === false
    ) {
      validError = true;
      error["email"] = "Please Entery Valid Email address";
    }
    if (!businessDetails?.websiteurl) {
      validError = true;
      error["websiteurl"] = "Website URL is required!";
    }
    if (!businessDetails?.about) {
      validError = true;
      error["about"] = "About us is required!";
    }
    /*  if (!businessDetails?.certificate) {
      validError = true;
      error["certificate"] = "Certificate  is required!";
    } */

    /*  if (!businessDetails?.service_area) {
       validError = true;
       error["service_area"] = "Service area is required!";
     }
     if (!businessDetails?.service_offer) {
       validError = true;
       error["service_offer"] = "Service offer is required!";
     } */
    // if (!businessDetails?.naicsid) {
    //   validError = true;
    //   error["naicsid"] = "Services is required!";
    // }
    if (selectedService.length == 0) {
      validError = true;
      error["naics"] = "Occupation is required!";
    }

    if (!businessDetails?.industry) {
      validError = true;
      error["industry"] = "Industry is required!";
    }
    if (!businessDetails?.year_revenue) {
      validError = true;
      error["year_revenue"] = "Year Revenue is required!";
    }
    if (!businessDetails?.hours) {
      validError = true;
      error["hours"] = "Hours Operation is required!";
    }
    /* if (!businessDetails?.pricehour) {
      validError = true;
      error["pricehour"] = "Price/hour is required!";
    } */
    console.log("pricemodel", businessDetails?.pricemodel);

    if (!businessDetails?.pricemodel) {
      validError = true;
      error["pricemodel"] = "Price model is required!";
    }
    console.log("numemps", businessDetails?.numemps);
    if (!businessDetails?.numemps) {
      validError = true;
      error["numemps"] = "Head Count is required!";
    }
    // console.log(
    //   "PhotoList -> businessDetails?.phone.length",
    //   businessDetails?.phone.length
    // );
    /*  if (!businessDetails?.photofile) {
      validError = true;
      error["photofile"] = "Business Url is required!";
    }  */
    /* if (businessDetails?.phone.length != 10) {
      validError = true;
      error["phone"] = "Enter 10 digits Mobie number";
    } */
    console.log("DetailList -> error", error);
    setFormError(error);
    return validError;
  }

  const [webUrlMsg, setWebUrlMsg] = useState("");
  function isUrlCheck(item) {
    let url;
    try {
      url = new URL(item);
      setBusinessDetail({
        ...businessDetails,
        websiteurl: item,
      });
      setWebUrlMsg("");
    } catch (_) {
      setWebUrlMsg("Enter Proper Web URL. Example http://");
      setBusinessDetail({
        ...businessDetails,
        websiteurl: "",
      });
    }
  }

  const naics_id = businessDetails?.naics?.map((x) => {
    return x.value;
  });
  // console.log("naics naics==>", naics_id)

  const submitBusinessForm = async () => {
    var servId =
      businessDetails &&
      businessDetails?.naics &&
      businessDetails?.naics.length > 0
        ? businessDetails.naics.map((x) => x.naicsid)
        : "";
    if (!validation()) {
      // setIsLoader(true);
      setProIsLoader(true);
      let businessData = new FormData();
      businessData.append("businessid", businessDetails.businessid);
      businessData.append("profileid", businessDetails.profileid);
      businessData.append("status", businessDetails.status);
      businessData.append("fullname", businessDetails.fullname);
      businessData.append("address", businessDetails.address);
      // businessData.append("cityid", selectedCity);
      businessData.append("cityid", businessDetails.cityid);
      businessData.append("city_name", businessDetails.city_name);
      businessData.append("numemps", businessDetails.numemps);
      businessData.append(
        "annualgrossrevenue",
        businessDetails.annualgrossrevenue
      );
      businessData.append("about", businessDetails.about);
      businessData.append("pricehour", businessDetails.pricehour);
      businessData.append("pricemodel", businessDetails.pricemodel);
      businessData.append("hours", businessDetails.hours);
      businessData.append("payments", JSON.stringify(paymentmethods));
      businessData.append("phone", businessDetails.phone);
      businessData.append("email", businessDetails.email);
      businessData.append("websiteurl", businessDetails.websiteurl);
      businessData.append("showcall", businessDetails.showcall);
      businessData.append("showtext", businessDetails.showtext);
      businessData.append("showemail", businessDetails.showemail);
      businessData.append("service_offer", businessDetails.service_offer);
      businessData.append("service_area", businessDetails.service_area);
      businessData.append("county", "1");
      businessData.append("state", "1");
      businessData.append("industry", businessDetails.industry);
      businessData.append("industry_name", businessDetails.industry_name);
      businessData.append("year_revenue", businessDetails.year_revenue);
      businessData.append("plan_type", plantype);
      businessData.append("plan_option", businessDetails.plan_option);
      businessData.append(
        "business_validation",
        businessDetails.business_validation
      );
      businessData.append(
        "naicsid",
        selectedService.length > 0 &&
          selectedService.map((item) => item.cat).join(", ")
      );
      businessData.append("facebookurl", businessDetails.facebookurl);
      businessData.append("linkedInurl", businessDetails.linkedInurl);
      businessData.append("twitterurl", businessDetails.twitterurl);
      businessData.append("youtubeurl", businessDetails.youtubeurl);
      businessData.append("instagramurl", businessDetails.instagramurl);
      businessData.append("photofile", businessDetails.photofile);
      businessData.append("certificate", businessDetails.certificate);
      businessData.append("is_nonprofit", businessDetails.is_nonprofit);
      businessData.append("is_minority", businessDetails.is_minority);
      businessData.append("allinformation", 1);

      // businessData.append("photofile", photofile);
      const header = {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      };
      const { data } = await apiCall(
        "POST",
        apiEndPoints.BUSINESSDETAILUPDATE,
        businessData,
        header
      );
      if (data.status == 200) {
        setIsLoader(false); // function handleIndustrySearch(){
        //   const status = industryListShow?false:true
        //   setIndustryListShow(status);
        // } // function handleIndustrySearch(){
        //   const status = industryListShow?false:true
        //   setIndustryListShow(status);
        // }

        document.getElementById("sucessModelBtn").click();
        //successToast(data.message);
        setProIsLoader(false);
        getBusinessData();
      } else {
        setProIsLoader(false);
        setIsLoader(false);
        errorToast(data.message);
      }
    }
  };

  async function handleCitySearch(city = "") {
    // console.log('search ==>', city)
    if (city != "" && city.length >= 3) {
      const params = { cityname: city };
      const { data } = await apiCall(
        "POST",
        apiEndPoints.GETCITIESLIST,
        params
      );
      if (data.status == 200) {
        setSearchCityList(data.data);
      } else {
        setSearchCityList([]);
      }
      setCityListShow(true);
    } else {
      setSearchCityList([]);
      setBusinessDetail({
        ...businessDetails,
        cityid: "",
        city_name: "",
      });
    }
  }

  function manageSearchCity(item) {
    if (item == "") {
      setSearchCityList([]);
      setBusinessDetail({
        ...businessDetails,
        cityid: "",
        city_name: "",
      });
    }
  }

  function handleSelectCity(cityname) {
    document.getElementById("citySearch").value = cityname;
    setCityListShow(false);
    setSearchCityList([]);
  }
  // if(isLoader){
  //   return(<Loader />)
  // }

  function handleSelectIndustry(item) {
    document.getElementById("industryId").value = item;
    setIndustryListShow(false);
  }

  async function handleIndustryServiceSearch(service) {
    if (service != "" && service.length >= 3) {
      const params = { servicename: service };
      const { data } = await apiCall(
        "POST",
        apiEndPoints.GETINDUSTRYLIST,
        params
      );
      if (data.status == 200) {
        setIndustryList(data.data);
        setIndustryListShow(true);
      } else {
        // errorToast(data.message);
        setIndustryList([]);
        setIndustryListShow(false);
      }
    } else {
      setServiceList([]);
      setIndustryListShow(false);
      setBusinessDetail({
        ...businessDetails,
        industry: "",
      });
    }
  }

  function manageSearchService(item) {
    if (item == "") {
      setServiceList([]);
      setIndustryListShow(false);
      setBusinessDetail({
        ...businessDetails,
        industry: "",
      });
    }
  }

  async function getImage(param) {
    if (param && param != "") {
      const params = {
        fileName: param,
      };
      const { data } = await apiCall("POST", apiEndPoints.GETIMAGE, params);

      if (data.status == 200) {
        setBucket_Img_url(data.url);
        setTimeout(() => {
          //setIsLoader(true);
        }, 1000);
      } else {
        //setIsLoader(false);
      }
    }
  }

  const onSelect = (latLng, area) => {
    const nextFormState = {
      ...businessDetails,
      address: area,
      latitude: latLng.lat,
      longitude: latLng.lng,
    };
    setBusinessDetail(nextFormState);
  };

  const handleInputArea = (e) => {
    const nextFormState = {
      ...businessDetails,
      address: e,
      latitude: "",
      longitude: "",
    };
    setBusinessDetail(nextFormState);
  };

  async function getImagecertificate(param) {
    //setIsLoader(true);
    const params = {
      fileName: param,
    };
    const { data } = await apiCall("POST", apiEndPoints.GETIMAGE, params);

    if (data.status == 200) {
      setBucketcertificate(data.url);
      setTimeout(() => {
        //setIsLoader(true);
      }, 1000);
    } else {
      //setIsLoader(false);
    }
  }

  const profile_photo_picker = () => {
    const getImage = inputFile.current.click();
  };
  function changeProfileImage(event) {
    if (event.target.files && event.target.files[0]) {
      setBusinessDetail({
        ...businessDetails,
        photofile: event.target.files[0],
      });
      setBucket_Img_url(URL.createObjectURL(event.target.files[0]));
    }
  }

  const imageLoading = (e) => {
    setImgLoad(true);
    setTimeout(() => {
      setImgLoad(false);
    }, 1000);
  };

  return (
    <>
      <div>
        {isLoader == false ? (
          <div>
            {businessDetails && Object.keys(businessDetails).length > 0 ? (
              <form>
                <div className="row">
                  <div className="col-md-4">
                    <div>
                      <div className="col-md-12">
                        <div className="text-center mb-3">
                          <img
                            onClick={profile_photo_picker}
                            src={bucket_Img_url}
                            height="100px"
                            width="100px"
                            style={{
                              borderRadius: "50px",
                            }}
                            onLoad={(e) => imageLoading(e)}
                            className="prof-img"
                          />
                          <img
                            src="./images/preview.gif"
                            width="50px"
                            height="50px"
                            className={`${imgLoad ? "load" : "displayNone"}`}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <i
                          onClick={profile_photo_picker}
                          className="fa fa-edit editbus-icon"
                        ></i>
                      </div>

                      <input
                        type="file"
                        id="addImage"
                        style={{ display: "none" }}
                        ref={inputFile}
                        accept="image/*"
                        onChange={(e) => changeProfileImage(e)}
                      />
                    </div>
                    <div className="form-group">
                      {/* <label className="d-block mb-0">Business Name:</label> */}
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Business Name"
                        value={businessDetails.fullname}
                        maxLength={50}
                        onChange={(e) =>
                          setBusinessDetail({
                            ...businessDetails,
                            fullname: e.target.value.trimStart(),
                          })
                        }
                      />
                      <small className="text-danger">
                        {!businessDetails?.fullname && formError.fullname
                          ? formError.fullname
                          : ""}
                      </small>
                    </div>
                    {/* <fieldset className="scheduler-border">
                      <legend className="scheduler-border">Parámetros</legend>
                      <textarea
                        className="form-control"
                        placeholder="Overview/introduction"
                        value={businessDetails.about}
                        onChange={(e) =>
                          setBusinessDetail({
                            ...businessDetails,
                            about: e.target.value,
                          })
                        }
                      ></textarea>
                    </fieldset> */}
                    <div className="form-group">
                      <div className="d-flex justify-content-between">
                        <label className="d-block mb-0">
                          Overview/Introduction
                        </label>
                        <img
                          src="/images/icons/arrow-a.svg"
                          className="fa-arrows fa__icons close"
                          style={{ color: "#707070" }}
                          data-toggle="modal"
                          data-target="#showOverview"
                        ></img>
                      </div>
                      <textarea
                        className="form-control"
                        placeholder="Overview/Introduction"
                        value={businessDetails.about}
                        onChange={(e) =>
                          setBusinessDetail({
                            ...businessDetails,
                            about: e.target.value.trimStart(),
                          })
                        }
                      ></textarea>
                      <small className="text-danger">
                        {!businessDetails?.about && formError.about
                          ? formError.about
                          : ""}
                      </small>
                    </div>
                    <div className="form-group">
                      {/* <label className="d-block mb-0">Address:</label> */}
                      {/* <AutocompletePlace
                          value={businessDetails.address}
                          onChange={handleInputArea}
                          onSelect={onSelect}
                        /> */}
                      <input
                        type="text"
                        className="form-control form-control-md"
                        placeholder="Address"
                        value={businessDetails.address}
                        onChange={(e) =>
                          setBusinessDetail({
                            ...businessDetails,
                            address: e.target.value.trimStart(),
                          })
                        }
                      />
                      <small className="text-danger">
                        {!businessDetails?.address && formError.address
                          ? formError.address
                          : ""}
                      </small>
                    </div>
                    <div className="form-group">
                      {/* <label className="d-block mb-0">City:</label> */}
                      <div ref={wrapperRefCity}>
                        <input
                          type="text"
                          className="form-control"
                          id="citySearch"
                          onChange={(e) => {
                            handleCitySearch(e.target.value);
                          }}
                          // onClick={() => manageCityList()}
                          onBlur={(e) => manageSearchCity(e.target.value)}
                          placeholder="City"
                          defaultValue={businessDetails.city_name +', '+businessDetails.statecode}
                          autoComplete="off"
                          disabled= "true"
                        />
                        <div>
                          <div
                            className={`city_list_box ${
                              cityListShow ? "show" : "hide"
                            }`}
                          >
                            {searchCityList && searchCityList.length > 0 ? (
                              <ul className="city_list_menu">
                                {searchCityList.map((item, i) => {
                                  return (
                                    <li
                                      onClick={() => {
                                        setBusinessDetail({
                                          ...businessDetails,
                                          cityid: item.id,
                                          city_name: item.city,
                                        });
                                        setCityListShow(false);
                                        handleSelectCity(item.city);
                                      }}
                                      className={`city_list ${
                                        businessDetails?.cityid == item.id
                                          ? "active"
                                          : ""
                                      }`}
                                    >
                                      {item.city}
                                    </li>
                                  );
                                }, [])}
                              </ul>
                            ) : (
                              <p
                                style={{
                                  textAlign: "center",
                                  marginTop: "2px",
                                  color: "gray",
                                }}
                              >
                                No data
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <small className="text-danger">
                        {!businessDetails?.cityid && formError.cityid
                          ? formError.cityid
                          : ""}
                      </small>
                    </div>
                    {/* <div className="form-group date">
                      <label className="d-block mb-0">Servicing areas:</label>
                      <textarea
                        className="form-control"
                        placeholder="Servicing areas:cities,counties,states"
                        value={businessDetails.service_area}
                        onChange={(e) =>
                          setBusinessDetail({
                            ...businessDetails,
                            service_area: e.target.value,
                          })
                        }
                      ></textarea>
                        <small className="text-danger">
                          {!businessDetails?.service_area &&
                          formError.service_area
                            ? formError.service_area
                            : ""}
                        </small>
                      </div>
                      <div className="form-group date">
                        <label className="d-block mb-0">Services offered:</label>
                        <textarea
                        className="form-control"
                        placeholder="Services offered within your main service"
                        value={businessDetails.service_offer}
                        onChange={(e) =>
                          setBusinessDetail({
                            ...businessDetails,
                            service_offer: e.target.value,
                          })
                        }
                        ></textarea>

                        <small className="text-danger">
                          {!businessDetails?.service_offer &&
                          formError.service_offer
                            ? formError.service_offer
                            : ""}
                        </small>
                      </div>
                      */}

                    {/* <div className="row">
                        <div className="form-group date col-6">
                          <label className="d-block mb-0">Price/hour:</label>
                          <input
                            type="text"
                            className="form-control form-control-md"
                            placeholder="Price/hour"
                            value={businessDetails.pricehour}
                            onChange={(e) =>
                              setBusinessDetail({
                                ...businessDetails,
                                pricehour: e.target.value,
                              })
                            }
                            onKeyPress={(event) => {
                              if (!/[0-99]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          <small className="text-danger">
                            {!businessDetails?.pricehour && formError.pricehour
                              ? formError.pricehour
                              : ""}
                          </small>
                        </div>
                        <div className="form-group date col-6">
                        <label className="d-block mb-0">Price Type:</label>
                        <input
                          type="text"
                          className="form-control form-control-md"
                          placeholder="Price Type"
                          value={businessDetails.price_type}
                          onChange={(e) =>
                            setBusinessDetail({
                              ...businessDetails,
                              price_type: e.target.value,
                            })
                          }
                          onKeyPress={(event) => {
                            // if (!/[0-99]/.test(event.key)) {
                            //   event.preventDefault();
                            // }
                          }}
                        />
                        <small className="text-danger">
                          {!businessDetails?.price_type && formError.price_type
                            ? formError.price_type
                            : ""}
                        </small>
                      </div>
                      </div> */}

                    <div className="form-group">
                      {/* <label className="d-block mb-0">Business Phone:</label> */}
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Business Phone"
                        value={businessDetails.phone}
                        onChange={(e) =>
                          setBusinessDetail({
                            ...businessDetails,
                            phone: e.target.value,
                          })
                        }
                        maxLength={15}
                        onKeyPress={(event) => {
                          if (
                            !/[0-99]/.test(event.key) ||
                            event.target.value.length > 14
                          ) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <small className="text-danger">
                        {!businessDetails?.phone ||
                        (formError.phone)
                          ? formError.phone
                          : ""}
                      </small>
                    </div>
                    <div className="form-group">
                      {/* <label className="d-block mb-0">Business Email:</label> */}
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control form-control-md"
                          placeholder="Business Email"
                          value={businessDetails.email}
                          maxLength={50}
                          onChange={(e) =>
                            setBusinessDetail({
                              ...businessDetails,
                              email: e.target.value.trimStart(),
                            })
                          }
                          /* max="10"
                          onKeyPress={(event) => {
                            if (
                              !/[0-99]/.test(event.key) ||
                              event.target.value.length > 9
                            ) {
                              event.preventDefault();
                            }
                          }
                         } */
                        />
                      </div>
                      <small className="text-danger">
                        {!businessDetails?.email || formError.email
                          ? formError.email
                          : ""}
                      </small>
                    </div>

                    {(businessDetails?.plan_type == '2') ?
                    <>

                    <div className="form-group">
                     
                      <select
                        name="maritalstatus"
                        id="maritalstatus"
                        placeholder="Marital Status"
                        className="form-control form-control-md form-control-city"
                        value={businessDetails.year_revenue}
                        onChange={(e) =>
                          setBusinessDetail({
                            ...businessDetails,
                            year_revenue: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Yearly Revenue</option>
                        <option value="0 - $100,000">0 - $100,000</option>
                        <option value="$100,001 - $250,000">
                          $100,001 - $250,000
                        </option>
                        <option value="$250,001 - $500,000">
                          $250,001 - $500,000
                        </option>
                        <option value="$500,001 - $1,000,000">
                          $500,001 - $1,000,000
                        </option>
                        <option value="$1,000,001 - $10,000,000">
                          $1,000,001 - $10,000,000
                        </option>
                        <option value="$10,000,001 or More">
                          $10,000,001 or More
                        </option>
                      </select>

                      <small className="text-danger">
                        {!businessDetails?.year_revenue &&
                        formError.year_revenue
                          ? formError.year_revenue
                          : ""}
                      </small>
                    </div>

                    <div className="form-group">
                     
                      <select
                        placeholder="Head Count"
                        className="form-control form-control-md form-control-city"
                        value={businessDetails?.numemps}
                        onChange={(e) =>
                          setBusinessDetail({
                            ...businessDetails,
                            numemps: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Head Count</option>
                        <option value="0 - 1">0 - 1</option>
                        <option value="2 - 10">2 - 10</option>
                        <option value="11 - 50">11 - 50</option>
                        <option value="51 - 200">51 - 200</option>
                        <option value="201 - 500">201 - 500</option>
                        <option value="501 - 1,000">501 - 1,000</option>
                        <option value="1,001 - 5,000">1,001 - 5,000</option>
                        <option value="5,001 - 10,000">5,001 - 10,000</option>
                        <option value="10,001 or More">10,001 or More</option>
                      </select>

                      <small className="text-danger">
                        {!businessDetails?.numemps && formError.numemps
                          ? formError.numemps
                          : ""}
                      </small>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="file-upload"
                        className="custom-file-upload"
                        data-toggle="modal"
                        data-target="#showCertificate"
                      >
                        {businessDetails?.certificate != "" ? (
                          //businessDetails?.certificate
                          <>
                          {"View Certificate"}
                          
                          </>
                        ) : (
                          <>
                            <i className="fa fa-cloud-upload"></i>
                            Upload business License
                          </>
                        )}
                      </label>
                    
                     {/*  <div>
                        <p className="paymentmethodstypes1___">
                          <small
                            className="text-default mr-2"
                            style={{ fontSize: "11px" }}
                          >
                            Look at the image
                          </small>
                          <img
                            src="/images/icons/eye.svg"
                            className="fa-arrows fa__icons"
                            style={{ color: "#707070" }}
                            data-toggle="modal"
                            data-target="#showCertificate"
                          ></img>
                        </p>
                      </div> */}
                    </div>
                    </>
                    :
                    <div className="form-group" style={{"marginTop": "32px"}}>
                     <label className="d-block mb-0">Subscription Type:</label>
                      <div className="input-group">
                       <input
                          type="text"
                          className="form-control form-control-md"
                          placeholder="Subscription Effective Date"
                          value={businessDetails.plan_type == 1 ? "Free" : businessDetails.plan_type == 2 ? "Paid" : "No any Subscription"}
                          disabled={true}
                         
                        />
                      </div>
                     
                    </div>
                    }

                    

                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      {/* <label className="d-block mb-0">Price Model:</label> */}
                      <select
                        placeholder="Price/hour"
                        className="form-control form-control-md form-control-city"
                        value={businessDetails.pricemodel}
                        onChange={(e) =>
                          setBusinessDetail({
                            ...businessDetails,
                            pricemodel: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Price Model</option>
                        <option value="Hourly Rate">Hourly Rate</option>
                        <option value="Commission">Commission</option>
                        <option value="Fixed Price">Fixed Price</option>
                        <option value="Contact Us">Contact Us</option>
                      </select>

                      <small className="text-danger">
                        {!businessDetails?.pricemodel && formError.pricemodel
                          ? formError.pricemodel
                          : ""}
                      </small>
                    </div>
                    <div
                      className="form-group"
                      style={{ marginBottom: "12px" }}
                    >
                      <label className="d-block mb-0">Payment Methods:</label>
                      <div className="d-flex col-md-12">
                       <div className="col-md-4">
                        <label className="checkbox-payment w-100">
                          <input
                            type="checkbox"
                            className="checkbox-payment-input mr-2"
                            onClick={(e) =>
                              setPaymentmethods({
                                ...paymentmethods,
                                cash:
                                  paymentmethods?.cash &&
                                  paymentmethods?.cash == 1
                                    ? 0
                                    : 1,
                              })
                            }
                            checked={
                              paymentmethods?.cash && paymentmethods?.cash == 1
                                ? true
                                : false
                            }
                          />
                          Cash
                        </label>
                       
                        
                        <label className="checkbox-payment w-100">
                          <input
                            type="checkbox"
                            className="checkbox-payment-input mr-2"
                            onClick={(e) =>
                              setPaymentmethods({
                                ...paymentmethods,
                                cashapp:
                                  paymentmethods?.cashapp &&
                                  paymentmethods?.cashapp == 1
                                    ? 0
                                    : 1,
                              })
                            }
                            checked={
                              paymentmethods?.cashapp &&
                              paymentmethods?.cashapp == 1
                                ? true
                                : false
                            }
                          />
                          {/* Cash App */}
                          Check
                        </label>
                        
                        
                        <label className="checkbox-payment w-100">
                          <input
                            type="checkbox"
                            className="checkbox-payment-input mr-2"
                            onClick={(e) =>
                              setPaymentmethods({
                                ...paymentmethods,
                                creditcard:
                                  paymentmethods?.creditcard &&
                                  paymentmethods?.creditcard == 1
                                    ? 0
                                    : 1,
                              })
                            }
                            checked={
                              paymentmethods?.creditcard &&
                              paymentmethods?.creditcard == 1
                                ? true
                                : false
                            }
                          />
                          {/* Credit Card */}
                          Card
                        </label>
                        </div>
                        <div className="col-md-4">
                        <label className="checkbox-payment w-100">
                          <input
                            type="checkbox"
                            className="checkbox-payment-input mr-2"
                            onClick={(e) =>
                              setPaymentmethods({
                                ...paymentmethods,
                                paypal:
                                  paymentmethods?.paypal &&
                                  paymentmethods?.paypal == 1
                                    ? 0
                                    : 1,
                              })
                            }
                            checked={
                              paymentmethods?.paypal &&
                              paymentmethods?.paypal == 1
                                ? true
                                : false
                            }
                          />
                          Paypal
                        </label>
                        
                        <label className="checkbox-payment w-100">
                          <input
                            type="checkbox"
                            className="checkbox-payment-input mr-2"
                            onClick={(e) =>
                              setPaymentmethods({
                                ...paymentmethods,
                                zelle:
                                  paymentmethods?.zelle &&
                                  paymentmethods?.zelle == 1
                                    ? 0
                                    : 1,
                              })
                            }
                            checked={
                              paymentmethods?.zelle &&
                              paymentmethods?.zelle == 1
                                ? true
                                : false
                            }
                          />
                          Zelle
                        </label>
                        </div>
                      </div>
                    
                      <small className="text-danger">
                        {!paymentmethods?.cash &&
                        !paymentmethods?.cashapp &&
                        !paymentmethods?.creditcard &&
                        !paymentmethods?.paypal &&
                        !paymentmethods?.zelle &&
                        formError.payments
                          ? formError.payments
                          : ""}
                      </small>
                    </div>
                    <div className="form-group">
                      {/* <label className="d-block mb-0">Services:</label>  */}
                      <Multiselect
                        displayValue="key"
                        onKeyPressFn={function noRefCheck() {}}
                        onRemove={(e) => setSelectedService(e)}
                        onSearch={(e) => searchServiceListData(e)}
                        onSelect={(e) => setSelectedService(e)}
                        selectionLimit={plantype == "2" ? "3" : "1"}
                        //selectionLimit={"1"}
                        options={options}
                        selectedValues={selectedService}
                        style={{ backgroundColor: "red" }}
                        placeholder="Occupation"
                      />
                      <small className="text-danger">
                        {selectedService.length == 0 && formError.naics
                          ? formError.naics
                          : ""}
                      </small>
                    </div>

                    <div className="form-group">
                      {/* <label className="d-block mb-0">Industry:</label> */}
                      <div ref={wrapperRef}>
                        <input
                          type="text"
                          className="form-control"
                          id="industryId"
                          onChange={(e) =>
                            handleIndustryServiceSearch(e.target.value)
                          }
                          onBlur={(e) => manageSearchService(e.target.value)}
                          // onFocus={() => setIndustryListShow(true)}
                          // onBlur={() => handleIndustrySearchDrop()}
                          placeholder="Industry"
                          defaultValue={businessDetails?.industry_name}
                          autoComplete="off"
                        />
                        <div>
                          <div
                            className={`city_list_box ${
                              industryListShow ? "show" : "hide"
                            }`}
                          >
                            {industryList.length > 0 ? (
                              <ul className="city_list_menu">
                                {industryList.map((item, i) => {
                                  return (
                                    <li
                                      key={i}
                                      onClick={() => {
                                        setBusinessDetail({
                                          ...businessDetails,
                                          industry: item.naicsid,
                                        });
                                        handleSelectIndustry(item.title);
                                      }}
                                      className={`city_list ${
                                        businessDetails?.industry ==
                                        item.naicsid
                                          ? "active"
                                          : ""
                                      }`}
                                    >
                                      <a> {item.title}</a>{" "}
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : (
                              <p
                                style={{
                                  textAlign: "center",
                                  marginTop: "2px",
                                  color: "gray",
                                }}
                              >
                                No data
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* <select
                          onChange={(e) =>
                            setBusinessDetail({
                              ...businessDetails,
                              industry: e.target.value,
                            })
                          }
                          defaultValue={businessDetails?.industry}
                          className="form-control form-control-md "
                        >
                          {console.log("businessDetails.industry_name ==> mo", businessDetails.industry_name)}
                          <option value={businessDetails.industry}>
                            {businessDetails.industry_name}
                          </option>
                          {serviceList &&
                            serviceList.length > 0 &&
                            serviceList.map((item, i) => {
                              return (
                                <option value={item.naicsid}>{item.title}</option>
                              );
                            })}
                        </select> */}
                      <small className="text-danger">
                        {!businessDetails?.industry && formError.industry
                          ? formError.industry
                          : ""}
                      </small>
                    </div>
                    {(businessDetails?.plan_type == '2') ?
                    <>
                    <div className="form-group" style={{"marginTop": "2px"}}>
                     <label className="d-block mb-0">Subscription Type:</label>
                      <div className="input-group">
                       <input
                          type="text"
                          className="form-control form-control-md"
                          placeholder="Subscription Effective Date"
                          value={businessDetails.plan_type == 1 ? "Free" : businessDetails.plan_type == 2 ? "Paid" : "No any Subscription"}
                          disabled={true}
                         
                        />
                      </div>
                     
                    </div>

                    <div className="form-group" style={{"marginTop": "2px"}}>
                     <label className="d-block mb-0">Subscription Option:</label>
                      <div className="input-group">
                       <input
                          type="text"
                          className="form-control form-control-md"
                          placeholder="Subscription Effective Date"
                          value={businessDetails.plan_option == 1 ? "City" : businessDetails.plan_option == 2 ? "County" : 
                          businessDetails.plan_option == 3 ? "State" : 
                          businessDetails.plan_option == 4 ? "Country" :"No any Subscription"}
                          disabled={true}
                         
                        />
                      </div>
                     
                    </div>

                 
                   
                    <div className="form-group">
                     <label className="d-block mb-0">Subscription Effective Date:</label>
                      <div className="input-group">
                       <input
                          type="text"
                          className="form-control form-control-md"
                          placeholder="Subscription Effective Date"
                          value={moment(businessDetails.plan_start_date).format("YYYY-MM-DD")}
                          disabled={true}
                         
                        />
                      </div>
                     
                    </div>
                   
                    <div className="form-group">
                     <label className="d-block mb-0">Subscription Expiration Date:</label>
                      <div className="input-group">
                      <input
                          type="text"
                          className="form-control form-control-md"
                          placeholder="Plan Start Date"
                          value={moment(businessDetails.plan_expiry_date).format("YYYY-MM-DD")}
                          disabled={true}
                        />
                      </div>
                     
                    </div>
                    </> : <>
                    <div className="form-group">
                     
                     <select
                       name="maritalstatus"
                       id="maritalstatus"
                       placeholder="Marital Status"
                       className="form-control form-control-md form-control-city"
                       value={businessDetails.year_revenue}
                       onChange={(e) =>
                         setBusinessDetail({
                           ...businessDetails,
                           year_revenue: e.target.value,
                         })
                       }
                     >
                       <option value="">Select Yearly Revenue</option>
                       <option value="0 - $100,000">0 - $100,000</option>
                       <option value="$100,001 - $250,000">
                         $100,001 - $250,000
                       </option>
                       <option value="$250,001 - $500,000">
                         $250,001 - $500,000
                       </option>
                       <option value="$500,001 - $1,000,000">
                         $500,001 - $1,000,000
                       </option>
                       <option value="$1,000,001 - $10,000,000">
                         $1,000,001 - $10,000,000
                       </option>
                       <option value="$10,000,001 or More">
                         $10,000,001 or More
                       </option>
                     </select>

                     <small className="text-danger">
                       {!businessDetails?.year_revenue &&
                       formError.year_revenue
                         ? formError.year_revenue
                         : ""}
                     </small>
                   </div>

                   <div className="form-group">
                    
                     <select
                       placeholder="Head Count"
                       className="form-control form-control-md form-control-city"
                       value={businessDetails?.numemps}
                       onChange={(e) =>
                         setBusinessDetail({
                           ...businessDetails,
                           numemps: e.target.value,
                         })
                       }
                     >
                       <option value="">Select Head Count</option>
                       <option value="0 - 1">0 - 1</option>
                       <option value="2 - 10">2 - 10</option>
                       <option value="11 - 50">11 - 50</option>
                       <option value="51 - 200">51 - 200</option>
                       <option value="201 - 500">201 - 500</option>
                       <option value="501 - 1,000">501 - 1,000</option>
                       <option value="1,001 - 5,000">1,001 - 5,000</option>
                       <option value="5,001 - 10,000">5,001 - 10,000</option>
                       <option value="10,001 or More">10,001 or More</option>
                     </select>

                     <small className="text-danger">
                       {!businessDetails?.numemps && formError.numemps
                         ? formError.numemps
                         : ""}
                     </small>
                   </div>
                   <div className="form-group">
                     <label
                       htmlFor="file-upload"
                       className="custom-file-upload"
                       data-toggle="modal"
                        data-target="#showCertificate"
                     >
                       {businessDetails?.certificate != "" ? (
                         <>
                         {"View Certificate"}
                         {/* <img
                           src="/images/icons/eye.svg"
                           className="fa-arrows fa__icons"
                           style={{ color: "#707070" }}
                           data-toggle="modal"
                           data-target="#showCertificate"
                         ></img> */}
                         </>
                       ) : (
                         <>
                           <i className="fa fa-cloud-upload"></i>
                           Upload business License
                         </>
                       )}
                     </label>
                   
                    {/*  <div>
                       <p className="paymentmethodstypes1___">
                         <small
                           className="text-default mr-2"
                           style={{ fontSize: "11px" }}
                         >
                           Look at the image
                         </small>
                         <img
                           src="/images/icons/eye.svg"
                           className="fa-arrows fa__icons"
                           style={{ color: "#707070" }}
                           data-toggle="modal"
                           data-target="#showCertificate"
                         ></img>
                       </p>
                     </div> */}
                   </div>
                    </>
                    }
                   
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      {/* <label className="d-block mb-0">Website URL:</label> */}
                      <input
                        className="form-control form-control-md"
                        placeholder="Website URL"
                        maxLength={100}
                        defaultValue={businessDetails.websiteurl}
                        onBlur={(e) => isUrlCheck(e.target.value)}
                        // onChange={(e) => setFormData({
                        //     ...formData,
                        //     website: e.target.value.trimstart()
                        // })}
                        onChange={() => setWebUrlMsg("")}
                      />
                      <small
                        className="text-danger"
                        style={{ display: "block" }}
                      >
                        {webUrlMsg != ""
                          ? webUrlMsg
                          : formError.websiteurl
                          ? formError.websiteurl
                          : ""}
                      </small>
                      {/* <small className="text-danger">{webUrlMsg}</small> */}
                    </div>

                    <div className="form-group" style={{ marginBottom: "8px" }}>
                      {/* <label className="d-block mb-0">
                        Hours of operation:
                      </label> */}
                      <input
                        type="text"
                        className="form-control form-control-md"
                        placeholder="Hours of operation"
                        value={businessDetails.hours}
                        onChange={(e) =>
                          setBusinessDetail({
                            ...businessDetails,
                            hours: e.target.value.trimStart(),
                          })
                        }
                        /* onKeyPress={(event) => {
                          if (!/[0-99]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }} */
                      />
                      <div>
                        <p className="paymentmethodstypes1___">
                          Mon to Fri 10 AM to 8 PM
                        </p>
                      </div>
                      <small className="text-danger">
                        {!businessDetails?.hours && formError.hours
                          ? formError.hours
                          : ""}
                      </small>
                    </div>
                    <div className="morebgstyle">
                      <div style={{ marginLeft: "30px" }}>
                        <div
                          className="form-check"
                          style={{ marginTop: "10px" }}
                        >
                          {businessDetails?.showcall == 0 ? (
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onClick={(e) =>
                                setBusinessDetail({
                                  ...businessDetails,
                                  showcall: 1,
                                })
                              }
                              checked={
                                businessDetails.showcall == 0 ? false : true
                              }
                            />
                          ) : (
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onClick={(e) =>
                                setBusinessDetail({
                                  ...businessDetails,
                                  showcall: 0,
                                })
                              }
                              checked={true}
                            />
                          )}
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            Show Call Button
                          </label>
                        </div>
                        <div className="form-check">
                          {businessDetails?.showtext == 0 ? (
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onClick={(e) =>
                                setBusinessDetail({
                                  ...businessDetails,
                                  showtext: 1,
                                })
                              }
                              checked={
                                businessDetails.showtext == 0 ? false : true
                              }
                            />
                          ) : (
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onClick={(e) =>
                                setBusinessDetail({
                                  ...businessDetails,
                                  showtext: 0,
                                })
                              }
                              checked={
                                businessDetails.showtext == 0 ? false : true
                              }
                            />
                          )}
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            Show Text Button
                          </label>
                        </div>
                        <div className="form-check">
                          {businessDetails?.showemail == 0 ? (
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onClick={(e) =>
                                setBusinessDetail({
                                  ...businessDetails,
                                  showemail: 1,
                                })
                              }
                              checked={
                                businessDetails.showemail == 0 ? false : true
                              }
                            />
                          ) : (
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onClick={(e) =>
                                setBusinessDetail({
                                  ...businessDetails,
                                  showemail: 0,
                                })
                              }
                              checked={
                                businessDetails.showemail == 0 ? false : true
                              }
                            />
                          )}
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            Show Email Button
                          </label>
                        </div>
                        <div className="form-check">
                          {businessDetails?.is_nonprofit == 0 ? (
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onClick={(e) =>
                                setBusinessDetail({
                                  ...businessDetails,
                                  is_nonprofit: 1,
                                })
                              }
                              checked={
                                businessDetails.is_nonprofit == 0 ? false : true
                              }
                            />
                          ) : (
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onClick={(e) =>
                                setBusinessDetail({
                                  ...businessDetails,
                                  is_nonprofit: 0,
                                })
                              }
                              checked={
                                businessDetails.is_nonprofit == 0 ? false : true
                              }
                            />
                          )}
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            Non-Profit
                          </label>
                        </div>
                        <div className="form-check">
                          {businessDetails?.is_minority == 0 ? (
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onClick={(e) =>
                                setBusinessDetail({
                                  ...businessDetails,
                                  is_minority: 1,
                                })
                              }
                              checked={
                                businessDetails.is_minority == 0 ? false : true
                              }
                            />
                          ) : (
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onClick={(e) =>
                                setBusinessDetail({
                                  ...businessDetails,
                                  is_minority: 0,
                                })
                              }
                              checked={
                                businessDetails.is_minority == 0 ? false : true
                              }
                            />
                          )}
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            Minority
                          </label>
                        </div>
                      </div>
                      <hr></hr>
                      <div className="savechangesbtn__ mt-4">
                        {/* <p>Current Subscription</p>
                        <p>
                          {plantype == 1
                            ? "Self Employed"
                            : plantype == 2
                            ? "Business Owner"
                            : plantype == 3
                            ? "Active Investor"
                            : "No any active subscription"}
                        </p> */}
                        Click on here for send request to upgrade or degrade
                        your subscription{" "}
                        <a
                          className="btn btn-dark updatesub"
                          target="_blank"
                          href={`mailto:mbt@maxelit.com?&subject=Upgrade Subscription&body=Dear MAXBIZ Team:%0D%0A%0D%0APlease upgrade my subscription from FREE to PAID for which I authorize MAXELIT LLC to send me a payment request via Zelle to pay for a full year. My selected options is: <<SELECT YOUR OPTION ACCORDING TO YOUR PREFERENCE REACH -> CITY = $120/YR | COUNTY = $300/YR, STATE = $1,200/YR, COUNTRY = $12,000/YR>>.%0D%0A%0D%0ARegards,%0D%0A%0D%0A<<YOUR FULL NAME>>%0D%0A<<YOUR ZELLE EMAIL ADDRESS OR PHONE NUMBER>>`}
                        >
                          Click Here
                        </a>
                      </div>

                     {/*  <div className="savechangesbtn__ mt-4">
                        Click on here for share the app link with others by sending an email{" "}
                        <a
                          className="btn btn-dark updatesub"
                          target="_blank"
                          href={`mailto:mbt@maxelit.com?&subject=Check out MAXBIZ&body=Dear <<YOUR CONTACT'S NAME>>:%0D%0A%0D%0Please check out MAXBIZ, Get FREE Local Leads in your City and Save Thousands of dollars in Marketing! Visit https://maxbiz.com.%0D%0A%0D%0ARegards,%0D%0A%0D%0A<<YOUR FULL NAME>>%0D%0A<<YOUR PHONE NUMBER>>`}
                        >
                          Share
                        </a>
                      </div>

                      <div className="savechangesbtn__ mt-4">
                        Click on here for send us an email including any suspicious content(profile, job, offer, etc.){" "}
                        <a
                          className="btn btn-dark updatesub"
                          target="_blank"
                          href={`mailto:mbt@maxelit.com?&subject=Suspicious Content&body=Dear MAXBIZ Team:%0D%0A%0D%0APlease review the following suspicious content: <<INCLUDE PAGE, SUSPICIOUS CONTENT AND ANY OTHER RELEVANT DETAILS>>.%0D%0A%0D%0ARegards,%0D%0A%0D%0A<<YOUR FULL NAME>>%0D%0A<<YOUR PHONE NUMBER>>`}
                        >
                          Report
                        </a>
                      </div> */}

                      <div className="savechangesbtn__ mt-4 mb-1">
                        <button
                          id="submit"
                          type="button"
                          className="btn main-btn w-100"
                          style={{ minWidth: 160 }}
                          onClick={submitBusinessForm}
                        >
                          {proIsLoader ? (
                            <img
                              src="images/loader_white.gif"
                              style={{ width: "28px" }}
                            />
                          ) : (
                            "Save Changes"
                          )}
                        </button>
                      </div>
                    </div>
                   

                    
                    {/* <div className="business-vaildation-style">
                        <div className="form-check">
                          {businessDetails?.business_validation == 0 ? (
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={
                                businessDetails.business_validation == 0
                                  ? false
                                  : true
                              }
                            />
                          ) : (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  businessDetails.business_validation == 0
                                    ? false
                                    : true
                                }
                              />
                            )}
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            Business Validation
                        </label>
                        </div>


                      </div> */}
                  </div>
                </div>
              </form>
            ) : (
              <div style={{ textAlign: "center" }}>
                <img src="images/infinity.gif" className="listloadergif" />
              </div>
            )}

            <a
              href="#"
              id="sucessModelBtn"
              data-toggle="modal"
              data-target="#sucessModel"
            ></a>
            <div
              className="modal fade success--modal"
              id="sucessModel"
              //tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="container">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body mt-4 pt-3 pb-4">
                      <div className="form-group text-center">
                        <img
                          src="images/right_img.png"
                          style={{ width: "85px" }}
                        />
                        <p className="sucess_msg_title">
                          Thanks for updating your business profile.
                        </p>
                        <p className="d-block">
                          Your business profile has been updated successfully.
                          You can add other details of your business.
                        </p>
                      </div>
                    </div>
                    <div className="modal-footer" style={{ borderTop: "0px" }}>
                      {/* <button
                  type="button"
                  className="main-btn rounded"
                  style={{ minWidth: 160 }}
                  onClick={() => popupclose()}
                >OK</button> */}

                      <button
                        style={{ minWidth: 160 }}
                        type="button"
                        className="main-btn rounded"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal showVideo--modal"
              id="showCertificate"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="container">
                <div
                  className="modal-dialog  video--modal"
                  style={{ maxWidth: "550px" }}
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel"></h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                      <div className="progress-bar" />
                    </div>
                    <div
                      className="modal-body"
                      style={{ minHeight: "500px", width: "100%" }}
                    >
                      <div className="swiper-slide">
                        {bucketcertificate && bucketcertificate !== "" ? (
                          <iframe
                            style={{
                              display: "block",
                              width: "100%",
                              height: "700px",
                            }}
                            className="embed-responsive-item"
                            frameBorder="0"
                            // src="https://www.orimi.com/pdf-test.pdf"
                            // src={showFilePath}
                            src={bucketcertificate}
                            allowFullScreen
                          ></iframe>
                        ) : bucket_Img_url && bucket_Img_url !== "" ? (
                          <img src={bucket_Img_url} />
                        ) : (
                          <img src="images/nodatafound.png" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal showVideo--modal"
              id="showLogo"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="container">
                <div
                  className="modal-dialog  video--modal"
                  style={{ maxWidth: "550px" }}
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel"></h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                      <div className="progress-bar" />
                    </div>
                    <div
                      className="modal-body"
                      style={{ minHeight: "500px", width: "100%" }}
                    >
                      <div className="swiper-slide">
                        {bucket_Img_url && bucket_Img_url !== "" ? (
                          <img src={bucket_Img_url} />
                        ) : (
                          <img src="images/nodatafound.png" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="showOverview"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div
                    className="modal-header"
                    style={{ paddingTop: "8px", paddingBottom: "8px" }}
                  >
                    <h6
                      className="modal-title"
                      id="exampleModalLabel"
                      style={{ color: "#707070" }}
                    >
                      Overview/Introduction
                    </h6>
                    <img
                      src="/images/icons/arrow-b.svg"
                      className="fa-arrows fa__icons close"
                      style={{ color: "#707070" }}
                      data-dismiss="modal"
                      aria-label="Close"
                    ></img>
                  </div>
                  <div className="modal-body">
                    <textarea
                      className="form-control"
                      style={{ height: "300px" }}
                      placeholder="Overview/Introduction"
                      value={businessDetails.about}
                      onChange={(e) =>
                        setBusinessDetail({
                          ...businessDetails,
                          about: e.target.value,
                        })
                      }
                    ></textarea>
                  </div>
                  {/* <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="button" className="btn btn-primary">
                      Save changes
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src="images/infinity.gif" className="listloadergif" />
          </div>
        )}
      </div>
    </>
  );
}

export default PhotoList;
