import React from "react";
import { Link } from "react-router-dom";

const HeaderMenuPrincipal = (props,{ pathname }) => {
  return (
    <ul className="navbar-nav ml-auto">
     

      <li className="nav-item mr-4">
        <Link
          to="/job_list"
          onClick={() => {
            props.setMenushow(!props.menushow)
            pathname == "/job_list" ? window.location.reload() : null 
          }}
          className={`nav-link ${pathname == "/job_list" && "active"}`}
          style={{ padding: "0px 20px" }}
        >
          Jobs
        </Link>
      </li>
      <li className="nav-item mr-4">
        <Link
          to="/offer_list"
          onClick={() => {props.setMenushow(!props.menushow)
            pathname == "/offer_list" ? window.location.reload() : null 
          }}
          className={`nav-link ${pathname == "/offer_list" && "active"}`}
          style={{ padding: "0px 20px" }}
        >
          Offers
        </Link>
      </li>

       {/* <li className="nav-item mr-4" style={{ marginTop: "8px" }}>
        <a
          href="/home#benefits"
          className="nav-link"
          style={{ padding: "0px 20px" }}
        >
          Benefits
        </a>
      </li>
     <li className="nav-item mr-4">
        <a
          href="/home#testimonials"
          className="nav-link"
          style={{ padding: "0px 20px" }}
        >
          Why MAXBIZ
        </a>
      </li> */}
      <li className={"nav-item mr-4"}>
        <Link
           onClick={() => {
            props.setMenushow(!props.menushow)
           }}
          to="/join_us"
          className={`nav-link ${pathname == "/join_us" && "active"}`}
          style={{ padding: "0px 20px" }}
        >
          Join
        </Link>
      </li>
     {/*  <li className={"nav-item mr-4"}>
        <Link
          to="/contact"
          className={`nav-link ${pathname === "/contact" && "active"}`}
          style={{ padding: "0px 20px" }}
        >
          Contact
        </Link>
      </li> */}
      <li className="nav-item mr-4">
        <Link
           onClick={() => {
            props.setMenushow(!props.menushow)
           }}
          to="/login"
          className={`nav-link ${pathname == "/login" && "active"}`}
          style={{ padding: "0px 20px" }}
        >
          Login
        </Link>
      </li>
    </ul>
  );
};

export default HeaderMenuPrincipal;
