import { useState, useEffect } from "react";
import { apiCall } from "utils/httpClient";
import { successToast } from "utils/httpClient";
import apiEndPoints from "utils/apiEndPoints";
import FormInput from "./form-inputs";
import FormOtp from "./form-otp";
import FormThank from "./form-thank";

function Contact() {
  const [flag, setFlag] = useState(0);
  const [formMessage, setFormMessage] = useState({});
  const [otpmail, setOtpmail] = useState("");
  const [codeOtp, setCodeOtp] = useState("");
  const [requiredOTP, setRequiredOTP] = useState(false);
  const [errorOTP, setErrorOTP] = useState(false);

  useEffect(() => {
    sendEmailOTP();
  }, [formMessage]);

  const sendEmailOTP = async () => {
    
    const { data } = await apiCall(
      "POST",
      apiEndPoints.CONTACTSUPPORTOTP,
      formMessage
    );
    if (data.status == 200) {
      setCodeOtp(data.code);
      setFlag(data.flag);
    } else {
      //alert('error OTP');
    }
  };

  const sendEmailMessage = async () => {
    
    const { data } = await apiCall(
      "POST",
      apiEndPoints.CONTACTSUPPORTSENDEMAIL,
      formMessage
    );
    if (data.status == 200) {
      setFlag(data.flag);
    } else {
      alert("error send email");
    }
  };

  const resendEmailOTP = async () => {

    const { data } = await apiCall(
      "POST",
      apiEndPoints.CONTACTSUPPORTRESENDOTP,
      formMessage
    );
    if (data.status == 200) {
      setCodeOtp(data.code);
      setErrorOTP(false);
      setRequiredOTP(false);
      setOtpmail("");
      successToast("The code OTP was resend");
    } else {
      alert("error resend otp ");
    }
  };

  const handleConfirm = () => {
    if (otpmail == "") {
      setRequiredOTP(true);
      setErrorOTP(false);
      return;
    }
    setRequiredOTP(false);

    if (otpmail == codeOtp) {
      sendEmailMessage();
      setErrorOTP(false);
      setOtpmail("");
    } else {
      setErrorOTP(true);
      setOtpmail("");
    }
  };

  const handleResendCode = () => {
    resendEmailOTP();
  };

  const handleCancel = () => {
    setFlag(0);
    setRequiredOTP(false);
    setFormMessage({});
  };
  const handleNewRequest = () => {
    setFlag(0);
    setFormMessage({});
  };  

  return (
    <div className="default-smb-area bg--light page-contact">
      <div className="page-contact-content">
        <div className="page-contact-form">
          {flag == 0 && <FormInput setFormMessage={setFormMessage} />}
          {flag == 1 && (
            <FormOtp
              otpmail={otpmail}
              setOtpmail={setOtpmail}
              handleCancel={handleCancel}
              handleConfirm={handleConfirm}
              handleResendCode={handleResendCode}
              requiredOTP={requiredOTP}
              errorOTP={errorOTP}
            />
          )}
          {flag == 2 && <FormThank handleNewRequest={handleNewRequest} />}
        </div>
        <div className="page-contact-image">
          <img src="/images/person.svg"></img>
        </div>
      </div>
    </div>
  );
}

export default Contact;
