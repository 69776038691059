import React, { useState, useEffect } from "react";
import Slider from "common/slider";
import { Player } from "video-react";




function VideoSlider(props) {
  const { videoList,setShowTitle } = props;

  const { businessServiceDetail } = props;
  const [slectedTab, onChangeTab] = useState(0);
  const [slectedPopupType, onChangePopupType] = useState(0);
  const [url, setUrl] = useState("");

  // const [videoList, setVideoList] = useState();

  const showVideo = (url) => {
    setUrl(url);
  };
  
  return (
    <>
      <div className="card-body" style={{ marginBottom: "130px" }}>
        <div className="row align-items-center business-externo">
          <div className="col-md-12 bus-detail-cont">
            <div className="srvc--containt card-body p-0">
              <div className="row">
                <div className="main--srvc-slider">
                  {videoList?.length == 0 ? (
                    <>
                    <div className="text-center" style={{"backgroundColor" :"white","height":"400px"}}>
                      <img src="images/nodatafound.png" />
                       <span className="not-found-content">Ooops, no videos found</span>
                       </div>
                   </>
                  ) : (
                    <Slider
                      arrows='true'
                      SliderArray={videoList}
                      SliderItem={(item, i) => (
                        <div className="swiper-slide" key={i}>
                          <div className="slid--box">
                            <a
                              data-toggle="modal"
                              data-target="#showVideo"
                              onClick={() =>
                                {
                                  props.setShowFilePath(
                                  // props.documentUrl + item.item.filefile
                                  item.item.youtubeLink ? item.item.youtubeLink : item.item.aws_url
                                );
                                setShowTitle(item?.item?.title)
                              }}
                            >
                              <div className="slider--ser_img">
                                <div className="iconsize">
                                   <img src="images/videos.png" className="slid-img" /> 
                                  
                                </div>
                                <div className="show-title">{item?.item?.title}</div>
                              </div>
                            </a>
                          </div>
                        </div>
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </>
  );
}

export default VideoSlider;
