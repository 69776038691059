import React, { useEffect, useState } from "react";
import axios from 'axios'
import LoginScreen from "./component/login";
import { useHistory } from "react-router-dom";
import { useAuth } from "component/context/UserContext";
import {successToast, errorToast, apiCall, setDefaultHeader} from '../../../utils/httpClient'; 
import ApiEndPoint from '../../../utils/apiEndPoints'; 
import { useAuthData } from '../../../component/context/UserContext'
import {useDispatch, useSelector } from 'react-redux'
import { setUserAuthData, setBusinessRegisterDetail, handleLoginStatus} from '../../../reduxStore/action/usersActions'
function Login() {
  // const [userData, setUserData] = useAuthData();
  // console.log('userData:  dddd', userData);
  let history = useHistory();
  const { signIn } = useAuth(); 
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState({})
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
    ipaddress : ''
  });
  //for two factor
  const [tofactor, setTofactor] = useState(false);
  const [resentLoding, setResentLoding] = useState(false);
  const [otpmail, setOtpmail] = useState('');
  const [formErrorfact, setFormErrorfact] = useState({})
  const [profileid, setProfileid] = useState()
  //const [ipaddress, setIpaddress] = useState(null);

  
  const dispatch = useDispatch();
  // const userList = useSelector(state => state.users);
  const userData = useSelector(state => state.userData);

  const getData = async () => {
    /* const res = await axios.get('https://geolocation-db.com/json/')
    console.log(res.data);
    setLoginData({ ...loginData, ipaddress: res.data.IPv4 }) */

    fetch('https://api.ipify.org?format=json').then(response => {
      return response.json();
    }).then((res) => {
      console.log("🚀 ~ fetch ~ res:", res)
      //setIpaddress(_.get(res, 'ip'))
      setLoginData({ ...loginData, ipaddress: _.get(res, 'ip') })
      
      //this.myIp = _.get(res, 'ip');
    }).catch((err) => setLoginData({ ...loginData, ipaddress:"0.0.0.0" }))
  }

useEffect(() => {
   getData()
}, []);

  function formValidation() {
    let error = {};
    let formErrorData = false;

    const emailValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!loginData.email || emailValid.test(loginData.email) === false) {
      formErrorData = true;
      error['email'] = "Please Entery Valid Email address";
    }
    if (!loginData.password) {
      formErrorData = true;
      error['password'] = "Enter correct Password";
    }
    setFormError(error);
    return formErrorData;
  }

  async function handleAuth(params) {

    if (!formValidation()) {
      setIsLoading(true)

     
        var { data } = await apiCall('POST', ApiEndPoint.USERLOGIN, loginData)
        if(data.status == 200){
          setFormError(data.message)
          setIsLoading(false)
          if(data.data.businessValidated === 1){

            console.log("🚀 ~ handleAuth ~ data.data.subscriptionplan:", data.data)
            if(data.data.subscriptionplan === 1 || data.data.subscriptionplan === 2 ){
               
              if(data.data.two_factor_auth === 1){

                setProfileid(data.data?.profileid)
                submitforgotform()
                
              }else{
              dispatch(setUserAuthData(data.data));
              dispatch(handleLoginStatus({username: data.data.first_name, userType: 'business', token: data.token}));
              await localStorage.setItem('authToken', data.token)
              signIn(0, data.token, 'business')
              await setDefaultHeader('token', data.token)
              await localStorage.setItem('allinformation', data.data.allinformation)
              await localStorage.setItem('plan_type', data.data.plan_type)
              history.push("/");
              }  
            }else{

              dispatch(setUserAuthData(data.data));
              dispatch(handleLoginStatus({username: data.data.first_name, userType: 'business', token: data.token}));
              await localStorage.setItem('authToken', data.token)
              signIn(0, data.token, 'business')
              await setDefaultHeader('token', data.token)
              await localStorage.setItem('allinformation', data.data.allinformation)
              await localStorage.setItem('plan_type', data.data.plan_type)
              history.push("/");

             /* If need to online plan purchase */
             /*  localStorage.setItem("profile_id",data.data?.profileid)
               dispatch(setBusinessRegisterDetail(data.data))
              history.push("/subscriptionplan"); */

            }
           
          }else{
            
            dispatch(setBusinessRegisterDetail(data.data))
            errorToast(data.message?.messageTost)
            history.push("/join_us");
          }
        }else{
          setFormError(data.message)
          setIsLoading(false)
          errorToast(data.message?.messageTost)
        }
     
    }
  }

  async function submitforgotform() {
   
      setIsLoading(true)   
      const { data } = await apiCall('POST', ApiEndPoint.FORGOTPASSWORD, {email : loginData?.email,type:"TFA"})
      
      if(data.status == 200){
        setIsLoading(false)
        setTofactor(true)
      }else{
        setIsLoading(false)
        setFormError(data.message)
        errorToast(data.message?.messageTost);
      }
    }

  

  const handleResentOtp = async () => {
    setResentLoding(true);
    const params = {email: loginData?.email}
    const { data } = await apiCall('POST', ApiEndPoint.RESENTOTP, params)
    if(data.status == 200){
      setResentLoding(false)
      setFormError(data.message)
      setOtpmail(null)
    }else{
      setResentLoding(false)
      setFormError(data.message)
      errorToast(data.message?.messageTost);
    }
  }

  function validationotp() {
    let error = {};
    let validError = false;
    if (!otpmail) {
      validError = true;
      error['otpmail'] = "Email OTP is required";
    }

    setFormError(error);
    return validError;
  }



  const reloginbusiness = async () =>{
    setIsLoading(true)
       const parms = {
         profile_id : profileid,
         
        }
        const { data } = await apiCall('POST', ApiEndPoint.RELOGIN_BUSINESS, parms)
        console.log("🚀 ~ reloginbusiness ~ data:", data)
        
      if(data.status === 200){
        
        setIsLoading(false)
          if(data.data.businessValidated === 1){

            if(data.data.subscriptionplan === 1 ){

           

              dispatch(setUserAuthData(data.data));
              dispatch(handleLoginStatus({username: data.data.first_name, userType: 'business', token: data.token}));
              await localStorage.setItem('authToken', data.token)
              signIn(0, data.token, 'business')
              await setDefaultHeader('token', data.token)
              await localStorage.setItem('allinformation', data.data.allinformation)
              await localStorage.setItem('plan_type', data.data.plan_type)
              history.push("/");

            }else{

              dispatch(setUserAuthData(data.data));
              dispatch(handleLoginStatus({username: data.data.first_name, userType: 'business', token: data.token}));
              await localStorage.setItem('authToken', data.token)
              signIn(0, data.token, 'business')
              await setDefaultHeader('token', data.token)
              await localStorage.setItem('allinformation', data.data.allinformation)
              await localStorage.setItem('plan_type', data.data.plan_type)
              history.push("/");

             
             /*  localStorage.setItem("profile_id",data.data?.profileid)
               dispatch(setBusinessRegisterDetail(data.data))
              history.push("/subscriptionplan"); */

            }
           
          }else{
            
            dispatch(setBusinessRegisterDetail(data.data))
            errorToast(data.message?.messageTost)
            history.push("/join_us");
          }
        
      }else{
        setIsLoading(false)
        errorToast(data.message?.message);
      }
  
    }

  
  const twoFactverify = async () => {

    
    if (!validationotp()) {
      setIsLoading(true)
      const params = {otp: otpmail, email: loginData?.email}
      const { data } = await apiCall('POST', ApiEndPoint.VERIFYEDOTP, params)
      
      if(data.status == 200){
        setIsLoading(false)

        reloginbusiness()
        
      }else{
        setIsLoading(false)
        setFormError(data.message)
        errorToast(data.message?.messageTost);
      }
    }
  }


  


  return <LoginScreen 
  isLoading={isLoading} 
  handleAuth={handleAuth} 
  setFormError={setFormError} 
  formError={formError} 
  loginData={loginData} 
  setLoginData={setLoginData}
  setTofactor={setTofactor}
  tofactor={tofactor}

  otpmail = {otpmail}
  setOtpmail = {setOtpmail}
  setFormErrorfact = {setFormErrorfact}
  formErrorfact = {formErrorfact}
  handleResentOtp = {handleResentOtp}
  resentLoding = {resentLoding}
  twoFactverify = {twoFactverify}

  />;
}

export default Login;
