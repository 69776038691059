import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  useRouteMatch,
  useParams
} from "react-router-dom";
import ListScreen from "./component/index";
import DetailsScreen from "./component/details";

function Dashboard(props) {
  let { path, url } = useRouteMatch();
  const location = useLocation();
  const { pathname } = location;

  const [pageShow, setPageShow] = useState('details');

  return (
    <>
      <div style={{ marginBottom: "2rem" }}></div>
      <div className="container">
        <div className="row" style={{ marginTop: "0.5rem" }}>
          <div className="col-md-12">
            <div class="card card-primary">
              <div class="card-header d-flex justify-content-between align-items-center" style={{ backgroundColor: '#867df1', marginBottom: 20 }}>
                <h5 class="card-title" style={{ color: '#ffff', cursor: 'pointer' }}>
                  Business Detail
            </h5>
                <Link to="/" style={{ color: '#ffff', cursor: 'pointer' }}><button type="button" class="btn" style={{ backgroundColor: '#ffff', color: '#867df1', cursor: 'pointer' }}>{"<< Back"}</button>
                </Link>

              </div>
              <div class="card-body" style={{ padding: 0 }}>
                <DetailsScreen pageShow={pageShow} />

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
