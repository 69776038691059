
import React from 'react';
import './Marker.css';

const Marker = (props) => {
    const { color, name, id,icon } = props;
    return (
      <div className=""
        //style={{ backgroundColor: color, cursor: 'pointer'}}
        title={name}
      >
        <img style={{width:50,height:50}} src={icon}></img>
        </div>
    );
  };

  export default Marker;