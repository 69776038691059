import { Link } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import HomeVideo from "../../../../common/homevideo";
import OtpInput from "react18-input-otp";
// import CounterContextProvider, {CounterContext} from 'Context/CounterContext';

function Login(props) {
  const {
    tofactor,
    otpmail,
    setOtpmail,
    setFormError,
    formError,
    handleResentOtp,
    resentLoding,
    twoFactverify,
  } = props;

  const [emailVaild, setEmailVaild] = useState(true);
  function emailValidationCheck(email) {
    // console.log('props.loginData: ', props.loginData);
    const emailValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (emailValid.test(props.loginData.email) === true) {
      setEmailVaild(false);
    }
  }
  // useEffect(()=> {
  //   var input = document.getElementById("passwordField");
  //   input.addEventListener("keypress", function(event) {
  //     if (event.key === "Enter") {
  //       event.preventDefault();
  //       document.getElementById("submitLogin").click();
  //     }
  //   });
  // },[props.loginData?.password])
  return (
    <>
      <section className="default-smb-area bg--light">
        <div className="container">
          <div class="col-md-6" style={{ margin: "auto" }}>
            <div className="card border-8 shadow">
              <h5 className="card-header border-t-8 bg--smb text-center text-light fs-2 p-3">
                Welcome to MAXBIZ
              </h5>
              <div className="card-body">
                <div className="row align-items-center mt-4 mb-4">
                  <div className="col-lg-3"></div>
                  <div className="col-lg-12">
                    {/* <div className="login--form-box"> */}
                    {/* <div className="heading1">
                      <h2>
                        {!tofactor
                          ? "Login update"
                          : "Two Factor Authentication"}
                      </h2>
                    </div> */}
                    {!tofactor ? (
                      <form>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control input--email"
                                id="formGroupExampleInput"
                                placeholder="Email"
                                value={props.loginData?.email}
                                onBlur={(e) =>
                                  emailValidationCheck(e.target.value)
                                }
                                onChange={(e) => {
                                  props.setLoginData({
                                    ...props.loginData,
                                    email: e.target.value,
                                  });
                                  props.setFormError({
                                    ...props.formError,
                                    email: "",
                                  });
                                }}
                              />
                              <small className="text-danger">
                                {props.formError.email
                                  ? props.formError.email
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <input
                                type="password"
                                className="form-control input--password"
                                id="formGroupExampleInputpass"
                                // id='passwordField'
                                placeholder="Password"
                                value={props.loginData?.password}
                                onChange={(e) => {
                                  props.setLoginData({
                                    ...props.loginData,
                                    password: e.target.value,
                                  });
                                  props.setFormError({
                                    ...props.formError,
                                    password: "",
                                  });
                                }}
                              />
                              <small className="text-danger">
                                {props.formError.password
                                  ? props.formError.password
                                  : ""}
                              </small>
                            </div>
                            <Link to="/forgot_password" className="text-forgot">
                              Forgot password?
                            </Link>

                            {/* <a href="/forgot_password" className="text-forgot">
                              Forgot password?
                            </a> */}
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group text-center">
                              <button
                                onClick={() => props.handleAuth()}
                                type="button"
                                className="btn main-btn mt-4 w-100"
                              >
                                {props.isLoading ? (
                                  <img
                                    src="images/loader_white.gif"
                                    style={{ width: "28px" }}
                                  />
                                ) : (
                                  "Login"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    ) : (
                      <form>
                        <div className="row">
                          <div className="otp-container mt-2">
                            <OtpInput
                              value={otpmail}
                              onChange={(e) => {
                                setOtpmail(e);
                                setFormError({});
                              }}
                              numInputs={4}
                              separator={<span>&#160;</span>}
                            />
                            <small className="text-danger">
                              {formError.otpmail ? formError.otpmail : ""}
                              {formError?.messageError &&
                                formError.messageError}
                            </small>
                            <small className="text-success">
                              {formError?.messageSuccess &&
                                formError.messageSuccess}
                            </small>
                            <div style={{ marginTop: "0.5rem" }}>
                              <span
                                onClick={() => handleResentOtp()}
                                className="text-forgot"
                              >
                                {" "}
                                {resentLoding ? (
                                  <img
                                    src="images/loader_dark.gif"
                                    style={{ width: "30px" }}
                                  />
                                ) : (
                                  "Resend One Time Password"
                                )}
                              </span><br />
                              <div className="check-email-inbox">
                                <span>
                                  We've sent you a 4 digit code to your Inbox,
                                  please enter it to validate your email. Check
                                  also your Junk, Spam or Trash folder.
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group text-center ">
                              <button
                                onClick={() => twoFactverify()}
                                type="button"
                                className="btn main-btn mt-4 w-100"
                              >
                                {props.isLoading ? (
                                  <img
                                    src="images/loader_white.gif"
                                    style={{ width: "28px" }}
                                  />
                                ) : (
                                  "Verify"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                    <div
                      className="text-center login--containt"
                      style={{ marginBottom: "-22px" }}
                    >
                      <p>
                        New user
                        <Link
                          to="/join_us"
                          className="signup--link"
                          style={{ marginLeft: 5 }}
                        >
                          Register or signup with
                        </Link>
                      </p>
                    </div>
                    {/* </div> */}
                  </div>
                  <div className="col-lg-3"></div>
                  {/*  <div className="col-lg-6 hiddenVideo">
                  <HomeVideo />
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
