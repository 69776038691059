import OtpInput from "react18-input-otp";
import Error from "./Error";

const FormOtp = ({
  otpmail,
  setOtpmail,
  handleCancel,
  handleConfirm,
  handleResendCode,
  requiredOTP,
  errorOTP,
}) => {
  return (
    <>
      <div className="page-contact-form__header">Enter you code</div>
      <div className="page-contact-form__body page-form-otc">
        <img
          src="/images/contact-phone.svg"
          className="page-form-otc-phone mt-2 mb-3"
        ></img>
        <p className="page-form-otc-description text-center">
          Enter the code that we sent to your email to validate your query
        </p>

        <div className="page-form-otc-code">
          <p className="page-form-otc-description" style={{ fontSize: "16px" }}>
            Enter your email OTP
          </p>
          {requiredOTP && <Error message="Code OTP is required" />}
          {errorOTP && <Error message="OTP is not matched enter correct OTP." />}
          <div className="page-form-otc-code-input">
            <div className="otp-container mt-2">
              <OtpInput
                value={otpmail}
                onChange={(e) => {
                  setOtpmail(e);
                  // setFormError({});
                }}
                numInputs={4}
                separator={<span>&#160;</span>}
              />
            </div>
            <button
              className="main-btn rounded"
              onClick={() => handleResendCode()}
            >
              <p style={{ fontSize: "18px" }}>Resend Code</p>
            </button>
          </div>
        </div>

        <div className="form-group mb-0 page-form-otc-buttons">
          <button
            className="btn main-btn rounded w-50"
            onClick={() => handleCancel()}
          >
            Cancel
          </button>
          <button
            className="btn main-btn rounded w-50"
            onClick={() => handleConfirm()}
          >
            Confirm
          </button>
        </div>
      </div>
    </>
  );
};

export default FormOtp;
