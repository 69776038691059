import React, { useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';

export const PrivateRoute = ({ children, loginState, ...rest }) => {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                loginState.userToken !== null ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/home",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

export const PublicRoute = ({ component: Component, loginState, restricted, ...rest }) => {
    return (
        <Route {...rest} render={props => (
            (loginState.userToken !== null) && restricted ?
                <Redirect to="/" />
                :
                <Component {...props} />
        )} />
    );
};

export const ScrollToTop = ({ children }) => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return children || null;
};