import React, { useRef, useEffect } from "react";
import Slider from "common/slider";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Link, useLocation } from "react-router-dom";
import base64 from "base-64";
import HomeVideo from "../../../common/homevideo";
import Footer from "../../../common/footer";
function Home(props) {
  // const { handleCitySearch, handleSelectCity, setCityListShow, cityListShow, searchCityList, businessDetails } = props()
  const wrapperRef = useRef(null);
  const wrapperRefService = useRef(null);
  let history = useHistory();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        props.setCityListShow(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
  }, [wrapperRef]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRefService.current &&
        !wrapperRefService.current.contains(event.target)
      ) {
        props.setServiceListShow(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
  }, [wrapperRefService]);

  const datatopservices = props.topService ? props.topService : [];

  const cityOptions =
    props.citiesList?.length > 0 &&
    props.citiesList.map((item, i) => {
      return { value: item.id, label: item.city };
    });
  const serviceOptions =
    props?.serviceList.length > 0
      ? props?.serviceList.map((item, i) => {
        return { value: item.naicsid, label: item.title };
      })
      : [];

  return (
    <section className="landing-smb-area bg--light">
      <div className="about__mainpage">
        <div className="">
          <div
            className="row align-items-center fondo-imagen"
            style={{ marginRight: "15px", marginLeft: "8px" }}
          >
            <div
              className="container row section-one"
              style={{ flexWrap: "wrap-reverse" }}
            >
              <div className="col-lg-6 mt-2">
                <div>
                  <div className="heading1 text-center">
                    <h2>Find Local Businesses</h2>
                  </div>
                  <hr
                    style={{
                      padding: "2px",
                      borderRadius: "4px",
                      color: "#6258D3",
                    }}
                  ></hr>
                  <div className="formdiv">
                    <form className="forminline">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group setform">
                            <label className="Form--lbl">Occupation</label>
                            <div ref={wrapperRefService}>
                              <input
                                type="text"
                                className="form-control"
                                id="serviceSearch"
                                onChange={(e) => {
                                  props.handleServiceSearch(
                                    e.target.value.trimStart()
                                  );
                                  props.setSearchServices(
                                    e.target.value.trimStart()
                                  );
                                }}
                                placeholder="Electricians, Plumbers, Lawyers"
                                autoComplete="off"
                              />
                              <div>
                                <div
                                  className={`city_list_box ${props.serviceListShow ? "show" : "hide"
                                    }`}
                                >
                                  {props.searchServiceList &&
                                    props.searchServiceList.length > 0 ? (
                                    <ul className="city_list_menu">
                                      {props.searchServiceList.map(
                                        (item, i) => {
                                          return (
                                            <li
                                              onClick={() => {
                                                props.setServiceData({
                                                  ...props.serviceData,
                                                  service: item.naicsid,
                                                  servicename: item.title,
                                                });
                                                props.handleSelectService(
                                                  item.title
                                                );
                                              }}
                                              className={`city_list ${props.serviceData?.service ==
                                                  item.naicsid
                                                  ? "active"
                                                  : ""
                                                }`}
                                            >
                                              {item.title}
                                            </li>
                                          );
                                        },
                                        []
                                      )}
                                    </ul>
                                  ) : (
                                    <p
                                      style={{
                                        textAlign: "center",
                                        marginTop: "2px",
                                        color: "gray",
                                      }}
                                    ></p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <small className="text-danger">
                              {!props.serviceData?.service &&
                                props.formError.service
                                ? props.formError.service
                                : ""}
                            </small>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group cityname">
                            <label className="Form--lbl">City</label>
                            <div ref={wrapperRef}>
                              <input
                                type="text"
                                className="form-control"
                                id="citySearch"
                                onChange={(e) => {
                                  props.handleCitySearch(e.target.value);
                                }}
                                placeholder="Boca Raton, Miami, Orlando"
                                autoComplete="off"
                              />
                              <div>
                                <div
                                  className={`city_list_box ${props.cityListShow ? "show" : "hide"
                                    }`}
                                >
                                  {props.searchCityList &&
                                    props.searchCityList.length > 0 ? (
                                    <ul className="city_list_menu">
                                      {props.searchCityList.map((item, i) => {
                                        return (
                                          <li
                                            onClick={() => {
                                              props.setServiceData({
                                                ...props.serviceData,
                                                city: item.id,
                                                cityname:
                                                  item.city +
                                                  ", " +
                                                  item.state_id,
                                              });
                                              props.setCityListShow(false);
                                              props.handleSelectCity(
                                                item.city,
                                                item.state_id
                                              );
                                            }}
                                            className={`city_list ${props.serviceData?.city == item.id
                                                ? "active"
                                                : ""
                                              }`}
                                          >
                                            {item.city}, {item.state_id}
                                          </li>
                                        );
                                      }, [])}
                                    </ul>
                                  ) : (
                                    <p
                                      style={{
                                        textAlign: "center",
                                        marginTop: "2px",
                                        color: "gray",
                                      }}
                                    ></p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <small className="text-danger">
                              {!props.serviceData?.city && props.formError.city
                                ? props.formError.city
                                : ""}
                            </small>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="servicebtn">
                    <button
                      onClick={() => props.navToList()}
                      type="button"
                      className="btn main-btn w-100"
                    >
                      Search Businesses
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                {/* <HomeVideo /> */}

                <div className="card border-8 shadow mt-4">
                  <h5 className="card-header border-t-8 bg--smb"></h5>
                  <div className="card-body text-center mt-2 mb-2">
                    <div className="row align-items-center">
                      <div className="col-lg-2 col-md-2 col-sm-2 col-2 text-center">
                        <i
                          className="fa fa-rocket text-default "
                          style={{ fontSize: "50px" }}
                        ></i>
                      </div>
                      <div className="col-lg-10 col-md-10 col-sm-10 col-10 text-left">
                        <p className="card-text card-text-rocket ">
                          Get FREE Local Leads in your City and Save Thousands of dollars in
                          Marketing!
                        </p>
                      </div>
                    </div>
                    <div className="row align-items-center mt-4">
                      <div className="col-md-12">
                        <button
                          type="button"
                          onClick={() => history.push("/join_us")}
                          className="btn btn-dark w-100"
                        >
                          Subscribe Your Business for FREE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a id="benefits"></a>
          <div className="container__benefits">
            <div className="container">
              <div className="row align-items-center">
                <p className="benefits__title-p">MAXBIZ Advantage</p>
                <p className="benefits__subtitle-p">
                Tired of burning money on online platforms and offline entities? MAXBIZ helps Small Business Owners Save Thousands of Dollars in Marketing - Join MAXBIZ today for FREE!
                </p>
              </div>

              <div className="row align-items-center">
                <div className="row d-flex justify-content-evenly m-auto">
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="card border-8 shadow mt-4">
                      <div className="card-body text-center mt-2 benefits-card">
                        <div className="row align-items-center">
                          <div className="col-md-12">
                            <i className="fa fa-lightbulb text-default display-2"></i>
                          </div>
                        </div>
                        <div className="row align-items-center mt-4">
                          <div className="col-md-12">
                            <p className="benefits__card-badge">EASIER</p>
                            <p className="benefits__card--p">
                              Avoid zip codes and answering <br />so many confusing <br />
                              questions
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="card border-8 shadow mt-4">
                      <div className="card-body text-center mt-2 benefits-card">
                        <div className="row align-items-center">
                          <div className="col-md-12">
                            <i className="fa fa-rocket text-default display-2"></i>
                          </div>
                        </div>
                        <div className="row align-items-center mt-4">
                          <div className="col-md-12">
                            <p className="benefits__card-badge">FASTER</p>
                            <p className="benefits__card--p">
                              Call, text or email a small <br /> business and contact
                              them right <br /> away
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="card border-8 shadow mt-4">
                      <div className="card-body text-center mt-2 benefits-card">
                        <div className="row align-items-center">
                          <div className="col-md-12">
                            <i className="fa fa-sack-dollar text-default display-2"></i>
                          </div>
                        </div>
                        <div className="row align-items-center mt-4">
                          <div className="col-md-12">
                            <p className="benefits__card-badge">CHEAPER</p>
                            <p className="benefits__card--p">
                            IT'S FREE! Stop paying thousands of dollars for Clicks and Leads
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center mt-4">
                <div className="col-md-6 m-auto">
                  <button
                    type="button"
                    onClick={() => history.push("/join_us")}
                    className="btn btn-dark w-100"
                    style={{ marginTop: "30px", marginBottom: "50px" }}
                  >
                    Subscribe Your Business for FREE
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="container__testimonials" style={{ marginTop: "10px" }}>
            <div className="container" style={{ paddingBottom: "60px" }}>
              <div className="row align-items-center">
                <p className="benefits__title-p">MAXBIZ Background</p>
                <p className="benefits__subtitle-p">
                Learn more about why MAXELIT built MAXBIZ for Small Business Owners in United States of America.
                </p>
              </div>
              <div
                id="carouselExampleCaptions"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="0"
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="card border-8">
                      <div className="card-body text-center benefits-card">
                        <p>
                          <img
                            src="images/images/maxelit.jpg"
                            alt="imagen-maxelit"
                            height="50px"
                            width="auto"
                          />
                          {/* <i
                            className="fa fa-user"
                            style={{ fontSize: "50px" }}
                          ></i> */}
                        </p>
                        <p>MAXELIT</p>
                        <p style={{"textAlign": "start"}}>
                        After spending dozens of thousands of dollars in marketing on both online platforms and offline entities MAXELIT learned all these efforts don't deliver the expected returns on investment. Examples of online platforms are social media, search engines and lead generation websites. Examples of offline entities are chambers of commerce, business nonprofit organizations, international business networks, lead generation and meetups groups where a lot of face-to-face time and costly memberships are invested without obtaining significant results.

                          <br />
                          <br />
                          Small Business Owners are spending thousands of dollars in marketing on these online platforms and offline entities expecting to get good leads and grow their businesses. These online platforms and offline entities are  becoming richer while making it unfairly harder to succeed for Small Business Owners who are the Backbone of USA. They are becoming the modern Robber Barons while controlling and monopolizing their platforms or entities solely for their own benefit.
                          <br />
                          <br />
                          MAXELIT launched MAXBIZ focused on helping Small Business Owners strive in these financial dark times while making progress towards their individual business goal and objectives. MAXBIZ is Free of use for legitimate Small Business Owners with upgrade options to Paid subscriptions, also will be supported by Corporate America through different collaboration channels.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="carousel-item">
                    <div className="card border-8">
                      <div className="card-body text-center benefits-card">
                        <p>
                          <i
                            className="fa fa-user"
                            style={{ fontSize: "50px" }}
                          ></i>
                        </p>
                        <p>Testimonial 2</p>
                        <p>
                          "Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Vestibulum convallis risus felis, vitae eleifend
                          est volutpat ut. Fusce ac porttitor nunc. Morbi et
                          diam egestas, pellentesque tortor et, venenatis
                          lectus. Suspendisse porttitor risus eget consectetur
                          scelerisque. Etiam et est"
                        </p>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="carousel-item">
                    <div className="card border-8">
                      <div className="card-body text-center benefits-card">
                        <p>
                          <i
                            className="fa fa-user"
                            style={{ fontSize: "50px" }}
                          ></i>
                        </p>
                        <p>Testimonial 3</p>
                        <p>
                          "Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Vestibulum convallis risus felis, vitae eleifend
                          est volutpat ut. Fusce ac porttitor nunc. Morbi et
                          diam egestas, pellentesque tortor et, venenatis
                          lectus. Suspendisse porttitor risus eget consectetur
                          scelerisque. Etiam et est".
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>
               {/*  <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button> */}
               {/*  <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide="next"
                >
                   <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span> 
                </button> */}
              </div>
            </div>
          </div>



          <a name="testimonials"></a>


          <div className="container__mobile">
            <div className="container">
              <div className="row align-items-center mt-5" style={{ marginBottom: "1rem" }}>
                <div className="col-lg-6 text-center">
                  <img
                    className=""
                    src="images/HomeMobile-smb.png"
                    style={{ height: "400px" }}
                  />
                </div>
                <div className="col-lg-6 mobiledownload">
                  <div className="">
                    <i className="fa fa-bullhorn display-2"></i>
                  </div>
                  <div style={{ width: "60%" }}>
                    <h1>MOBILE APP</h1>
                    <h4>Coming soon</h4>
                    <span style={{ fontSize: 13 }}>
                      You can use MAXBIZ application in your Mobile Device.
                      Download Apps.
                    </span>
                  </div>
                  <div>
                    <img className="Download-icon" src="images/appstore.png" />
                    <img
                      className="Download-icon androidicon"
                      src="images/playstore.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container__footer">
            {/*  {pathname == "/home" ? (
              <div className="row align-items-center fondo-imagen-footer">
                <div className="row section-one">
                  <div className="col-lg-6 text-center">
                    <p>
                      <Link to="/contact" className="mr-4 text-white">
                        <i className="fa fa-envelope"></i> Contact Support
                      </Link>
                    </p>
                    <p>
                      <Link
                        to="#"
                        className="mr-4 text-white"
                        onClick={(e) => {
                          window.location = `tel:561-877-1717`;
                          e.preventDefault();
                        }}
                      >
                        <i className="fa fa-phone"></i> 561-877-1717
                      </Link>
                    </p>
                    <p>
                      <Link to="/privacy_policy" className="mr-4 text-white">
                        <i className="fa fa-shield-check"></i> Privacy Policy
                      </Link>
                    </p>
                    <p>
                      <a
                        href="https://maxelit.com/"
                        target="_blank"
                        className="mr-4 text-white"
                      >
                        Powered by MAXELIT LLC
                      </a>
                    </p>

                  
                  </div>
                  <div className="col-lg-6 text-center">
                  
                  </div>
                </div>
              </div>
            ) : (
              <Footer />
            )} */}

          </div>

        </div>
      </div>
      {/* <Footer/> */}

    </section>
  );
}

export default Home;
