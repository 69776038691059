import React, { useState } from "react";
import { apiCall, errorToast } from "utils/httpClient";
import ApiEndPoint from "utils/apiEndPoints";
import { useHistory } from "react-router-dom";
function Changepassword(props) {
  let history = useHistory();
  console.log("🚀 ~ changepassword ~ props:", props)
  const { setCheckFirstLogin } = props
  const [passwordData, setPasswordData] = useState({
    newpassword: "",
    confirmpassword: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(props?.email);
  const [passwordError, setPasswordError] = useState({});
  const [enterotp, setEnterotp] = useState(2);



  function validationpassword() {
    let error = {};
    let validError = false;
    if (!passwordData?.newpassword) {
      validError = true;
      error["newpassword"] = "New Password is required!";
    } else if (passwordData?.newpassword.length < 6 || passwordData?.newpassword.length > 15) {
      validError = true;
      error["newpassword"] = "New Password Shuld be between 6 to 15 Charactor!";
    } else if (!passwordData?.confirmpassword) {
      validError = true;
      error["confirmpassword"] = "Confirm Password is required!";
    } else if (passwordData?.newpassword !== passwordData?.confirmpassword) {
      validError = true;
      error["passwordnotmatch"] = "Confirm Password not match !";
    } else {
      validError = false;
    }
    setPasswordError(error);
    return validError;
  }

  const changepassword = async () => {

    if (!validationpassword()) {
      setIsLoading(true);
      const params = {
        password: passwordData?.newpassword,
        email: email,
      };
      console.log("🚀 ~ changepassword ~ params:", params)
      const { data } = await apiCall(
        "POST",
        ApiEndPoint.PASSWORDUPDATE,
        params
      );

      if (data.status == 200) {
        setIsLoading(false);
        setCheckFirstLogin(2)
      } else {
        setIsLoading(false);
        errorToast(data.message);
      } 
    }
  };


  return (
    <>
      <section className="default-smb-area bg--light">
        <div className="container ">
          <div className="row justify-content-center">
            {enterotp == 2 ? (
              <div className="col-lg-6">
                <div className="login--form-box Larger shadow bg-white">
                  <div className="heading1">
                    <h3>Change Your Password</h3>
                  </div>
                  <p></p>
                  <div className="formdiv">
                    <form className="forminline">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group setform">
                            <label style={{ fontWeight: "bold" }}>
                              New Password:
                            </label>
                            <input
                              type="password"
                              className="form-control input--email"
                              id="formGroupExampleInput"
                              placeholder="New Password"
                              onChange={(e) => {
                                setPasswordData({
                                  ...passwordData,
                                  newpassword: e.target.value,
                                });
                              }}
                              min = "6"
                            />
                            <small className="text-danger">
                              {passwordError.newpassword
                                ? passwordError.newpassword
                                : ""}
                            </small>
                          </div>

                          <div className="form-group setform">
                            <label style={{ fontWeight: "bold" }}>Confirm Password</label>
                            <input
                              type="password"
                              className="form-control input--email"
                              id="formGroupExampleInput"
                              placeholder="Confirm Password"
                              onChange={(e) => {
                                setPasswordData({
                                  ...passwordData,
                                  confirmpassword: e.target.value,
                                });
                                setPasswordError({
                                  ...passwordError,
                                  passwordnotmatch: "",
                                  confirmpassword: "",
                                });
                              }}
                            />
                            <small className="text-danger">
                              {!passwordData?.confirmpassword &&
                                passwordError.confirmpassword
                                ? passwordError.confirmpassword
                                : ""}
                            </small>
                            <small className="text-danger">
                              {passwordError.passwordnotmatch
                                ? passwordError.passwordnotmatch
                                : ""}
                            </small>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group text-center ">
                            <button
                              onClick={() => changepassword()}
                              type="button"
                              className="btn btn-lg main-btn mt-4"
                              style={{ width: 195 }}
                            >
                              {isLoading ? (
                                <img
                                  src="images/loader_white.gif"
                                  style={{ width: "30px" }}
                                />
                              ) : (
                                "Change Password"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            ) : (
              null
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Changepassword;
