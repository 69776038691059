import React, { useState } from "react";
import ApiEndPoint from "utils/apiEndPoints";
import { apiCall } from "utils/httpClient";

export const SearchContext = React.createContext();

export const SearchServiceProvider = (props) => {
  const [searchArray, setSearchArray] = useState([]);
  const [searchError, setSearchError] = useState({});

  const onSearch = {
    searchData: searchArray,
    searchService: async (scheme) => {
      var params = {
        searchService: "COM",
        searchCity: "Kil",
      };
      const { data } = await apiCall("GET", ApiEndPoint.searchService, params);
      //console.log("SearchServiceProvider -> data", data)
      if (data.status === 200) {
        setSearchArray([{}, {}]);
      } else {
      }
    },
  };
  return (
    <SearchContext.Provider value={onSearch}>
      {props.children}
    </SearchContext.Provider>
  );
};


export const useSearch = () => {
  return React.useContext(SearchContext);
};
