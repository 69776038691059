
import React, { useState, useEffect } from 'react';
import { apiCall, errorToast, setDefaultHeader } from 'utils/httpClient';
import ApiEndPoint from 'utils/apiEndPoints';
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setUserAuthData, setBusinessRegisterDetail, handleLoginStatus } from '../../../reduxStore/action/usersActions'
import { useAuth } from "component/context/UserContext";

function PayalCancel(props) {
    const search = useLocation().search;
    const token = new URLSearchParams(search).get('token');
    let history = useHistory();
    const [subscriptiondata, setSubscriptiondata] = useState({});

    const [isLoading, setIsLoading] = useState(false);
    const [succeeded, setSucceeded] = useState(false);
    const { signIn } = useAuth();
    const dispatch = useDispatch();

    useEffect(() => {



        const subscriptiondatalocal = JSON.parse(localStorage.getItem("subscriptiondata") || {})
        //console.log("Redirect -> subscriptiondatalocal", subscriptiondatalocal)
        if (!subscriptiondatalocal) {
            history.push('/')
        } else {
            //purchaseplan(subscriptiondatalocal)
            setSubscriptiondata(subscriptiondatalocal)
           

        }
    }, []);

    const purchaseplan = async (subscriptiondata) => {
        console.log('returndata: ', subscriptiondata);
        setIsLoading(true)
        const parms = {
            profile_id: subscriptiondata?.profile_id,
            plan_type: subscriptiondata?.plan_type,
            token: token,
        }
        console.log("purchaseplan -> parms", parms)
        const { data } = await apiCall('POST', ApiEndPoint.PURCHASEPLAN, parms)

        if (data.status === 200) {
            setIsLoading(false)

        } else {
            setIsLoading(false)
            errorToast(data.message?.message);
        }

    }


    return (
        <>
            <section className="about-us-area pb-10 bg-light hero--mrgn-top">
                <div className="about__mainpage">
                    <div className="container">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body mt-4 pt-3 pb-4">
                                    <div className="form-group text-center">
                                        <img src="images/crose-icon-image2.png" style={{ width: "85px" }} />
                                        <p className="sucess_msg_title" style={{ marginTop: "20px" }}>
                                            <h3>Your Subscription has been canceled.</h3>
                                        </p>
                                        <p className="d-block">
                                            Click on continue button for select another one subscription
                          </p>
                                    </div>
                                </div>
                                <div className="modal-footer" style={{ borderTop: "0px" }}>
                                   <Link to="/subscriptionplan"
                                        //href="/login"
                                        type="button"
                                        className="main-btn rounded"
                                        //onClick={() => reloginbusiness()}
                                        style={{ minWidth: 150 }}

                                    >{isLoading ? <img src="images/loader_white.gif" style={{ width: '28px' }} /> : 'Continue'}

                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}



export default PayalCancel;
