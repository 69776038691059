import { GET_USERS, GETAUTHuserData } from '../types'

const initialState = {
    searchService:{},
    serviceBusinessData:[],
    loading:true
}

const searchService = (state = initialState, action) => {
    switch (action.type) {

        case GET_USERS:
        return {
            ...state,
            userData:action.payload,
        }

        case 'SETSERVICESEARCH':
        return {
            ...state,
            searchService: action.payload,
        }
        case 'SETSERVICEBUSINESSDATA':
        return {
            ...state,
            serviceBusinessData: action.payload,
        }
        default: return state
    }
}

export default searchService