import React, { useState, useEffect, useCallback } from "react";

import Slider from "common/slider";
import { Rating } from "react-simple-star-rating";
import SimpleImageSlider from "react-simple-image-slider";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
function PhotoSelider(props) {
  const { photoList, setShowFilePath,setShowTitle } = props;
  const [show, setShow] = useState(false);
  const [showImg, setShowImg] = useState(0);
  console.log("🚀 ~ file: photoSlider.js:6 ~ PhotoSelider ~ photoList:", photoList)

  const images = [
    { url: "https://smbmblobtest.blob.core.windows.net/attachments/photo/1687275608412_file.png" },
    { url: "https://smbmblobtest.blob.core.windows.net/attachments/photo/1687275646205_file.png" },
    { url: "https://smbmblobtest.blob.core.windows.net/attachments/photo/1687275655170_file.png" },
    { url: "https://smbmblobtest.blob.core.windows.net/attachments/photo/1687275663718_file.png" },
    { url: "https://smbmblobtest.blob.core.windows.net/attachments/photo/1687275676624_file.png" },

  ];

  const onClick = useCallback((idx, event) => {
    setShowImg(idx)
    console.log(`[App onClick] ${idx} ${event.currentTarget}`);
    setShow(true)
  }, []);



  const handleClose = () => setShow(false);


  return (
    <>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={images[showImg].url} width="100%" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary">Understood</Button> */}
        </Modal.Footer>
      </Modal>
      <div className="card-body">
        <div className="row align-items-center business-externo">
          <div className="col-md-12 bus-detail-cont">
            <div className="srvc--containt card-body p-0">
              <div className="row">
                <div className="col-md-12">
                  <div className="main--srvc-slider photo-srvc-slider">

                    {/*  <SimpleImageSlider
                      width={'97%'}
                      height={400}
                      images={images}
                      onClick = {onClick}
                      showBullets={true}
                      showNavs={true}
                    /> */}
                   {photoList?.length == 0 ? (
                      <>
                      <div className="text-center" style={{"backgroundColor" :"white","height":"400px"}}>
                        <img src="images/nodatafound.png" />
                         <span className="not-found-content">Ooops, no photos found</span>
                         </div>
                     </>
                    ) : (
                      
                      <Slider
                        arrows='true'
                        SliderArray={photoList}
                        SliderItem={(item, i) => (
                          <div className="swiper-slide">
                            <div className="slid--box" key={i}>
                              <a
                                data-toggle="modal"
                                data-target="#showVideo"
                                onClick={() =>{
                                  // setShowFilePath(props.documentUrl + item.item.filefile)
                                  setShowFilePath(item.item.aws_url);
                                  setShowTitle(item?.item?.title)
                                }}
                              >
                                <div className="slider--ser_img" style={{ height: "322px", width: "315px" }}>
                                  <div className="iconsize img_size-photo">
                                 {/*  {item?.item?.title} */}
                                    <img
                                      src={
                                        item.item.aws_url
                                          ? // ? props.documentUrl + item.item.filefile
                                          item.item.aws_url
                                          : "images/no_image.png"
                                      }
                                      className="slid-img"
                                    />
                                    <div className="show-title">{item?.item?.title}</div> 
                                  </div>
                               
                                  
                                </div>
                              </a>
                            </div>
                          </div>
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PhotoSelider;
