import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import base64 from "base-64";
import Loader from "common/loader";
import moment from "moment";
import { Rating } from "react-simple-star-rating";
import LogoSlider from "screen/service/serviceDetail/component/logoSlider";
function OffersList(props) {

  const [bucket_Img_url, setBucket_Img_url] = useState();
  const [popuptitle, setPopuptitle] = useState("");
  const [openModal, setOpenModal] = useState(false)

  const [showmore, setShowmore] = useState("");
  const moreshow = (offerid) => {
    setShowmore(offerid);
  };
  const correctjobdec = (description) => {
    const datahhh = description || '';
    return datahhh.replace(/(\r\n|\n|\r)/g, '<br />');
  };


  const fullviewimage = (imagepath, title) => {
    setOpenModal(!openModal)
    setBucket_Img_url(imagepath)
    setPopuptitle(title)
  }

  const SponsoredCount = props.IndustryListData.length > 0 ? props.IndustryListData.filter(item => item.plan_type === 2) : [];

  const freeCount = props.IndustryListData.length > 0 ? props.IndustryListData.filter(item => item.plan_type === 1) : [];

  return (
    <section className="default-smb-area bg--light">

      {openModal ? <LogoSlider
        bucket_Img_url={bucket_Img_url}
        setOpenModal={setOpenModal}
        popuptitle={popuptitle}
      /> : ""}

      <div className="container">
        <div className="mb-4">
          <div className="row">
            <div className="col-md-4 mar-top">
              <div className="">
                <label className="Form--lbl">Industry</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    id="serviceSearch"
                    defaultValue={props.reset != 1 ? props.serviceName : ""}
                    onChange={(e) => {
                      props.handleIndustrySearch(e.target.value);
                    }}
                    onBlur={(e) => props.manageSearchService(e.target.value)}
                    placeholder="Beauty Salons, Full-Service Restaurants"
                    autoComplete="off"
                  />
                  <div>
                    <div
                      className={`city_list_box ${props.industryListShow ? "show" : "hide"
                        }`}
                    >
                      {props.searchIndustryList &&
                        props.searchIndustryList.length > 0 ? (
                        <ul className="city_list_menu">
                          {props.searchIndustryList.map((item, i) => {
                            return (
                              <li
                                onClick={() => {
                                  props.setSelectedIndustry(item.naicsid);
                                  props.handleSelectIndustry(item.title);
                                  props.setFormError({
                                    ...props.formError,
                                    service: "",
                                  });
                                }}
                                className={`city_list ${props.serviceData?.service == item.naicsid
                                  ? "active"
                                  : ""
                                  }`}
                              >
                                {item.title}
                              </li>
                            );
                          }, [])}
                        </ul>
                      ) : (
                        <p
                          style={{
                            textAlign: "center",
                            marginTop: "2px",
                            color: "gray",
                          }}
                        ></p>
                      )}
                    </div>
                  </div>
                  <small className="text-danger">
                    {props.formError?.service ? props.formError?.service : ""}
                  </small>
                </div>
              </div>
            </div>
            <div className="col-md-4 mar-top">
              <div className="">
                <label className="Form--lbl">City</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    id="citySearch"
                    defaultValue={props.reset != 1 ? props.cityName : ""}
                    onChange={(e) => {
                      props.handleCitySearch(e.target.value);
                    }}
                    onBlur={(e) => props.manageSearchCity(e.target.value)}
                    placeholder="Boca Raton, Miami, Orlando"
                    autoComplete="off"
                  />
                  <div>
                    <div
                      className={`city_list_box ${props.cityListShow ? "show" : "hide"
                        }`}
                    >
                      {props.searchCityList &&
                        props.searchCityList.length > 0 ? (
                        <ul className="city_list_menu">
                          {props.searchCityList.map((item, i) => {
                            return (
                              <li
                                onClick={() => {
                                  props.setSelectedCity(item.id);
                                  props.setCityListShow(false);
                                  props.handleSelectCity(
                                    item.city,
                                    item.state_id
                                  );
                                  props.setFormError({
                                    ...props.formError,
                                    city: "",
                                  });
                                }}
                                className={`city_list ${props.serviceData?.city == item.id
                                  ? "active"
                                  : ""
                                  }`}
                              >
                                {item.city}, {item.state_id}
                              </li>
                            );
                          }, [])}
                        </ul>
                      ) : (
                        <p
                          style={{
                            textAlign: "center",
                            marginTop: "2px",
                            color: "gray",
                          }}
                        ></p>
                      )}
                    </div>
                  </div>
                  <small className="text-danger">
                    {props.formError?.city ? props.formError?.city : ""}
                  </small>
                </div>
              </div>
            </div>
            <div className="col-md-2 mar-top">
              <button
                style={{ "marginTop": "22px" }}
                type="button"
                className="btn main-btn w-100"
                // onClick={() => props.getServiceDataList(0)}
                onClick={() => props.searchServiceDataList()}
              >
                Search Offers
              </button>
            </div>
            {/*  <div className="col-md-2 mar-top">
              <button
                type="button"
                className="btn main-btn w-100"
                onClick={() => props.resetDetail()}
              >
                Reset
              </button>
            </div> */}
          </div>
        </div>
        <div className="">
          {!props.resetIsLoading ? (
            <div>
              <div className="page-container border-bottom-0">
                <div className="row">
                  {SponsoredCount.length > 0 ? <h5 style={{ "color": "#6258d3", "fontSize": "12pt" }}>Sponsored</h5> : null}
                  {props.IndustryListData &&
                    props.IndustryListData.length > 0 ? (

                    (SponsoredCount.length > 0) ?
                      SponsoredCount.map((item, i) => (
                        <div
                          className="page-content col-xl-6 mb-6 mb-xl-0"
                          key={i}
                        >
                          <div className="widget mb-8">
                            <div className="rm-listings">
                              <div className="card border-8 shadow mb-3">
                                <div
                                  className="card-header bg-white border-t-8"
                                  style={{
                                    borderBottom: "none",
                                    marginBottom: "-10px",
                                  }}
                                >
                                  <div
                                    className="bg--smb w-100 p-2"
                                    style={{ borderRadius: "10px" }}
                                  >
                                    <div className="d-flex justify-content-between row">
                                      <div className="col-md-9">
                                        <h5 className="m-0 text-white">
                                          {item.business_name}
                                        </h5>
                                      </div>
                                      <div className="col-md-3">
                                        <span
                                          className="badge bg-white p-1"
                                          style={{ borderRadius: "30px" }}
                                        >
                                          <small className="srvc--text">
                                            {/*  Rating ({Math.round(item.ranting)}) */}
                                            <div
                                              className="d-flex"
                                              style={{
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Rating initialValue={Math.round(item.ranting)} readonly="true" size="18" />
                                             
                                            </div>
                                          </small>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-md-3">
                                      <div className="ser_img_lst">
                                        <img
                                          src={
                                            item.offer_image
                                              ? item.offer_image
                                              : "images/no_image.png"
                                          }
                                          className="card-img rounded pointer-show"
                                          alt="Business Image"
                                          onClick={() => fullviewimage(item.offer_image
                                            ? item.offer_image
                                            : "images/no_image.png", item?.title)}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-9">
                                      <div className="srvc--containt card-body p-0">
                                        <small className="srvc--text serice--list-text">
                                          {item.brand_text}
                                        </small>
                                        <small>
                                          {item?.title != null ? (
                                            <span className="service--amunt-rigt">
                                              Offer Title: {item?.title}
                                            </span>
                                          ) : null}
                                        </small>
                                        <small className="srvc--text serice--list-text">
                                          <b>Industry:</b>&nbsp;{" "}
                                          {item?.industry_name}
                                        </small>
                                        <small className="srvc--text serice--list-text">
                                        
                                          <b>Start/End Dates:</b>&nbsp;{" "}
                                          {moment(item?.offerstartdate).format(
                                            "MM-DD-YYYY"
                                          )}
                                          &nbsp;/&nbsp;
                                          {moment(item?.expirationdate).format(
                                            "MM-DD-YYYY"
                                          )}
                                        </small>
                                        <small className="srvc--text serice--list-text">
                                          <b>City:</b>&nbsp;{item?.city},&nbsp;
                                          {item?.state_name}
                                        </small>
                                        {
                                          (item?.description !== "") ? <>
                                            <small
                                              className={
                                                "srvc--text serice--list-text" +
                                                (item?.offerid
                                                  === showmore
                                                  ? " more-desc"
                                                  : " lim-desc")
                                              }
                                            >
                                              {/* <b>Service:</b>&nbsp;{item.naics_name} */}
                                              <b>Description:</b>&nbsp;{" "}
                                              <div dangerouslySetInnerHTML={{ __html: correctjobdec(item?.description) }}></div>
                                              

                                            </small>


                                            <small className="srvc--text serice--list-text more-lable">
                                              {/* <span onClick={(jobid) => moreshow(item?.jobid)}>More</span> */}

                                              {item?.offerid === showmore ? (
                                                <b onClick={(offerid
                                                ) => moreshow(0)}>
                                                  {"Less >>"}
                                                </b>
                                              ) : (
                                                <b
                                                  onClick={(offerid
                                                  ) =>
                                                    moreshow(item?.offerid
                                                    )
                                                  }
                                                >
                                                  {"Show More >>"}
                                                </b>
                                              )}
                                            </small>
                                          </> : null
                                        }

                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer text-muted bg-white border-b-8">
                                  <div className="d-flex justify-content-between">
                                    <div>
                                       <label
                                          className="business__card-badge mr-1"
                                          style={{
                                            backgroundColor: "#6258d3",
                                          }}
                                        >
                                        <Link
                                          to={`/${item?.businessUrlName
                                            .replace(/\s+/g, "")
                                            .toLowerCase()}`}
                                          className="business__card-badge--p"
                                          style={{
                                            color: "#ffff",
                                          }}
                                        >
                                          <i class="fa fa-eye"></i>{" "}
                                          <small
                                            className="p__text"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Detail
                                          </small>
                                        </Link>
                                      </label> 
                                      {item.showtext > 0 ? (
                                        <label className="business__card-badge mr-1">
                                          <Link
                                            to="#"
                                            onClick={(e) => {
                                              window.location = `sms:${item.phone}?&body=<<Type here the information you want to request from the business>>`;
                                              e.preventDefault();
                                            }}
                                          >
                                            <p className="business__card-badge--p">
                                              <i className="fa fa-comments"></i>{" "}
                                              <small
                                                className="p__text"
                                                style={{ fontSize: "14px" }}
                                              >
                                                Text
                                              </small>
                                            </p>
                                          </Link>
                                        </label>
                                      ) : (
                                        ""
                                      )}

                                      {item.showcall > 0 ? (
                                        <label className="business__card-badge mr-1">
                                          <Link
                                            to="#"
                                            onClick={(e) => {
                                              window.location = `tel:${item.phone}`;
                                              e.preventDefault();
                                            }}
                                          >
                                            <p className="business__card-badge--p">
                                              <i className="fa fa-phone"></i>{" "}
                                              <small
                                                className="p__text"
                                                style={{ fontSize: "14px" }}
                                              >
                                                Call
                                              </small>
                                            </p>
                                          </Link>
                                        </label>
                                      ) : (
                                        ""
                                      )}

                                      {item.showemail > 0 ? (
                                        <label className="business__card-badge mr-1">
                                          <a
                                            target="_blank"
                                            href={`mailto:${item.email}?&subject=New Lead from MAXBIZ&body=<<Type here the information you want to request from the business>>`}
                                          >
                                            <p className="business__card-badge--p">
                                              <i className="fa fa-envelope"></i>{" "}
                                              <small
                                                className="p__text"
                                                style={{ fontSize: "14px" }}
                                              >
                                                Email
                                              </small>
                                            </p>
                                          </a>
                                        </label>
                                      ) : (
                                        ""
                                      )}

                                      <label className="business__card-badge mr-1">
                                        <a target="_blank" href={item.websiteurl}>
                                          <p className="business__card-badge--p">
                                            <i class="fa fa-globe"></i>{" "}
                                            <small
                                              className="p__text"
                                              style={{ fontSize: "14px" }}
                                            >
                                              Web
                                            </small>
                                          </p>
                                        </a>
                                      </label>
                                      <label className="business__card-badge mr-1">
                                        <a
                                          target="_blank"
                                          href={`https://www.google.com/maps/search/${item.address}`}
                                        >
                                          <p className="business__card-badge--p">
                                            <i class="fa fa-map"></i>{" "}
                                            <small
                                              className="p__text"
                                              style={{ fontSize: "14px" }}
                                            >
                                              Map
                                            </small>
                                          </p>
                                        </a>
                                      </label>
                                     {/*  <label
                                          className="business__card-badge mr-1"
                                          style={{
                                            backgroundColor: "#6258d3",
                                          }}
                                        >
                                        <Link
                                          to={`/${item?.businessUrlName
                                            .replace(/\s+/g, "")
                                            .toLowerCase()}`}
                                          className="business__card-badge--p"
                                          style={{
                                            color: "#ffff",
                                          }}
                                        >
                                          <i class="fa fa-eye"></i>{" "}
                                          <small
                                            className="p__text"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Detail
                                          </small>
                                        </Link>
                                      </label> */}
                                       <label className="business__card-badge mr-1">
                                        <a target="_blank" href={`mailto:mbt@maxelit.com?&subject=Suspicious Content&body=Dear MAXBIZ Team:%0D%0A%0D%0APlease review the following suspicious content: <<INCLUDE PAGE, SUSPICIOUS CONTENT AND ANY OTHER RELEVANT DETAILS>>.%0D%0A%0D%0ARegards,%0D%0A%0D%0A<<YOUR FULL NAME>>%0D%0A<<YOUR PHONE NUMBER>>`}>
                                          <p className="business__card-badge--p">
                                            <i class="fa fa-flag"></i> <small className="p__text" style={{ fontSize: "14px" }}>Report</small>
                                          </p>
                                        </a>
                                      </label> 
                                      
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* </Link> */}
                        </div>
                      ))
                      : null


                  ) : (
                    <div className="text-center" style={{ "backgroundColor": "white", "height": "400px" }}>
                      <img src="images/nodatafound.png" />
                      <span className="not-found-content">Ooops, no offers found</span>
                    </div>
                  )}
                </div>
                <div className="row">

                  {freeCount && freeCount.length > 0 ? <div className="borderfree"></div> : null}

                  {
                    (freeCount.length > 0) ?
                      freeCount.map((item, i) => (
                        <div
                          className="page-content col-xl-6 mb-6 mb-xl-0"
                          key={i}
                        >
                          <div className="widget mb-8">
                            <div className="rm-listings">
                              <div className="card border-8 shadow mb-3">
                                <div
                                  className="card-header bg-white border-t-8"
                                  style={{
                                    borderBottom: "none",
                                    marginBottom: "-10px",
                                  }}
                                >
                                  <div
                                    className="bg--smb w-100 p-2"
                                    style={{ borderRadius: "10px" }}
                                  >
                                    <div className="d-flex justify-content-between row">
                                      <div className="col-md-9">
                                        <h5 className="m-0 text-white">
                                          {item.business_name}
                                        </h5>
                                      </div>
                                      <div className="col-md-3">
                                        <span
                                          className="badge bg-white p-1"
                                          style={{ borderRadius: "30px" }}
                                        >
                                          <small className="srvc--text">
                                            {/*  Rating ({Math.round(item.ranting)}) */}
                                            <div
                                              className="d-flex"
                                              style={{
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Rating initialValue={Math.round(item.ranting)} readonly="true" size="18" />
                                              
                                            </div>
                                          </small>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-md-3">
                                      <div className="ser_img_lst">
                                        <img
                                          src={
                                            item.offer_image
                                              ? item.offer_image
                                              : "images/no_image.png"
                                          }
                                          className="card-img rounded pointer-show"
                                          alt="Business Image"
                                          onClick={() => fullviewimage(item.offer_image
                                            ? item.offer_image
                                            : "images/no_image.png", item?.title)}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-9">
                                      <div className="srvc--containt card-body p-0">
                                        <small className="srvc--text serice--list-text">
                                          {item.brand_text}
                                        </small>
                                        <small>
                                          {item?.title != null ? (
                                            <span className="service--amunt-rigt">
                                              Offer Title: {item?.title}
                                            </span>
                                          ) : null}
                                        </small>
                                        <small className="srvc--text serice--list-text">
                                          <b>Industry:</b>&nbsp;{" "}
                                          {item?.industry_name}
                                        </small>
                                        <small className="srvc--text serice--list-text">
                                         
                                          <b>Start/End Dates:</b>&nbsp;{" "}
                                          {moment(item?.offerstartdate).format(
                                            "MM-DD-YYYY"
                                          )}
                                          &nbsp;/&nbsp;
                                          {moment(item?.expirationdate).format(
                                            "MM-DD-YYYY"
                                          )}
                                        </small>
                                        <small className="srvc--text serice--list-text">
                                          <b>City:</b>&nbsp;{item?.city},&nbsp;
                                          {item?.state_name}
                                        </small>
                                        {
                                          (item?.description !== "") ? <>
                                            <small
                                              className={
                                                "srvc--text serice--list-text" +
                                                (item?.offerid
                                                  === showmore
                                                  ? " more-desc"
                                                  : " lim-desc")
                                              }
                                            >
                                              {/* <b>Service:</b>&nbsp;{item.naics_name} */}
                                              <b>Description:</b>&nbsp;{" "}
                                              <div dangerouslySetInnerHTML={{ __html: correctjobdec(item?.description) }}></div>
                                             

                                            </small>


                                            <small className="srvc--text serice--list-text more-lable">
                                              {/* <span onClick={(jobid) => moreshow(item?.jobid)}>More</span> */}

                                              {item?.offerid === showmore ? (
                                                <b onClick={(offerid
                                                ) => moreshow(0)}>
                                                  {"Less >>"}
                                                </b>
                                              ) : (
                                                <b
                                                  onClick={(offerid
                                                  ) =>
                                                    moreshow(item?.offerid
                                                    )
                                                  }
                                                >
                                                  {"Show More >>"}
                                                </b>
                                              )}
                                            </small>
                                          </> : null
                                        } 
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer text-muted bg-white border-b-8">
                                  <div className="d-flex justify-content-between">
                                    <div>
                                    <label
                                        className="business__card-badge mr-1"
                                        style={{
                                          backgroundColor: "#6258d3",
                                        }}
                                      >
                                        <Link
                                          to={`/${item?.businessUrlName
                                            .replace(/\s+/g, "")
                                            .toLowerCase()}`}
                                          className="business__card-badge--p"
                                          style={{
                                            color: "#ffff",
                                            /* border: "1px solid #6258d3",
                                        borderRadius: "4px",
                                        color: "white",
                                        //padding: "3px 12px",
                                        backgroundColor: "#6258d3" */
                                          }}
                                        >
                                          <i class="fa fa-eye"></i>{" "}
                                          <small
                                            className="p__text"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Detail
                                          </small>
                                        </Link>
                                      </label>
                                      {item.showtext > 0 ? (
                                        <label className="business__card-badge mr-1">
                                          <Link
                                            to="#"
                                            onClick={(e) => {
                                              window.location = `sms:${item.phone}?&body=<<Type here the information you want to request from the business>>`;
                                              e.preventDefault();
                                            }}
                                          >
                                            <p className="business__card-badge--p">
                                              <i className="fa fa-comments"></i>{" "}
                                              <small
                                                className="p__text"
                                                style={{ fontSize: "14px" }}
                                              >
                                                Text
                                              </small>
                                            </p>
                                          </Link>
                                        </label>
                                      ) : (
                                        ""
                                      )}

                                      {item.showcall > 0 ? (
                                        <label className="business__card-badge mr-1">
                                          <Link
                                            to="#"
                                            onClick={(e) => {
                                              window.location = `tel:${item.phone}`;
                                              e.preventDefault();
                                            }}
                                          >
                                            <p className="business__card-badge--p">
                                              <i className="fa fa-phone"></i>{" "}
                                              <small
                                                className="p__text"
                                                style={{ fontSize: "14px" }}
                                              >
                                                Call
                                              </small>
                                            </p>
                                          </Link>
                                        </label>
                                      ) : (
                                        ""
                                      )}

                                      {item.showemail > 0 ? (
                                        <label className="business__card-badge mr-1">
                                          <a
                                            target="_blank"
                                            href={`mailto:${item.email}?&subject=New Lead from MAXBIZ&body=<<Type here the information you want to request from the business>>`}
                                          >
                                            <p className="business__card-badge--p">
                                              <i className="fa fa-envelope"></i>{" "}
                                              <small
                                                className="p__text"
                                                style={{ fontSize: "14px" }}
                                              >
                                                Email
                                              </small>
                                            </p>
                                          </a>
                                        </label>
                                      ) : (
                                        ""
                                      )}

                                      <label className="business__card-badge mr-1">
                                        <a target="_blank" href={item.websiteurl}>
                                          <p className="business__card-badge--p">
                                            <i class="fa fa-globe"></i>{" "}
                                            <small
                                              className="p__text"
                                              style={{ fontSize: "14px" }}
                                            >
                                              Web
                                            </small>
                                          </p>
                                        </a>
                                      </label>
                                      <label className="business__card-badge mr-1">
                                        <a
                                          target="_blank"
                                          href={`https://www.google.com/maps/search/${item.address}`}
                                        >
                                          <p className="business__card-badge--p">
                                            <i class="fa fa-map"></i>{" "}
                                            <small
                                              className="p__text"
                                              style={{ fontSize: "14px" }}
                                            >
                                              Map
                                            </small>
                                          </p>
                                        </a>
                                      </label>
                                      <label className="business__card-badge mr-1">
                                        <a target="_blank" href={`mailto:mbt@maxelit.com?&subject=Suspicious Content&body=Dear MAXBIZ Team:%0D%0A%0D%0APlease review the following suspicious content: <<INCLUDE PAGE, SUSPICIOUS CONTENT AND ANY OTHER RELEVANT DETAILS>>.%0D%0A%0D%0ARegards,%0D%0A%0D%0A<<YOUR FULL NAME>>%0D%0A<<YOUR PHONE NUMBER>>`}>
                                          <p className="business__card-badge--p">
                                            <i class="fa fa-flag"></i> <small className="p__text" style={{ fontSize: "14px" }}>Report</small>
                                          </p>
                                        </a>
                                      </label>
                                    
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* </Link> */}
                        </div>
                      )) : null
                  }

                </div>




              </div>
            </div>
          ) : (
            <div className="text-center">
              {" "}
              <div className="" style={{ height: "300px" }}>
                <div className="lds-ellipsis">
                  <span />
                  <span />
                  <span />
                </div>
              </div>{" "}
            </div>
          )}

          {((props.IndustryListData && props.IndustryListData.length > 9) ||
            props.itemOffset > 0) && (
              <div className="btm-pagination">
                <div className="d-flex justify-content-end">
                  <ReactPaginate
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={props.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={4}
                    onPageChange={props.handlePageClick}
                    containerClassName={
                      "pagination pagination-style-02 justify-content-center"
                    }
                    subContainerClassName={"pages pagination"}
                    activeClassName={"current active active_pg"}
                    activeLinkClassName={"current active active_pg"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link bg-gray"}
                    previousClassName={"page-item"}
                    nextClassName={"page-item"}
                    previousLinkClassName={"page-link bg-gray"}
                    nextLinkClassName={"page-link bg-gray"}
                    previousLabel={<i className="fal fa-chevron-left"></i>}
                    nextLabel={<i className="fal fa-chevron-right"></i>}
                  />
                </div>
              </div>
            )}
        </div>
      </div>
    </section>
  );
}
export default OffersList;
