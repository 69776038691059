import React, { useState, useEffect } from "react";
import Slider from "common/slider";
import { Rating } from "react-simple-star-rating";
import { Player } from "video-react";
import ReactPlayer from "react-player";

export default function Popupmodels(props) {
  const { showFilePath, slectedTab,setShowFilePath,showFileTitle } = props;
  console.log("🚀 ~ Popupmodels ~ showFilePath:", showFilePath)

  const stopplay = () =>{
    
    setShowFilePath('')
  }
  return (
    <>
      <div className="container">
        <div className="modal-dialog  video--modal">
          <div className="modal-content">
            <div className="modal-header">
              {showFileTitle}
              <h5 className="modal-title" id="exampleModalLabel"></h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" onClick={()=> stopplay()}>×</span>
              </button>
              <div className="progress-bar" />
            </div>
            <div className="modal-body" >
              <div className="swiper-slide" >
                {slectedTab === 0 ? (
                  <div
                    className="embed-responsive"
                    // style={{ paddingTop: "3%" }}
                  >
                    {/* {showFilePath &&
                        <video
                          width="320"
                          height="240"
                          controls={true}
                        >
                          <source
                            src={showFilePath}
                            type="video/mp4"
                          />
                        </video>
                      } */}
                    {showFilePath && (
                      <div>

                         <ReactPlayer playing="true"  controls="true" width= '600px' height= "450px" url={showFilePath} />
                        {/* <Player
                          src={showFilePath}
                          width="460"
                          height="250"
                          controls={false}
                        />  */}
                      </div>
                    )}
                  </div>
                ) : slectedTab === 4 ? (
                  <div className="embed-responsive embed-responsive-16by9">
                     <iframe
                      style={{ display: "block" }}
                      className="embed-responsive-item"
                      frameBorder="0"
                      //src="https://www.orimi.com/pdf-test.pdf"
                      src={showFilePath.aws_url}
                      allowfullscreen
                    ></iframe>
                    {/* <embed
                      src={showFilePath.aws_url}
                      type="application/pdf"
                      frameBorder="0"
                      scrolling="auto"
                      height="400px"
                      width="100%"
                    ></embed> */}
                  </div>
                ) : (
                  <img
                    src={showFilePath}
                    style={{ height: "100%", width: "100%" }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
