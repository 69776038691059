import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from "moment";
import base64 from "base-64";
import ApiEndPoint from "utils/apiEndPoints";
import { apiCall, errorToast } from "utils/httpClient";
import Loader from "common/loader";


import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export default function Businesslist(props) {
  const { searchkey } = props;
  let history = useHistory();
  const [pageShow, setPageShow] = useState("details");

  const [isLoading, setIsLoading] = useState(false);
  const [allbusinessListData, setAllBusinessListData] = useState([]);
  const [businessListData, setBusinessListData] = useState([]);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState();
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(1000);
  const [showrecord, setShowrecord] = useState(25);
  const [showloader, setShowloader] = useState(true);


  useEffect(() => {
    getBusinessData(itemOffset);
  }, []);

  useEffect(() => {
    filterbusiness(searchkey)
  }, [searchkey]);

  const filterbusiness = (searchTerm) => {
    if (searchTerm.length >= 3) {
      const filteredBusiness = allbusinessListData.filter(item => {

        const fullname = item.fullname ? item.fullname.toLowerCase() : "";
        const email = item.email ? item.email.toLowerCase() : "";
        return fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
          email.toLowerCase().includes(searchTerm.toLowerCase())
      }
      );

      setBusinessListData(filteredBusiness); // Update the state with the filtered data
    } else {
      setBusinessListData(allbusinessListData);
    }
  };



  async function getBusinessData(offset) {
    showloader ? setIsLoading(true) : console.log("");
    const header = {
      Authorization: `Bearer ${localStorage.getItem("userToken")}`,
    };
    const params = { limit: itemsPerPage, offset: offset };
    var { data } = await apiCall(
      "POST",
      ApiEndPoint.BUSINESSLIST,
      params,
      header
    );

    if (data.status == 200) {
      setShowloader(false);
      setAllBusinessListData(data.data);
      setBusinessListData(data.data);
      const parPage = data.total_business / itemsPerPage;
      //console.log("getBusinessData -> parPage", parPage)
      setPageCount(parPage);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }

  }
  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    setItemOffset(newOffset);
    getBusinessData(newOffset);
  };

  const businessVerifyed = async (profileId, verifiedStatus) => {
    if (verifiedStatus != 1) {
      const header = {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      };
      const params = { profileid: profileId };
      var { data } = await apiCall(
        "POST",
        ApiEndPoint.SENDBUSINESSNOTIFICATION,
        params,
        header
      );
      if (data.status == 200) {
        getBusinessData(itemOffset);
      } else {
        errorToast(data.message);
      }
    }
  };
  /*  if (isLoading) {
    return (
      <Loader />
    )
  }
 */

  const alignth = {
    color: "white",
    backgroundColor: "#877cf2",
    padding: "10px",
    fontFamily: "Arial",
    textAlign: "left",
  };
  const aligntd = {
    textAlign: "left",
  };


  const formatSubscription = (item) => {
    if (item.verified === 0) {
      return "-";
    } else {
      return item.plan_type === 1 ? "Free" : "Paid";
    }
  };

  const formatOption = (item) => {
    // if (item.verified === 0) {
    //   return "-";
    // } else {
    //   switch(item.plan_option) {
    //       case 1: return "City";
    //       case 2: return "County";
    //       case 3: return "State";
    //       case 4: return "Country";
    //       default: return "City";
    //   }
    // }
    return (
      item.verified === 0 ? "-" :
        item.plan_option === 1 ? "City" :
          item.plan_option === 2 ? "County" :
            item.plan_option === 3 ? "State" :
              item.plan_option === 4 ? "Country" : "City"
    )
  };

  const formatStatus = (item) => {
    return item.verified === 1 ? "Verified" : "Unverified";
  };

  const formatStatusStyle = (item) => {
    return item.verified === 1 ? "btn btn-success btn-sm" : "btn btn-danger btn-sm";
  };

  const actionTemplate = (item) => {
    return (
      <Link
        to={`/businessdetails/${base64.encode(item.businessid)}/${base64.encode(item.profileid)}`}
      >
        View
      </Link>
    );
  };


  return (
    <>
      <div className="row" style={{ marginTop: "0.5rem" }}>
        <div className="col-md-12">
          <div class="card card-primary">
            <div class="card-header" style={{ backgroundColor: "#867df1" }}>
              <h5 class="card-title" style={{ color: "#ffff" }}>
                Business List
              </h5>
            </div>
            <div class="card-body" style={{ padding: 0 }}>
              <div className="">
                {isLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <img
                      className="listloadergif-admin"
                      src="images/infinity.gif"
                    />
                  </div>
                ) : businessListData.length > 0 ? (
                  <div>
                    {/* <div className="display--table">
                      <table className="table table-border table-light">
                        <thead>
                          <tr className="tbl--row">
                            <th
                              className=""
                              style={{ width: "2%" }}
                              scope="col"
                            >
                              #
                            </th>
                            <th
                              className=""
                              style={{ width: "10%" }}
                              scope="col"
                            >
                              Business Name
                            </th>
                            <th
                              className=""
                              style={{ width: "10%" }}
                              scope="col"
                            >
                              Email
                            </th>
                            <th
                              className=""
                              style={{ width: "10%" }}
                              scope="col"
                            >
                              City
                            </th>
                            <th
                              className=""
                              style={{ width: "8%" }}
                              scope="col"
                            >
                              Phone
                            </th>
                            <th
                              className=""
                              style={{ width: "8%" }}
                              scope="col"
                            >
                              Subscription
                            </th>
                            <th
                              className=""
                              style={{ width: "8%" }}
                              scope="col"
                            >
                              Option
                            </th>
                     
                            <th
                              style={{ width: "2%" }}
                              scope="col"
                              className=""
                            >
                              Status
                            </th>
                            <th
                              style={{ width: "2%" }}
                              scope="col"
                              className=""
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {businessListData.map((item, i) => {
                            return (
                              <>
                                <tr key={i} className="tbl--row">
                                  <td style={aligntd}>{i + 1 + itemOffset}</td>
                                  <td style={aligntd}>{item.fullname}</td>
                                  <td style={aligntd}>
                                    <a>{item.email}</a>
                                  </td>
                                  <td style={aligntd}>{item.city_name }, {item.city_state}</td>
                                  <td style={aligntd}>{item.phone}</td>
                                  <td style={aligntd}>{item.verified == "0" ? "-" : item.plan_type == "1" ? "Free" : "Paid"}</td>
                                  <td style={aligntd}>{item.verified == "0" ? "-" : 
                                  item.plan_option == "1" ? "City" : 
                                  item.plan_option == "2" ? "County" : 
                                  item.plan_option == "3" ? "State" :
                                  item.plan_option == "4" ? "Country" : "City"}</td>
                               
                                  <td style={aligntd}>
                                    <span
                                      className={
                                        item.verified == 1
                                          ? "btn btn-success btn-sm"
                                          : "btn btn-danger btn-sm"
                                      }
                                    >
                                      {item.verified == 1
                                        ? "Verified"
                                        : "Unverified"}
                                    </span>
                                  </td>
                                  <td style={aligntd}>
                                    <Link
                                      to={`/businessdetails/${base64.encode(
                                        item.businessid
                                      )}/${base64.encode(item.profileid)}`}
                                    >
                                      View
                                    </Link>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </div> */}
                    <div className="display--table">
                      <DataTable className="table table-border table-light" value={businessListData} paginator rows={showrecord} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}>
                        <Column field="id" header="#" body={(item, options) => options.rowIndex + 1} />
                        <Column field="fullname" header="Business Name" />
                        <Column field="email" header="Email" />
                        <Column field="city_name" header="City" body={(item) => `${item.city_name}, ${item.city_state}`} />
                        <Column field="phone" header="Phone" />
                        <Column field="plan_type" header="Subscription" body={formatSubscription} />
                        <Column field="plan_option" header="Option" body={formatOption} />
                        <Column field="verified" header="Status" body={(item) => <span className={formatStatusStyle(item)}>{formatStatus(item)}</span>} />
                        <Column header="Action" body={actionTemplate} />
                      </DataTable>
                    </div>

                    {/* <div className="btm-pagination mt-4">
                      <div className="d-flex justify-content-end">
                        <ReactPaginate
                          // previousLabel={'previous'}
                          // nextLabel={'next'}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={pageCount}
                          marginPagesDisplayed={4}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          containerClassName={
                            "pagination pagination-style-02 justify-content-center"
                          }
                          subContainerClassName={"pages pagination"}
                          activeClassName={"current active active_pg"}
                          activeLinkClassName={"current active active_pg"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link bg-gray"}
                          previousClassName={"page-item"}
                          nextClassName={"page-item"}
                          previousLinkClassName={"page-link bg-gray"}
                          nextLinkClassName={"page-link bg-gray"}
                          previousLabel={
                            <i className="fal fa-chevron-left"></i>
                          }
                          nextLabel={<i className="fal fa-chevron-right"></i>}
                        />
                      </div>
                    </div> */}
                  </div>
                ) : (
                  <div className="text-center">
                    <img
                      className="no-foundimage"
                      src="images/nodatafound.png"
                    />
                    <span style={{ "marginBottom": "3%" }} className="not-found-content">Ooops, no Business found</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
