import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";

function Footer() {
 /*  const [showFooter, setShowFooter] = useState(false);
  const location = useLocation();

  React.useEffect(() => {
    setShowFooter(location.pathname != "/home");
  }, [location.pathname]);

  if (!showFooter) return null; */

  return (
    <footer>
      <div className="footer--text bg-light">
        <div className="d-flex justify-content-between">
          <div className="text-center footer--containt">
            <p className="font-text">
              {/* MAXELIT LLC All Rights Reserved  */}
              <a href="https://maxelit.com/" target="_blank" className="mr-4 ml-3">
                {" "}
                Powered by &nbsp;<i className="fa fa-heart"></i> MAXELIT LLC
              </a>

             {/*  <Link
                to="#"
                className="mr-4"
                onClick={(e) => {
                  window.location = `tel:561-877-1717`;
                  e.preventDefault();
                }}
              >
                <i className="fa fa-phone"></i> 561-877-1717
              </Link> */}

              <Link
                to="/contact"
                className="mr-4"
              >
                <i className="fa fa-envelope"></i> Contact
              </Link>

              <Link
                to="/privacy_policy"
              >
                <i className="fa fa-shield-check ml-2"></i> Privacy Policy
              </Link>
            </p>
          </div>
          <div className="text-center footer--containt">
            {/* <p className="font-text">
              <a href="https://maxelit.com/" target="_blank" className="mr-3">
                {" "}
                <img
                  src="images/icons/facebook.svg"
                  style={{ height: "15px", width: "15px" }}
                ></img>
              </a>
              <a href="https://maxelit.com/" target="_blank" className="mr-3">
                {" "}
                <img
                  src="images/icons/instagram.svg"
                  style={{ height: "15px", width: "15px" }}
                ></img>
              </a>
              <a href="#" target="_blank">
                {" "}
                <img
                  src="images/icons/whatsapp.svg"
                  style={{ height: "15px", width: "15px" }}
                ></img>
              </a>
            </p> */}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
