// Api end points
export default {
  JWTTOKEN: "jwtToken/tokenGenerate",

  // Auth API Start
  USERLOGIN: "userAuth/userLogin",
  USERREGISTER: "userAuth/userRegister",
  VERIFYEMAILMOBILE: "userAuth/verifyEmailMobile",
  REGISTERBUSINESSDETAIL: "userAuth/registerBusinessDetail",
  BUSINESSCERTIFICATEUPDATE: "userAuth/businessCertificateUpdate",
  USERPROFILEDETAILS: "userAuth/getUserProfileDetails",
  UPDATEPROFILEDETAILS: "userAuth/profileDetailsUpdate",

  FORGOTPASSWORD: "userAuth/forgotPassword",
  VERIFYEDOTP: "userAuth/verifyedOtp",
  PASSWORDUPDATE: "userAuth/passwordUpdate",
  CHANGEPASSWORD: "userAuth/changePassword",
  RESENTOTP: "userAuth/resentOtp",
  UPDATETWOFACTOR: "userAuth/updatefactor",
  PURCHASEPLAN: "userAuth/purchaseplan",
  RELOGIN_BUSINESS: "userAuth/reloginbusiness",

  ADMINLOGIN: "admin/adminLogin",
  BUSINESSLIST: "admin/businessList",
  SENDBUSINESSNOTIFICATION: "admin/sendBusinessNotification",
  CODETFA: "admin/CodeTFA",
  RESENTCODETFA: "admin/resentCodeTFA",
  VERIFYEDOTPADMIN: "admin/verifyedOtpAdmin",
  RELOGIN_ADMIN: "admin/reloginAdmin",
  CONTACTSUPPORTOTP: "admin/contactSupportOTP",
  CONTACTSUPPORTRESENDOTP: "admin/contactSupportResendOTP",
  CONTACTSUPPORTSENDEMAIL: "admin/contactSupportSendEmail",

  searchService: "searchServiceAndCity",
  listTopService: "listTopService",
  businessServiceDetail: "searchBusinessServiceDetail",

  //master
  GETCITIESLIST: "master/getCitiesList",
  GETSERVICELIST: "master/getServiceList",
  GETSERVICESOCLIST: "master/getServiceSocList",
  GETTOPSERVICEBUSINESSLIST: "master/getTopServiceBusinessList",
  GETVALIDATIONLIST: "master/getValidationList",

  GETLISTSERVICESSEARCH: "service/getListServicesSearch",
  GETLISTJOBSEARCH: "service/getListJobSearch",
  GETLISTOFFERSEARCH: "service/getListOfferSearch",
  GETSERVICESDETAILS: "service/getServicesDetails",

  //dashboard

  GETBUSINESSDETAILS: "/userAuth/getBusinessDetails",
  GETALLUSERLIST: "/userAuth/getUserlist",
  ADDOFFER: "/business/addOffer",
  GETBUSINESSOFFERlIST: "/business/getBusinessOfferList",
  UPDATEOFFER: "/business/updateOffer",
  OFFERDELETE: "/business/offerDelete",
  ADDJOB: "/business/addJob",
  GETBUSINESSJOBLIST: "/business/getBusinessJobList",
  UPDATEJOB: "/business/updateJob",
  JOBDELETE: "/business/jobDelete",
  ADDVIDEOFILE: "/business/addVideoFile",
  GETVIDEODOCUMENTDATA: "/business/getVideoDocumentData",
  UPDATEVIDEOFILE: "/business/updateVideoFile",
  VIDEODOCUMENTDELETE: "/business/videoDocumentDelete",
  BUSINESSDETAILUPDATE: "/userAuth/businessDetailUpdate",
  GETINDUSTRYLIST: "/master/getIndustryList",
  GETMYINVESTORREQUESTLIST: "/investor/getmyinvestorrequest",
  GETINVESTORREQUESTLIST: "/investor/getinvestorrequestlist",
  ADDINVESTORREQUEST: "/investor/addinvestorrequest",
  VISITORREQUESTDELETE: "/investor/deleteinvestorrequest",

  // service review
  ADDUSERMOBILE: "review/addUserMobile",
  USERMOBILEOTPVERIFY: "review/userMobileOtpVerify",
  ADDREVIEW: "review/addReview",
  RESENDMOBILEOTP: "userAuth/resentMobileOtp",

  //get photo
  GETIMAGE: "business/getImage",
  CHECKUSERNAME: "/userAuth/checkusername",

  //property list repdo API

  GETPROPERTYLIST: "/rapidapi/getSaleList",
  GETPROPERTYDETAIL: "/rapidapi/getpropertydetail",
};
