import Modal from 'react-bootstrap/Modal';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';

function LogoSlider(props) {
    const { bucket_Img_url, setOpenModal,popuptitle="" } = props
    const [show, setShow] = useState(true);

    const handleClose = () => {
        setOpenModal(false)
        setShow(false)
    };

    return (
        <div>
            <Modal
                show={show}
                onHide={false}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton onClick={() => handleClose()}>
                 {popuptitle}
                </Modal.Header>
                <Modal.Body>
                    <img width="100%" height="100%" src={
                        bucket_Img_url
                        ? bucket_Img_url
                        : "images/no_image.png"} />
                </Modal.Body>

            </Modal>

        </div>
    )
}

export default LogoSlider
