import React from "react";

const HeaderMenuAdmin = ({ userLoginStatus, userSignOut }) => {
  return (
    <ul className="navbar-nav ml-auto">
      <li className="nav-item">
        <span className="nav-link cursor-pointer">
          <a style={{ padding: "0px 20px" }}>
            <img
              src="images/user.png"
              className="user--icon"
              style={{
                marginTop: "-4px",
                width: "36px",
                height: "30px",
                borderRadius: "50px",
              }}
            />{" "}
            <span>{userLoginStatus.userName}</span>
          </a>
        </span>
      </li>
      <li className="nav-item log-out-btn">
        <a
          onClick={() => userSignOut()}
          style={{ borderBottom: "0", padding: "0px 10px" }}
          className="nav-link log--out-btn cursor-pointer"
        >
          <i className="fa fa-power-off" />
        </a>
      </li>
    </ul>
  );
};

export default HeaderMenuAdmin;
