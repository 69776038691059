import React, { useState, useEffect, useRef } from "react";
import PropertyListing from './propertylisting'
import PropertyDetail from './propertydetail'
function RealEstate(props) {
    const [showcontent, setshowContent] = useState('PropertyListing')
    const [propertyId, setPropertyId] = useState('')

    const [propertySearch, setPropertySearch] = useState({
        city: "",
        state_code: "",
        prop_type: "",
        price_max: "",
        hidepending: false,
        hidecontingent: false,
        hideforeclosure: false,

    });


    const showDetail = (pagename, property_id) => {

        setshowContent(pagename)
        setPropertyId(property_id)


    }

    return (
        <>
            <div className="container">
                {
                    showcontent == 'PropertyListing' ?
                        <PropertyListing
                            propertySearch={propertySearch}
                            setPropertySearch={setPropertySearch}
                            setshowContent={setshowContent}
                            showDetail={(pagename, property_id) => showDetail(pagename, property_id)}
                        /> :
                        <PropertyDetail
                            setshowContent={setshowContent}
                            propertyId={propertyId} />
                }

            </div>
        </>
    );
}

export default RealEstate;
