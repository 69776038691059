import React from "react";

const FormThank = ({handleNewRequest}) => {
  return (
    <>
      <div className="page-contact-form__header">Message Send</div>
      <div className="page-contact-form__body page-form-otc">
        <img
          src="/images/contact-send.svg"
          className="page-form-otc-phone mt-5 mb-5"
        ></img>
        <p className="page-form-thank-description text-center mr-5 ml-5">
          Thank you for contacting <span>MAXBIZ TEAM</span>, we will be in touch
          as soon as possible.
        </p>

        <div className="form-group mt-5 mb-0">
          <button
            className="btn main-btn rounded w-100"
            onClick={() => handleNewRequest()}
          >
            New request
          </button>
        </div>
      </div>
    </>
  );
};

export default FormThank;
