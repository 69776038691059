import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import apiEndPoints from "utils/apiEndPoints";
import { apiCall } from "utils/httpClient";
import moment from "moment";
import SimpleImageSlider from "react-simple-image-slider";
import GoogleMapReact from 'google-map-react';
import Marker from './Marker/Marker'
import Highlighter from "react-highlight-words";

/* const images = [
   { url:'https://ap.rdcpix.com/ec824b557a7249eb146db9d7c6ed075fl-m4289032504x.jpg'},
   { url:'https://ap.rdcpix.com/ec824b557a7249eb146db9d7c6ed075fl-m2708494918x.jpg'},
   { url:'https://ap.rdcpix.com/ec824b557a7249eb146db9d7c6ed075fl-m4120662103x.jpg'},
   { url:'https://ap.rdcpix.com/ec824b557a7249eb146db9d7c6ed075fl-m3163762797x.jpg'},
   { url:'https://ap.rdcpix.com/ec824b557a7249eb146db9d7c6ed075fl-m4289032504x.jpg'},
   { url:'https://ap.rdcpix.com/ec824b557a7249eb146db9d7c6ed075fl-m735221347x.jpg'},
   { url:'https://ap.rdcpix.com/ec824b557a7249eb146db9d7c6ed075fl-m3001858021x.jpg'}
] */
function PropertyDetail(props) {
    const { setshowContent, propertyId } = props
    const [isListLoading, setIsListLoading] = useState(false)
    const [propertyDetail, setPropertyDetail] = useState([])
    const [images, setImagelist] = useState([])
    const [showDays, setShowDays] = useState(0)
    const [searchedText, setSearchedText] = useState('Country Club')
    //const [searchedText, setSearchedText] = useState('Affordable')
    const [searchedText1, setSearchedText1] = useState('Mandatory Membership')
    //const [searchedText1, setSearchedText1] = useState('community')
    const [finaltext, setFinaltext] = useState('')

    //console.log("PropertyDetail -> propertyDetail", propertyDetail)

    const [center, setCenter] = useState({ lat:0, lng:0 });
    const [zoom, setZoom] = useState(15);

    useEffect(() => {
        getPropertyDetail(propertyId);

    }, []);

    async function getPropertyDetail(propertyId) {
        const params = {
            property_id:propertyId,
        }

        const { data } = await apiCall(
            "POST",
            apiEndPoints.GETPROPERTYDETAIL,
            params
        );
        if (data.status == 200) {
            setPropertyDetail(data.data[0])

            var newArray = []
            if (data.data[0]?.photo_count > 0) {
                newArray = data.data[0]?.photos.map(
                    item => ({ ['url']:item.href })
                )
                console.log("getPropertyDetail -> newArray", newArray)
            }

            setCenter({ ...center, lat:data.data[0]?.address?.lat, lng:data.data[0]?.address?.lon })

            setImagelist(newArray);

            var today = new Date()
            today = moment(today).format(
                "MMMM D, YYYY")
            var saledate = data.data[0]?.list_date;
            saledate = moment(saledate).format(
                "MMMM D, YYYY")
            // To calculate the time difference of two dates
            var msDiffdate = new Date().getTime() - new Date(saledate).getTime();
            var dayinDiff = Math.floor(msDiffdate / (1000 * 60 * 60 * 24));

            setShowDays(dayinDiff)

            setIsListLoading(false)


        } else {
            setIsListLoading(false)

        }
    }


    function getHighlightedText(text, highlight) {
        // Split on highlight term and include term into parts, ignore case
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return <span> { parts.map((part, i) => 
            <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold',color:'red' } : {} }>
                { part }
            </span>)
        } </span>;
        
        

    }

    function getHighlightedText2(text, highlight) {
    console.log("PropertyDetail -> text", text)
        // Split on highlight term and include term into parts, ignore case
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return <span> { parts.map((part, i) => 
            <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold',color:'red' } : {} }>
                { part }
            </span>)
        } </span>;
        
       

    }


    return (

        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <a href="#" class="add--more-btn" onClick={() => setshowContent('PropertyListing')}>{'<< Go Back'}</a>
                </div>
                <div className="row">

                </div>
                <div className="col-md-6">
                    <div class="card card-primary">
                        <div class="card-header" style={{ backgroundColor:'#867df1' }}>
                            <h5 class="card-title" style={{ color:'#ffff' }}>Photo Gallery</h5>
                        </div>


                        <form>
                            <div class="card-body">
                                <div className="row">
                                    <div className="col-md-12">

                                        {
                                            propertyDetail?.photo_count > 0 && images.length > 0
                                                ?

                                                /*  <img src={propertyDetail?.photos[0].href} style={{ width:"100%", height:'400px' }} /> */

                                                <SimpleImageSlider
                                                    width={'96%'}
                                                    height={400}
                                                    images={images}
                                                    showBullets={true}
                                                    showNavs={true}
                                                />

                                                :
                                                <img src="images/no_image.png" style={{ width:"100%", height:'400px' }} />
                                        }





                                    </div>
                                </div>
                            </div>


                        </form>
                    </div>
                </div>

                <div className="col-md-6">
                    <div class="card card-primary">
                        <div class="card-header" style={{ backgroundColor:'#867df1' }}>
                            <h5 class="card-title" style={{ color:'#ffff' }}>Google Maps Widget</h5>
                        </div>


                        <form>
                            <div class="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        {/*  <iframe class="custom-shadow" id="map-canvas" width="100%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=700 Oakland Pl Apt 3B&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> */}

                                      



                                        <div style={{ height:'58vh', width:'100%' }}>
                                            {center?.lat !== 0 ?
                                                <GoogleMapReact
                                                    bootstrapURLKeys={{ key:"AIzaSyCbDx7Lk4eTMzptrQKXZvOPYgEMggrq8o4" }}
                                                    defaultCenter={center}
                                                    defaultZoom={zoom}
                                                >
                                                    <Marker
                                                        lat={center.lat}
                                                        lng={center.lng}
                                                        icon='https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Marker-Outside-Pink.png'
                                                   
                                                    />
                                                </GoogleMapReact>
                                                :null}
                                        </div>


                                    </div>
                                </div>
                            </div>


                        </form>
                    </div>
                </div>


            </div>

            <div className="row" style={{ marginTop:"3rem" }}>
                <div className="col-md-12">
                    <div class="card card-primary">
                        <div class="card-header" style={{ backgroundColor:'#867df1' }}>
                            <h5 class="card-title" style={{ color:'#ffff' }}>Results</h5>
                        </div>
                        <div class="card-body">
                            <div class="row invoice-info" style={{ marginBottom:"2rem" }}>
                                <div class="col-sm-3 invoice-col">
                                    <b>ID</b>:&nbsp;{propertyDetail?.property_id}<br></br>
                                    <b>Status</b>:&nbsp;{
                                    /* propertyDetail?.prop_status.charAt(0).toUpperCase() + propertyDetail?.prop_status.slice(1)  */
                                    propertyDetail?.prop_status ?
                                    propertyDetail?.prop_status[0].toUpperCase() +  propertyDetail?.prop_status.slice(1).replace('_',' ')
                                    :''
                                    }
                                    
                                    <br></br>
                                    <b>Type</b>:&nbsp;{
                                    propertyDetail?.prop_type ?
                                    propertyDetail?.prop_type[0].toUpperCase() +  propertyDetail?.prop_type.slice(1).replace('_',' ')
                                     : null} <br></br>
                                    <b>Price</b>:&nbsp;${propertyDetail?.price?.toLocaleString('en-US')}<br></br>
                                    <b> Beds/Baths</b>:&nbsp;{propertyDetail?.beds}/{propertyDetail?.baths}<br></br>
                                    <b>Sqft</b>:&nbsp;
                                  {propertyDetail?.building_size?.size ?
                                        Math.round(propertyDetail?.building_size?.size)
                                        :0
                                    }/{
                                        propertyDetail?.lot_size?.size ?
                                            Math.round(propertyDetail?.lot_size?.size)
                                            :0
                                    }
                                    <br></br>
                                    <b>HOA</b>:&nbsp;${propertyDetail?.hoa_fee ? propertyDetail?.hoa_fee :0}<br></br>
                                    <b>Price Sqft</b>:&nbsp; ${propertyDetail?.price && propertyDetail?.building_size?.size ?
                                        Math.round(propertyDetail?.price / propertyDetail?.building_size?.size).toLocaleString('en-US')
                                        :0
                                    }/${
                                        propertyDetail?.price && propertyDetail?.lot_size?.size ?
                                            Math.round(propertyDetail?.price / propertyDetail?.lot_size?.size).toLocaleString('en-US')
                                            :0
                                    }<br></br>
                                    <b>Garage</b>:&nbsp;<br></br>
                                    <b>Year Built</b>:&nbsp;{propertyDetail?.year_built ? propertyDetail?.year_built :null}<br></br>
                                </div>
                                <div class="col-sm-3 invoice-col">
                                    <b>Listed</b>:&nbsp;{propertyDetail?.list_date ? moment(propertyDetail?.list_date).format(
                                        "D MMM YYYY"
                                    ) :null}<br></br>
                                    <b>Listing</b>:&nbsp;{propertyDetail?.listing_status ? propertyDetail?.listing_statu :0}<br></br>
                                    <b>Last Sold</b>:&nbsp;{propertyDetail?.sold_history ? propertyDetail?.sold_history[0].date.Year :null}<br></br>
                                    <b> Days</b>:&nbsp;{showDays}<br></br>
                                    <b>Taxes</b>:&nbsp;{propertyDetail?.tax_history ?
                                        propertyDetail?.tax_history[0]?.year + '/ $' + propertyDetail?.tax_history[0]?.tax?.toLocaleString('en-US') :0}<br></br>
                                    <b>Payment</b>:&nbsp;<br></br>
                                    <b>Trend</b>:&nbsp;{              
                                        propertyDetail?.trend ?
                                            propertyDetail?.trend?.listing_count?.for_sale + '/' +
                                            propertyDetail?.trend?.listing_count?.rental + '/' +
                                            propertyDetail?.trend?.listing_count?.sold :0 / 0 / 0
                                    }<br></br>
                                    <b>Url</b>:&nbsp;<a target="_blank" href={propertyDetail?.rdc_web_url ? propertyDetail?.rdc_web_url :'#'}> {propertyDetail?.rdc_web_url ? propertyDetail?.rdc_web_url :null}</a><br></br>
                                </div>
                                <div class="col-sm-6 invoice-col">

                                    <b>Description</b>:&nbsp;<br></br>

                                    {
                                       /*  propertyDetail?.description?.replace(searchedText, <span class={`red-color`}>searchedText</span>).replace(searchedText1, <span class={`red-color`}>searchedText1</span>) */
                                       /* propertyDetail?.description.replace(searchedText, (highlight) => `<strong>${highlight}</strong>`) */

                                      /*  propertyDetail?.description.replace(searchedText, (highlight) => `<div>${highlight}</div>`) */
                                      
                                     /*  (finaltext && finaltext != '')?
                                      getHighlightedText(finaltext,searchedText1)
                                      :null  */
                                      propertyDetail?.description && propertyDetail?.description != '' ?
                                    <Highlighter
                                    highlightClassName="YourHighlightClass"
                                    searchWords={[searchedText, searchedText1]}
                                    autoEscape={true}
                                    textToHighlight={propertyDetail?.description}
                                /> : null
                                      

                                      
         


                                        
                                    }

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default PropertyDetail;
