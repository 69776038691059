import React, { useState } from 'react';
import SubscriptionplanScreen from "./component/subscriptionplan";
import ReturnplanScreen from "./../redirectplan/component/redirectplan"
import { apiCall, errorToast,setDefaultHeader } from 'utils/httpClient';
import ApiEndPoint from 'utils/apiEndPoints';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Modal from 'react-modal';
import {setUserAuthData, setBusinessRegisterDetail, handleLoginStatus} from '../../../reduxStore/action/usersActions'
import { useAuth } from "component/context/UserContext";

const customStyles = {
  content: {
    position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  },
};


function Subscription() {


  const businessData = useSelector(store => store.businessData);
  console.log("Subscription -> businessData", businessData)
  let history = useHistory();
  const { signIn } = useAuth(); 
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [updateplan, setUpdateplan] = useState(false);
  const [paynowModel, setPaynowModel] = useState(false);
  const [subscriptiondata, setSubscriptiondata] = useState({});

  const onPresyes = (parms) =>{
      //window.open('http://localhost:3000/redirectplan');
      //purchaseplanAPI(parms)

  }


  const purchaseplanAPI = async () =>{
    setIsLoading(true)
       const parms = {
         profile_id : subscriptiondata?.profile_id,
         plan_type : subscriptiondata?.plan_type
        }
        const { data } = await apiCall('POST', ApiEndPoint.PURCHASEPLAN, parms)
        
      if(data.status === 200){
        setIsLoading(false)
        setUpdateplan(true)
        
      }else{
        setIsLoading(false)
        errorToast(data.message?.message);
      }
  
    }
  const reloginbusiness = async () =>{
    setIsLoading(true)
       const parms = {
         profile_id : subscriptiondata?.profile_id,
         
        }
        const { data } = await apiCall('POST', ApiEndPoint.RELOGIN_BUSINESS, parms)
        
      if(data.status === 200){
        
        setIsLoading(false)
          if(data.data.businessValidated === 1){

            if(data.data.subscriptionplan === 1 ){

              dispatch(setUserAuthData(data.data));
              dispatch(handleLoginStatus({username: data.data.first_name, userType: 'business', token: data.token}));
              // successToast(data.message)
              await localStorage.setItem('authToken', data.token)
              signIn(0, data.token, 'business')
              await setDefaultHeader('token', data.token)
              await localStorage.setItem('allinformation', data.data.allinformation)
              await localStorage.setItem('plan_type', data.data.plan_type)
              history.push("/");

            }else{

             
              localStorage.setItem("profile_id",data.data?.profileid)
               dispatch(setBusinessRegisterDetail(data.data))
              history.push("/subscriptionplan");

            }
           
          }else{
            
            dispatch(setBusinessRegisterDetail(data.data))
            errorToast(data.message?.messageTost)
            history.push("/join_us");
          }
        
      }else{
        setIsLoading(false)
        errorToast(data.message?.message);
      }
  
    }


  const purchaseplan = async (planprice,plantype,plan_id,plan_name) =>{

      const parms = {
        profile_id : businessData?.businessRegisterDetail?.profileid,
        plan_type : plantype,
        amount : planprice,
        plan_id : plan_id,
        plan_name : plan_name,
      }
      localStorage.setItem("subscriptiondata",JSON.stringify(parms))
      setSubscriptiondata(parms)

      var stringshow = planprice > 0 ? '/Month' : ''
      setPaynowModel(true)
       /* confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure you want Buy $'+ planprice+''+ stringshow +' Subscription?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => onPresyes(parms)
        },
        {
          label: 'No',
          onClick: () => null
        }
      ]
    }); */


   



    /* alert(plantype)
   
    const { data } = await apiCall('POST', ApiEndPoint.PURCHASEPLAN, parms)
    console.log("purchaseplan -> data", data)
      
    if(data.status == 200){
      setIsLoading(false)
      
    }else{
      setIsLoading(false)
      errorToast(data.message?.message);
    }
 */
  }

 /*  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  } */

  function closeModal() {
    setPaynowModel(false)
  }
  
  return (
    <>
    { !updateplan ?
       <SubscriptionplanScreen 
        purchaseplan={(planprice,plantype,plan_id,plan_name) => purchaseplan(planprice,plantype,plan_id,plan_name)}
        paynowModel={paynowModel}
        closeModal={closeModal}
        customStyles={customStyles}
        subscriptiondata={subscriptiondata}
        purchaseplanAPI={() => purchaseplanAPI()}
        isLoading={isLoading}
        businessData={businessData}
      /> 
     :
     <ReturnplanScreen 
     reloginbusiness={()=>reloginbusiness()}
     isLoading={isLoading}
    /> 
  }
   </>
  
  );
}

export default Subscription;
