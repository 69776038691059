import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { successToast, errorToast } from "utils/httpClient";
import { apiCall } from "utils/httpClient";
import apiEndPoints from "utils/apiEndPoints";
import moment from "moment";
import Swal from "sweetalert2";
import { warnToast } from "utils/httpClient";

function JobList() {
  const wrapperRefoccup = useRef(null);
  let history = useHistory();
  const [editData, setEditData] = useState({
    title: "",
    createddate: "",
    description: "",
    expirationdate: "",
    jobid: "",
    occupation_id: "",
    occupation: "",
  });
  const [jobListData, setJobListData] = useState([]);
  const [oldData, setOldData] = useState({});
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isLoader, setIsLoader] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);

  const [occupationListShow, setOccupationListShow] = useState(false);
  const [serviceSocList, setServiceSocList] = useState([]);

  const todaydate = moment().format("DD-MM-YYYY");
  const currentdate = moment().format("YYYY-MM-DD");

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRefoccup.current &&
        !wrapperRefoccup.current.contains(event.target)
      ) {
        setOccupationListShow(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
  }, [wrapperRefoccup]);

  async function handleClickOcupation(item) {
    if (item != "" && item.length > 2) {
      const params = { servicename: item };
      const { data } = await apiCall(
        "POST",
        apiEndPoints.GETSERVICESOCLIST,
        params
      );
      if (data.status == 200) {
        setServiceSocList(data.data);
        // const status = occupationListShow ? false:true
        setOccupationListShow(true);
      } else {
        setServiceSocList([]);
        setOccupationListShow(true);
      }
    } else {
      setServiceSocList([]);
      setOccupationListShow(false);
    }
  }

  function manageOccupationSearch(item) {
    if (item == "") {
      setOccupationListShow(false);
      setEditData({
        ...editData,
        occupation_id: "",
        occupation: "",
      });
    }
  }

  function handleSelectOccupation(item) {
    document.getElementById("occupationId").value = item;
    setOccupationListShow(false);
  }

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    getJobList(newOffset);
  };
  const [formError, setFormError] = useState({});
  function formValidation() {
    let error = {};
    let formErrorData = false;
    console.log("rreditData.title.length", editData.title.length);
    if (!editData.title) {
      formErrorData = true;
      error["title"] = "Job title is required";
    } else if (editData.title.length < 5) {
      formErrorData = true;
      error["title"] = "Job title Length more then 5!";
    }

    if (!editData.occupation) {
      formErrorData = true;
      error["occupation"] = "Occupation is required";
    }
    if (!editData.description) {
      formErrorData = true;
      error["description"] = "Description is required";
    }
    if (!editData.createddate) {
      formErrorData = true;
      error["createddate"] = "Effective date is required";
    } else if (editData.createddate < currentdate) {
      formErrorData = true;
      error["createddate"] = "Effective date should not be before today.";
    }
    if (!editData.expirationdate) {
      formErrorData = true;
      error["expirationdate"] = "Expiration date is required";
    } else if (editData.expirationdate < currentdate) {
      formErrorData = true;
      error["expirationdate"] = "Expiration date should not be before today.";
    }
    setFormError(error);
    return formErrorData;
  }
  useEffect(() => {
    getJobList(itemOffset);
    getValidationList();
  }, []);

  const [validationData, setValidationData] = useState([]);
  const [jobNumberValidation, setJobNumberValidation] = useState(0);

  async function getValidationList() {
    const { data } = await apiCall("GET", apiEndPoints.GETVALIDATIONLIST);
    if (data.status == 200) {
      setValidationData(data.data);
      setJobNumberValidation(data.data.find((x) => x.lable == "addjob"));
    } else {
      setValidationData([]);
    }
  }

  console.log(moment(editData.createddate).format("YYYY-MM-DD"));
  async function getJobList(offset) {
    // const header = { "Authorization": `Bearer ${localStorage.getItem('authToken')}` }
    setIsListLoading(true);
    const params = {
      limit: itemsPerPage,
      offset: offset,
    };
    const { data } = await apiCall(
      "POST",
      apiEndPoints.GETBUSINESSJOBLIST,
      params
    );
    if (data.status == 200) {
      setIsListLoading(false);
      setJobListData(data.data);
      const pageCount = data.total_data / itemsPerPage;
      setPageCount(pageCount);
    } else {
      setIsListLoading(false);

      setJobListData([]);
    }
  }

  const checkcountvalidation = () => {
    if (jobListData.length < jobNumberValidation.number) {
      return true;
    } else {
      Swal.fire({
        title: "Oops...",
        text: `You have already Added ${jobNumberValidation.number} Jobs`,
        icon: "info",
        //showCancelButton: true,
        //confirmButtonColor: "#6258D3",
        cancelButtonColor: "#000",
        cancelButtonText: "OK",
        //confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {});
    }
  };

  async function handleAddJob() {

    if (!formValidation() && checkcountvalidation()) {
      setIsLoader(true);
      var param = {
        occupation_id: editData.occupation_id,
        title: editData.title,
        createddate: editData.createddate,
        expirationdate: editData.expirationdate,
        description: editData.description,
      };
      const { data } = await apiCall("POST", apiEndPoints.ADDJOB, param);
      if (data.status == 200) {
        successToast(data.message);
        setIsLoader(false);
        getJobList(itemOffset);
        cleanSetEditData();
      } else {
        errorToast(data.message);
        setIsLoader(false);
      }
    }
  }

  function editFormValidation() {
    let error = {};
    let formErrorData = false;

    if (!editData.title) {
      formErrorData = true;
      error["title"] = "Job title is required";
    } else if (editData.title.length < 5) {
      formErrorData = true;
      error["title"] = "Job title Length more then 5!";
    }
    if (!editData.description) {
      formErrorData = true;
      error["description"] = "Description is required";
    }
    if (!editData.createddate) {
      formErrorData = true;
      error["createddate"] = "Effective date is required";
    } else if (
      oldData.createddate != editData.createddate &&
      editData.createddate < currentdate
    ) {
      formErrorData = true;
      error["createddate"] = "Effective date should not be before today.";
    }
    if (!editData.expirationdate) {
      formErrorData = true;
      error["expirationdate"] = "Expiration date is required";
    } else if (
      oldData.expirationdate != editData.expirationdate &&
      editData.expirationdate < currentdate
    ) {
      formErrorData = true;
      error["expirationdate"] = "Expiration date should not be before today.";
    }
    setFormError(error);
    return formErrorData;
  }

  function handleSubmit() {
    if (editData?.jobid == "") {
      handleAddJob();
    } else {
      updateJob();
    }
  }

  async function updateJob() {
    if (!editFormValidation()) {
      setIsLoader(true);
      var param = {
        jobid: "",
        occupation_id: editData.occupation_id,
        title: editData.title,
        createddate: editData.createddate,
        expirationdate: editData.expirationdate,
        description: editData.description,
        jobid: editData.jobid,
      };
      const { data } = await apiCall("POST", apiEndPoints.UPDATEJOB, param);
      if (data.status == 200) {
        cleanSetEditData();
        getJobList(itemOffset);
        successToast(data.message);
        setIsLoader(false);
      } else {
        errorToast(data.message);
        setIsLoader(false);
      }
    }
  }

  async function deleteJob(jobid) {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    };
    const params = {
      jobid: jobid,
    };

    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this job ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#6258D3",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { data } = await apiCall("POST", apiEndPoints.JOBDELETE, params);
        if (data.status == 200) {
          getJobList(itemOffset);
          successToast(data.message);
          cleanSetEditData();
        } else {
          errorToast(data.message);
        }
      }
    });
  }

  function cleanSetEditData() {
    setEditData({
      title: "",
      createddate: "",
      description: "",
      expirationdate: "",
      jobid: "",
      occupation_Id: 0,
      occupation: "",
    });

    /*  setEditData({
      ...editData,
      occupation : "",
    }) */
  }

  return (
    <>
      {/* ------------------------------------------ add job ----------------------------- */}
      <div
        className="modal fade addNewPhoto--modal"
        id="addNewPhoto"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="container">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add new job
                </h5>
                <button
                  id="closeModel"
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>

                <div className="progress-bar" />
              </div>
              <div className="modal-body">
                {jobListData.length <
                (jobNumberValidation && jobNumberValidation.number) ? (
                  <form>
                    <div className="form-group">
                      <label className="mb-0">Title</label>{" "}
                      <input
                        type="text"
                        className="form-control form-control-md"
                        placeholder="Title"
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            title: e.target.value,
                          })
                        }
                      />
                      <small className="text-danger">
                        {!editData.title && formError.title
                          ? formError.title
                          : ""}
                      </small>
                    </div>

                    <div className="form-group">
                      <label className="mb-0">Effective Date</label>{" "}
                      <input
                        type="date"
                        className="form-control form-control-md"
                        placeholder="Effective Date"
                        min={moment(new Date()).format("YYYY-MM-DD")}
                        max={moment(editData.expirationdate).format(
                          "YYYY-MM-DD"
                        )}
                        value={moment(editData.createddate)
                          .format("YYYY-MM-DD")
                          .toString()}
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            createddate: e.target.value,
                          })
                        }
                        // min={todaydate}
                      />
                      {
                        <small className="text-danger">
                          {!editData?.createddate && formError?.createddate
                            ? formError.createddate
                            : ""}
                        </small>
                      }
                    </div>
                    <div className="form-group">
                      <label className="mb-0">Expiration Date</label>{" "}
                      <input
                        type="date"
                        className="form-control form-control-md"
                        placeholder="Expiration Date"
                        // min={moment(new Date()).format("YYYY-MM-DD")}
                        min={moment(editData?.createddate)
                          .format("YYYY-MM-DD")
                          .toString()}
                        value={moment(editData.expirationdate)
                          .format("YYYY-MM-DD")
                          .toString()}
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            expirationdate: e.target.value,
                          })
                        }
                        // min={todaydate}
                      />
                      <small className="text-danger">
                        {!editData?.expirationdate && formError?.expirationdate
                          ? formError.expirationdate
                          : ""}
                      </small>
                    </div>
                    {/* <div className="form-group">
                    <label className="mb-0">Image</label>{" "}<small>Multiple</small>
                    <small className='text-danger'></small>
                    <input
                      type="file"
                      className="form-control form-control-md"
                      placeholder="Name"
                    />
                  </div> */}
                    <div className="form-group">
                      <label className="mb-0">Description</label>
                      <textarea
                        className="form-control form-control-md"
                        placeholder="Description"
                        defaultValue={""}
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            description: e.target.value,
                          })
                        }
                      />
                      <small className="text-danger">
                        {!editData.description && formError.description
                          ? formError.description
                          : ""}
                      </small>
                    </div>
                    <div className="form-group date text-right">
                      <button
                        type="button"
                        className="main-btn rounded"
                        style={{ minWidth: 160 }}
                        //onClick={() => handleAddJob()}
                        onClick={() => {
                          isLoader ? console.log('save') : handleAddJob();
                        }}
                      >
                        {isLoader ? (
                          <img
                            src="images/loader_white.gif"
                            style={{ width: "30px" }}
                          />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </form>
                ) : (
                  <div className="text-center">
                    <h5>
                      You can upload only {jobNumberValidation?.number} jobs.
                    </h5>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          {
            /* jobListData.length <
                    (jobNumberValidation && jobNumberValidation.number) ? */
            <>
              <div className="form-group">
                <label>Occupation</label>
                <div ref={wrapperRefoccup}>
                  <input
                    type="text"
                    className="form-control"
                    id="occupationId"
                    onChange={(e) => handleClickOcupation(e.target.value)}
                    placeholder="Occupation"
                    defaultValue={
                      editData?.occupation != "" ? editData?.occupation : ""
                    }
                    onBlur={(e) => manageOccupationSearch(e.target.value)}
                  />
                  <div>
                    <div
                      className={`city_list_box ${
                        occupationListShow ? "show" : "hide"
                      }`}
                      style={{ width: "91%" }}
                    >
                      {serviceSocList && serviceSocList.length > 0 ? (
                        <ul className="city_list_menu">
                          {serviceSocList.map((item, i) => {
                            return (
                              <li
                                key={i}
                                onClick={() => {
                                  setEditData({
                                    ...editData,
                                    occupation: item.title,
                                    occupation_id: item.socid,
                                  });
                                  handleSelectOccupation(item.title);
                                }}
                                className={`city_list ${
                                  editData?.occupation == item.socid
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <a> {item.title}</a>{" "}
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        <p
                          style={{
                            textAlign: "center",
                            marginTop: "2px",
                            color: "gray",
                          }}
                        >
                          No data
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <small className="text-danger">
                  {!editData.occupation && formError.occupation
                    ? formError.occupation
                    : ""}
                </small>
              </div>

              <div className="form-group">
                <label className="d-block mb-0">Job Title</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  placeholder="Job Title"
                  maxLength={100}
                  value={editData?.title}
                  onChange={(e) =>
                    setEditData({
                      ...editData,
                      title: e.target.value.trimStart(),
                    })
                  }
                />
                <small className="text-danger">
                  {!editData.title || formError.title ? formError.title : ""}
                </small>
              </div>
              {/* <div className="form-group">
                <label className="d-block mb-0">Effective Date</label>
                <input
                  type="date"
                  className="form-control form-control-md"
                  min={moment(new Date()).format("YYYY-MM-DD")}
                  value={editData?.createddate}
                  onChange={(e) =>
                    setEditData({
                      ...editData,
                      createddate: e.target.value,
                    })
                  }
                />
                <small className="text-danger">
                  {!editData?.createddate && formError?.createddate
                    ? formError.createddate
                    : ""}
                </small>
              </div> */}
              <div className="form-group">
                <label className="mb-0">Effective Date</label>{" "}
                <input
                  type="date"
                  className="form-control form-control-md"
                  placeholder="Effective Date"
                  min={moment().format("YYYY-MM-DD")}
                  max={moment(editData?.expirationdate).format("YYYY-MM-DD")}
                  value={editData?.createddate}
                  onChange={(e) =>
                    setEditData({
                      ...editData,
                      createddate: e.target.value,
                    })
                  }
                />
                <small className="text-danger">
                  {formError?.createddate ? formError.createddate : ""}
                  {/* {console.log('editData?.createddate', formError?.createddate)} */}
                </small>
              </div>
              <div className="form-group">
                <label className="d-block mb-0">Expiration Date</label>
                <input
                  type="date"
                  className="form-control form-control-md"
                  min={moment(editData?.createddate).format("YYYY-MM-DD")}
                  value={editData?.expirationdate}
                  onChange={(e) =>
                    setEditData({
                      ...editData,
                      expirationdate: e.target.value,
                    })
                  }
                />
                <small className="text-danger">
                  {/* {!editData?.expirationdate && formError?.expirationdate
                    ? formError.expirationdate
                    : ""} */}
                  {formError?.expirationdate ? formError.expirationdate : ""}
                </small>
              </div>
              <div className="form-group">
                <label className="d-block mb-0">Description</label>
                <textarea
                  className="form-control form-control-md"
                  placeholder="Description"
                  value={editData?.description}
                  onChange={(e) =>
                    setEditData({
                      ...editData,
                      description: e.target.value,
                    })
                  }
                />
                <small className="text-danger">
                  {!editData.description && formError.description
                    ? formError.description
                    : ""}
                </small>
              </div>
              <div className="form-group">
                <button
                  type="button"
                  className="btn main-btn w-100"
                  /*onClick={() => {
                    handleSubmit();
                  }} */
                  onClick={ () => {
                    isLoader ? console.log('save') : handleSubmit();
                  }}
                >
                 {/*  {editData?.jobid ? "Update Job" : "Save Job"} */}
                  {isLoader ? (
                    <img
                      src="images/loader_white.gif"
                      style={{ width: "30px" }}
                    />
                  ) :  editData?.jobid ? "Update Job" : "Save Job"
                 }
                </button>
              </div>
            </>
            /*:
        <div className="limit-msg">{`You have uploaded ${jobNumberValidation.number} Jobs`}</div> */
          }
        </div>
        <div className="col-md-6">
          <div className="card border-8">
            <label className="card-header border-t-8 bg--smb text-white p-1">
              My list job
              <i
                onClick={() => {
                  cleanSetEditData();
                }}
                className="fa fa-plus add-new-item"
                style={{ marginLeft: "83%" }}
              ></i>
            </label>
            <div className="card-body text-center">
              <div className="row align-items-center">
                {isListLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <img className="listloadergif" src="images/infinity.gif" />
                  </div>
                ) : jobListData && jobListData.length > 0 ? (
                  <>
                    <table className="table table-sm table-responsive">
                      <thead>
                        <tr className="tbl--row">
                          <th scope="col" className="font-size-14" width="1%">
                            #
                          </th>
                          <th scope="col" className="font-size-14">
                            Name
                          </th>
                          <th scope="col" className="font-size-14">
                            Eff. Date
                          </th>
                          <th scope="col" className="font-size-14">
                            Exp. Date
                          </th>
                          <th scope="col" className="font-size-14">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {jobListData.map((item, i) => (
                          <tr className="tbl--row" key={i}>
                            <td className="font-size-14">{i + 1}</td>
                            <td className="font-size-14">{item.title}</td>
                            <td className="font-size-14">
                              {moment(item.createddate).format("MM-DD-YYYY")}
                            </td>
                            <td className="font-size-14">
                              {moment(item.expirationdate).format("MM-DD-YYYY")}
                            </td>
                            <td
                              className="font-size-14"
                              style={{ display: "contents" }}
                            >
                              {/* <a
                                onClick={() => setEditData(item)}
                                title="View"
                                className="cursor-pointer color__light mr-1"
                                data-toggle="modal"
                                data-target="#viewJob"
                              >
                                <i className="fa fa-play fa__icons"></i>
                              </a> */}
                              <a
                                onClick={() => {
                                  setEditData(item);
                                  setOldData(item);
                                }}
                                title="Edit"
                                className="color__light mr-1"
                              >
                                <i className="fa fa-edit fa__icons"></i>
                              </a>
                              <a
                                onClick={() => {
                                  deleteJob(item.jobid);
                                }}
                                title="Delete"
                                className="color__light"
                              >
                                <i className="fa fa-trash fa__icons"></i>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {jobListData.length > 10 ? (
                      <>
                        <div className="btm-pagination mt-4">
                          <div className="d-flex justify-content-end">
                            <ReactPaginate
                              // previousLabel={'previous'}
                              // nextLabel={'next'}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={pageCount}
                              marginPagesDisplayed={4}
                              pageRangeDisplayed={2}
                              onPageChange={handlePageClick}
                              containerClassName={
                                "pagination pagination-style-02 justify-content-center"
                              }
                              subContainerClassName={"pages pagination"}
                              activeClassName={"current active active_pg"}
                              activeLinkClassName={"current active active_pg"}
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link bg-gray"}
                              previousClassName={"page-item"}
                              nextClassName={"page-item"}
                              previousLinkClassName={"page-link bg-gray"}
                              nextLinkClassName={"page-link bg-gray"}
                              previousLabel={
                                <i className="fal fa-chevron-left"></i>
                              }
                              nextLabel={
                                <i className="fal fa-chevron-right"></i>
                              }
                              // --------------------------------------
                              currentPage={1}
                              todosPerPage={3}
                              upperPageBound={3}
                              lowerPageBound={0}
                              isPrevBtnActive={"disabled"}
                              isNextBtnActive={""}
                              paeBound={3}
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                  </>
                ) : (
                  <div className="text-center">
                    <img
                      className="no-foundimage"
                      src="images/nodatafound.png"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JobList;
