import React, { useRef, useState, useEffect } from "react";
import {
  ValidatePopup,
  RegisterPopup,
  CertifyPopup,
  FormSucessPopup,
  SubscriptionPopup,
} from "./registrationPopup";
import {
  apiCall,
  successToast,
  errorToast,
} from "../../../../utils/httpClient";
import ApiEndPoint from "../../../../utils/apiEndPoints";
import { useSelector, useDispatch } from "react-redux";
import { setBusinessRegisterDetail } from "../../../../reduxStore/action/usersActions";
import axios from "axios";
export default function Joinus() {
  const businessData = useSelector((store) => store.businessData);

  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);
  const [formVaildate, setFormVaildate] = useState({
    countrycode: "+1",
    mobile: "",
    email: "",
  });
  const [formVaildateStatus, setFormVaildateStatus] = useState(false);
  const [formError, setFormError] = useState({});
  console.log("🚀 ~ Joinus ~ formError:", formError);

  const [formsubmit, setFormsubmit] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setFormError({})
  //   }, 5000);
  // }, [formError])

  useEffect(() => {
    getIpAddress()
  }, []);

  function formValidation() {
    let error = {};
    let formErrorData = false;

    const emailValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!formVaildate.email || emailValid.test(formVaildate.email) === false) {
      formErrorData = true;
      error["email"] = "Please Entery Valid Email address";
    }

    // if (!formVaildate.mobile) {
    //   formErrorData = true;
    //   error["mobile"] = "Mobile is required";
    // }

    // if (formVaildate.mobile.length !== 10) {
    //   formErrorData = true;
    //   error["mobile"] = "Enter 10 digits Mobile number";
    // }

    setFormError(error);
    return formErrorData;
  }
    
  const [otpShow, setOtpShow] = useState(false);
  async function handleVaildateIdentity() {
    if (!formValidation()) {
      setIsLoader(true);

      const param = {
        email: formVaildate.email,
        ipaddress: ipaddress,
        // mobile: formVaildate.mobile,
        // countrycode: formVaildate.countrycode,
      };
      console.log("🚀 ~ handleVaildateIdentity ~ param:", param)

      const { data } = await apiCall("POST", ApiEndPoint.USERREGISTER, param);
      if (data.status == 200) {
        setIsLoader(false);
        if (data.data.indentityValidated == 0) {
          setOtpShow(true);
          
        } else {
          if (data.data.businessValidated == 1) {
            setIsLoader(false);
            //setbusinessRegiDataStatus(true);
            document.getElementById("businessRegiMdlClose").click();
            otpVarify();
          } else {
            console.log("tercero else");
            setIsLoader(false);
            otpVarify();
          }
        }
        successToast(data.message?.messageTost);
        dispatch(setBusinessRegisterDetail(data.data));
        // dispatch(setBusinessRegisterDetail(formVaildate))

        // setOtpShow(true)
        // successToast(data.message)
        // dispatch(setBusinessRegisterDetail(formVaildate))
        // setFormVaildateStatus(true);
        // document.getElementById('vaildateIdentityModel').click()
      } else {
        console.log("cuarto if");
        setIsLoader(false);
        setFormError(data.message);
        errorToast(data.message?.messageTost);
      }
    }
  }

  function otpVarify() {
    setFormVaildateStatus(true);
    document.getElementById("vaildateIdentityModel").click();
    document.getElementById("openregpopup").click();
  }

  // businessRegiData
  const [businessRegiDataStatus, setbusinessRegiDataStatus] = useState(false);
  const [serviceSelect, setServiceSelect] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState({});
  const [selectedSocService, setSelectedSocService] = useState({});
  const [selectedCity, setSelectedCity] = useState({});
  const [ipaddress, setIpaddress] = useState(null);

  const [businessRegiData, setBusinessRegiData] = useState({
    business_username: "",
    business_name: "",
    payment_method: "",
    phone: "",
    address: "",
    business_url: "",
    lastModifiedDate: "",
    cityid: "",
    facebookurl: "",
    // head_count: "",
    hours_operation: "",
    industry: "",
    introduction: "",
    linkedInurl: "",
    numemps: "",
    pricehour: "",
    revenue: "",
    servicing_areas: "",
    servicing_offer: "",
    twitterurl: "",
    website: "",
    youtubeurl: "",
    service_offer: "",
    instagramurl: "",
    certificate: "",
    is_non_profit: 0,
  });

  // const [formError, setFormError] = useState({})

  function validation() {

    
    let errors = {};
    let validError = false;
    if (!businessRegiData?.business_username) {
      validError = true;
      errors["business_username"] = "Business username is required!";
    }

    if (!businessRegiData?.business_name) {
      validError = true;
      errors["business_name"] = "Business Name is required!";
    }
   /*  if (
      !businessRegiData?.address ||
      (businessRegiData?.address &&
        businessRegiData?.address.trim().length === 0)
    ) {
      validError = true;
      errors["address"] = "Address is required!";
    } */

    if (!businessRegiData?.certificate) {
      validError = true;
      errors["certificate"] = "Business License is required!";
    }

    if (selectedOption.length == 0) {
      validError = true;
      errors["service"] = "Occupation is required!";
    }
   /*  if (!businessRegiData?.industry) {
      validError = true;
      errors["industry"] = "Industry is required!";
    } */

    if (!businessRegiData?.cityid) {
      validError = true;
      errors["cityid"] = "City is required!";
    }

    setFormError(errors);
    return validError;
  }
    

  const getIpAddress = async () => {
   /*  const res = await axios.get("https://geolocation-db.com/json/");
    return res.data.IPv4; */

    fetch('https://api.ipify.org?format=json').then(response => {
    return response.json();
  }).then((res) => {
    console.log("🚀 ~ fetch ~ res:", res)
    setIpaddress(_.get(res, 'ip'))
    
    //this.myIp = _.get(res, 'ip');
  }).catch((err) => setIpaddress("0.0.0.0"))

    
  };

  async function submitBusinessForm() {
    const serviceValu =
      selectedOption && selectedOption.length > 0
        ? selectedOption.map((item) => item.cat).join(",")
        : "";

    if (!validation() && !formsubmit) {
      setIsLoader(true);
      const frmData = new FormData();
      frmData.append(
        "profileid",
        businessData?.businessRegisterDetail?.profileid
      );
      frmData.append("fullname", businessRegiData?.business_name);
      frmData.append("address", businessRegiData?.address);
      // frmData.append('cityid', selectedCity.value);
      frmData.append("cityid", businessRegiData.cityid);
      /* frmData.append("state", 0);
      frmData.append("county", 0); */
      frmData.append("numemps", businessRegiData?.numemps);
      //frmData.append('numemps', selectedSocService.value);
      frmData.append("annualgrossrevenue", 2000); //linea comentada : sacar el valor y agregar esto : businessRegiData?.revenue
      frmData.append("about", businessRegiData?.introduction);
      frmData.append("pricehour", 0); //linea comentada : sacar el valor y agregar esto : businessRegiData?.pricehour
      frmData.append("hours", businessRegiData.hours_operation);
      frmData.append("payments", businessRegiData?.payment_method);
      frmData.append("phone", businessRegiData?.phone);
      frmData.append("email", businessRegiData?.phone);
      //frmData.append('email', businessData?.businessRegisterDetail?.email);
      frmData.append("websiteurl", businessRegiData?.website);
      frmData.append("showcall", 1);
      frmData.append("showtext", 1);
      frmData.append("showemail", 1);
      frmData.append("service_offer", businessRegiData?.service_offer);
      frmData.append("service_area", businessRegiData?.service_area);
      frmData.append("industry", businessRegiData.industry);
      frmData.append("year_revenue", ""); //linea comentada : sacar el valor y agregar esto : businessRegiData?.revenue
      frmData.append("business_validation", 1);
      frmData.append("naicsid", serviceValu);
      frmData.append("facebookurl", businessRegiData?.facebookurl);
      frmData.append("linkedInurl", businessRegiData?.linkedInurl);
      frmData.append("twitterurl", businessRegiData?.twitterurl);
      frmData.append("youtubeurl", businessRegiData?.youtubeurl);
      frmData.append("instagramurl", businessRegiData?.instagramurl);
      frmData.append("photofile", businessRegiData?.business_url);
      frmData.append("is_nonprofit", businessRegiData?.is_non_profit);
      frmData.append("certificate", businessRegiData?.certificate);
      frmData.append("businessusername", businessRegiData?.business_username);
      frmData.append("ipaddress", ipaddress);

      const header = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const { data } = await apiCall(
        "POST",
        ApiEndPoint.REGISTERBUSINESSDETAIL,
        frmData,
        header
      );

      if (data.status == 200) {
        setIsLoader(false);
        setOtpShow(true);
        successToast(data.message);
        dispatch(setBusinessRegisterDetail(data.data));
        setbusinessRegiDataStatus(true);
        document.getElementById("businessRegiMdlClose").click();
        document.getElementById("sucessModelBtn").click();
      } else {
        setIsLoader(false);
        if (data.status == 401) {
          let errors = {};
          errors["business_name_check"] = data.message;
          setFormError(errors);
        }
        errorToast(data.message);
      }
    }
  }

  // certityBusiness
  const [certityBusiness, setCertityBusiness] = useState({
    mobile: "",
    email: "",
  });
  const [certityBusinessStatus, setCertityBusinessStatus] = useState(false);

  // const [formError, setFormError] = useState({})
  function certiFormValidation() {
    let valiError = {};
    let formErrorData = false;

    const emailValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (
      !certityBusiness.email ||
      emailValid.test(certityBusiness.email) === false
    ) {
      formErrorData = true;
      valiError["email"] = "Please Entery Valid Email address";
    }
    if (!certityBusiness.mobile) {
      formErrorData = true;
      valiError["mobile"] = "Mobile is required";
    }
    setFormError(valiError);
    return formErrorData;
  }

  function handleCertityBusiness() {
    // if (!certiFormValidation()) {
    //     console.log('certityBusiness: ===> ', certityBusiness);
    //     setCertityBusinessStatus(true)
    //     document.getElementById('certifyBusiness').click();
    // }
    setCertityBusinessStatus(true);
    document.getElementById("certifyBusiness").click();
    document.getElementById("sucessModelBtn").click();
  }

  return (
    <>
      <ValidatePopup
        isLoader={isLoader}
        otpVarify={otpVarify}
        otpShow={otpShow}
        handleVaildateIdentity={handleVaildateIdentity}
        formVaildate={formVaildate}
        setFormVaildate={setFormVaildate}
        formError={formError}
        formVaildateStatus={formVaildateStatus}
        setFormError={setFormError}
      />

      <RegisterPopup
        setFormError={setFormError}
        isLoader={isLoader}
        submitBusinessForm={submitBusinessForm}
        businessRegiData={businessRegiData}
        setBusinessRegiData={setBusinessRegiData}
        formError={formError}
        setServiceSelect={setServiceSelect}
        serviceSelect={serviceSelect}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        selectedIndustry={selectedIndustry}
        setSelectedIndustry={setSelectedIndustry}
        selectedSocService={selectedSocService}
        setSelectedSocService={setSelectedSocService}
        selectedCity={selectedCity}
        setSelectedCity={setSelectedCity}
        setFormsubmit={setFormsubmit}
      />

      {/* <RegisterPopup submitBusinessForm={submitBusinessForm} businessRegiData={businessRegiData} setBusinessRegiData={setBusinessRegiData} formError={formError} setServiceSelect={setServiceSelect} serviceSelect={serviceSelect} /> */}
      <CertifyPopup
        handleCertityBusiness={handleCertityBusiness}
        certityBusiness={certityBusiness}
        setCertityBusiness={setCertityBusiness}
        formError={formError}
        setCertityBusinessStatus={setCertityBusinessStatus}
      />

      <FormSucessPopup />
      <SubscriptionPopup />

      <section className="default-smb-area bg--light">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12">
              <div className="section-title text-center">
                <h3 className="title">
                  Join MAXBIZ &amp; get FREE local leads
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-6" style={{ margin: "auto" }}>
            <div className="card border-8 shadow">
              <h5 className="card-header border-t-8 bg--smb"></h5>
              <div className="card-body text-center mt-2 mb-2">
                <div className="row align-items-center mt-4 mb-4">
                  <div className="col-lg-12">
                    <img src="images/user-check-solid.svg"></img>
                    {/* <i
                      className="fa fa-user-check"
                      style={{ fontSize: "100px", color: "#6258D3" }}
                    ></i> */}
                  </div>
                </div>
                <h5 className="card-title">Validate your identity</h5>
                <p className="card-text">
                  We want to provide users with a legitimate, safe and
                  transparent experience in our website.
                </p>
              </div>
              <div className="col-lg-12">
                <div className="form-group text-center ">
                  {formVaildateStatus ? (
                    <a
                      href="#"
                      className="btn main-btn mt-4 w-100"
                      data-toggle="modal"
                      data-target={
                        formVaildateStatus ? "#businessRegister" : ""
                      }
                    >
                      Begin
                    </a>
                  ) : (
                    <a
                      href="#"
                      className="btn main-btn mt-4 w-100"
                      data-toggle="modal"
                      data-target="#vaildateId"
                    >
                      Begin
                    </a>
                  )}
                  <a
                    href="#"
                    style={{ display: "none" }}
                    id="openregpopup"
                    className="btn main-btn mt-4 w-100"
                    data-toggle="modal"
                    data-target="#businessRegister"
                  >
                    Begin
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12">
              <div className="section-title text-center">
                <h3 className="title">
                  Join SMB Market &amp; get FREE local leads
                </h3>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-9">
              <div className="join-item join--smb-item mt-30">
                <div className="text-center join--itemnum-box"></div>
                <div className="join--idnty-top text-center">
                  <div className="join--idnty-img">
                    <img src="images/icons/idcard.png" />
                  </div>
                  <a href="#">
                    <span>Validate your identity</span>
                  </a>
                </div>
                <div className="join--idnty-content text-center">
                  <p>
                    We want to provide users with a legitimate, safe and
                    transparent experience in our website.
                  </p>
                  {formVaildateStatus ? (
                    <a
                      href="#"
                      className="main-btn join--begin-btn rounded"
                      data-toggle="modal"
                      data-target={
                        formVaildateStatus ? "#businessRegister" : ""
                      }
                    >
                      Begin
                    </a>
                  ) : (
                    <a
                      href="#"
                      className={`join--begin-btn rounded ${
                        formVaildateStatus ? "main-btn" : "main-btn--gray"
                      }`}
                      data-toggle="modal"
                      data-target="#vaildateId"
                    >
                      Begin
                    </a>
                  )}
                  <a
                    href="#"
                    style={{ display: "none" }}
                    id="openregpopup"
                    className={`${
                      businessRegiDataStatus ? "main-btn" : "main-btn--gray"
                    } join--begin-btn rounded`}
                    data-toggle="modal"
                    data-target="#businessRegister"
                  >
                    Begin
                  </a>
                </div>
              </div>
            </div>
          </div> */}
          <a
            href="#"
            id="sucessModelBtn"
            data-toggle="modal"
            data-target="#sucessModel"
          ></a>
        </div>
      </section>
    </>
  );
}
