import { combineReducers } from 'redux'
// import userReducer from './userReducers'
import {userDataStore, userBusinessDataStore, userLoginStatusStore} from './userReducers'
import searchServiceData from './searchService'
import serviceDetail from './serviceRoute'

export default combineReducers({
  // users: userReducer,-
  userData: userDataStore,
  businessData: userBusinessDataStore,
  userLoginStatus: userLoginStatusStore,
  searchService: searchServiceData,
  serviceDetail: serviceDetail
  // userDataStore
})