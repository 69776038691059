import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { apiCall } from "utils/httpClient";
import apiEndPoints from "utils/apiEndPoints";
import { successToast } from "utils/httpClient";
import { errorToast } from "utils/httpClient";
import moment from "moment";
import base64 from "base-64";
import { Document, Page } from "react-pdf";
function UserList(props) {
  const {searchkey}= props;
  const formRef = useRef();
  let history = useHistory();
  const [editData, setEditData] = useState({
    title: "",
    required_amount: "",
    summary: "",

  });

  const [editStatus, setEditStatus] = useState(false);
  const [alluserListData, setAllUserListData] = useState([]);
  const [userListData, setUserListData] = useState([]);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState();
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const [isListLoading, setIsListLoading] = useState(false)
  const [showloader, setShowloader] = useState(true)





  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage);
    setItemOffset(newOffset)
    getuserlist(newOffset);
  };


  useEffect(() => {
    getuserlist(itemOffset);

  }, []);

  useEffect(() => {
    filterbusiness(searchkey)
  }, [searchkey]);

  const filterbusiness = (searchTerm) => {
     const filteredBusiness = alluserListData.filter(item =>{

     const firstname = item.firstname ? item.firstname.toLowerCase() : "";
     const email = item.email ? item.email.toLowerCase() : "";
     return  firstname.toLowerCase().includes(searchTerm.toLowerCase()) || 
     email.toLowerCase().includes(searchTerm.toLowerCase())
    }
    );

    setUserListData(filteredBusiness); // Update the state with the filtered data
  };

  async function getuserlist(offset) {
    showloader ?
      setIsListLoading(true)
      : console.log('')
    const params = {
      limit: itemsPerPage,
      offset: offset,
    };
    const { data } = await apiCall(
      "POST",
      apiEndPoints.GETALLUSERLIST,
      params
    );
    if (data.status == 200) {
      setShowloader(false)
      setIsListLoading(false)
      setUserListData(data.data);
      setAllUserListData(data.data);
      const parPage = (data.total_data / itemsPerPage);
      setPageCount(parPage)

    } else {
      setIsListLoading(false)
      setUserListData([]);
    }
  }


  function handleModel() {
    setEditStatus(false);
    const hh = "";
    setEditData({});
    console.log("handleModel -> setEditData", editData)

  }
  return (
    <>

      {/* ----------------------------------------------- edit --------------------------------------- */}
      <div
        className="modal fade addNewPhoto--modal"
        id="editNew"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="container">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {editStatus ? "User Detail" : "User Detail"}
                </h5>
                <button
                  id="closeEditModal"
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => handleModel()}
                >
                  <span aria-hidden="true">×</span>
                </button>

                <div className="progress-bar" />
              </div>
              <div className="modal-body">
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="d-block mb-0">User Name:-</label>
                        <span className="show-detail">{(editData.firstname !== '' && editData.firstname !== null) ? editData.firstname + " " + editData.lastname : '-'}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="d-block mb-0">Email:-</label>
                        <span className="show-detail">{editData?.email}</span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                   {/*  <div className="col-md-6">
                      <div className="form-group">
                        <label className="d-block mb-0">Mobile:-</label>
                        <span className="show-detail">{editData?.mobile}</span>
                      </div>
                    </div> */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="d-block mb-0">DOB:-</label>
                        <span className="show-detail">{(editData.dob !== '' && editData.dob !== null) ? moment(editData.dob).format("MM-DD-YYYY") : '-'}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="d-block mb-0">Gender:-</label>
                        <span className="show-detail">{editData?.gender == 1 ? 'Male' : 'Female'}</span>
                      </div>
                    </div>
                  </div>




                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="d-block mb-0">City:-</label>
                        <span className="show-detail">{(editData.city_name !== '' && editData.city_name !== null) ? editData.city_name : '-'}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="d-block mb-0">Occupation:-</label>
                        <span className="show-detail">{(editData.occupation_name !== '' && editData.occupation_name !== null) ? editData.occupation_name : '-'}</span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="d-block mb-0">Yearly Income:-</label>
                        <span className="show-detail">{(editData.income !== '' && editData.income !== null) ? editData.income : '-'}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="d-block mb-0">Education:-</label>
                        <span className="show-detail">{(editData.education !== '' && editData.education !== null) ? editData.education : '-'}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="d-block mb-0">Marital Status:-</label>
                        <span className="show-detail">{(editData.maritalstatus !== '' && editData.maritalstatus !== null) ? editData.maritalstatus == 0 ? 'UnMarried' : 'Married' : '-'}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="d-block mb-0">Subscription:-</label>
                        <span className="show-detail">{
                          // editData.plan_type === 1 ? 'Self Employed' :
                          editData.plan_type === 2 ? 'Paid' :
                            // editData.plan_type === 3 ? 'Active Investor' :
                            'Free'}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="d-block mb-0">Have Kids:-</label>
                        <span className="show-detail">{editData?.havekids == 0 ? 'No' : 'Yes'}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="d-block mb-0">IP Address:-</label>
                        <span className="show-detail">{(editData.ipaddress !== '' && editData.ipaddress !== null) ? editData.ipaddress : '-'}</span>
                      </div>
                    </div>
                   
                  </div>
                  <div className="row">
                   
                  </div>






                  <div className="form-group date text-right">

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* --------------------------------------------- view ------------------------------- */}
      <div className="row" style={{ marginTop: "0.5rem" }}>
        <div className="col-md-12">
          <div class="card card-primary">
            <div class="card-header" style={{ backgroundColor: '#867df1' }}>
              <h5 class="card-title" style={{ color: '#ffff' }}>Users List</h5>
            </div>
            <div class="card-body" style={{ padding: 0 }}>


              <div className="">
                {isListLoading ? (
                  <div style={{ textAlign: 'center' }}>
                    <img className="listloadergif-admin" src="images/infinity.gif" />

                  </div>
                ) : userListData && userListData.length > 0 ? (

                  <div>
                    <div className="display--table">
                      <table className="table table-border table-light">
                        <thead>
                          <tr className="tbl--row">

                            <th className="">#</th>
                            <th className="">User Name</th>
                            {/* <th className="">Mobile</th> */}
                            <th className="">Email</th>
                            <th className="">DOB</th>
                            {/* <th className="">Subscription</th> */}
                            <th className="">Email Verify</th>
                            <th className="">Action</th>
                          </tr>
                        </thead>
                        <tbody>

                          {userListData.map((item, i) => (
                            <tr className="tbl--row">

                              <td>{itemOffset + i + 1}</td>
                              <td>{(item.firstname !== '' && item.firstname !== null) ? item.firstname + " " + item.lastname : '-'}</td>
                              {/* <td>{item.mobile}</td> */}
                              <td>{item.email}</td>


                              <td>{(item.dob !== '' && item.dob !== null) ? moment(item.dob).format("MM-DD-YYYY") : '-'}</td>
                              <td>
                                <span
                                  className={
                                    item.emailvalid == "0"
                                      ? "btn btn-danger btn-sm"
                                      : "btn btn-success btn-sm"
                                  }
                                >
                                  {item.emailvalid == "0"
                                    ? "Pending"
                                    : "Verified"}
                                </span>
                              </td>


                              <td>
                               {" "}
                                <a
                                  onClick={() => {
                                    setEditData(item);
                                    setEditStatus(true);
                                  }}
                                  data-toggle="modal"
                                  data-target="#editNew"
                                >
                                  <img
                                    className="tbl--actin-icon"
                                    src="images/icons/eye.png"
                                  />
                                </a>{" "}

                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="btm-pagination mt-4">
                      <div className="d-flex justify-content-end">
                        <ReactPaginate
                          // previousLabel={'previous'}
                          // nextLabel={'next'}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={pageCount}
                          marginPagesDisplayed={4}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          containerClassName={
                            "pagination pagination-style-02 justify-content-center"
                          }
                          subContainerClassName={"pages pagination"}
                          activeClassName={"current active active_pg"}
                          activeLinkClassName={"current active active_pg"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link bg-gray"}
                          previousClassName={"page-item"}
                          nextClassName={"page-item"}
                          previousLinkClassName={"page-link bg-gray"}
                          nextLinkClassName={"page-link bg-gray"}
                          previousLabel={<i className="fal fa-chevron-left"></i>}
                          nextLabel={<i className="fal fa-chevron-right"></i>}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-center">
                    <img className="no-foundimage" src="images/nodatafound.png" />
                    <span style={{"marginBottom": "3%"}} className="not-found-content">Ooops, no Users found</span>
                  </div>
                )}
              </div>

            </div>
          </div>
        </div>
      </div>



    </>
  );
}

export default UserList;
