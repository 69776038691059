import { SEARCHSERVICESELECT } from '../types'

const initialState = {
    serviceDetail:{},
    loading:true
}

const serviceDetail = (state = initialState, action) => {
    switch (action.type) {

        case SEARCHSERVICESELECT:
        return {
            ...state,
            serviceDetail:action.payload,
        }
        default: return state
    }
}

export default serviceDetail