import React, { useState, useEffect } from 'react';
import ReturnplanScreen from "./component/redirectplan";
import { apiCall, errorToast, setDefaultHeader } from 'utils/httpClient';
import ApiEndPoint from 'utils/apiEndPoints';

import { useHistory } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { setUserAuthData, setBusinessRegisterDetail, handleLoginStatus } from '../../../reduxStore/action/usersActions'
import { useAuth } from "component/context/UserContext";


// This values are the props in the UI
/* const amount = "2";
const currency = "USD";
const style = {"layout":"vertical","shape":"rect","color":"gold"}; */

// Custom component to wrap the PayPalButtons and handle currency changes

function Redirect(props) {

  const { signIn } = useAuth();
  const dispatch = useDispatch();


  const { subscriptiondataplan, purchaseplanAPI, isLoadingloader } = props
  let history = useHistory();
  const [subscriptiondata, setSubscriptiondata] = useState(subscriptiondataplan || {});
  
  const [isLoading, setIsLoading] = useState(false);
  const [succeeded, setSucceeded] = useState(false);



  useEffect(() => {
    const subscriptiondatalocal = JSON.parse(localStorage.getItem("subscriptiondata") || {})
    //console.log("Redirect -> subscriptiondatalocal", subscriptiondatalocal)
    if (!subscriptiondatalocal) {
      history.push('/')
    } else {
      //console.log("Redirect -> subscriptiondata", subscriptiondata)

      /* (subscriptiondata?.plan_type === 1) ?
        purchaseplan(subscriptiondata) :
        setSubscriptiondata(subscriptiondata) */
      //setSubscriptiondata(subscriptiondata)
    }
  }, []);

  const reloginbusiness = async () => {
    setIsLoading(true)
    const parms = {
      profile_id: subscriptiondata?.profile_id,

    }
    const { data } = await apiCall('POST', ApiEndPoint.RELOGIN_BUSINESS, parms)

    if (data.status === 200) {

      setIsLoading(false)
      if (data.data.businessValidated === 1) {

        if (data.data.subscriptionplan === 1) {

          dispatch(setUserAuthData(data.data));
          dispatch(handleLoginStatus({ username: data.data.first_name, userType: 'business', token: data.token }));
          // successToast(data.message)
          await localStorage.setItem('authToken', data.token)
          signIn(0, data.token, 'business')
          await setDefaultHeader('token', data.token)
          await localStorage.setItem('allinformation', data.data.allinformation)
          await localStorage.setItem('plan_type', data.data.plan_type)
          history.push("/");

        } else {


          localStorage.setItem("profile_id", data.data?.profileid)
          dispatch(setBusinessRegisterDetail(data.data))
          history.push("/subscriptionplan");

        }

      } else {

        dispatch(setBusinessRegisterDetail(data.data))
        errorToast(data.message?.messageTost)
        history.push("/join_us");
      }

    } else {
      setIsLoading(false)
      errorToast(data.message?.message);
    }

  }













  return (

    <div style={{ maxWidth: "100%", "text-align": "center" }}>
      {
        !succeeded ?

          (Object.keys(subscriptiondata).length > 0 && subscriptiondata?.plan_type !== 1) ?

            subscriptiondata?.plan_type === 2 ?
            <>
              
              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input type="hidden" name="hosted_button_id" value="QE8YFDEEKU43U" />
                <table style={{ display: 'none' }}>
                  <tr><td><input type="hidden" name="on0" value="Scope Options" />Scope Options</td></tr><tr><td>
                    <select name="os0">
                      <option value="City">${subscriptiondata?.amount} USD - monthly</option>

                    </select> </td></tr>
                </table>
                <input type="hidden" name="currency_code" value="USD" />
                <input
                  style={{ width: "172px" }}
                  type="image"
                  src="https://www.paypalobjects.com/en_US/i/btn/btn_subscribeCC_LG.gif"
                  border="0"
                  name="submit"
                  alt="PayPal - The safer, easier way to pay online!"
                />
                <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
              </form>
              </>
              :
              <>
              
              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input type="hidden" name="hosted_button_id" value="NCWGFWSVVF87L" />
                <input style={{ width: "172px" }} type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_subscribeCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
                <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
              </form>
              </>


            :
            <div style={{ marginTop: 27 }}>
              <button
                type="button"
                className="main-btn rounded"
                style={{ minWidth: 150, marginRight: 8 }}
                onClick={() => purchaseplanAPI()}

              >{isLoadingloader ? <img src="images/loader_white.gif" style={{ width: '28px' }} /> : 'Yes'}</button>
              <button
                type="button"
                className="main-btn rounded"
                style={{ minWidth: 150 }}
              //onClick={accountDetailUpdate}
              >No</button>
            </div>
          :
          <ReturnplanScreen

            reloginbusiness={() => reloginbusiness()}
            isLoading={isLoading}

          />

      }





    </div>

  )
}

export default Redirect;
