
function loader() {
    return (
        <div className="preloader">
            <div className="lds-ellipsis">
                <span />
                <span />
                <span />
            </div>
        </div>
    );
}

export default loader;
