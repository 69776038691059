import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import ListScreen from "./component/index";
import DetailsScreen from "./component/details";
import ChangePasswordScreen from "./component/changepassword";
import ActiveinvestorScreen from "./component/investorRequestList";
import RealEstateScreen from "./component/realestate";
import { useEffect, useState } from "react";
import apiEndPoints from "utils/apiEndPoints";
import { apiCall } from "utils/httpClient";
import { useSelector } from "react-redux";
import Loader from "common/loader";

function Dashboard(props) {
  let { path, url } = useRouteMatch();
  const location = useLocation();
  //const { pathname } = location;
  const userData = useSelector((store) => store.userData);
  console.log("🚀 ~ Dashboard ~ userData:", userData
  )
  const [pageShow, setPageShow] = useState("Details");
  const [isLoader, setIsLoader] = useState(false);
  const [checkFirstLogin, setCheckFirstLogin] = useState(userData?.userData.firstlogin);
  const [businessDetails, setBusinessDetail] = useState({});
  const [plantype, setPlantype] = useState(0);

  var allinformation = localStorage.getItem("allinformation");
  /* var plantype = localStorage.getItem("plan_type");
  console.log('plantype: ', plantype); */

  // const [selectedService, setSelectedService] = useState([]);
  useEffect(() => {
    getProfileData()
  }, []);

  const getProfileData = async () => {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    };
    const { data } = await apiCall(
      "GET",
      apiEndPoints.USERPROFILEDETAILS,
      header
    );
    if (data.status == 200) {
      if(data.data?.plan_type){
        setPlantype(data.data?.plan_type)
        await localStorage.setItem('plan_type', data.data.plan_type)
        //plantype = data.data?.plan_type
        //console.log("🚀 ~ getProfileData ~ data.data?.plan_type:", data.data?.plan_type)
        
      }
      //console.log("🚀 ~ getProfileData ~ data.data:", data.data)
      
    }
  };


  return (
    <section
      className="default-smb-business bg--light"
      style={{ minHeight: 500 }}
    >
      <div className="container">
        <div className="col-lg-12 col-pd">
          <div className="mb-3 mt-4 pt-2">
            {allinformation == 1 ? (
              <>
                <div className="srvc-dt-menu">
                  <ul className="srvc--menu ">
                    <li onClick={() => setPageShow("Details")}
                      className={`srvc--gallary-menu ${pageShow === `Details` && "active-link"
                        }`}
                    >
                      <span className="lable-txt">
                        Details
                      </span>
                    </li>{
                      console.log("🚀 ~ Dashboard ~ plantype:", plantype)
                    }
                    {plantype == 1 ? (
                      <>

                        <li  onClick={() => setPageShow("Photo")}
                          className={`srvc--gallary-menu ${pageShow === `Photo` && "active-link"
                            }`}
                        >
                          <span className="lable-txt">
                            Photos
                          </span>
                        </li>
                        <li onClick={() => setPageShow("Video")}
                          className={`srvc--gallary-menu ${pageShow === `Video` && "active-link"
                            }`}
                        >
                          <span className="lable-txt">
                            Videos
                          </span>
                        </li>
                        
                        <li onClick={() => setPageShow("Documents")}
                          className={`srvc--gallary-menu ${pageShow === `Documents` && "active-link"
                            }`}
                        >
                          <span className="lable-txt">
                            Documents
                          </span>
                        </li>
                         <li onClick={() => setPageShow("Video")}
                          className={`srvc--gallary-menu
                            }`}
                          >
                           <a
                           style={{"color":"#707070","fontSize":"13pt"}}
                           className="share-btn"
                          target="_blank"
                          href={`mailto:?&subject=Check out MAXBIZ!&body=Dear <<YOUR CONTACT'S NAME>>:%0D%0A%0D%0APlease check out MAXBIZ, Get FREE Local Leads in your City and Save Thousands of dollars in Marketing! Visit https://maxbiz.com%0D%0A%0D%0ARegards,%0D%0A%0D%0A<<YOUR FULL NAME>>%0D%0A<<YOUR PHONE NUMBER>>`}
                        >
                          
                          Share
                          {/* <i style={{"fontSize":"12pt","marginLeft": "3px"}} class="fa fa-share"></i> */}
                        </a>
                        </li> 
                           </>
                    ) : null}
                    {plantype == 2 ? (
                      <>
                       
                        <li onClick={() => setPageShow("Photo")}
                          className={`srvc--gallary-menu ${pageShow === `Photo` && "active-link"
                            }`}
                        >
                          <span className="lable-txt">
                            Photos
                          </span>
                        </li>

                        <li onClick={() => setPageShow("Video")}
                          className={`srvc--gallary-menu ${pageShow === `Video` && "active-link"
                            }`}
                        >
                          <span className="lable-txt">
                            Videos
                          </span>
                        </li>
                        
                        <li onClick={() => setPageShow("Documents")}
                          className={`srvc--gallary-menu ${pageShow === `Documents` && "active-link"
                            }`}
                        >
                          <span className="lable-txt">
                            Documents
                          </span>
                        </li>

                        <li onClick={() => setPageShow("Jobs")}
                          className={`srvc--gallary-menu ${pageShow === `Jobs` && "active-link"
                            }`}
                        >
                          <span className="lable-txt">Jobs</span>
                        </li>
                        <li  onClick={() => setPageShow("Offers")}
                          className={`srvc--gallary-menu ${pageShow === `Offers` && "active-link"
                            }`}
                        >
                          <span className="lable-txt">
                            Offers
                          </span>
                        </li>
                        <li 
                          className={`srvc--gallary-menu`}
                        >
                           <a
                           style={{"color":"#707070","fontSize":"13pt"}}
                           className="share-btn"
                          target="_blank"
                          href={`mailto:?&subject=Check out MAXBIZ!&body=Dear <<YOUR CONTACT'S NAME>>:%0D%0A%0D%0APlease check out MAXBIZ, Get FREE Local Leads in your City and Save Thousands of dollars in Marketing! Visit https://maxbiz.com%0D%0A%0D%0ARegards,%0D%0A%0D%0A<<YOUR FULL NAME>>%0D%0A<<YOUR PHONE NUMBER>>`}
                        >
                          Share
                          {/* <i style={{"fontSize":"12pt","marginLeft": "3px"}} class="fa fa-share"></i> */}
                          
                        </a>
                        </li>
                       {/*  <li onClick={() => setPageShow("activeinvestor")}
                          className={`srvc--gallary-menu ${pageShow === `activeinvestor` && "active-link"
                            }`}
                        >
                          <span className="lable-txt">
                            Invesment Requests
                          </span>
                        </li> */}

                        {/*  <li
                          className={`srvc--gallary-menu ${
                            pageShow === `Investment Request` && "active-link"
                          }`}
                        >
                           <span
                            onClick={() => setPageShow("Investment Request")}
                          >
                            Investment Requests
                          </span> 
                        </li> */}
                      </>
                    ) : null}
                    {plantype == 3 ? (
                      <>
                        <li  onClick={() => setPageShow("realestate")}
                          className={`srvc--gallary-menu ${pageShow === `realestate` && "active-link"
                            }`}
                        >
                          <span>
                            Real Estate
                          </span>
                        </li>

                      </>
                    ) : null}
                  </ul>
                </div>
              </>
            ) : checkFirstLogin == 2 ? (
              <div className="message-profile-update">
                Please complete your profile to start receiving FREE Leads in
                your City
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {/* <Switch>
        <Route exact path={path}>
          <DetailsScreen />
          </Route>
          <Route pageShow={pageShow}>
          <ListScreen />
          </Route>
        </Switch> */}

      {pageShow !== "realestate" ? (
        <div className="container">
          <div className="row" style={{ marginTop: "0.5rem" }}>
            <div className="col-md-12">
              <div className="card border-8 shadow p-2">
                {checkFirstLogin == 2 ? (
                  <div className="card-body">
                    {pageShow == "  " ? (
                      <DetailsScreen pageShow={pageShow} />
                    ) : pageShow == "activeinvestor" ? (
                      <ActiveinvestorScreen pageShow={pageShow} />
                    ) : pageShow == "realestate" ? (
                      <RealEstateScreen pageShow={pageShow} />
                    ) : (
                      <ListScreen pageShow={pageShow}  plantype={plantype}/>
                    )}
                  </div>
                ) : (

                  <div className="card-body">
                    <ChangePasswordScreen
                      email={userData.userData.email}
                      setCheckFirstLogin={setCheckFirstLogin}
                    />
                  </div>
                )
                }
              </div>
            </div>
          </div>
        </div>
      ) : (
        <RealEstateScreen pageShow={pageShow}
        />
      )}
    </section>
  );
}

export default Dashboard;
