import React, { useState, useRef, useEffect } from "react";
import axios from 'axios'
import { useHistory } from "react-router";
import ChangePasswordcreen from "./component/changePassword";
import { useDispatch, useSelector } from "react-redux";
import {
 
  setUserAuthData,
  getUserAuthData,
} from "../../../reduxStore/action/usersActions";
import { successToast, errorToast } from "utils/httpClient";
import { apiCall } from "utils/httpClient";
import apiEndPoints from "utils/apiEndPoints";
function ChangePassword() {
  const formRef = useRef();
  let history = useHistory();
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState();
  const userData = useSelector((store) => store.userData);
  const [awsImage, setAwsImage] = useState('')

  const [factorIsLoader, setFactorIsLoader] = useState(false)
  const [ipaddress, setIpaddress] = useState(null);

  useEffect(() => {
    dispatch(getUserAuthData());
  }, [dispatch]);

  const getData = async()=>{
   /*  const res = await axios.get('https://geolocation-db.com/json/')
    //console.log(res.data);
    setIP(res.data.IPv4) */
    fetch('https://api.ipify.org?format=json').then(response => {
      return response.json();
    }).then((res) => {
      console.log("🚀 ~ fetch ~ res:", res)
      setIpaddress(_.get(res, 'ip'))
    }).catch((err) => setIpaddress("0.0.0.0"))
}



  useEffect(() => {
    getProfileData();
    getData()
  }, []);

  const [userDetails, setUserDetails] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    ipaddress: "1.1.1.1",
    gender: "",
    dob: "",
    income: "",
    occupation: "",
    maritalstatus: "",
    interests: "",
    cityid: "",
    photofile: "",
    havekids:0,
    two_factor_auth:0,
    education:'',
   
  });


  //   ---------------------------------------- profile validations --------------------------

  const [profileFormError, setProfileFormError] = useState({});
  function profile_validation() {
    let error = {};
    let formErrorData = false;

    if (!userDetails.firstname) {
      formErrorData = true;
      error["firstname"] = "First Name is required";
    }
    if (!userDetails.lastname) {
      formErrorData = true;
      error["lastname"] = "Last Name is required";
    }
    if (!userDetails.email) {
      formErrorData = true;
      error["email"] = "Email is required";
    }
    if (!userDetails.mobile) {
      formErrorData = true;
      error["mobile"] = "Phone Number is required";
    }
    // if (!userDetails.gender) {
    //   formErrorData = true;
    //   error["gender"] = "Gender is required";
    // }
    if (!userDetails.dob) {
      formErrorData = true;
      error["dob"] = "Date of Birth is required";
    }
    if (!userDetails.income) {
      formErrorData = true;
      error["income"] = "Yearly Income is required";
    }
    if (!userDetails.education) {
      formErrorData = true;
      error["education"] = "Education is required";
    }
    if (!userDetails.occupation) {
      formErrorData = true;
      error["occupation"] = "Occupation is required";
    }
    if (!userDetails.havekids) {
      formErrorData = true;
      error["havekids"] = "Select Have kids option";
    }
   /*  if (!userDetails.interests) {
      formErrorData = true;
      error["interests"] = "Interest is required";
    } */
    if (!userDetails.occupation) {
      formErrorData = true;
      error["occupation"] = "Occupation is required";
    }
    if (!userDetails.cityid) {
      formErrorData = true;
      error["cityid"] = "City is required";
    }

    setProfileFormError(error);
    return formErrorData;
  }

  //   ---------------------------------- get Profile details -----------------------------

  const [profileImage, setProfileImage] = useState("images/user.png");

  const getProfileData = async () => {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    };
    const { data } = await apiCall(
      "GET",
      apiEndPoints.USERPROFILEDETAILS,
      header
    );
    if (data.status == 200) {
      setUserDetails(data.data);
      setAwsImage(data.base_url)
      // setProfileImage(data.base_url + data.data.photofile);
      // setProfileImage();
      if (data.data.photofile != '') {
        data.data.photofile = data.base_url + data.data.photofile
      } else {
        data.data.photofile = 'images/user.png'
      }
      let obj=data.data
      obj.img=data.base_url
      dispatch(setUserAuthData(obj));
      
    }
  };


  //   ----------------------------------- Profile Image ------------------------
  const [selectProfile, setSelectProfile] = useState();

  const [userPassword, setUserPassword] = useState({
    old_password: "",
    new_password: "",
    confirm_password: ""
  });


  function navToList() {
    history.push("/service_list");
  }

  //   ------------------------------------------- get Address api ----------------------

  const [cityList, setCityList] = useState({});
  const [selectedCity, setSelectedCity] = useState({});

  useEffect(() => {
    // getCityList();
    // getSocServiceData();
  }, []);



  // ------------------------------ old Select city input ----------------------------------

  // const getCityList = async () => {
  //   const header = {
  //     Authorization: `Bearer ${localStorage.getItem("authToken")}`,
  //   };
  //   const { data } = await apiCall("GET", apiEndPoints.GETCITIESLIST, header);
  //   if (data.status == 200) {
  //     // setCityList(data.data)
  //     const cityOptions =
  //       data.data?.length > 0 &&
  //       data.data.map((item, i) => {
  //         if (i < 10) {
  //           if (item.id == cityList.id) {
  //             setSelectedCity({ value: item.id, label: item.city });
  //           }
  //           return { value: item.id, label: item.city };
  //         }
  //       });
  //     setCityList(cityOptions);
  //   }
  // };

  // -------------------------------------- new html select input ---------------------
  const getCityList = async () => {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    };
    const { data } = await apiCall("GET", apiEndPoints.GETCITIESLIST, header);
    if (data.status == 200) {
      setCityList(data.data)
    }
  };
  const accountDetailUpdate = async () => {
    if (!profile_validation()) {
      setIsLoader(true)
      let userProfileData = new FormData();
      userProfileData.append("firstname", userDetails.firstname);
      userProfileData.append("lastname", userDetails.lastname);
      userProfileData.append("email", userDetails.email);
      userProfileData.append("mobile", userDetails.mobile);
      userProfileData.append("ipaddress", ipaddress);
      userProfileData.append("gender", userDetails.gender);
      userProfileData.append("dob", userDetails.dob);
      userProfileData.append("income", userDetails.income);
      userProfileData.append("occupation", userDetails.occupation);
      userProfileData.append("maritalstatus", userDetails.maritalstatus);
      userProfileData.append("interests", userDetails.interests);
      userProfileData.append("cityid", userDetails.cityid);
      userProfileData.append("photofile", selectProfile);
      userProfileData.append("havekids", userDetails.havekids);
      userProfileData.append("education", userDetails.education);
      

      const header = {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      };

      const { data } = await apiCall(
        "POST",
        apiEndPoints.UPDATEPROFILEDETAILS,
        userProfileData,
        header
      );
      if (data.status == 200) {
        successToast(data.message);
        getProfileData()
        setIsLoader(false)
      } else {
        errorToast(data.message);
        setIsLoader(false)
      }
    }
  };

  const [formError, setFormError] = useState({});
  function validation() {
    let error = {};
    let formErrorData = false;

    if (!userPassword.old_password) {
      formErrorData = true;
      error["old_password"] = "Old password required";
    }
    if (!userPassword.new_password) {
      formErrorData = true;
      error["new_password"] = "New password required";
    }
    if (userPassword.new_password.length < 6 || userPassword.new_password.length > 15) {
      formErrorData = true;
      error["new_password_length"] = "New Password Shuld be between 6 to 15 Charactor!";
    }
    if (userPassword.new_password != userPassword.confirm_password) {
      formErrorData = true;
      error["confirm_password"] = "Password not match!";
    }
   
    setFormError(error);
    return formErrorData;
  }

  const [passIsLoader, setPassIsLoader] = useState(false)
  async function passwordUpdate() {
    if (!validation()) {
      setPassIsLoader(true)
      const header = {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      };
      const param = {
        old_password: userPassword.old_password,
        new_password: userPassword.new_password,
      };
      const { data } = await apiCall(
        "POST",
        apiEndPoints.CHANGEPASSWORD,
        param,
        header
      );
      if (data.status == 200) {
        setPassIsLoader(false)
        document.getElementById('passwordForm').reset()
        successToast(data.message?.messageTost);
        setUserPassword({})
        setFormError(data.message)
      } else {
        setPassIsLoader(false)
        // setUserPassword({})
        // document.getElementById('passwordForm').reset()
        setFormError(data.message)
        errorToast(data.message?.messageTost)
      }
    }
  }
  // ----------------------------- city search ---------------------------------

  const [searchCityList, setSearchCityList] = useState([]);
  const [cityListShow, setCityListShow] = useState(false);

  function handleSelectCity(cityname) {
    document.getElementById('citySearch').value = cityname;
    setCityListShow(false)
    setSearchCityList([])
  }
  function manageCitySearch(item) {
    if (item == "") {
      setCityListShow(false);
      setUserDetails({
        ...userDetails,
        cityid: '',
        cityname: '',
      })
    }
  }

  async function handleCitySearch(city = '') {
    if (city != '' && city.length >= 3) {
      const params = { 'cityname': city };
      const { data } = await apiCall(
        "POST",
        apiEndPoints.GETCITIESLIST,
        params
      );
      if (data.status == 200) {
        setSearchCityList(data.data)
      } else {
        setSearchCityList([])
      }
      setCityListShow(true)
    } else {
      setSearchCityList([])
      setCityListShow(false)
      setUserDetails({
        ...userDetails,
        cityid: '',
        cityname: '',
      })
    }
  }

  // ======================
  const [serviceSocList, setServiceSocList] = useState([]);  
  const [occupationListShow, setOccupationListShow] = useState(false);
  function handleSelectOccupation(item) {
    document.getElementById('occupationId').value = item
    setOccupationListShow(false)
  }

  async function handleClickOcupation(item) {
    if (item != '' && item.length > 2) {
      const params = { 'servicename': item };
      const { data } = await apiCall("POST", apiEndPoints.GETSERVICESOCLIST, params);
      if (data.status == 200) {
        setServiceSocList(data.data)
        // const status = occupationListShow ? false:true
        setOccupationListShow(true)
      } else {
        setServiceSocList([])
        setOccupationListShow(true)
      }
    } else {
      setServiceSocList([])
      setOccupationListShow(false)
    }
  }

  function manageOccupationSearch(item) {
    if (item == "") {
      setCityListShow(false);
      setUserDetails({
        ...userDetails,
        occupation: '',
        occupation_name: '',
      })
    }
  }

  async function updatetwofactor(item) {
    if (item !== "") {
            
            setFactorIsLoader(true)
            const header = {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            };
            const param = {
              two_factor_auth: item,
            };
            const { data } = await apiCall(
              "POST",
              apiEndPoints.UPDATETWOFACTOR,
              param,
              header
            );
            if (data.status == 200) {
              setFactorIsLoader(false)
              successToast(data.message?.messageTost);
              setFormError(data.message)

              setUserDetails({
                ...userDetails,
                two_factor_auth: item
              })

            } else {
              setFactorIsLoader(false)
              // setUserPassword({})
              // document.getElementById('passwordForm').reset()
              setFormError(data.message)
              errorToast(data.message?.messageTost)
            }
          }
      
    }
  


  // =====================================================
 
  // const getSocServiceData = async (service) => {
  //   if (service != '' && service.length > 2) {

  //     const { data } = await apiCall("GET", apiEndPoints.GETSERVICESOCLIST);
  //     if (data.status == 200) {
  //       setServiceSocList(data.data)
  //     } else {
  //       setServiceSocList(data.data)
  //     }
  //   } else {
  //     setServiceSocList([])
  //   }
  // };


  return (
    <ChangePasswordcreen
      setCityListShow={setCityListShow}
      cityListShow={cityListShow}
      handleSelectCity={handleSelectCity}
      handleCitySearch={handleCitySearch}
      setSearchCityList={setSearchCityList}
      searchCityList={searchCityList}
      cityList={cityList}
      navToList={navToList}
      userDetails={userDetails}
      profileFormError={profileFormError}
      setSelectProfile={setSelectProfile}
      selectProfile={selectProfile}
      profileImage={profileImage}
      setProfileImage={setProfileImage}
      setUserDetails={setUserDetails}
      accountDetailUpdate={accountDetailUpdate}
      userPassword={userPassword}
      setUserPassword={setUserPassword}
      passwordUpdate={passwordUpdate}
      formError={formError}
      setSelectedCity={setSelectedCity}
      selectedCity={selectedCity}

      occupationListShow={occupationListShow}
      serviceSocList={serviceSocList}
      handleSelectOccupation={handleSelectOccupation}
      handleClickOcupation={handleClickOcupation}
      isLoader={isLoader}
      setFormError={setFormError}
      passIsLoader={passIsLoader}
      manageCitySearch={manageCitySearch}
      manageOccupationSearch={manageOccupationSearch}
      setOccupationListShow={setOccupationListShow}
      awsImage={awsImage}
      setAwsImage={setAwsImage}
      updatetwofactor={updatetwofactor}
      factorIsLoader={factorIsLoader}
    />
  );
}

export default ChangePassword;
