import "./App.css";
import Navigation from "navigation/mainNavigation";
import { SearchServiceProvider } from "component/context/SearchContext";
import { useEffect } from "react";
import axios from "axios";
import { apiCall } from "utils/httpClient";
// import { UserDataAuth } from './component/context/UserContext'
// import MyCounter from "Component/MyCounter";
// import CounterContextProvider from "Context/CounterContext";
// import ComponentA from "Component/ComponentA";

function App() {
  useEffect(() => {
    getTokenData();
  }, []);


  async function getTokenData() {

  }

  return (
    <SearchServiceProvider>
      <Navigation />
    </SearchServiceProvider>
  );
}

export default App;
