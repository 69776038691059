import React, { useState, useEffect} from 'react';
import ServiceDetails from './component/serviceDetails';
import { apiCall } from "../../../utils/httpClient";
import ENDPOINTS from "../../../utils/apiEndPoints";
import PhotoList from 'screen/dashboard/component/photoList';
import { useParams } from "react-router";
import Loader from 'common/loader';
import base64 from "base-64";
function ServiceDetailsScreen() {
    const {business_id} = useParams()
    const {business_name} = useParams()
    const [isLoading, setIsLoading] = useState(false);
    const [businessServiceDetail, setBusinessServiceDetail] = useState({});
    const [baseUrl, setBaseUrl] = useState('');
    const [documentUrl, setDocumentUrl] = useState('');
    
   
    useEffect(() => {
        getServicesDetails()
    }, []);

    const getServicesDetails = async () => {

        try {
            setIsLoading(true);
            const params = {businessid: business_id ? base64.decode(business_id) : '',businessname : business_name ? business_name.replace(/\s+/g,'').toLowerCase() : ''}
            const {data} = await apiCall('POST', ENDPOINTS.GETSERVICESDETAILS, params)
            if (data.status == 200) {
                setBusinessServiceDetail(data.data)
                setBaseUrl(data.base_url)
                setDocumentUrl(data.document_url)
                setIsLoading(false)
            } else {
                setIsLoading(false)
                setBusinessServiceDetail({})
            }
        } catch (error) {

        }
    }
    if(isLoading){
        return(
            <Loader />
        )
    }
    return (
        <ServiceDetails
            businessServiceDetail={businessServiceDetail}
            baseUrl={baseUrl}
            documentUrl={documentUrl}
            getServicesDetails={getServicesDetails}
            setBusinessServiceDetail={setBusinessServiceDetail}
        />
    );
}

export default ServiceDetailsScreen;
