import React, { useEffect, useState } from "react";
import axios from 'axios'
import AdminLoginScreen from "./component/AdminLogin";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../component/context/UserContext";
import {successToast, errorToast, apiCall, setDefaultHeader} from '../../utils/httpClient'; 
import ApiEndPoint from '../../utils/apiEndPoints'; 
import { useAuthData } from '../../component/context/UserContext'
import {useDispatch, useSelector } from 'react-redux'
import {setUserAuthData, setBusinessRegisterDetail, handleLoginStatus} from '../../reduxStore/action/usersActions'
function AdminLogin() {
  // const [userData, setUserData] = useAuthData();
  // console.log('userData:  dddd', userData);
  let history = useHistory();
  const { signIn } = useAuth(); 
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState({})
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
    ipaddress : ''
  });
  //for two factor
  const [tofactor, setTofactor] = useState(false);
  const [resentLoding, setResentLoding] = useState(false);
  const [otpmail, setOtpmail] = useState('');
  const [formErrorfact, setFormErrorfact] = useState({})
  const [profileid, setProfileid] = useState()

  
  const dispatch = useDispatch();
  // const userList = useSelector(state => state.users);
  const userData = useSelector(state => state.userData);

  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    console.log(res.data);
    setLoginData({ ...loginData, ipaddress: res.data.IPv4 })
  }

useEffect(() => {
   getData()
}, []);

  function formValidation() {
    let error = {};
    let formErrorData = false;

    const emailValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!loginData.email || emailValid.test(loginData.email) === false) {
      formErrorData = true;
      error['email'] = "Please Entery Valid Email address";
    }
    if (!loginData.password) {
      formErrorData = true;
      error['password'] = "Enter correct Password";
    }
    setFormError(error);
    return formErrorData;
  }

  async function handleAuth(params) {

    if (!formValidation()) {
      setIsLoading(true)

       var { data } = await apiCall('POST', ApiEndPoint.ADMINLOGIN, loginData)
        
        if(data.status == 200){
          setIsLoading(false)
          if(data.data.two_factor_auth === 1){
            setProfileid(data.data?.userid)
            submitTFA()            
          }        
        }else{
          setIsLoading(false)
          errorToast(data.message?.messageTost)
          setFormError(data.message)
        }
    
    }
  }

  async function submitTFA() {
   
      setIsLoading(true)   
      const { data } = await apiCall('POST', ApiEndPoint.CODETFA, {email : loginData?.email,type:"TFA"})
      
      if(data.status == 200){
        setIsLoading(false)
        setTofactor(true)
      }else{
        setIsLoading(false)
        setFormError(data.message)
        errorToast(data.message?.messageTost);
      }
  }  

  const handleResentOtp = async () => {
    setResentLoding(true);
    const params = {email: loginData?.email, type:"TFA"}
    const { data } = await apiCall('POST', ApiEndPoint.CODETFA, params)
    if(data.status == 200){
      setResentLoding(false)
      setFormError(data.message)
      setOtpmail(null)
    }else{
      setResentLoding(false)
      setFormError(data.message)
      errorToast(data.message?.messageTost);
    }
  }

  function validationotp() {
    let error = {};
    let validError = false;
    if (!otpmail) {
      validError = true;
      error['otpmail'] = "Email OTP is required";
    }

    setFormError(error);
    return validError;
  }



  const reloginAdmin = async () =>{
    setIsLoading(true)
       const parms = {
         profile_id : profileid
        }
        const { data } = await apiCall('POST', ApiEndPoint.RELOGIN_ADMIN, parms)
        
      if(data.status === 200){

        setIsLoading(false)
        dispatch(setUserAuthData(data.data));
        dispatch(handleLoginStatus({username:data.data.username, userType:'admin', token:data.token}));
        await setDefaultHeader('token', data.token)
        // successToast(data.message)
        await localStorage.setItem('authToken', data.token)
        signIn(0, data.token, 'admin')
        history.push("/adminhome");        

      }else{
        setIsLoading(false)
        errorToast(data.message?.message);
      }
  
    }

  
  const twoFactverify = async () => {

    
    if (!validationotp()) {
      setIsLoading(true)
      const params = {otp: otpmail, email: loginData?.email}
      const { data } = await apiCall('POST', ApiEndPoint.VERIFYEDOTPADMIN, params)
      
      if(data.status == 200){
        setIsLoading(false)

        reloginAdmin()
        
      }else{
        setIsLoading(false)
        setFormError(data.message)
        errorToast(data.message?.messageTost);
      }
    }
  }


  


  return <AdminLoginScreen 
  isLoading={isLoading} 
  handleAuth={handleAuth} 
  setFormError={setFormError} 
  formError={formError} 
  loginData={loginData} 
  setLoginData={setLoginData}
  setTofactor={setTofactor}
  tofactor={tofactor}

  otpmail = {otpmail}
  setOtpmail = {setOtpmail}
  setFormErrorfact = {setFormErrorfact}
  formErrorfact = {formErrorfact}
  handleResentOtp = {handleResentOtp}
  resentLoding = {resentLoding}
  twoFactverify = {twoFactverify}

  />;
}

export default AdminLogin;

/* 
    return <AdminLoginScreen
    isLoading={isLoading} 
//   handleAuth={handleAuth} 
  setFormError={setFormError} 
  formError={formError} 
  loginData={loginData} 
  setLoginData={setLoginData}
  setTofactor={setTofactor}
  tofactor={tofactor}

  otpmail = {otpmail}
  setOtpmail = {setOtpmail}
  setFormErrorfact = {setFormErrorfact}
  formErrorfact = {formErrorfact}
//   handleResentOtp = {handleResentOtp}
  resentLoding = {resentLoding}
//   twoFactverify = {twoFactverify}
    />
}

export default AdminLogin
 */