import { useState } from "react";
import Error from "./Error";

const FormInputs = ({ setFormMessage }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  const objetoMessage = {
    name,
    email,
    message,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if ([name, email, message].includes("")) {
      setError(true);
      return;
    }
    setError(false);
    setFormMessage(objetoMessage);
  };

  return (
    <>
      <div className="page-contact-form__header">Contact Support</div>
      <div className="page-contact-form__body">
        {error && <Error message="All fields are required" />}
        <div className="form-group">
          <input
            type="text"
            className="form-control form-control-md"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value.trimStart())}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control form-control-md"  
            placeholder="E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value.trimStart())}
          />
        </div>
        <div className="form-group">
          <textarea
            className="form-control form-control-md"
            placeholder="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value.trimStart())}
          />
        </div>
        <div className="form-group mb-0">
          <button className="btn main-btn w-100" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default FormInputs;
