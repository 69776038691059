import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { apiCall, successToast, errorToast, warnToast } from "utils/httpClient";
import apiEndPoints from "utils/apiEndPoints";
import ReactPlayer from "react-player";
import Swal from "sweetalert2";
function VideoList(props) {
  const {plantype}=props
  const [editData, setEditData] = useState({}); 
  const [viewVideo, setViewVideo] = useState("");
  const [editStatus, setEditStatus] = useState(false);
  const [videoListData, setVideoListData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [base_url, setBase_url] = useState();
  const [formError, setFormError] = useState({});
  const [fileSizeError, setFileSizeError] = useState("");
  const [bucket_Img_url, setBucket_Img_url] = useState("");
  

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    getVideoList(newOffset);
  };
  useEffect(() => {
    getVideoList(itemOffset);
    getValidationList();
  }, []);

  const [validationData, setValidationData] = useState([]);
  const [videoNumberValidation, setVideoNumberValidation] = useState(1);
  async function getValidationList() {
    const { data } = await apiCall("GET", apiEndPoints.GETVALIDATIONLIST);
    if (data.status == 200) {
      setValidationData(data.data);
      setVideoNumberValidation(data.data.find((x) => x.lable == "addvideo"));
    } else {
      setValidationData([]);
    }
  }

 
  async function getVideoList(offSet) {
    // const header = {
    //   Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    // };
    setIsListLoading(true);
    const params = {
      filetype: "video",
      limit: itemsPerPage,
      offset: offSet,
    };
    const { data } = await apiCall(
      "POST",
      apiEndPoints.GETVIDEODOCUMENTDATA,
      params
    );
    if (data.status == 200) {
      setVideoListData(data.data);
      const pageCount = data.total_data / itemsPerPage;
      setPageCount(pageCount);
      setBase_url(data.base_url);
      setIsListLoading(false);
    } else {
      setVideoListData([]);
      setIsListLoading(false);

      // errorToast(data.message);
    }
  }

  function formValidation() {
    let error = {};
    let formErrorData = false;

    if (!editData?.title) {
      formErrorData = true;
      error["title"] = "title is required";
    } //else if (editData?.title.length < 5) {
    //   formErrorData = true;
    //   error["title"] = "title length more than 5!";
    // }
    if (!editData?.video) {
      formErrorData = true;
      error["video"] = "Video is required";
    }
    setFormError(error);
    return formErrorData;
  }
  function formValidationvideo() {
    let error = {};
    let formErrorData = false;

    if (!editData?.title) {
      formErrorData = true;
      error["title"] = "Title is required";
    } else if (editData?.title.length < 5) {
      formErrorData = true;
      error["title"] = "Title length more than 5 words!";
    }
    if (!editData?.youtubeLink) {
      formErrorData = true;
      error["youtubeLink"] = "YouTube/Vimeo video Link is required";   
    }
    if (editData?.youtubeLink) {
      var regExp =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      var regExp2 = /\/\/(?:www\.)?vimeo.com\/([0-9a-z\-_]+)/i;
      if (!editData?.youtubeLink.match(regExp) && !editData?.youtubeLink.match(regExp2)) {
        formErrorData = true;
        error["youtubeLink"] = "Use only YouTube/Vimeo video URL for Video";
      }
    }
    /* if(videoListData.length >= videoNumberValidation?.number){
        
        formErrorData = true;
        error["videoLengtherror"] = `You can uploade only ${videoNumberValidation?.number}`;
      } */

    setFormError(error);
    return formErrorData;
  }

  const [pageActive, setPageActive] = useState(0);

  const checkcountvalidation = (plantype) => {
    /* if(plantype == 1){
      if (videoListData.length < 1) {
        return true;
      } else {
        Swal.fire({
          title: "Oops...",
          text: `You can upload only Intro video with Free Plan!`,
          icon: "info",
          //showCancelButton: true,
          //confirmButtonColor: "#6258D3",
          cancelButtonColor: "#000",
          cancelButtonText: "OK",
          //confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {});
      }
    } 
    else{*/
      if (videoListData.length < videoNumberValidation.number) {
        return true;
      } else {
        Swal.fire({
          title: "Oops...",
          text: `You have already uploaded ${videoNumberValidation?.number} videos`,
          icon: "info",
          //showCancelButton: true,
          //confirmButtonColor: "#6258D3",
          cancelButtonColor: "#000",
          cancelButtonText: "OK",
          //confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {});
      }
    //}
   
   
  };

  function cleanSetEditData() {
    setEditData({
      title: "",
      filetype: "video",
      createddate: moment(new Date()).format("MM-DD-YYYY"),
      fileid: "",
      youtubeLink: "",
      description: "",
    });
  }

  async function handleuploadVideo() {
    const videoData = new FormData();

    if (!formValidationvideo() && checkcountvalidation(plantype)) {
      setIsLoader(true);
      videoData.append("name", editData.title);
      videoData.append("youtubeLink", editData.youtubeLink);
      videoData.append("filetype", "video");
      //videoData.append("video", editData.video);
      videoData.append("description", editData.description);
      //videoData.append("miniature", editData.miniature);
      videoData.append("createddate", moment(new Date()).format("MM/DD/YYYY"));

      const { data } = await apiCall(
        "POST",
        apiEndPoints.ADDVIDEOFILE,
        videoData
      );

      if (data.status == 200) {
        setEditStatus(false);
        setIsLoader(false);
        successToast(data.message);
        getVideoList(itemOffset);
        setPageActive(1);
        cleanSetEditData();
      } else {
        errorToast(data.message);
        setIsLoader(false);
      }
    }
  }

  function formEditValidation() {
    let error = {};
    let formErrorData = false;

    if (!editData.title) {
      formErrorData = true;
      error["title"] = "Name is required";
    } else if (editData?.title.length < 5) {
      formErrorData = true;
      error["title"] = "title length more than 5!";
    }
    if (fileSizeError != "") {
      formErrorData = true;
    }
    setFormError(error);
    return formErrorData;
  }

  async function updateVideo() {
    if (!formValidationvideo()) {
      setIsLoader(true);

      const videoData = new FormData();
      videoData.append("name", editData.title);
      videoData.append("filetype", "video");
      videoData.append("fileid", editData.fileid);
      videoData.append("description", editData.description);
      videoData.append("youtubeLink", editData.youtubeLink);
      videoData.append("createddate", moment(new Date()).format("MM/DD/YYYY"));

      const { data } = await apiCall(
        "POST",
        apiEndPoints.UPDATEVIDEOFILE,
        videoData
      );
      if (data.status == 200) {
        // setVideoListData(data.data);
        getVideoList(itemOffset);
        successToast(data.message);
        setIsLoader(false);
        cleanSetEditData();
        setEditStatus(false);
      } else {
        errorToast(data.message);
        setIsLoader(false);
      }
    }
  }

  async function deleteVideo(fileid, filetype) {
    const params = {
      fileid: fileid,
      filetype: filetype,
    };

    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this video ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#6258D3",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { data } = await apiCall(
          "POST",
          apiEndPoints.VIDEODOCUMENTDELETE,
          params
        );

        if (data.status == 200) {
          getVideoList(itemOffset);
          successToast(data.message);
          setEditData({});
          cleanSetEditData()
        } else {
          errorToast(data.message);
        }
      }
    });
  }

  const handleVideoFileSize = (e) => {
    const validationStatus = validationData.find(
      (x) => x.lable == "videouploadsize"
    );
    var _size = Math.floor(e.target.files[0].size / 1000000); //MB

    if (_size >= validationStatus.number) {
      setFileSizeError(
        `Please select Less than ${
          validationStatus.number + validationStatus.type
        } file`
      );
      setEditData({
        ...editData,
        video: "",
      });
    } else {
      setEditData({
        ...editData,
        video: e.target.files[0],
      });
      setFileSizeError("");
    }
  };

  async function getImage(param) {
    setIsLoader(true);
    const params = {
      fileName: param,
    };
    const { data } = await apiCall("POST", apiEndPoints.GETIMAGE, params);
    if (data.status == 200) {
      setBucket_Img_url(data.url);
      setTimeout(() => {
        setIsLoader(false);
      }, 500);
    } else {
      setIsLoader(false);
    }
  }

  return (
    <>
      <div
        className="modal fade deleteModal--modal"
        id="deleteModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="container">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <div className="progress-bar" />
              </div>
              <div className="modal-body">
                <div className="text-center mdl--main-containt">
                  <h3 className="heading-title mb-0">Are You sure ?</h3>
                  <label className="mdl-text ">
                    Are you sure you want to delete this video ?{" "}
                  </label>
                </div>
              </div>
              <div className="text-center mb-4">
                <button
                  type="button"
                  className="main-btn rounded mr-1 mdl--btn"
                  data-toggle="modal"
                  data-target="#deleteModal"
                  onClick={() => {
                    setEditStatus(false);
                    // setEditData({});
                    deleteVideo();
                  }}
                >
                  Yes
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#deleteModal"
                  className="main-gray-btn rounded ml-1 mdl--btn"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ------------------------------ view file ------------------------------ */}
      <div
        className="modal fade addNewPhoto--modal"
        id="viewFile"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="container">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  View Video
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setEditStatus(false);
                    // setEditData({});
                    setBucket_Img_url("");
                  }}
                >
                  <span aria-hidden="true">×</span>
                </button>
                <div className="progress-bar" />
              </div>
              <div className="modal-body">
                <form>
                  {/*  <div className="form-group">
                    <label className="d-block mb-0">Name</label>
                    <input
                      type="text"
                      className="form-control form-control-md"
                      placeholder="Name"
                      defaultValue={editData?.title}
                    />
                  </div> */}
                  <div className="form-group">
                    {/* <span className="d-block mb-0">Video</span> */}
                    {editStatus ? (
                      isLoader ? (
                        <img
                          src="images/loader_dark.gif"
                          style={{
                            width: "30px",
                            color: "blue",
                            marginLeft: "45%",
                          }}
                        />
                      ) : viewVideo && viewVideo !== "" ? (
                        <ReactPlayer
                          className="player-you"
                          controls="true"
                          width="100%"
                          height="350px"
                          url={viewVideo}
                        />
                      ) : (
                        <>
                          {" "}
                          <video
                            className="border-radius-4"
                            width="100%"
                            controls
                            poster="images/images/miniatura-2.png"
                          >
                            <source
                              src="video/FinalVideo.mp4"
                              type="video/mp4"
                            />
                          </video>
                        </>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ------------------------------ end view file ------------------------------ */}

      <div className="row">
        <div className="col-md-6">
          <div className="form-group container__img">
            <div
              className="animated wow fadeInRight"
              data-wow-duration="3000ms"
              data-wow-delay="0ms"
            >
              {editData?.youtubeLink && editData?.youtubeLink !== "" ? (
                <ReactPlayer
                  controls="true"
                  width="100%"
                  height="300px"
                  url={editData?.youtubeLink}
                />
              ) : (
                <>
                  {" "}
                 {/*  <video
                    className="border-radius-4"
                    width="100%"
                    controls
                    poster="images/images/miniatura-2.png"
                  >
                    <source src="video/FinalVideo.mp4" type="video/mp4" />
                  </video> */}
                   <div
                    className="border-radius-4"
                    width="100%"
                    height="300px"
                  >
                    {/* <source src="video/FinalVideo.mp4" type="video/mp4" /> */}
                  </div>
                </>
              )}
              {/*  <video
                className="border-radius-4"
                width="100%"  
                controls
                poster="images/images/miniatura-2.png"
              >
                <source src="video/FinalVideo.mp4" type="video/mp4" />
              </video> */}
            </div>
          </div>
          {/*  <div className="form-group">
            <label htmlFor="file-upload" className="custom-file-upload">
              <i className="fa fa-cloud-upload"></i> Upload video
            </label>
            <input
              id="file-upload"
              type="file"
              onChange={(e) => handleVideoFileSize(e)}
              accept="video/mp4,video/x-m4v,video/*"
              style={{ display: "none" }}
            />
            <small className="text-danger">
              {fileSizeError != ""
                ? fileSizeError
                : !editData?.video && formError?.video
                ? formError?.video
                : ""}
            </small>
          </div> */}

          <div className="form-group">
            <input
              className="form-control"
              placeholder="YouTube/Vimeo video Link"
              value={editData?.youtubeLink}
              onChange={(e) =>
                setEditData({
                  ...editData,
                  youtubeLink: e.target.value.trimStart(),
                })
              }
            />
            <small className="text-danger">
              {formError.youtubeLink ? formError.youtubeLink : ""}
            </small>
          </div>

          <div className="form-group">
            <input
              className="form-control"
              placeholder="Title video"
              value={editData?.title}
              maxLength={100}
              onChange={(e) =>
                setEditData({
                  ...editData,
                  title: e.target.value.trimStart(),
                })
              }
            />
            <small className="text-danger">
              {!editData?.title || formError.title ? formError.title : ""}
            </small>
          </div>
          <div className="form-group">
            <div className="d-flex justify-content-between">
              <label className="d-block mb-0">Description</label>
              <img
                src="/images/icons/arrow-a.svg"
                className="fa-arrows fa__icons close"
                style={{ color: "#707070" }}
                data-toggle="modal"
                data-target="#showOverview"
              ></img>
            </div>
            <textarea
              className="form-control"
              placeholder="Description"
              value={editData.description}
              onChange={(e) =>
                setEditData({
                  ...editData,
                  description: e.target.value,
                })
              }
            >
              {editData?.description}
            </textarea>
            <small className="text-danger">
              {!editData?.description && formError.description
                ? formError.description
                : ""}
            </small>
          </div>
          {/* <div className="form-group">
            <label className="d-block mb-0">Miniature</label>
            <input className="form-control" placeholder="Miniature" 
            value={editData?.miniature}
            onChange={(e) =>
              setEditData({
                ...editData,
                miniature: e.target.value,
              })
            }
            />
          </div> */}
          <div className="form-group">
            <small className="text-danger">
              {!editData?.description && formError.videoLengtherror
                ? formError.videoLengtherror
                : ""}
            </small>
            <button
              type="button"
              className="btn main-btn w-100"
              //style={{ minWidth: 160 }}
              onClick={() => {
                isLoader ? console.log('save') : editData.fileid && editData.fileid != ""
                  ? updateVideo()
                  : handleuploadVideo();
              }}
            >
              {" "}
              {isLoader ? (
                <img src="images/loader_white.gif" style={{ width: "30px" }} />
              ) : editData.fileid && editData.fileid != "" ? (
                "Update Video"
              ) : (
                "Save Video"
              )}
            </button>
            {/* {editData.fileid && editData.fileid != '' ?
              <button
                type="button"
                className="btn main-btn w-100"
                //style={{ minWidth: 160 }}
                onClick={() => {
                  cleanSetEditData();
                }}
                style={{ "marginTop": "10px" }}
              >
                {" "}
                {isLoader ? (
                  <img
                    src="images/loader_white.gif"
                    style={{ width: "30px" }}
                  />
                ) : (
                  "Reset Form"
                )}
              </button>
              : ""} */}
          </div>
        </div>
        <div className="col-md-6">
          <div className="card border-8">
            <label className="card-header border-t-8 bg--smb text-white p-1">
              My list videos
              <i
                onClick={() => {
                  cleanSetEditData();
                }}
                className="fa fa-plus add-new-item"
                style={{ marginLeft: "80%" }}
              ></i>
            </label>

            <div className="card-body text-center">
              <div className="row align-items-center">
                {isListLoading ? (
                  <div className="text-center">
                    <img className="listloadergif" src="images/infinity.gif" />
                  </div>
                ) : videoListData && Object.keys(videoListData).length > 0 ? (
                  <>
                    <table className="table table-sm table-responsive">
                      <thead>
                        <tr className="tbl--row">
                          {/* <th scope="col">
                      <div className="form-check">
                        <input
                          onClick={()=>handleSelectAllItem(allSelect)}
                          type="checkbox"
                          defaultValue
                          id="flexCheckDefault"
                          defaultChecked={allSelect}
                        />
                      </div>
                    </th> */}
                          <th scope="col" className="font-size-14" width="1%">
                            #
                          </th>
                          <th scope="col" className="font-size-14">
                            Name
                          </th>
                          <th scope="col" className="font-size-14">
                            Url
                          </th>
                          {/*  <th scope="col" className="font-size-14">
                            Date
                          </th> */}
                          <th scope="col" className="font-size-14">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {videoListData.map((item, i) => (
                          <>
                            <tr className="tbl--row" key={i}>
                              <td className="font-size-14">{i + 1}</td>
                              <td className="font-size-14">{item.title}</td>
                              <td className="font-size-14">
                                <a
                                  href="#"
                                  onClick={() => {
                                    setViewVideo(item?.youtubeLink);
                                    setEditStatus(true);
                                    //getImage(item.filefile);
                                  }}
                                  data-toggle="modal"
                                  data-target="#viewFile"
                                >
                                  {item.filefile != ""
                                    ? item.filefile
                                    : item.youtubeLink}
                                </a>
                              </td>
                              {/* <td className="font-size-14">
                                {moment(item.createddate).format("MM-DD-YYYY")}
                              </td> */}
                              <td
                                className="font-size-14"
                                style={{ display: "contents" }}
                              >
                                <a
                                  onClick={() => {
                                    setViewVideo(item?.youtubeLink);
                                    setEditStatus(true);
                                    // setImage(item.filefile)
                                    getImage(item.filefile);
                                  }}
                                  title="View"
                                  className="cursor-pointer color__light mr-1"
                                  data-toggle="modal"
                                  data-target="#viewFile"
                                >
                                  <i className="fa fa-play fa__icons"></i>
                                </a>
                                <a
                                  onClick={() => {
                                    setEditData(item);
                                    //setEditStatus(true);
                                  }}
                                  title="Edit"
                                  className="color__light mr-1"
                                  /*  data-toggle="modal"
                                 data-target="#editNewPhoto" */
                                >
                                  <i className="fa fa-edit fa__icons"></i>
                                </a>
                                <a
                                  onClick={() => {
                                    deleteVideo(item.fileid, item.filetype);
                                  }}
                                  title="Delete"
                                  className="color__light"
                                >
                                  <i className="fa fa-trash fa__icons"></i>
                                </a>
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                    {videoListData.length > 10 ? (
                      <>
                        <div className="btm-pagination mt-4">
                          <div className="d-flex justify-content-end">
                            {
                              <ReactPaginate
                                // previousLabel={'previous'}
                                // nextLabel={'next'}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={4}
                                onPageChange={handlePageClick}
                                containerClassName={
                                  "pagination pagination-style-02 justify-content-center"
                                }
                                subContainerClassName={"pages pagination"}
                                activeClassName={"current active active_pg"}
                                activeLinkClassName={"current active active_pg"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link bg-gray"}
                                previousClassName={"page-item"}
                                nextClassName={"page-item"}
                                previousLinkClassName={"page-link bg-gray"}
                                nextLinkClassName={"page-link bg-gray"}
                                previousLabel={
                                  <i className="fal fa-chevron-left"></i>
                                }
                                nextLabel={
                                  <i className="fal fa-chevron-right"></i>
                                }
                                // renderOnZeroPageCount={null}
                                // forcePage={null}
                              />
                            }
                          </div>
                        </div>
                      </>
                    ) : null}
                  </>
                ) : (
                  <div className="text-center">
                    <img
                      className="no-foundimage"
                      src="images/nodatafound.png"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoList;
