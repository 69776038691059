import axios from 'axios'
import { GLOBAL_URL, GLOBAL_URL_LOCAL } from './constants'
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Api Base Url 
const httpClient = axios.create({
  baseURL: `${GLOBAL_URL}`
});

// Set jwt Token
export function setDefaultHeader(header, value) {
  // httpClient.defaults.headers.common[header] = value
  // httpClient.defaults.headers.common[header] = value
  // httpClient.defaults.headers.headers.common[{ Authorization: `Bearer ${token}` }]
  httpClient.defaults.headers.common = { 'Authorization': `Bearer ${value}` }
  //  httpClient.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('token')}`}
}

// Api Call
/* export async function apiCall(method, url, data, header = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }) { */
export async function apiCall(method, url, data, header = {'Access-Control-Allow-Origin': '*' }) {
  // console.log('url: ', url);
  try {
    // console.log("apiCall -> data", data)
    const response = await httpClient({
      method,
      url,
      data,
      headers: header,
      // withCredentials: false
    })
    // console.log('response:dddd ', response);
    return response;
  } catch (error) {
    if (error.response) {
      if (error.response.status === 403) {
        tokenExpire();
        return error.response;
      } else {
        return error.response;
      }
    } else {
      // console.log("error.response: ", error.response);
      // console.log("Error message : ", error.message);
      // console.log("Error request : ", error.request);
      return { data: { status: 500, message: "Internal server error" } };
    }
  }
}


export async function authentication(common) {
  let userActiveIndex;
  userActiveIndex = null;
  let activeCompany;
  activeCompany = null;
  try {
    activeCompany = await localStorage.getItem("activeCompany");
    userActiveIndex = await localStorage.getItem("activeIndex");
    if (userActiveIndex) {

      return userActiveIndex;
    } else {
      return 0;
    }
  } catch (e) {
    console.log(e);
  }
}
export async function getCommonContext() {
  let commonData;
  commonData = null;
  try {
    commonData = await localStorage.getItem("commonData");
    if (commonData) return JSON.parse(commonData);
  } catch (e) {
    console.log(e);
  }
}
export function isEmpty(params) {
  if (params && params != "Null" && params != "undefined") {
    return true;
  } else {
    return false;
  }
}

export const successToast = (msg) => {
  toast.success(msg, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,

  });
};
export const errorToast = (msg) => {
  toast.error(msg, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
export const warnToast = (msg) => {
  toast.warn(msg, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
export const infoToast = (msg) => {
  toast.info(msg, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
export const tokenExpire = () => {
  confirmAlert({
    title: "Session expired",
    message: "Please retry or login again to continue EL KABLAN.",
    buttons: [
      {
        label: "Yes",
        onClick: () => signOut(),
      },

    ],
  });
};

const signOut = async () => {
  try {
    await localStorage.removeItem("userToken");
    await localStorage.removeItem("userData");
    await localStorage.removeItem("commonData");
    await localStorage.removeItem("activeCompany");

    window.location.assign("/");

  } catch (e) {
    console.log(e);
  }
};