import React, {useState, useEffect} from 'react';
import Admindashboard from './component/admindashboard';


function Index(){

    
    return(
        <>

          {/* <Admindashboard businessListData={businessListData} handlePageClick={handlePageClick} itemOffset={itemOffset} itemsPerPage={itemsPerPage} pageCount={pageCount} businessVerifyed={businessVerifyed} /> */}
          <Admindashboard/>
        </>
    )
}

export default Index;