import React, { useState } from 'react';
import OtpInput from 'react18-input-otp'
import { apiCall, errorToast } from 'utils/httpClient';
import ApiEndPoint from 'utils/apiEndPoints';
import { useHistory } from "react-router-dom";
function Redirectplan(props) {

  const {reloginbusiness,isLoading} = props
  let history = useHistory();

return (
    <>
      <section className="about-us-area pb-10 bg-light hero--mrgn-top">
        <div className="about__mainpage">
        <div className="container"> 
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body mt-4 pt-3 pb-4">
                <div className="form-group text-center">
                  <img src="images/right_img.png" style={{ width: "85px" }} />
                  <p className="sucess_msg_title" style={{marginTop : "20px"}}>
                    <h3>Thank For Subscription.</h3>
                  </p>
                  <p className="d-block">
                    Click on continue button and login with your account and update your business detail
                  </p>
                </div>
              </div>
              <div className="modal-footer" style={{ borderTop: "0px" }}>
                <a
                  //href="/login"
                  type="button"
                  className="main-btn rounded"
                  onClick={()=>reloginbusiness()}
                  style={{ minWidth: 150 }}
                 
                >{isLoading?<img src="images/loader_white.gif" style={{width:'28px'}} />:'Continue'}
                  
                </a>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>
    </>
  );
}

export default Redirectplan;
