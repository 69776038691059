import React from "react";
import { Link } from "react-router-dom";

const HeaderMenuBusiness = ({pathname, allinformation, userAuthData, userSignOut, userLoginStatus,setMenushow,menushow}) => {
  return (
    <ul className="navbar-nav ml-auto">
      <li className="nav-item">
          {allinformation == 1 ? (
            <>
              <Link
                style={{ padding: "0px 20px" }}
                onClick={() => {
                  setMenushow(!menushow)
                }}
                to="/change_password"
                className={`nav-link ${pathname == "/change_password" && "active"}`}
              >
                <img
                  src={
                    userAuthData.userData.img
                      ? userAuthData.userData.img
                      : userAuthData.userData.photofile ==
                        "undefineddefault.png"
                      ? "./images/user.png"
                      : userAuthData.userData.photofile
                      ? userAuthData.userData.photofile
                      : "./images/user.png"
                  }
                  className="user--icon"
                  style={{
                    marginTop: "-4px",
                    width: "30px",
                    height: "30px",
                    borderRadius: "50px",
                  }}
                />
                <span className="name-user">
                  {" "}
                  {userAuthData?.userData?.firstname != null
                    ? userAuthData?.userData?.firstname
                    : "" + " " + userAuthData?.userData?.lastname != null
                    ? userAuthData?.userData?.lastname
                    : ""}
                </span>{" "}
              </Link>
            </>
          ) : null}
      </li>
      <li className="nav-item log-out-btn">
        <a
          onClick={() => userSignOut(userLoginStatus.userType)}
          style={{ borderBottom: "0", padding: "0px 10px" }}
          className="nav-link log--out-btn cursor-pointer"
        >
          <i className="fa fa-power-off" />
        </a>
      </li>
    </ul>
  );
};

export default HeaderMenuBusiness;
