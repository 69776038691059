import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { apiCall } from "utils/httpClient";
import apiEndPoints from "utils/apiEndPoints";
import { successToast } from "utils/httpClient";
import { errorToast } from "utils/httpClient";
import moment from "moment";
import base64 from "base-64";
import { Document, Page } from "react-pdf";
function InvestorRequest() {
  const formRef = useRef();
  let history = useHistory();
  const [editData, setEditData] = useState({
    title: "",
    required_amount: "",
    summary: "",
   
  });
  const [addData, setAddData] = useState({
    title: "",
    required_amount: "",
    summary: "",
   
  });
  const [editStatus, setEditStatus] = useState(false);
  const [investorListData, setInvestorListData] = useState([]);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isLoader, setIsLoader] = useState(false);
  const [editDocument, setEditDocument] = useState("");
  const [base_url, setBase_url] = useState();
  const [fileSizeError, setFileSizeError] = useState("");
  const todaydate = moment().format("YYYY-MM-DD");
  const [bucket_Img_url, setBucket_Img_url] = useState();
  const [numPages, setNumPages] = useState(null); 
  const [pageNumber, setPageNumber] = useState(1);
  const [isListLoading, setIsListLoading] = useState(false)



  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    myInvestorRequestList(newOffset);
  };

  useEffect(() => {
    myInvestorRequestList(itemOffset);
    getValidationList();
  }, []);

  const [validationData, setValidationData] = useState([]);
  const [documentNumberValidation, setDocumentNumberValidation] = useState(0);
  
  async function getValidationList() {
    const { data } = await apiCall("GET", apiEndPoints.GETVALIDATIONLIST);
    if (data.status == 200) {
      setValidationData(data.data);
      setDocumentNumberValidation(
        data.data.find((x) => x.lable == "investorRequest")
      );
    } else {
      setValidationData([]);
    }
  }

  async function myInvestorRequestList(offset) {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    };
    setIsListLoading(true)
    const params = {
      limit: itemsPerPage,
      offset: offset,
    };
    const { data } = await apiCall(
      "POST",
      apiEndPoints.GETMYINVESTORREQUESTLIST,
      params
    );
    if (data.status == 200) {
      setIsListLoading(false)
      setInvestorListData(data.data);
      const pageCount = data.total_data / itemsPerPage;
      setPageCount(pageCount);
    } else {
      setIsListLoading(false)
      setInvestorListData([]);
    }
  }
  function handleModel() {
    setEditStatus(false);
    const hh = "";
    setEditData({});
    console.log("handleModel -> setEditData", editData)
    
  }

  const [formError, setFormError] = useState({});
  function formValidation() {
    let error = {};
    let formErrorData = false;

    if (!addData.title) {
      formErrorData = true;
      error["title"] = "Title is required";
    }
    if (!addData.required_amount) {
      formErrorData = true;
      error["requiredamount"] = "Required Amount is required";
    }
    if (!addData.summary) {
      formErrorData = true;
      error["summary"] = "Summary is required";
    }
    
    setFormError(error);
    return formErrorData;
  }
  function formValidationedit() {
    let error = {};
    let formErrorData = false;

    if (!editData.title) {
      formErrorData = true;
      error["title"] = "Title is required";
    }
    if (!editData.required_amount) {
      formErrorData = true;
      error["requiredamount"] = "Required Amount is required";
    }
    if (!editData.summary) {
      formErrorData = true;
      error["summary"] = "Summary is required";
    }
    
    setFormError(error);
    return formErrorData;
  }

  async function handleAddinvestorrequest() {
    
    if (!formValidation()) {
      setIsLoader(true);
    
      var param = {
        title: addData.title,
        requiredamount: addData.required_amount,
        summary: addData.summary,
        
      }
      const { data } = await apiCall("POST", apiEndPoints.ADDINVESTORREQUEST, param);


      if (data.status == 200) {
        successToast(data.message);
        setIsLoader(false);
        document.getElementById("closeModel").click();
        setEditStatus(false);
        formRef.current.reset();
        myInvestorRequestList(itemOffset);
      
      } else {
        errorToast(data.message);
        formRef.current.reset();
        setIsLoader(false);
      }
    }
  }



  async function updateDocument() {
    if (!formValidationedit()) {
      setIsLoader(true);
     
      var param = {
        title: editData.title,
        requiredamount: editData.required_amount,
        summary: editData.summary,
        request_id: editData.request_id,
        status: editData.status,
        
      }
      const { data } = await apiCall("POST", apiEndPoints.ADDINVESTORREQUEST, param);

     
      if (data.status == 200) {
        //setInvestorListData(data.data);
        myInvestorRequestList(itemOffset);
        successToast(data.message);
        setIsLoader(false);
        setEditStatus(false);
        document.getElementById("closeEditModal").click();
        setEditData({});
        setEditDocument();
        //formRef.current.reset();
      } else {
        errorToast(data.message);
        setIsLoader(false);
      }
    }
  }

  async function deleteinvestor() {
    
    const params = {
      request_id: editData.request_id,
      
    };

    const { data } = await apiCall(
      "POST",
      apiEndPoints.VISITORREQUESTDELETE,
      params
    );
    if (data.status == 200) {
      myInvestorRequestList(itemOffset);
      successToast(data.message);
    } else {
      errorToast(data.message);
    }
  }

 

  
  return (
    <>
      {/* ------------------------------------- delete -------------------------------- */}
      <div
        className="modal fade deleteModal--modal"
        id="deleteModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="container">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <div className="progress-bar" />
              </div>
              <div className="modal-body">
                <div className="text-center mdl--main-containt">
                  <h3 className="heading-title mb-0">Are You sure ?</h3>
                  <label className="mdl-text ">
                    Are you sure you want to delete this Request ?{" "}
                  </label>
                </div>
              </div>
              <div className="text-center mb-4">
                <button
                  type="button"
                  className="main-btn rounded mr-1 mdl--btn"
                  data-toggle="modal"
                  data-target="#deleteModal"
                  onClick={() => {
                    setEditData({});
                    deleteinvestor();
                  }}
                >
                  Yes
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#deleteModal"
                  className="main-gray-btn rounded ml-1 mdl--btn"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ---------------------------------------- add new ------------------------------ */}
      <div
        className="modal fade addNewPhoto--modal"
        id="addNewPhoto"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="container">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {editStatus ? "Edit Investment Request" : "Add Investment Request"}
                </h5>
                <button
                  id="closeModel"
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => handleModel()}
                >
                  <span aria-hidden="true">×</span>
                </button>

                <div className="progress-bar" />
              </div>
              <div className="modal-body">
                {investorListData.length <
                (documentNumberValidation &&
                  documentNumberValidation.number) ? (
                  <form ref={formRef}>
                    <div className="form-group">
                      <label className=" mb-0">Title</label>{" "}
                      <input
                        type="text"
                        className="form-control form-control-md"
                        placeholder="Required amount"
                        value={addData?.title}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            title: e.target.value,
                          })
                        }
                      />
                      <small className="text-danger">
                        {!addData.title && formError.title ? formError.title : ""}
                      </small>
                    </div>
                    <div className="form-group">
                      <label className=" mb-0">Required amount</label>{" "}
                      <input
                        type="number"
                        className="form-control form-control-md"
                        placeholder="Required amount"
                        value={addData?.required_amount}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            required_amount: e.target.value,
                          })
                        }
                      />
                      <small className="text-danger">
                        {!addData.requiredamount && formError.requiredamount ? formError.requiredamount : ""}
                      </small>
                    </div>
                  <div className="form-group">
                      <label className="mb-0">Summary</label>{" "}
                      <textarea
                        className="form-control form-control-md"
                        placeholder="Summary"
                        defaultValue={addData?.summary}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            summary: e.target.value,
                          })
                        }
                      />
                      <small className="text-danger">
                        {!addData.summary && formError.summary
                          ? formError.summary
                          : ""}
                      </small>
                    </div>
                    <div className="form-group date text-right">
                      <button
                        type="button"
                        className="main-btn rounded"
                        style={{ minWidth: 160 }}
                        onClick={() => {
                          handleAddinvestorrequest();
                        }}
                      >
                        {isLoader ? (
                          <img
                            src="images/loader_white.gif"
                            style={{ width: "30px" }}
                          />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </form>
                ) : (
                  <div className="text-center">
                    <h5>
                      You can Create only {documentNumberValidation?.number}{" "}
                      Request.
                    </h5>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ----------------------------------------------- edit --------------------------------------- */}
      <div
        className="modal fade addNewPhoto--modal"
        id="editNew"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="container">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {editStatus ? "Edit Investment Request" : "Add Investment Request"}
                </h5>
                <button
                  id="closeEditModal"
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => handleModel()}
                >
                  <span aria-hidden="true">×</span>
                </button>

                <div className="progress-bar" />
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label className="d-block mb-0">Title</label>
                    <input
                      type="text"
                      className="form-control form-control-md"
                      placeholder="Title"
                      value={editData?.title}
                      onChange={(e) =>
                        setEditData({
                          ...editData,
                          title: e.target.value,
                        })
                      }
                    />
                    <small className="text-danger">
                      {!editData.title && formError.title ? formError.title : ""}
                    </small>
                  </div>
                  <div className="form-group">
                    <label className="d-block mb-0">Required Amount</label>
                    <input
                      type="text"
                      className="form-control form-control-md"
                      placeholder="Required Amount"
                      value={editData?.required_amount}
                      onChange={(e) =>
                        setEditData({
                          ...editData,
                          required_amount: e.target.value,
                        })
                      }
                    />
                    <small className="text-danger">
                      {!editData.required_amount && formError.required_amount ? formError.required_amount : ""}
                    </small>
                  </div>
                
                  <div className="form-group">
                    <label className="d-block mb-0">Summary</label>
                    <textarea
                      className="form-control form-control-md"
                      placeholder="Description"
                      defaultValue={""}
                      value={editData?.summary}
                      onChange={(e) =>
                        setEditData({
                          ...editData,
                          summary: e.target.value,
                        })
                      }
                    />
                    <small className="text-danger">
                      {!editData.summary && formError.summary
                        ? formError.summary
                        : ""}
                    </small>
                  </div>

                  {console.log("InvestorRequest -> editData", editData)}
                  <div className="form-group">
                    <label className="d-block mb-0">Status</label>
                    <select
                          //name="maritalstatus"
                          //id="maritalstatus"
                          placeholder="Head Count"
                          className="form-control form-control-md form-control-city"
                          value={editData?.status}
                          onChange={(e) =>
                            setEditData({
                              ...editData,
                              status: e.target.value,
                            })
                          
                          }
                        >
                          <option value="">Select Status</option>
                          <option value="3">Closed</option>
                          
                          
                        </select>
                    <small className="text-danger">
                      {!editData.required_amount && formError.required_amount ? formError.required_amount : ""}
                    </small>
                  </div>



                  <div className="form-group date text-right">
                    <button
                      type="button"
                      className="main-btn rounded"
                      style={{ minWidth: 160 }}
                      onClick={() => {
                        updateDocument();
                      }}
                    >
                      {isLoader ? (
                        <img
                          src="images/loader_white.gif"
                          style={{ width: "30px" }}
                        />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* -------------------------------------------------- view ------------------------------------- */}
      <div
        className="modal fade addNewPhoto--modal"
        id="viewDocument"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="container">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  View Investment Request Detail
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setEditData({});
                  }}
                >
                  <span aria-hidden="true">×</span>
                </button>

                <div className="progress-bar" />
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label className="d-block mb-0">Title:-</label>
                    <span className="show-detail">{editData?.title}</span>
                  </div>
                  <div className="form-group">
                    <label className="d-block mb-0">Required Amount:-</label>
                    <span className="show-detail">${editData?.required_amount}</span>
                   
                  </div>
                  <div className="form-group">
                    <label className="d-block mb-0">Summary:-</label>
                    <span className="show-detail">{editData?.summary}</span>
                    
                  </div>
                  
                </form>
                
            
              </div>
            </div>
          </div>
        </div>
      </div>
       
      <div className="">
        {isListLoading ? (
          <div style={{textAlign:'center'}}>
              <img className="listloadergif" src="images/infinity.gif"  />

          </div>
        ) : investorListData && investorListData.length > 0 ? (
        
          <div>
            <div className="display--table">
              <table className="table table-border table-light">
                <thead>
                  <tr className="tbl--row">
                    {/* <th scope="col">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          defaultValue
                          id="flexCheckDefault"
                        />
                      </div>
                    </th> */}
                    <th className="">#</th>
                    <th className="">Title</th>
                    <th className="">Amount</th>
                    <th className="">Summary</th>
                    <th className="">Effective Date</th>
                    <th className="">Status</th>
                    <th className="">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr className="spacer-row">
                    <td colSpan={7} />
                  </tr> */}
                  {investorListData.map((item, i) => (
                    <tr className="tbl--row">
                     
                      <td>{i + 1}</td>
                      <td>{item.title}</td>
                      <td>${item.required_amount.toLocaleString('en-US')}</td>
                      <td style={{width: '40%', padding: '8px'}}><span className="ellipsis"
                      >{item.summary}</span></td>
                    
                      <td>{moment(item.created_date).format("MM-DD-YYYY")}</td>
                      <td className={
                                item.status == 1 ? 'Requested-class' :
                                  item.status == 2 ? 'Open-class' :
                                    item.status == 3 ? 'Close-class' :
                                      'Disable-class'
                              }>
                                {
                                  item.status == 1 ? 'Requested' :
                                    item.status == 2 ? 'Opened' :
                                      item.status == 3 ? 'Closed' :
                                        'Rejected'
                                }</td>

                      
                      <td>
                       <a
                          onClick={() => {
                            setEditData(item);
                          }}
                          data-toggle="modal"
                          data-target="#viewDocument"
                        >
                          <img
                            className="tbl--actin-icon"
                            src="images/icons/eye.png"
                          />
                        </a> {" "}
                        <a
                          onClick={() => {
                            setEditData(item);
                            setEditStatus(true);
                          }}
                          data-toggle="modal"
                          data-target="#editNew"
                        >
                          <img
                            className="tbl--actin-icon"
                            src="images/icons/edit.png"
                          />
                        </a>{" "}
                        <a
                          data-toggle="modal"
                          onClick={() => {
                            setEditData(item);
                            setEditStatus(true);
                          }}
                          data-target="#deleteModal"
                        >
                          <img
                            className="tbl--actin-icon"
                            src="images/icons/delete.png"
                          />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="btm-pagination mt-4">
              <div className="d-flex justify-content-end">
                <ReactPaginate
                  // previousLabel={'previous'}
                  // nextLabel={'next'}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={4}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={
                    "pagination pagination-style-02 justify-content-center"
                  }
                  subContainerClassName={"pages pagination"}
                  activeClassName={"current active active_pg"}
                  activeLinkClassName={"current active active_pg"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link bg-gray"}
                  previousClassName={"page-item"}
                  nextClassName={"page-item"}
                  previousLinkClassName={"page-link bg-gray"}
                  nextLinkClassName={"page-link bg-gray"}
                  previousLabel={<i className="fal fa-chevron-left"></i>}
                  nextLabel={<i className="fal fa-chevron-right"></i>}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <img className="no-foundimage" src="images/nodatafound.png" />
          </div>
        )}
      </div>
    </>
  );
}

export default InvestorRequest;
