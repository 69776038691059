import React, { useState } from "react";
export const AuthContext = React.createContext();
// export const UserAuthContext = React.createContext();
export const UserContext = React.createContext();
export const UserDataAuth = (props) => {    
    const [userData, setUserData] = useState(null);
    return (
        <UserContext.Provider value={[userData, setUserData]}>
            {props.children}
            {/* <User /> */}
        </UserContext.Provider>
    )
}
export const useAuthData = () => {
    return React.useContext(UserContext);
}

export const useAuth = () => {
    return React.useContext(AuthContext);
}

{/* <UserAuthContext.Provider >

</UserAuthContext.Provider> */}

// export const userAuthData = () => {
//     return React.useContext(UserAuthContext);
// }