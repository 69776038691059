import React, { useState, useEffect } from "react";
import Slider from "common/slider";
import { Rating } from "react-simple-star-rating";
export default function DocumentSelider(props) {
  const { documentList, setShowFilePath,setShowTitle } = props;
  const [url, setUrl] = useState("images/document.pdf");

  const showDocument = (url) => {
    setUrl(url);
  };

  return (
    <>
      <div className="card-body">
        <div className="row align-items-center business-externo">
          <div className="col-md-12 bus-detail-cont">
            <div className="srvc--containt card-body p-0">
              <div className="row">
                <div className="main--srvc-slider">
                  {documentList.length == 0 ? (
                    <>
                    <div className="text-center" style={{"backgroundColor" :"white","height":"400px"}}>
                      <img src="images/nodatafound.png" />
                       <span className="not-found-content">Ooops, no documents found</span>
                       </div>
                   </>
                  ) : (
                    <Slider
                      arrows='true'
                      SliderArray={documentList}
                      SliderItem={(item, index) => (
                        <div key={index} className="swiper-slide">
                          <div className="slid--box">
                            <a
                              href="#"
                              data-toggle="modal"
                              data-target="#showVideo"
                              onClick={() => {setShowFilePath(item.item);
                                setShowTitle(item?.item?.title)}}
                            >
                              <div className="slider--ser_img">
                                <div className="iconsize">
                                  <img
                                    // src={(item.item.filefile != 'null')?"images/document.png": props.documentUrl + item.item.filefile}
                                    src="images/document.png"
                                    className="slid-img"
                                  />
                                </div>
                                <div className="show-title">{item?.item?.title}</div>
                              </div>
                            </a>
                          </div>
                        </div>
                      )}
                    />
                  )}
                </div> </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
