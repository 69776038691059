// import React from "react";
// import VideoListScreen from "./videoList";
// import PhotoListScreen from "./photoList";
// import DetailsScreen from "./details";
// import JobListScreen from "./jobList";
// import OfferListScreen from "./offerList";
// import DocumentListScreen from "./documentList";
// import { useHistory, useParams } from "react-router-dom";

// function Dashboard() {
//   let { listType } = useParams();

//   return listType === "video" ? (
//     <VideoListScreen />
//   ) : listType === "photo" ? (
//     <PhotoListScreen />
//   ) : listType === "offers" ? (
//     <OfferListScreen />
//   ) : listType === "jobs" ? (
//     <JobListScreen />
//   ) : listType === "Documents" ? (
//     <DocumentListScreen />
//   ) : (
//     <DetailsScreen />
//   );
// }

// export default Dashboard;

import React from "react";
import VideoListScreen from "./videoList";
import PhotoListScreen from "./photoList";
import DetailsScreen from "./details";
import JobListScreen from "./jobList";
import OfferListScreen from "./offerList";
import DocumentListScreen from "./documentList";
import InvestorRequestScreen from "./investorRequest";
import { useHistory, useParams } from "react-router-dom";

function Dashboard(props) {
  let { listType } = useParams();
 const {plantype}=props;


  return props.pageShow === "Video" ? (
    <VideoListScreen plantype={plantype} />
  ) : props.pageShow === "Photo" ? (
    <PhotoListScreen plantype={plantype}/>
  ) : props.pageShow === "Offers" ? (
    <OfferListScreen />
  ) : props.pageShow === "Jobs" ? (
    <JobListScreen />
  ) : props.pageShow === "Documents" ? (
    <DocumentListScreen />
  ) : props.pageShow === "Investment Request" ? (
    <InvestorRequestScreen />
  ) : (
    <DetailsScreen />
  );
}

export default Dashboard;
