import React, { useState } from 'react';
import OtpInput from 'react18-input-otp'
import { apiCall, errorToast } from 'utils/httpClient';
import ApiEndPoint from 'utils/apiEndPoints';
import { useHistory } from "react-router-dom";
import Modal from 'react-modal';
import RedirectScreen from '../../redirectplan'
function Subscriptionplan(props) {
  
  const {purchaseplan,paynowModel,closeModal,customStyles,subscriptiondata,purchaseplanAPI,isLoading,businessData} = props

  const profile_id = localStorage.getItem("profile_id")
  console.log('const: ', profile_id);
  let history = useHistory();

return (
    <>
      <section className="about-us-area pb-10 bg-light hero--mrgn-top">
        <div className="about__mainpage">
          <div className="container ">
            <div className="row align-items-center">


              <div className="col-lg-12">
                <div className="">
                  <div className="heading1">
                    <h2>Select your Subscription Plan</h2>
                  </div>


                  <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 col-sm-9">
                      <div className="join-item join--smb-item mt-30">
                        <div className="text-center join--itemnum-box">
                          {/* <a className={`${businessData?.businessRegisterDetail?.indentityValidated == 1 ? 'join--item-num-active' : 'join--item-num'}`}>1</a> */}
                          {/*  <a className={`${formVaildateStatus ? 'join--item-num-active' : 'join--item-num'}`}>1</a> */}
                          <span className={'join--item-num-active-plan'}>$0/Free</span>
                        </div>
                       
                        <div className="join--idnty-top-plan text-plan">
                           <span className="plan-name"> Self Employed </span> 
                        </div>
                        <div className="join--idnty text-plan">
                          <span>
                          <img className='plan-image-icon' src="images/right_img.png" />Business profile
                            </span>
                        </div>
                      {/*   <div className="join--idnty text-plan">
                             <span>
                             <img className='plan-image-icon' src="images/right_img.png" /> Intro video
                             </span>
                        </div> */}
                        <div className="join--idnty text-plan">
                             <span>
                             <img className='plan-image-icon' src="images/right_img.png" />Business logo
                              </span>
                        </div>
                        <div className="join--idnty text-plan">
                             <span>
                              <img className='plan-image-icon' src="images/right_img.png" />
                              Business license
                              </span>
                        </div>
                        {/* <div className="join--idnty text-plan">
                             <span>
                              <img className='plan-image-icon' src="images/crose-icon-image.png" />Job
                              </span>
                        </div>
                        <div className="join--idnty text-plan">
                             <span>
                              <img className='plan-image-icon' src="images/crose-icon-image.png" />Offer
                              </span>
                        </div>
                        <div className="join--idnty text-plan">
                             <span>
                              <img className='plan-image-icon' src="images/crose-icon-image.png" />Priority search result
                              </span>
                        </div>
                        <div className="join--idnty text-plan">
                             <span>
                              <img className='plan-image-icon' src="images/crose-icon-image.png" />Invesment Opportunities
                              <span className='sub-heading-plan'> (Business, Real Estate,FinTech,etc...)</span>
                              </span>
                        </div> */}
                        <div className="join--idnty-content-plan">
                          <span 
                            onClick={(plantype)=>purchaseplan(0,1,'','Self Employed')}
                            className='btn-purple rounded'>Buy Now</span>
                        </div>

                      </div>
                    </div>
                
                   {/* { 
                   businessData?.businessRegisterDetail?.two_factor_auth === 1 ?  */}
                   <>
                    <div className="col-lg-4 col-md-6 col-sm-9">
                      <div className="join-item join--smb-item mt-30">
                        <div className="text-center join--itemnum-box">
                        
                          <span className={'join--item-num-active-plan'}>$10/Month</span>
                        </div>

                        <div className="join--idnty-top-plan text-plan">
                           <span className="plan-name"> Business Owner </span> 
                        </div>
                        <div className="join--idnty text-plan">
                          <span>
                          <img className='plan-image-icon' src="images/right_img.png" /> Business profile
                            </span>
                        </div>
                        <div className="join--idnty text-plan">
                             <span>
                             <img className='plan-image-icon' src="images/right_img.png" /> Intro video
                             </span>
                        </div>
                        <div className="join--idnty text-plan">
                             <span>
                             <img className='plan-image-icon' src="images/right_img.png" />Business logo
                              </span>
                        </div>
                        <div className="join--idnty text-plan">
                             <span>
                              <img className='plan-image-icon' src="images/right_img.png" /> Business license
                              </span>
                        </div>
                        <div className="join--idnty text-plan">
                             <span>
                              <img className='plan-image-icon' src="images/right_img.png" />Document files
                              </span>
                        </div>
                        <div className="join--idnty text-plan">
                             <span>
                              <img className='plan-image-icon' src="images/right_img.png" />10 Photos
                              </span>
                        </div>
                        <div className="join--idnty text-plan">
                             <span>
                              <img className='plan-image-icon' src="images/right_img.png" />10 Jobs postings
                              </span>
                        </div>
                        <div className="join--idnty text-plan">
                             <span>
                              <img className='plan-image-icon' src="images/right_img.png" />10 Offers postings
                              </span>
                        </div>
                        <div className="join--idnty text-plan">
                             <span>
                              <img className='plan-image-icon' src="images/right_img.png" />Priority ranking
                              </span>
                        </div>
                        <div className="join--idnty text-plan">
                             <span>
                              <img className='plan-image-icon' src="images/right_img.png" />Investment requests
                              </span>
                        </div>
                       {/*  <div className="join--idnty text-plan">
                             <span>
                              <img className='plan-image-icon' src="images/crose-icon-image.png" />Invesment Opportunities
                              <span className='sub-heading-plan'> (Business, Real Estate,FinTech,etc...)</span>
                              </span>
                        </div> */}
                        <div className="join--idnty-content-plan">
                          <span 
                            onClick={(plantype)=>purchaseplan(10,2,'P-2KH790366X450330SMO4B5DY','Business Owner')}
                            className='btn-purple rounded'>Buy Now</span>
                        </div>
                      

                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-9">
                      <div className="join-item join--smb-item mt-30">
                        <div className="text-center join--itemnum-box">
                          
                          <span className={'join--item-num-active-plan'}>$100/month</span>
                        </div>
                        <div className="join--idnty-top-plan text-plan">
                           <span className="plan-name"> Active Investor </span> 
                        </div>
                        <div className="join--idnty text-plan">
                          <span>
                          <img className='plan-image-icon' src="images/right_img.png" /> Investment opportunities
                            </span>
                            
                        </div>
                        <div className="join--idnty text-plan">
                             <span>
                             <img className='plan-image-icon' src="images/right_img.png" />  Discover SMBs looking for investors
                             </span>
                             
                        </div>
                        
                        <div className="join--idnty text-plan">
                             <span>
                             <img className='plan-image-icon' src="images/right_img.png" />Search undervalued real estate deals
                              </span>
                        </div>
                       
                        <div className="join--idnty text-plan">
                             <span>
                              <img className='plan-image-icon' src="images/right_img.png" /> Discover new assets for investment
                              </span>
                        </div>
                        <div className="join--idnty text-plan">
                             <span>
                              <img className='plan-image-icon' src="images/right_img.png" />Partner with other investors for bigger deals
                              </span>
                        </div>
                        <div className="join--idnty text-plan">
                             <span>
                              <img className='plan-image-icon' src="images/right_img.png" />Give back, find SMBs looking for mentors
                              </span>
                        </div>
                        {/* <div className="join--idnty text-plan">
                             <span>
                              <img className='plan-image-icon' src="images/right_img.png" />Priority search result
                              </span>
                        </div>
                        <div className="join--idnty text-plan">
                             <span>
                              <img className='plan-image-icon' src="images/right_img.png" />Invesment Opportunities 
                              <span className='sub-heading-plan'> (Business, Real Estate,FinTech,etc...)</span>
                              </span>
                        </div> */}
                        <div className="join--idnty-content-plan">
                          <span 
                            onClick={(plantype)=>purchaseplan(100,3,'P-70967648FS892891LMO4B65I','Active Investor')}
                            className='btn-purple rounded'>Buy Now</span>
                        </div>

                      </div>
                    </div>
                    </>
                    {/* : '' } */}
                   </div>
                 </div>
              </div>



            </div>
          </div>
        </div>
      
        <Modal
            isOpen={paynowModel}
           //onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            ariaHideApp={false}
            >
            
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Confirmation
                </h5>
                <button
                  id="vaildateIdentityModel"
                  type="button"
                  className="close"
                  onClick={closeModal}
                  
                >
                  <span >×</span>
                </button>
                <div className="progress-bar" />
              </div>


              <div className="modal-content-doticon" style={{ "margin": "20px" }}>
                
              <h6>Are you sure you want to Buy <span className="planname-lable">${subscriptiondata?.amount}/Month {subscriptiondata?.plan_name}</span> Subscription ?
              </h6></div>


            
                <div className="modal-body pay-model">

                  <RedirectScreen
                  subscriptiondataplan={subscriptiondata}
                  purchaseplanAPI={() => purchaseplanAPI()}
                  isLoadingloader={isLoading}
                  />
                  {/* <form>
                    <div className="form-group">
                     
                    </div>
                    <div className="form-group">
                      <label className="d-block mb-0" />
                      <div className="inputsel">
                        <input
                          autoComplete="off"
                          id="myTextBox"
                          type="text"
                          className="form-control form-control-md "
                          placeholder="Your Mobile Number"
                         
                          max="10"
                         
                        />
                      </div>
                      <small className="text-danger">
                       
                      </small>
                    </div>

                   
                  </form> */}

                </div>

              
               
             

              

           
        </Modal>
      
      
      </section>
    </>
  );
}

export default Subscriptionplan;
