import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { apiCall } from "utils/httpClient";
import apiEndPoints from "utils/apiEndPoints";
import { successToast } from "utils/httpClient";
import { errorToast } from "utils/httpClient";
import moment from "moment";
import base64 from "base-64";
import { Document, Page } from "react-pdf";
function InvestorRequest() {
  const formRef = useRef();
  let history = useHistory();
  const [editData, setEditData] = useState({
    title: "",
    required_amount: "",
    Summary: "",

  });
  const [addData, setAddData] = useState({
    title: "",
    required_amount: "",
    Summary: "",

  });
  const [editStatus, setEditStatus] = useState(false);
  const [investorListData, setInvestorListData] = useState([]);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState();
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isLoader, setIsLoader] = useState(false);
  const [editDocument, setEditDocument] = useState("");
  const [base_url, setBase_url] = useState();
  const [fileSizeError, setFileSizeError] = useState("");
  const todaydate = moment().format("YYYY-MM-DD");
  const [bucket_Img_url, setBucket_Img_url] = useState();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isListLoading, setIsListLoading] = useState(false)
  const [showloader, setShowloader] = useState(true)





  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage);
    setItemOffset(newOffset)
    myInvestorRequestList(newOffset);
  };


  useEffect(() => {
    myInvestorRequestList(itemOffset);

  }, []);



  async function myInvestorRequestList(offset) {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    };
    showloader ? 
    setIsListLoading(true)
   : console.log('')
    const params = {
      limit: itemsPerPage,
      offset: offset,
    };
    const { data } = await apiCall(
      "POST",
      apiEndPoints.GETMYINVESTORREQUESTLIST,
      params,
      header
    );
    if (data.status == 200) {
      setShowloader(false)
      setIsListLoading(false)
      setInvestorListData(data.data);
      /*    const pageCount = data.total_data / itemsPerPage;
         setPageCount(pageCount); */

      const parPage = (data.total_data / itemsPerPage);
      console.log("myInvestorRequestList -> parPage", parPage)
      setPageCount(parPage)

    } else {
      setIsListLoading(false)
      setInvestorListData([]);
    }
  }
  function handleModel() {
    setEditStatus(false);
    const hh = "";
    setEditData({});
    console.log("handleModel -> setEditData", editData)

  }

  const [formError, setFormError] = useState({});

  function formValidationedit() {
    let error = {};
    let formErrorData = false;

    if (!editData.title) {
      formErrorData = true;
      error["title"] = "Title is required";
    }
    if (!editData.required_amount) {
      formErrorData = true;
      error["requiredamount"] = "Required Amount is required";
    }
    if (!editData.Summary) {
      formErrorData = true;
      error["Summary"] = "Summary is required";
    }

    setFormError(error);
    console.log(error,'error message');
    return formErrorData;
  }





  async function updateDocument() {
    if (true) { // update !formValidationedit()
      setIsLoader(true);

      var param = {
        title: editData.title,
        requiredamount: editData.required_amount,
        Summary: editData.Summary,
        request_id: editData.request_id,
        status: editData.status,

      }
      const { data } = await apiCall("POST", apiEndPoints.ADDINVESTORREQUEST, param);


      if (data.status == 200) {
        //setInvestorListData(data.data);
        myInvestorRequestList(itemOffset);
        successToast(data.message);
        setIsLoader(false);
        setEditStatus(false);
        document.getElementById("closeEditModal").click();
        setEditData({});
        setEditDocument();
        //formRef.current.reset();
      } else {
        errorToast(data.message);
        setIsLoader(false);
      }
    }
  }

 



  return (
    <>

      {/* ----------------------------------------------- edit --------------------------------------- */}
      <div
        className="modal fade addNewPhoto--modal"
        id="editNew"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="container">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {editStatus ? "Edit Investor Request" : "Add Investor Request"}
                </h5>
                <button
                  id="closeEditModal"
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => handleModel()}
                >
                  <span aria-hidden="true">×</span>
                </button>

                <div className="progress-bar" />
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label className="d-block mb-0">Business Name:-</label>
                    <span className="show-detail">{editData?.business_name}</span>
                  </div>
                  <div className="form-group">
                    <label className="d-block mb-0">Title:-</label>
                    <span className="show-detail">{editData?.title}</span>
                    {/*  <input
                      type="text"
                      className="form-control form-control-md"
                      placeholder="Title"
                      value={editData?.title}
                      onChange={(e) =>
                        setEditData({
                          ...editData,
                          title: e.target.value,
                        })
                      }
                    /> */}

                  </div>
                  <div className="form-group">
                    <label className="d-block mb-0">Required Amount:- </label>
                    <span className="show-detail">${editData?.required_amount}</span>
                  
                  </div>

                  <div className="form-group">
                    <label className="d-block mb-0">Summary:- </label>

                    <span className="show-detail">{editData?.summary}</span>
                   

                  </div>

                  {console.log("InvestorRequest -> editData", editData)}
                  <div className="form-group">
                    <label className="d-block mb-0">Status:-</label>
                    <select
                      //name="maritalstatus"
                      //id="maritalstatus"
                      placeholder="Head Count"
                      className="form-control form-control-md form-control-city"
                      value={editData?.status}
                      onChange={(e) =>
                        setEditData({
                          ...editData,
                          status: e.target.value,
                        })

                      }
                    >
                      <option value="">Select Status</option>
                      <option value="1">Request</option>
                      <option value="2">Open</option>
                      <option value="3">Close</option>
                      <option value="4">Reject</option>

                    </select>
                    <small className="text-danger">
                      {!editData.required_amount && formError.required_amount ? formError.required_amount : ""}
                    </small>
                  </div>



                  <div className="form-group date text-right">
                    <button
                      type="button"
                      className="main-btn rounded"
                      style={{ minWidth: 160 }}
                      onClick={() => {
                        updateDocument();
                      }}
                    >
                      {isLoader ? (
                        <img
                          src="images/loader_white.gif"
                          style={{ width: "30px" }}
                        />
                      ) : (
                          "Submit"
                        )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* --------------------------------------------- view ------------------------------- */}
      <div className="row" style={{ marginTop: "0.5rem" }}>
        <div className="col-md-12">
          <div class="card card-primary">
            <div class="card-header" style={{ backgroundColor: '#867df1' }}>
              <h5 class="card-title" style={{ color: '#ffff' }}>Invesment Requests</h5>
            </div>
            <div class="card-body" style={{ padding: 0 }}>


              <div className="">
                {isListLoading ? (
                  <div style={{ textAlign: 'center' }}>
                    <img className="listloadergif-admin" src="images/infinity.gif" />

                  </div>
                ) : investorListData && investorListData.length > 0 ? (

                  <div>
                    <div className="display--table">
                      <table className="table table-border table-light">
                        <thead>
                          <tr className="tbl--row">

                            <th className="">#</th>
                            <th className="">Business Name</th>
                            <th className="">Title</th>
                            <th className="">Amount</th>
                            <th className="">Summary</th>
                            <th className="">Date</th>
                            <th className="">Status</th>
                            <th className="">Action</th>
                          </tr>
                        </thead>
                        <tbody>

                          {investorListData.map((item, i) => (
                            <tr className="tbl--row">

                              <td>{i + 1 + itemOffset}</td>
                              <td>{item.business_name}</td>
                              <td>{item.title}</td>
                              <td>${item.required_amount.toLocaleString('en-US')}</td>
                              <td style={{ width: '28%', padding: '8px' }}><span className="ellipsis"
                              >{item.summary}</span></td>

                              <td>{moment(item.created_date).format("MM-DD-YYYY")}</td>
                              <td className={
                                item.status == 1 ? 'Requested-class' :
                                  item.status == 2 ? 'Open-class' :
                                    item.status == 3 ? 'Close-class' :
                                      'Disable-class'
                              }>
                                {
                                  item.status == 1 ? 'Requested' :
                                    item.status == 2 ? 'Opened' :
                                      item.status == 3 ? 'Closed' :
                                        'Rejected'
                                }</td>

                              <td>
                                {/* <a
                          
                          data-toggle="modal"
                          data-target="#viewDocument"
                        >
                          <img
                            className="tbl--actin-icon"
                            src="images/icons/eye.png"
                          />
                        </a> */}{" "}
                                <a
                                  onClick={() => {
                                    console.log("InvestorRequest -> item", item)
                                    setEditData(item);

                                    setEditStatus(true);
                                  }}
                                  data-toggle="modal"
                                  data-target="#editNew"
                                >
                                  <img
                                    className="tbl--actin-icon"
                                    src="images/icons/edit.png"
                                  />
                                </a>{" "}

                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="btm-pagination mt-4">
                      <div className="d-flex justify-content-end">
                        <ReactPaginate
                          // previousLabel={'previous'}
                          // nextLabel={'next'}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={pageCount}
                          marginPagesDisplayed={4}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          containerClassName={
                            "pagination pagination-style-02 justify-content-center"
                          }
                          subContainerClassName={"pages pagination"}
                          activeClassName={"current active active_pg"}
                          activeLinkClassName={"current active active_pg"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link bg-gray"}
                          previousClassName={"page-item"}
                          nextClassName={"page-item"}
                          previousLinkClassName={"page-link bg-gray"}
                          nextLinkClassName={"page-link bg-gray"}
                          previousLabel={<i className="fal fa-chevron-left"></i>}
                          nextLabel={<i className="fal fa-chevron-right"></i>}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                      <div className="text-center">
                        <img className="no-foundimage" src="images/nodatafound.png" />
                        <span style={{"marginBottom": "3%"}} className="not-found-content">Ooops, no Invesment Requests found</span>
                      </div>
                    )}
              </div>

            </div>
          </div>
        </div>
      </div>



    </>
  );
}

export default InvestorRequest;
