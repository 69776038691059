import {GET_USERS, USERS_ERROR} from '../types'
import axios from 'axios'


export const setUserAuthData = (item) => {
    return {
        type: 'SETUSERAUTHDATA',
        payload: item
    }   
}
export const setServiceSearch = (item) => {
    return {
        type: 'SETSERVICESEARCH',
        payload: item
    }   
}

export const setServiceBusinessData = (item) => {
    return {
        type: 'SETSERVICEBUSINESSDATA',
        payload: item
    }   
}
export const setBusinessRegisterDetail = (item) => {
    return {
        type: 'SETBUSINESSREGISTERDETAIL',
        payload: item
    }   
}

export const handleLoginStatus = (item) => {
    return {
        type: 'LOGINSTATUS',
        payload: item
    }   
}

export const getUserAuthData = () => {
    const res =  axios.get(`http://jsonplaceholder.typicode.com/users`)
    return {
        type: 'GETUSERAUTHDATA',
        payload: res.data
    }   
}

export const serchServiceCitySelect = (item) => {
    return {
        type: 'SEARCHSERVICESELECT',
        payload: item
    }
}
