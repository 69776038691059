import React from "react";
import { Link } from "react-router-dom";



const Details = ({
  businessServiceDetail,
  paymentList,
  servicelist,
  abouthiden,
  seemore,
}) => {

  const datahhh = businessServiceDetail?.about ? businessServiceDetail.about : null;


  const formattedText = datahhh ? datahhh.replace(/(\r\n|\n|\r)/g, '<br />') : null;

  /* var parsedObject = JSON.parse(paymentList); */

  // Filter the keys with a value of 1 and join them into a comma-separated string
  /* var resultpayment = Object.keys(paymentList)
    .filter(key => paymentList[key] === 1)
    .join(', '); */


  {/* {paymentList?.cash == 1 ? "Cash" : null}
                  {paymentList?.creditcard == 1 ? ", Card" : null}
                  {paymentList?.cashapp == 1 ? ", Check " : null}
                  {paymentList?.paypal == 1 ? ", Paypal " : null}
                  {paymentList?.zelle == 1 ? ", Zelle" : null} */}
  {/* {businessServiceDetail.year_revenue} */ }


  var replacements = {
    cash: 'Cash',
    cashapp: 'Check',
    creditcard: 'Card',
    paypal: 'Paypal',
    zelle: 'Zelle'
  };

  // Filter the keys with a value of 1, map them to the replacements, and join them into a comma-separated string
  var resultpayment = Object.keys(paymentList)
    .filter(key => paymentList[key] === 1)
    .map(key => replacements[key])
    .join(', ');


  return (
    <div className="card-body">
      <div className="row align-items-center business-externo">
        <div className="col-md-12 bus-detail-cont">
          <div className="srvc--containt card-body p-0">
            <div className="row">
              {/*  <div className="col-md-6">
                <small className="srvc--text serice--list-text">
                  <b>City:</b>&nbsp;
                  {businessServiceDetail?.city},&nbsp;
                  {businessServiceDetail?.state_name}
                </small>
              </div> */}
              <div className="col-md-6">
                <small className="srvc--text serice--list-text">
                  <b>Pricing Model:{" "}</b>&nbsp;
                  {businessServiceDetail.pricemodel}
                </small>
              </div>
              {/* <div className="col-md-6">
                <small className="srvc--text serice--list-text">
                  <b>Phone:</b>&nbsp;
                  {businessServiceDetail.phone}
                </small>
              </div> */}

              {/* <div className="col-md-6">
                <small className="srvc--text serice--list-text">
                  <b>Email:</b>&nbsp;
                  {businessServiceDetail.email}
                </small>
              </div> */}
              {/* <div className="col-md-6">
                                        <small className="srvc--text serice--list-text">
                                          <b>Head count:</b>&nbsp;
                                          {businessServiceDetail.numemps}
                                        </small>
                                      </div> */}
              {/*  <div className="col-md-6">
                <small className="srvc--text serice--list-text">
                  <b>Industry:</b>&nbsp;
                  {businessServiceDetail.industry_name}
                </small>
              </div> */}
              {/* <div className="col-md-6">
                                        <small className="srvc--text serice--list-text">
                                          <b>Servicing:</b>
                                          {businessServiceDetail.service_area}
                                        </small>
                                      </div> */}
              <div className="col-md-6">
                <small className="srvc--text serice--list-text">
                  <b>Payments:</b>&nbsp;
                  {
                    console.log("ServiceDetails -> paymentList", resultpayment)
                  }
                  {/* {paymentList?.cash == 1 ? "Cash" : null}
                  {paymentList?.creditcard == 1 ? ", Card" : null}
                  {paymentList?.cashapp == 1 ? ", Check " : null}
                  {paymentList?.paypal == 1 ? ", Paypal " : null}
                  {paymentList?.zelle == 1 ? ", Zelle" : null} */}
                  {/* {businessServiceDetail.year_revenue} */}
                  {resultpayment}
                </small>
              </div>
              {/* <div className="col-md-6">
                <small className="srvc--text serice--list-text">
                  <b>Occupation:</b>&nbsp;
                  {servicelist.substring(0, servicelist.length - 2)}
                </small>
              </div> */}
              <div className="col-md-6">
                <small className="srvc--text serice--list-text">
                  <b>Hours:</b>&nbsp;
                  {businessServiceDetail.hours}
                </small>
              </div>
              {/*   <div className="col-md-6">
                                        <small className="srvc--text serice--list-text">
                                          <b>Service Offer:</b>
                                          {businessServiceDetail.service_offer}
                                        </small>
                                      </div> */}
              {/* <div className="col-md-6">
                                        <small className="srvc--text serice--list-text">
                                          <b>Year Revenue:</b>
                                          {businessServiceDetail.year_revenue}
                                        </small>
                                      </div> */}
              <div className="col-md-6 ">
                <small className="srvc--text serice--list-text website">
                  <b>Website:</b>&nbsp;
                  <a href={businessServiceDetail.websiteurl} target="_blank">
                    {" "}
                    {businessServiceDetail.websiteurl}
                  </a>
                </small>
              </div>

              <div className="col-md-12">
                <hr></hr>
                <small className="srvc--text serice--list-text">
                  <div
                    className={
                      abouthiden
                        ? businessServiceDetail.about?.length > 150
                          ? "hideall"
                          : ""
                        : "showall"
                    }
                  >

                    <b>About:</b>&nbsp;
                    <div dangerouslySetInnerHTML={{ __html: formattedText }}></div>
                    {/* {businessServiceDetail.about} */}
                  </div>

                  {businessServiceDetail.about?.length > 150 ? (
                    abouthiden ? (
                      <div onClick={() => seemore(false)} className="seemore">
                        See More
                      </div>
                    ) : (
                      <div onClick={() => seemore(true)} className="seemore">
                        See Less
                      </div>
                    )
                  ) : null}
                </small>
              </div>
            </div>
            {/* <h5 className="serc--details-head">
                                      Overview :
                                    </h5>
                                    <p className="serc--details-text">
                                      {businessServiceDetail.about}{" "}
                                    </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
