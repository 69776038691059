import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import base64 from "base-64";
import Loader from "common/loader";
import { Rating } from "react-simple-star-rating";
import LogoSlider from "screen/service/serviceDetail/component/logoSlider";
function serviceList(props) {

  const [bucket_Img_url, setBucket_Img_url] = useState();
  const [openModal, setOpenModal] = useState(false)
  const [popuptitle, setPopuptitle] = useState("");

  const fullviewimage = (imagepath, title) => {

    setOpenModal(!openModal)
    setBucket_Img_url(imagepath)
    setPopuptitle(title)
  }

  const SponsoredCount = props.serviceListData.length > 0 ? props.serviceListData.filter(item => item.plan_type === 2) : [];

  const freeCount = props.serviceListData.length > 0 ? props.serviceListData.filter(item => item.plan_type === 1) : [];



  return (
    <section className="default-smb-area bg--light">
      {openModal ? <LogoSlider
        bucket_Img_url={bucket_Img_url}
        setOpenModal={setOpenModal}
        popuptitle={popuptitle}
      /> : ""}
      <div className="container">
        <div className="mb-4">
          <div className="row">
            <div className="col-md-5 serach-filed">
              <div className="">
                <label className="Form--lbl">Occupation</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    id="serviceSearch"
                    defaultValue={props.reset != 1 ? props.serviceName : ""}
                    onChange={(e) => {
                      props.handleServiceSearch(e.target.value);
                    }}
                    onBlur={(e) => props.manageSearchService(e.target.value)}
                    placeholder="Electricians, Plumbers, Lawyers"
                    autoComplete="off"
                  />
                  <div>
                    <div
                      className={`city_list_box ${props.serviceListShow ? "show" : "hide"
                        }`}
                    >
                      {props.searchServiceList &&
                        props.searchServiceList.length > 0 ? (
                        <ul className="city_list_menu">
                          {props.searchServiceList.map((item, i) => {
                            return (
                              <li
                                onClick={() => {
                                  props.setSelectedService(item.naicsid);
                                  props.handleSelectService(item.title);
                                  props.setFormError({
                                    ...props.formError,
                                    service: "",
                                  });
                                }}
                                className={`city_list ${props.serviceData?.service == item.naicsid
                                  ? "active"
                                  : ""
                                  }`}
                              >
                                {item.title}
                              </li>
                            );
                          }, [])}
                        </ul>
                      ) : (
                        <p
                          style={{
                            textAlign: "center",
                            marginTop: "2px",
                            color: "gray",
                          }}
                        ></p>
                      )}
                    </div>
                  </div>
                  <small className="text-danger">
                    {props.formError?.service ? props.formError?.service : ""}
                  </small>
                </div>
              </div>
            </div>
            <div className="col-md-4 serach-filed">
              <div className="">
                <label className="Form--lbl">City</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    id="citySearch"
                    defaultValue={props.reset != 1 ? props.cityName : ""}
                    onChange={(e) => {
                      props.handleCitySearch(e.target.value);
                    }}
                    onBlur={(e) => props.manageSearchCity(e.target.value)}
                    placeholder="Boca Raton, Miami, Orlando"
                    autoComplete="off"
                  />
                  <div>
                    <div
                      className={`city_list_box ${props.cityListShow ? "show" : "hide"
                        }`}
                    >
                      {props.searchCityList &&
                        props.searchCityList.length > 0 ? (
                        <ul className="city_list_menu">
                          {props.searchCityList.map((item, i) => {
                            return (
                              <li
                                onClick={() => {
                                  props.setSelectedCity(item.id);
                                  props.setCityListShow(false);
                                  props.handleSelectCity(
                                    item.city,
                                    item.state_id
                                  );
                                  props.setFormError({
                                    ...props.formError,
                                    city: "",
                                  });
                                }}
                                className={`city_list ${props.serviceData?.city == item.id
                                  ? "active"
                                  : ""
                                  }`}
                              >
                                {item.city}, {item.state_id}
                              </li>
                            );
                          }, [])}
                        </ul>
                      ) : (
                        <p
                          style={{
                            textAlign: "center",
                            marginTop: "2px",
                            color: "gray",
                          }}
                        ></p>
                      )}
                    </div>
                  </div>
                  <small className="text-danger">
                    {props.formError?.city ? props.formError?.city : ""}
                  </small>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <button
                style={{ "marginTop": "22px" }}
                type="button"
                className="btn main-btn w-100"
                // onClick={() => props.getServiceDataList(0)}
                onClick={() => props.searchServiceDataList()}
              >
                Search Businesses
              </button>
            </div>

          </div>
        </div>
        <div className="">
          {!props.resetIsLoading ? (
            <div>
              <div className="page-container border-bottom-0">
                <div className="row">
                  {SponsoredCount.length > 0 ? <h5 style={{ "color": "#6258d3", "fontSize": "12pt" }}>Sponsored</h5> : null}
                  {props.serviceListData && props.serviceListData.length > 0 ? (
                    props.serviceListData && props.serviceListData.length > 0 ? (
                      SponsoredCount.map((item, i) => (

                        <div
                          className="page-content col-xl-6 mb-6 mb-xl-0"
                          key={i}
                        >
                          <div className="widget mb-8">
                            <div className="rm-listings">
                              <div className="card border-8 shadow mb-3">
                                <div className="card-header bg-white border-t-8" style={{ borderBottom: "none", marginBottom: "-10px" }}>
                                  <div
                                    className="bg--smb w-100 p-2"
                                    style={{ borderRadius: "10px" }}
                                  >
                                    <div className="d-flex justify-content-between row">
                                      <div className="col-md-9">
                                        <h5 className="m-0 text-white">
                                          {item.business_name}
                                        </h5>
                                      </div>
                                      <div className="col-md-3 item-end-show">
                                        <span
                                          className="badge bg-white p-1"
                                          style={{ borderRadius: "30px" }}
                                        >
                                          <small className="srvc--text">
                                            {/*  Rating ({Math.round(item.ranting)}) */}
                                            <div
                                              className="d-flex"
                                              style={{
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Rating initialValue={Math.round(item.ranting)} readonly="true" size="18" />


                                            </div>
                                          </small>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-md-3">
                                      <div className="ser_img_lst">
                                        <img
                                          // src={
                                          //   item.photofile != "null"
                                          //     ? props.baseUrl + item.photofile
                                          //    : "images/no_image.png"
                                          // }
                                          src={
                                            item.aws_url
                                              ? item.aws_url
                                              : "images/no_image.png"
                                          }
                                          className="card-img rounded "
                                          alt="Business Image"
                                          onClick={() => fullviewimage(item.aws_url
                                            ? item.aws_url
                                            : "images/no_image.png", item?.business_name)}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-9">
                                      <div className="srvc--containt card-body p-0">
                                        <small className="srvc--text serice--list-text">
                                          {item.brand_text}
                                        </small>
                                        <small>
                                          {item?.pricemodel != null ? (
                                            <span className="service--amunt-rigt">
                                              Pricing Model: {item?.pricemodel}
                                            </span>
                                          ) : null}
                                        </small>
                                        <small className="srvc--text serice--list-text">
                                          {/* <b>Service:</b>&nbsp;{item.naics_name} */}
                                          {/* <b>Services:</b>&nbsp; */}

                                          <b>Occupation:</b>&nbsp;
                                          {item?.service_name.map(
                                            (items, index) => {
                                              return (
                                                <span key={index}>
                                                  {" "}
                                                  {items.title}
                                                  {item?.service_name.length !==
                                                    index + 1
                                                    ? ", "
                                                    : null}{" "}
                                                </span>
                                              );
                                            }
                                          )}
                                        </small>

                                        <small className="srvc--text serice--list-text">
                                          <b>Industry:</b>&nbsp;{item?.industryname}
                                        </small>
                                        <small className="srvc--text serice--list-text">
                                          <b>City:</b>&nbsp;{item?.city},&nbsp;{item?.state_name}
                                        </small>
                                        <small className="srvc--text serice--list-text">
                                          <b>Hours:</b>&nbsp;{item?.hours}
                                        </small>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer text-muted bg-white border-b-8">
                                  <div className="d-flex justify-content-between">
                                    <div>
                                      <label className="business__card-badge mr-1" style={{ backgroundColor: "#6258d3" }}>

                                        <Link
                                          to={`/${item?.businessUrlName
                                            .replace(/\s+/g, "")
                                            .toLowerCase()}`}
                                          className="business__card-badge--p"
                                          style={{
                                            color: "#ffff"

                                          }}
                                        >
                                          <i class="fa fa-eye"></i> <small className="p__text" style={{ fontSize: "14px" }}>Detail</small>
                                        </Link>
                                      </label>
                                      {item.showtext > 0 ? (
                                        <label className="business__card-badge mr-1">
                                          <Link
                                            to="#"
                                            onClick={(e) => {
                                              window.location = `sms:${item.phone}?&body=<<Type here the information you want to request from the business>>`;
                                              e.preventDefault();
                                            }}
                                          >
                                            <p className="business__card-badge--p">
                                              <i className="fa fa-comments"></i>{" "}
                                              <small className="p__text" style={{ fontSize: "14px" }}>Text</small>
                                            </p>
                                          </Link>
                                        </label>
                                      ) : (
                                        ""
                                      )}

                                      {item.showcall > 0 ? (
                                        <label className="business__card-badge mr-1">
                                          <Link
                                            to="#"
                                            onClick={(e) => {
                                              window.location = `tel:${item.phone}`;
                                              e.preventDefault();
                                            }}
                                          >
                                            <p className="business__card-badge--p">
                                              <i className="fa fa-phone"></i> <small className="p__text" style={{ fontSize: "14px" }}>Call</small>
                                            </p>
                                          </Link>
                                        </label>
                                      ) : (
                                        ""
                                      )}

                                      {item.showemail > 0 ? (
                                        <label className="business__card-badge mr-1">
                                          <a
                                            target="_blank"
                                            href={`mailto:${item.email}?&subject=New Lead from MAXBIZ&body=<<Type here the information you want to request from the business>>`}
                                          >
                                            <p className="business__card-badge--p">
                                              <i className="fa fa-envelope"></i>{" "}
                                              <small className="p__text" style={{ fontSize: "14px" }}>Email</small>
                                            </p>
                                          </a>
                                        </label>
                                      ) : (
                                        ""
                                      )}

                                      <label className="business__card-badge mr-1">
                                        <a target="_blank" href={item.websiteurl}>
                                          <p className="business__card-badge--p">
                                            <i class="fa fa-globe"></i> <small className="p__text" style={{ fontSize: "14px" }}>Web</small>
                                          </p>
                                        </a>
                                      </label>
                                      <label className="business__card-badge mr-1">
                                        <a target="_blank" href={`https://www.google.com/maps/search/${item.address}`}>
                                          <p className="business__card-badge--p">
                                            <i class="fa fa-map"></i> <small className="p__text" style={{ fontSize: "14px" }}>Map</small>
                                          </p>
                                        </a>
                                      </label>
                                      <label className="business__card-badge mr-1">
                                        <a target="_blank" href={`mailto:mbt@maxelit.com?&subject=Suspicious Content&body=Dear MAXBIZ Team:%0D%0A%0D%0APlease review the following suspicious content: <<INCLUDE PAGE, SUSPICIOUS CONTENT AND ANY OTHER RELEVANT DETAILS>>.%0D%0A%0D%0ARegards,%0D%0A%0D%0A<<YOUR FULL NAME>>%0D%0A<<YOUR PHONE NUMBER>>`}>
                                          <p className="business__card-badge--p">
                                            <i class="fa fa-flag"></i> <small className="p__text" style={{ fontSize: "14px" }}>Report</small>
                                          </p>
                                        </a>
                                      </label>

                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* </Link> */}
                        </div>
                      ))
                    ) : null
                  ) : (
                    <div className="text-center" style={{ "backgroundColor": "white", "height": "450px" }}>
                      <img src="images/nodatafound.png" />
                      <span className="not-found-content">Ooops, no Businesses found</span>
                    </div>
                  )}
                </div>
                <div className="row">

                  {freeCount.length > 0 && SponsoredCount.length > 0 ? <div className="borderfree"></div> : null}

                  {freeCount && freeCount.length > 0 ? (
                    freeCount.map((item, i) => (
                      <>
                        {
                         <div
                                className="page-content col-xl-6 mb-6 mb-xl-0"
                                key={i}
                              >
                                <div className="widget mb-8">
                                  <div className="rm-listings">
                                    <div className="card border-8 shadow mb-3">
                                      <div className="card-header bg-white border-t-8" style={{ borderBottom: "none", marginBottom: "-10px" }}>
                                        <div
                                          className="bg--smb w-100 p-2"
                                          style={{ borderRadius: "10px" }}
                                        >
                                          <div className="d-flex justify-content-between row">
                                            <div className="col-md-9">
                                              <h5 className="m-0 text-white">
                                                {item.business_name}
                                              </h5>
                                            </div>
                                            <div className="col-md-3 item-end-show">
                                              <span
                                                className="badge bg-white p-1"
                                                style={{ borderRadius: "30px" }}
                                              >
                                                <small className="srvc--text">
                                                  {/*  Rating ({Math.round(item.ranting)}) */}
                                                  <div
                                                    className="d-flex"
                                                    style={{
                                                      justifyContent: "space-between",
                                                    }}
                                                  >
                                                    <Rating initialValue={Math.round(item.ranting)} readonly="true" size="18" />

                                                    {/*  <div className="usr--head-rating-list">
                                            <span
                                              className={`fa fa-star ${
                                                item.ranting >= 1
                                                  ? "checked"
                                                  : "unChecked"
                                              }`}
                                            />
                                            <span
                                              className={`fa fa-star ${
                                                item.ranting >= 2
                                                  ? "checked"
                                                  : "unChecked"
                                              }`}
                                            />
                                            <span
                                              className={`fa fa-star ${
                                                item.ranting >= 3
                                                  ? "checked"
                                                  : "unChecked"
                                              }`}
                                            />
                                            <span
                                              className={`fa fa-star ${
                                                item.ranting >= 4
                                                  ? "checked"
                                                  : "unChecked"
                                              }`}
                                            />
                                            <span
                                              className={`fa fa-star ${
                                                item.ranting >= 5
                                                  ? "checked"
                                                  : "unChecked"
                                              }`}
                                            />
                                          </div> */}
                                                  </div>
                                                </small>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card-body">
                                        <div className="row">
                                          <div className="col-md-3">
                                            <div className="ser_img_lst">
                                              <img
                                                // src={
                                                //   item.photofile != "null"
                                                //     ? props.baseUrl + item.photofile
                                                //    : "images/no_image.png"
                                                // }
                                                src={
                                                  item.aws_url
                                                    ? item.aws_url
                                                    : "images/no_image.png"
                                                }
                                                className="card-img rounded "
                                                alt="Business Image"
                                                onClick={() => fullviewimage(item.aws_url
                                                  ? item.aws_url
                                                  : "images/no_image.png", item?.business_name)}
                                              />
                                            </div>
                                          </div>
                                          <div className="col-md-9">
                                            <div className="srvc--containt card-body p-0">
                                              <small className="srvc--text serice--list-text">
                                                {item.brand_text}
                                              </small>
                                              <small>
                                                {item?.pricemodel != null ? (
                                                  <span className="service--amunt-rigt">
                                                    Pricing Model: {item?.pricemodel}
                                                  </span>
                                                ) : null}
                                              </small>
                                              <small className="srvc--text serice--list-text">
                                                {/* <b>Service:</b>&nbsp;{item.naics_name} */}
                                                {/* <b>Services:</b>&nbsp; */}

                                                <b>Occupation:</b>&nbsp;
                                                {item?.service_name.map(
                                                  (items, index) => {
                                                    return (
                                                      <span key={index}>
                                                        {" "}
                                                        {items.title}
                                                        {item?.service_name.length !==
                                                          index + 1
                                                          ? ", "
                                                          : null}{" "}
                                                      </span>
                                                    );
                                                  }
                                                )}
                                              </small>
                                              {/*  <small className="srvc--text serice--list-text">
                                        <b>Mobile:</b>&nbsp;{item.phone}
                                      </small>
                                      <small className="srvc--text serice--list-text">
                                        <b>Email:</b>&nbsp;{item.email}
                                      </small> */}
                                              <small className="srvc--text serice--list-text">
                                                <b>Industry:</b>&nbsp;{item?.industryname}
                                              </small>
                                              <small className="srvc--text serice--list-text">
                                                <b>City:</b>&nbsp;{item?.city},&nbsp;{item?.state_name}
                                              </small>
                                              <small className="srvc--text serice--list-text">
                                                <b>Hours:</b>&nbsp;{item?.hours}
                                              </small>
                                              {/*  <small className="srvc--text serice--list-text location">
                                        <b>Location:</b>&nbsp;
                                        <i className="fa fa-map-marker"> </i>
                                        &nbsp;
                                        <a
                                          target="_blank"
                                          href={`https://www.google.com/maps/search/${item.address}`}
                                        >
                                          {item.address}
                                        </a>
                                      </small> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card-footer text-muted bg-white border-b-8">
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <label className="business__card-badge mr-1" style={{ backgroundColor: "#6258d3" }}>

                                              <Link
                                                to={`/${item?.businessUrlName
                                                  .replace(/\s+/g, "")
                                                  .toLowerCase()}`}
                                                className="business__card-badge--p"
                                                style={{
                                                  color: "#ffff"

                                                }}
                                              >
                                                <i class="fa fa-eye"></i> <small className="p__text" style={{ fontSize: "14px" }}>Detail</small>
                                              </Link>

                                            </label>
                                            {item.showtext > 0 ? (
                                              <label className="business__card-badge mr-1">
                                                <Link
                                                  to="#"
                                                  onClick={(e) => {
                                                    window.location = `sms:${item.phone}?&body=<<Type here the information you want to request from the business>>`;
                                                    e.preventDefault();
                                                  }}
                                                >
                                                  <p className="business__card-badge--p">
                                                    <i className="fa fa-comments"></i>{" "}
                                                    <small className="p__text" style={{ fontSize: "14px" }}>Text</small>
                                                  </p>
                                                </Link>
                                              </label>
                                            ) : (
                                              ""
                                            )}

                                            {item.showcall > 0 ? (
                                              <label className="business__card-badge mr-1">
                                                <Link
                                                  to="#"
                                                  onClick={(e) => {
                                                    window.location = `tel:${item.phone}`;
                                                    e.preventDefault();
                                                  }}
                                                >
                                                  <p className="business__card-badge--p">
                                                    <i className="fa fa-phone"></i> <small className="p__text" style={{ fontSize: "14px" }}>Call</small>
                                                  </p>
                                                </Link>
                                              </label>
                                            ) : (
                                              ""
                                            )}

                                            {item.showemail > 0 ? (
                                              <label className="business__card-badge mr-1">
                                                <a
                                                  target="_blank"
                                                  href={`mailto:${item.email}?&subject=New Lead from MAXBIZ&body=<<Type here the information you want to request from the business>>`}
                                                >
                                                  <p className="business__card-badge--p">
                                                    <i className="fa fa-envelope"></i>{" "}
                                                    <small className="p__text" style={{ fontSize: "14px" }}>Email</small>
                                                  </p>
                                                </a>
                                              </label>
                                            ) : (
                                              ""
                                            )}

                                            <label className="business__card-badge mr-1">
                                              <a target="_blank" href={item.websiteurl}>
                                                <p className="business__card-badge--p">
                                                  <i class="fa fa-globe"></i> <small className="p__text" style={{ fontSize: "14px" }}>Web</small>
                                                </p>
                                              </a>
                                            </label>
                                            <label className="business__card-badge mr-1">
                                              <a target="_blank" href={`https://www.google.com/maps/search/${item.address}`}>
                                                <p className="business__card-badge--p">
                                                  <i class="fa fa-map"></i> <small className="p__text" style={{ fontSize: "14px" }}>Map</small>
                                                </p>
                                              </a>
                                            </label>

                                            <label className="business__card-badge mr-1">
                                              <a target="_blank" href={`mailto:mbt@maxelit.com?&subject=Suspicious Content&body=Dear MAXBIZ Team:%0D%0A%0D%0APlease review the following suspicious content: <<INCLUDE PAGE, SUSPICIOUS CONTENT AND ANY OTHER RELEVANT DETAILS>>.%0D%0A%0D%0ARegards,%0D%0A%0D%0A<<YOUR FULL NAME>>%0D%0A<<YOUR PHONE NUMBER>>`}>
                                                <p className="business__card-badge--p">
                                                  <i class="fa fa-flag"></i> <small className="p__text" style={{ fontSize: "14px" }}>Report</small>
                                                </p>
                                              </a>
                                            </label>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* </Link> */}
                         </div>
                            
                        }
                      </>
                    ))
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center">
              {" "}
              <div className="" style={{ height: "300px" }}>
                <div className="lds-ellipsis">
                  <span />
                  <span />
                  <span />
                </div>
              </div>{" "}
            </div>
          )}

          {((props.serviceListData && props.serviceListData.length > 10) || props.itemOffset > 0) && (
            <div className="btm-pagination">
              <div className="d-flex justify-content-end">
                <ReactPaginate
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={props.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={4}
                  onPageChange={props.handlePageClick}
                  containerClassName={
                    "pagination pagination-style-02 justify-content-center"
                  }
                  subContainerClassName={"pages pagination"}
                  activeClassName={"current active active_pg"}
                  activeLinkClassName={"current active active_pg"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link bg-gray"}
                  previousClassName={"page-item"}
                  nextClassName={"page-item"}
                  previousLinkClassName={"page-link bg-gray"}
                  nextLinkClassName={"page-link bg-gray"}
                  previousLabel={<i className="fal fa-chevron-left"></i>}
                  nextLabel={<i className="fal fa-chevron-right"></i>}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
export default serviceList;
