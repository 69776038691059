import { GET_USERS, GETAUTHuserData } from '../types'

const initialState = {
    userData:{},
    loading:true
}

export const userDataStore = (state = initialState, action) => {
    switch (action.type) {

        case GET_USERS:
        return {
            ...state,
            userData:action.payload,
        }

        case 'SETUSERAUTHDATA':
        return {
            ...state,
            userData: action.payload,
        }
        case 'SETBUSINESSREGISTERDETAIL':
        return {
            ...state,
            businessRegisterDetail: action.payload,
        }
       
        default: return state
    }

}

const buiData = {
    businessRegisterDetail:{},
    loading:true
}
export const userBusinessDataStore = (state = buiData, action) => {
    switch (action.type) {

        case 'SETBUSINESSREGISTERDETAIL':
        return {
            ...state,
            businessRegisterDetail: action.payload,
        }
       
        default: return state
    }

}

const loginStatusData = {
    userName: '',
    userType: 'business',
    userToken: null,
    isLoading: true,
  }
export const userLoginStatusStore = (state = loginStatusData, action) => {
    switch (action.type) {
        case 'LOGINSTATUS':
        return {
            ...state,
            userName: action.payload?.username,
            userType: action.payload?.userType,
            userToken: action.payload?.token,
            isLoading: false,
        }
       
        default: return state
    }

}
