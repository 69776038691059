import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import apiEndPoints from "utils/apiEndPoints";
import { apiCall } from "utils/httpClient";
import moment from "moment";
function PropertyListing(props) {
    const wrapperRef = useRef(null);
    const { setshowContent, showDetail, propertySearch, setPropertySearch } = props


    const [formError, setFormError] = useState({});

    const [propertyListData, setPropertyListData] = useState([]);
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState();
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(50);

    const [isListLoading, setIsListLoading] = useState(false)
    const [showloader, setShowloader] = useState(true)
    const [searchCityList, setSearchCityList] = useState([]);
    const [cityListShow, setCityListShow] = useState(false);

    //for sale avg
    const [showTotalSaleRow, setshowTotalSaleRow] = useState(0);
    const [showsellavgsqft, setShowsellavgsqft] = useState(0);
    const [showavrgsellhomeprice, setShowavrgsellhomeprice] = useState(0);
    const [showavrgsellday, setShowavrgsellday] = useState(0);
    //for sold avg
    const [showsoldavgsqft, setShowsoldavgsqft] = useState(0);
    const [showavrgsoldhomeprice, setShowavrgsoldhomeprice] = useState(0);
    const [showavrgsoldday, setShowavrgsoldday] = useState(0);

    //for total avg
    const [showTotalavgsqft, setShowTotalavgsqft] = useState(0);
    const [showavrgTotalhomeprice, setShowavrgTotalhomeprice] = useState(0);
    const [showavrgTotalday, setShowavrgTotalday] = useState(0);


    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage);
        setItemOffset(newOffset)

        /*  !showloader ?
             setIsListLoading(true)
             : null */

        getSaleList(newOffset, true);
    };
    const filterProperty = (check) => {
        /*  !check ?
             setPropertySearch(
                 {
                     ...propertySearch,
                     city: "New York City",
                     state_code: "NY",
                     prop_type: "",
                     price_max: "",
                     hidepending: false,
                     hidecontingent: false,
                     hideforeclosure: false,
                 }
             )
             : null */

        if (!FormValidation()) {

            !showloader ?
                setIsListLoading(true)
                : console.log('')

            getSaleList(0, check);
        }
    };


    useEffect(() => {
        //getSaleList(itemOffset, true);

    }, []);

    function FormValidation() {
        let error = {};
        let formErrorData = false;

        if (!propertySearch.city) {
            formErrorData = true;
            error['city'] = "City is required";
        }
        if (!propertySearch.city) {
            formErrorData = true;
            error['state_code'] = "State code is required";
        }
        if (!propertySearch.prop_type) {
            formErrorData = true;
            error['prop_type'] = "Property Type is required";
        }
        if (!propertySearch.price_max) {
            formErrorData = true;
            error['price_max'] = "Price Max is required";
        }
        setFormError(error);
        return formErrorData;
    }


    async function getSaleList(offset, reset) {

        if (!FormValidation()) {
            //var params = ''
            showloader ?
                setIsListLoading(true)
                : console.log('')

            /*  if (!reset) {
                 params = {
                     city: "New York City",
                     state_code: "NY",
                     prop_type: "",
                     price_max: "",
                     hidepending: false,
                     hidecontingent: false,
                     hideforeclosure: false,
                     limit: itemsPerPage,
                     offset: offset,
                 }
             } else {
                 params = {
                     city: propertySearch.city,
                     state_code: propertySearch.state_code,
                     prop_type: propertySearch.prop_type,
                     price_max: propertySearch.price_max,
                     hidepending: propertySearch.hidepending,
                     hidecontingent: propertySearch.hidecontingent,
                     hideforeclosure: propertySearch.hideforeclosure,
                     limit: itemsPerPage,
                     offset: offset,
                 };
             } */

            const params = {
                city: propertySearch.city,
                state_code: propertySearch.state_code,
                prop_type: propertySearch.prop_type,
                price_max: propertySearch.price_max,
                hidepending: propertySearch.hidepending,
                hidecontingent: propertySearch.hidecontingent,
                hideforeclosure: propertySearch.hideforeclosure,
                limit: itemsPerPage,
                offset: offset,
            };

            const { data } = await apiCall(
                "POST",
                apiEndPoints.GETPROPERTYLIST,
                params
            );
            if (data.status == 200) {

                setShowloader(false)
                setIsListLoading(false)

                var saleRowcount = 0;
                var soldRowcount = 0;
                var soldDatecount = 0;
                var AllTotalRow = 0;
                var AllTotalprice = 0;
                var AllHomePrice = 0;
                var Allrowcount = 0;
                var AllavrgDay = 0;
                //calculate Avg for sale property


                //Average Sell Price Per Sqft
                var TotalSaleRow = data?.data?.meta?.matching_rows;
                setshowTotalSaleRow(TotalSaleRow)
                var totalsqft = 0;
                var sellavgsqft = 0;
                // Average Price Per Sqft
                var totalprice = 0;
                var averageprice = 0;

                //Average Sell Home Price:
                var avrgsellhomeprice = 0;
                var avrgsellpricetotal = 0;
                //Average Sell Day:
                var avrselltotalgday = 0;
                var avrgsellday = 0;
                //Average Sell Day:
                var avrsoldtotalday = 0;
                var avrgsoldday = 0;


                if (data.data?.properties.length > 0) {
                    data.data?.properties.map((item) => {

                        if (item.building_size != null && item.building_size.size != 0) {

                            var sqftprice = item.price / item.building_size.size;
                            totalsqft = totalsqft + sqftprice;

                            if (item.building_size != null) {
                                /*  var sqftprPrice = item.price / item.building_size.size;
                                 totalprice = totalprice + sqftprPrice; */

                                avrgsellpricetotal = avrgsellpricetotal + item.price;
                                saleRowcount = saleRowcount + 1;
                            }
                            var today = new Date()
                            today = moment(today).format(
                                "MMMM D, YYYY")
                            var saledate = item.last_update;
                            saledate = moment(saledate).format(
                                "MMMM D, YYYY")
                            // To calculate the time difference of two dates
                            var msDiffdate = new Date().getTime() - new Date(saledate).getTime();
                            var dayinDiff = Math.floor(msDiffdate / (1000 * 60 * 60 * 24));
                            console.log("getSaleList -> dayinDiff", dayinDiff)
                            avrselltotalgday = avrselltotalgday + dayinDiff;

                        }


                    })
                    avrgsellday = avrselltotalgday / saleRowcount;
                    setShowavrgsellday(avrgsellday)


                    //sale sqft avg calculate
                    if (saleRowcount != 0) {

                        sellavgsqft = totalsqft / saleRowcount;
                        setShowsellavgsqft(sellavgsqft)

                        avrgsellhomeprice = avrgsellpricetotal / saleRowcount;
                        console.log("getSaleList -> avrgsellpricetotal", avrgsellpricetotal)
                        setShowavrgsellhomeprice(avrgsellhomeprice)
                        console.log("getSaleList -> saleRowcount", saleRowcount)

                    }

                }

                //end calculate Avg for sale property



                //calculate Avg for sold property


                //Average Sold Price Per Sqft
                var TotalsoldRow = data?.solddata?.meta?.matching_rows;
                var Sold = data?.solddata?.properties;
                var soldtotalsqft = 0;
                var soldavgsqft = 0;

                //Average Sold Home Price:
                var avrgsoldHomeprice = 0;
                var totlsoldprice = 0;


                if (data.solddata?.properties.length > 0) {

                    data.solddata?.properties.map((solditem) => {

                        if (solditem.building_size != null && solditem.building_size.size != 0) {


                            if (solditem.building_size != null && solditem.building_size.size != 0) {
                                var soldsqftprice = solditem.price / solditem.building_size.size;
                                soldtotalsqft = soldtotalsqft + soldsqftprice;
                                //Average Sold Home Price:
                                totlsoldprice = totlsoldprice + solditem.price;
                                soldRowcount = soldRowcount + 1;
                            }

                            var list_date = solditem.list_date;
                            var last_update = solditem.last_update;
                            if (list_date != null && last_update != null) {
                                soldDatecount = soldDatecount + 1;


                                list_date = moment(list_date).format(
                                    "MMMM D, YYYY")

                                last_update = moment(last_update).format(
                                    "MMMM D, YYYY")
                                var soledaytotal = new Date(last_update).getTime() - new Date(list_date).getTime();
                                var SolddayinDiff = Math.floor(soledaytotal / (1000 * 60 * 60 * 24));
                                // console.log("getSaleList -> SolddayinDiff", SolddayinDiff)
                                avrsoldtotalday = avrsoldtotalday + SolddayinDiff;

                            }
                        }
                    })
                    // sold day avg calculate

                    avrgsoldday = avrsoldtotalday / soldDatecount;
                    setShowavrgsoldday(avrgsoldday)
                    if (soldRowcount != 0) {
                        soldavgsqft = soldtotalsqft / soldRowcount;
                        setShowsoldavgsqft(soldavgsqft)
                        //Average Sold Home Price:
                        avrgsoldHomeprice = totlsoldprice / soldRowcount;
                        setShowavrgsoldhomeprice(avrgsoldHomeprice)
                    }


                }
                //end calculate Avg for sold property

                //calculate total avg 





                /* AllTotalRow = saleRowcount + soldRowcount;
                var totalAll = soldtotalsqft + totalsqft;
                AllTotalprice = totalAll / AllTotalRow; */

                // var totalAll = showsoldavgsqft + showsellavgsqft;
                var totalAll = soldavgsqft + sellavgsqft;
                AllTotalprice = totalAll / 2;
                setShowTotalavgsqft(AllTotalprice)

                //all home price


                /* var homeprice = avrgsellpricetotal + totlsoldprice;
                AllHomePrice = homeprice / AllTotalRow; */

                //var homeprice = showavrgsellhomeprice + showavrgsoldhomeprice;
                var homeprice = avrgsellhomeprice + avrgsoldHomeprice;
                AllHomePrice = homeprice / 2;

                setShowavrgTotalhomeprice(AllHomePrice)

                /* Allrowcount = TotalSaleRow + TotalsoldRow;
                var totalavrgdays = avrselltotalgday + avrsoldtotalday;
                AllavrgDay = totalavrgdays / Allrowcount; */



                // var totalavrgdays = showavrgsellday + showavrgsoldday;
                var totalavrgdays = avrgsellday + avrgsoldday;
                AllavrgDay = totalavrgdays / 2;

                setShowavrgTotalday(AllavrgDay)

                setPropertyListData(data.data?.properties || []);

                const total_data = data?.data?.meta?.matching_rows
                const parPage = (total_data / itemsPerPage);
                setPageCount(parPage)

            } else {
                setIsListLoading(false)
                setPropertyListData([]);
            }
        }
    }


    async function handleCitySearch(city) {

        setPropertySearch({
            ...propertySearch,
            city: city,
        })

        if (city != '' && city.length >= 3) {
            const params = { cityname: city };
            const { data } = await apiCall(
                "POST",
                apiEndPoints.GETCITIESLIST,
                params
            );
            if (data.status == 200) {
                setSearchCityList(data.data);
            } else {
                setSearchCityList([]);
            }
            setCityListShow(true);
        } else {
            setCityListShow(false);
            setSearchCityList([]);
            /* setServiceData({
              ...serviceData,
              city: '',
              cityname: '',
            }); */
        }
    }

    function handleSelectCity(cityname, state_id) {
        document.getElementById("citySearch").value = cityname + ', ' + state_id;
        setCityListShow(false);
        setSearchCityList([]);
    }




    return (

        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div class="card card-primary">
                        <div class="card-header" style={{ backgroundColor: '#867df1' }}>
                            <h5 class="card-title" style={{ color: '#ffff' }}>Parameters</h5>
                        </div>


                        <form>
                            <div class="card-body">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div class="form-group">
                                            <div ref={wrapperRef}>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="citySearch"
                                                    value={propertySearch?.city}
                                                    onChange={(e) => {
                                                        handleCitySearch(e.target.value);
                                                    }}
                                                    placeholder="City"
                                                    autoComplete="off"
                                                />
                                                <small className="text-danger">
                                                    {!propertySearch?.city &&
                                                        formError?.city
                                                        ? formError?.city
                                                        : ""}
                                                </small>
                                                <div>
                                                    <div
                                                        className={`city_list_box ${cityListShow ? "show" : "hide"
                                                            }`}
                                                    >
                                                        {searchCityList &&
                                                            searchCityList.length > 0 ? (
                                                            <ul className="city_list_menu">
                                                                {searchCityList.map((item, i) => {
                                                                    return (
                                                                        <li
                                                                            onClick={() => {
                                                                                /* setServiceData({
                                                                                  ...serviceData,
                                                                                  city: item.id,
                                                                                  cityname: item.city+', '+item.state_id,
                                                                                }); */

                                                                                setPropertySearch({
                                                                                    ...propertySearch,
                                                                                    city: item.city,
                                                                                    state_code: item.state_id,
                                                                                })

                                                                                setCityListShow(false);
                                                                                handleSelectCity(item.city, item.state_id);
                                                                            }}
                                                                            className={`city_list ${propertySearch?.city == item.id
                                                                                    ? "active"
                                                                                    : ""
                                                                                }`}
                                                                        >
                                                                            {item.city}, {item.state_id}
                                                                        </li>
                                                                    );
                                                                }, [])}
                                                            </ul>
                                                        ) : (
                                                            <p
                                                                style={{
                                                                    textAlign: "center",
                                                                    marginTop: "2px",
                                                                    color: "gray",
                                                                }}
                                                            ></p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="form-group">
                                            <input type="number" class="form-control"
                                                onChange={(e) =>
                                                    setPropertySearch({
                                                        ...propertySearch,
                                                        price_max: e.target.value,
                                                    })
                                                }
                                                value={propertySearch?.price_max}
                                                id="exampleInputPassword1" placeholder="Price Max" />
                                            <small className="text-danger">
                                                {!propertySearch?.price_max &&
                                                    formError?.price_max
                                                    ? formError?.price_max
                                                    : ""}
                                            </small>
                                        </div>

                                    </div>

                                    <div className="col-md-3">
                                        <div class="form-group">
                                            <input type="text" class="form-control" readOnly
                                                value={propertySearch?.state_code}
                                                placeholder="State" />
                                            <small className="text-danger">
                                                {!propertySearch?.state_code &&
                                                    formError?.state_code
                                                    ? formError?.state_code
                                                    : ""}
                                            </small>
                                        </div>
                                        <div class="form-group">
                                            <select
                                                name="propertytype"
                                                id="propertytype"
                                                placeholder="Property Type"
                                                className="form-control form-control-md form-control-city"
                                                value={propertySearch.prop_type}
                                                onChange={(e) =>
                                                    setPropertySearch({
                                                        ...propertySearch,
                                                        prop_type: e.target.value,
                                                    })
                                                }
                                            >
                                                <option value="">Select Property Type</option>
                                                <option value="single_family">Single Family</option>
                                                <option value="multi_family">Multi Family</option>
                                                <option value="condo">Condo</option>
                                                <option value="mobile">Mobile</option>
                                                <option value="land">Land</option>
                                                <option value="farm">Farm</option>
                                                <option value="other">Other</option>
                                            </select>
                                            <small className="text-danger">
                                                {!propertySearch?.prop_type &&
                                                    formError?.prop_type
                                                    ? formError?.prop_type
                                                    : ""}
                                            </small>
                                        </div>

                                    </div>

                                    <div className="col-md-2" style={{ marginLeft: '7%' }}>


                                        <div class="form-check">
                                            {propertySearch?.hidepending ?

                                                <input type="checkbox" class="form-check-input"
                                                    onClick={(e) =>
                                                        setPropertySearch({
                                                            ...propertySearch,
                                                            hidepending: false,
                                                        })
                                                    }
                                                    checked={true}
                                                    id="exampleCheck1" />
                                                :
                                                <input type="checkbox" class="form-check-input"
                                                    onClick={(e) =>
                                                        setPropertySearch({
                                                            ...propertySearch,
                                                            hidepending: true,
                                                        })
                                                    }
                                                    id="exampleCheck1" />
                                            }


                                            <label class="form-check-label" for="exampleCheck1">Hide Pending</label>
                                        </div>
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" id="exampleCheck1"
                                                onClick={(e) =>
                                                    setPropertySearch({
                                                        ...propertySearch,
                                                        hidecontingent: propertySearch?.hidecontingent ? false : true,
                                                    })
                                                }
                                                checked={propertySearch?.hidecontingent}
                                            />
                                            <label class="form-check-label" for="exampleCheck1">Hide Contingent</label>
                                        </div>



                                    </div>


                                    <div className="col-md-2" style={{ marginLeft: '7%' }}>



                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" id="exampleCheck1"
                                                onClick={(e) =>
                                                    setPropertySearch({
                                                        ...propertySearch,
                                                        hideforeclosure: propertySearch?.hideforeclosure ? false : true,
                                                    })
                                                }
                                                checked={propertySearch?.hideforeclosure}
                                            />
                                            <label class="form-check-label" for="exampleCheck1">Hide Foreclosure</label>
                                        </div>
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                                            <label class="form-check-label" for="exampleCheck1">Hide Membership</label>
                                        </div>


                                    </div>

                                </div>
                            </div>

                            <div class="card-footer">
                                <button type="button" onClick={() => filterProperty(true)} class="btn btn-primary">{isListLoading ? (
                                    <img
                                        src="images/loader_white.gif"
                                        style={{ width: "30px" }}
                                    />
                                ) : (
                                    "Search"
                                )}</button>

                                {/* <button style={{ marginLeft: 23 }} type="button" onClick={() => filterProperty(false)} class="btn btn-secondary">Reset Search</button> */}
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="row" style={{ marginTop: "3rem" }}>
                <div className="col-md-12">
                    <div class="card card-primary">
                        <div class="card-header" style={{ backgroundColor: '#867df1' }}>
                            <h5 class="card-title" style={{ color: '#ffff' }}>Results</h5>
                        </div>
                        <div class="card-body">

                            {isListLoading ? (
                                <div style={{ textAlign: 'center' }}>
                                    <img className="listloadergif-admin" src="images/infinity.gif" />

                                </div>
                            ) : propertyListData.length > 0 ? (

                                <>
                                    <div class="row invoice-info" style={{ marginBottom: "2rem" }}>
                                        <div class="col-sm-4 invoice-col">
                                            <b>Average Sold Price Per Sqft:</b>&nbsp;${Math.round(showsoldavgsqft).toLocaleString('en-US')}<br></br>
                                            <b>Average Sell Price Per Sqft:</b>&nbsp;${Math.round(showsellavgsqft).toLocaleString('en-US')}<br></br>
                                            <b>Average Price Per Sqft:</b>&nbsp;${Math.round(showTotalavgsqft).toLocaleString('en-US')}<br></br>
                                        </div>
                                        <div class="col-sm-4 invoice-col">
                                            <b>Average Sold Home Price:</b>&nbsp;${Math.round(showavrgsoldhomeprice).toLocaleString('en-US')}<br></br>
                                            <b>Average Sell Home Price:</b>&nbsp;${Math.round(showavrgsellhomeprice).toLocaleString('en-US')}<br></br>
                                            <b>Average Home Price:</b>&nbsp;${Math.round(showavrgTotalhomeprice).toLocaleString('en-US')}<br></br>
                                        </div>
                                        <div class="col-sm-4 invoice-col">
                                            <b>Average Sold Days:</b>&nbsp;{Math.round(showavrgsoldday)}<br></br>
                                            <b>Average Sell Days:</b>&nbsp;{Math.round(showavrgsellday)}<br></br>
                                            <b>Average Days:</b>&nbsp;{Math.round(showavrgTotalday)} <br></br>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="display--table">
                                                <table className="table table-border table-light">
                                                    <thead>
                                                        <tr className="tbl--row">
                                                            <th scope="col">#</th>
                                                            <th scope="col">MLS Id</th>
                                                            <th scope="col">Address</th>
                                                            <th scope="col">Price</th>
                                                            <th scope="col">Details</th>
                                                            <th scope="col">$ Sqft</th>
                                                            <th scope="col">Last Update</th>
                                                            {/* <th scope="col" className="tabl-action">Under</th> */}
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {propertyListData.map((item, i) => {
                                                            return (
                                                                <>


                                                                    <tr className={`tbl--row ${Math.round(item?.price / item?.building_size?.size) <= Math.round(showTotalavgsqft) ? "highlight"//we can use class when need to show heighlight highlight
                                                                        : ""} `} >

                                                                        <td>{i + 1 + itemOffset}</td>
                                                                        <td>{item?.property_id}</td>
                                                                        <td>{item?.address?.line} {item?.address?.city}, {item?.address?.state_code} {item?.address?.postal_code}</td>
                                                                        <td>${item?.price.toLocaleString('en-US')}</td>
                                                                        <td>{item?.beds} bed, {item?.baths} bath, {item?.building_size?.size} {item?.building_size?.units}</td>
                                                                        <td>
                                                                            ${item?.price && item?.building_size?.size ? Math.round(item?.price / item?.building_size?.size)
                                                                                : 0}
                                                                        </td>
                                                                        <td>{moment(item?.last_update).format(
                                                                            "MM-DD-YYYY")}</td>

                                                                        {/* <td className="tabl-action">
                                                        <div className="form-check">
                                                    {
                                                     item?.price && item?.building_size?.size ?
                                                     
                                                     Math.round(item?.price / item?.building_size?.size) <= Math.round(showTotalavgsqft) ?
                                                     <input type="checkbox" class="form-check-input" checked id="exampleCheck1" />
                                                     : 
                                                     <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                                                      :
                                                      <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                                                      }
                                                    
                                                    </div></td> */}
                                                                        <td> <a href="#" onClick={() => showDetail('PropertyDetail', item?.property_id)}  ><img className="tbl--actin-icon" src="images/icons/eye.png" />
                                                                        </a></td>
                                                                    </tr>



                                                                </>
                                                            );
                                                        })
                                                        }




                                                    </tbody>
                                                </table>
                                            </div>


                                            <div className="btm-pagination mt-4">
                                                <div className="d-flex justify-content-end">
                                                    <div className="pag-lable-show">Show Result {itemsPerPage} from {showTotalSaleRow}</div>
                                                    <ReactPaginate
                                                        // previousLabel={'previous'}
                                                        // nextLabel={'next'}
                                                        breakLabel={"..."}
                                                        breakClassName={"break-me"}
                                                        pageCount={pageCount}
                                                        marginPagesDisplayed={4}
                                                        pageRangeDisplayed={2}
                                                        onPageChange={handlePageClick}
                                                        containerClassName={
                                                            "pagination pagination-style-02 justify-content-center"
                                                        }
                                                        subContainerClassName={"pages pagination"}
                                                        activeClassName={"current active active_pg"}
                                                        activeLinkClassName={"current active active_pg"}
                                                        pageClassName={"page-item"}
                                                        pageLinkClassName={"page-link bg-gray"}
                                                        previousClassName={"page-item"}
                                                        nextClassName={"page-item"}
                                                        previousLinkClassName={"page-link bg-gray"}
                                                        nextLinkClassName={"page-link bg-gray"}
                                                        previousLabel={<i className="fal fa-chevron-left"></i>}
                                                        nextLabel={<i className="fal fa-chevron-right"></i>}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </>

                            ) : (
                                <div className="text-center">
                                    <img className="no-foundimage" src="images/nodatafound.png" />
                                </div>
                            )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default PropertyListing;
