import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Table from "common/table";
import ReactPaginate from "react-paginate";
import { apiCall } from "utils/httpClient";
import apiEndPoints from "utils/apiEndPoints";
import { successToast } from "utils/httpClient";
import { errorToast } from "utils/httpClient";
import moment from "moment";
import Swal from "sweetalert2";
import { size } from "lodash";

function PhotoList(props) {
  const {plantype}=props
  let history = useHistory();
  const [photo, setPhoto] = useState({
    photo: "",
  });
  const [editData, setEditData] = useState({
    title: "",
    filetype: "photo",
    createddate: moment(new Date()).format("MM-DD-YYYY"),
    fileid: "",
    islogo: false,
  });

  const [photoListData, setPhotolistData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [base_url, setBase_url] = useState();
  const [fileSizeError, setFileSizeError] = useState("");
  const [image_url, setImage_url] = useState("");
  const [bucket_Img_url, setBucket_Img_url] = useState();
  const [bucket_Img_url_Modal, setBucket_Img_url_Modal] = useState();
  const [title, setTitle] = useState("");
  const [isListLoading, setIsListLoading] = useState(false);
  // useEffect(() => {
  //   // Fetch items from another resources.
  //   const endOffset = itemOffset + itemsPerPage;
  //   console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  //   setCurrentItems(photoListData.slice(itemOffset, endOffset));
  //   setPageCount(Math.ceil(photoListData.length / itemsPerPage));
  // }, [itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    getPhotoList(newOffset);
    console.log("hanlepageclick", itemOffset);
  };

  useEffect(() => {
    getPhotoList(itemOffset);
    getValidationList();
  }, []);

  const [validationData, setValidationData] = useState([]);
  const [photoNumberValidation, setPhotoNumberValidation] = useState(0);
  const [photoNumberValidationFree, setPhotoNumberValidationFree] = useState(0);
  async function getValidationList() {
    const { data } = await apiCall("GET", apiEndPoints.GETVALIDATIONLIST);
    if (data.status == 200) {
      setValidationData(data.data);
      setPhotoNumberValidation(data.data.find((x) => x.lable == "addphoto"));
      setPhotoNumberValidationFree(data.data.find((x) => x.lable == "addphotofree"));
    } else {
      setValidationData([]);
    }
  }
 

  async function getPhotoList(offset) {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    };
    showLoader ? setIsListLoading(true) : console.log("");
    const params = {
      filetype: "photo",
      limit: itemsPerPage,
      offset: offset,
    };
    const { data } = await apiCall(
      "POST",
      apiEndPoints.GETVIDEODOCUMENTDATA,
      params
    );
    if (data.status == 200) {
      setShowLoader(false);
      setIsListLoading(false);
      setPhotolistData(data.data);
      setBase_url(data.base_url);
      const pageCount = data.total_data / itemsPerPage;
      setPageCount(pageCount);
    } else {
      setPhotolistData([]);
      setIsListLoading(false);
    }
  }
  const [formError, setFormError] = useState({});
  function formValidation() {
    let error = {};
    let formErrorData = false;

    if (!editData.title) {
      formErrorData = true;
      error["title"] = "Title is required";
    } else if (editData.title.length < 5) {
      formErrorData = true;
      error["title"] = "Title Length more then 5!";
    }
    if (!photo.photo) {
      formErrorData = true;
      error["photo"] = "Image is required";
    }
    setFormError(error);
    return formErrorData;
  }

  const checkcountvalidation = () => {
    if (plantype == 2 && photoListData.length < photoNumberValidation.number) {
      return true;
    } else if (plantype == 1 && photoListData.length < photoNumberValidationFree.number) {
      return true;
    } else {
      Swal.fire({
        title: "Oops...",
        text: `You have already uploaded ${plantype == 1 ? photoNumberValidationFree.number : photoNumberValidation.number} photos`,
        icon: "info",
        //showCancelButton: true,
        //confirmButtonColor: "#6258D3",
        cancelButtonColor: "#000",
        cancelButtonText: "OK",
        //confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {});
    }
  };

  async function handleAddPhoto() {
    if (!formValidation() && checkcountvalidation()) {
      const photData = new FormData();
      setIsLoader(true);
      photData.append("name", editData.title);
      photData.append("filetype", "photo");
      photData.append("photo", photo.photo);
      photData.append("createddate", moment(new Date()).format("MM-DD-YYYY"));
      photData.append("islogo", editData.islogo ? editData.islogo : false);
      const { data } = await apiCall(
        "POST",
        apiEndPoints.ADDVIDEOFILE,
        photData,
        {'Access-Control-Allow-Origin': '*' }
      );
      if (data.status == 200) {
        setIsLoader(false);
        successToast(data.message);
        getPhotoList(itemOffset);
        setImage_url(data.base_url);
        cleanSetEditData();
        setBucket_Img_url("");
        setPhoto({
          photo: "",
        });
      } else {
        cleanSetEditData();
        errorToast(data.message);
        setIsLoader(false);
      }
    }
      
  }

  function editFormValidation() {
    let error = {};
    let formErrorData = false;

    if (!editData.title) {
      formErrorData = true;
      error["title"] = "title is required";
    } else if (editData.title.length < 5) {
      formErrorData = true;
      error["title"] = "title Length more then 5!";
    }
    if (fileSizeError != "") {
      formErrorData = true;
    }
    setFormError(error);
    return formErrorData;
  }

  function handleSubmit() {
    if (editData?.fileid == "") {
      handleAddPhoto();
    } else {
      updateDocument();
    }
  }

  async function updateDocument() {
    if (!editFormValidation()) {
      setIsLoader(true);
      const photData = new FormData();
      photData.append("name", editData.title);
      photData.append("filetype", "photo");
      photData.append("photo", photo.photo);
      photData.append("createddate", moment(new Date()).format("MM-DD-YYYY"));
      photData.append("fileid", editData.fileid);
      photData.append("islogo", editData.islogo ? editData.islogo : false);

      const { data } = await apiCall(
        "POST",
        apiEndPoints.UPDATEVIDEOFILE,
        photData
      );
      if (data.status == 200) {
        //setPhotolistData(data.data);
        getPhotoList(itemOffset);
        successToast(data.message);
        setIsLoader(false);
        setImage_url(data.base_url);
        cleanSetEditData();
        setBucket_Img_url("");
      } else {
        errorToast(data.message);
        setIsLoader(false);
      }
    }
  }

  async function deletePhoto(fileid, filetype) {
    const params = {
      fileid: fileid,
      filetype: filetype,
    };

    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this photo ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#6258D3",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { data } = await apiCall(
          "POST",
          apiEndPoints.VIDEODOCUMENTDELETE,
          params
        );
        if (data.status == 200) {
          getPhotoList(itemOffset);
          successToast(data.message);
          cleanSetEditData();
          setBucket_Img_url("");
          setPhoto({
            photo: "",
          });
        } else {
          errorToast(data.message);
        }
      }
    });
  }

  function cleanSetEditData() {
    setEditData({
      title: "",
      filetype: "photo",
      createddate: moment(new Date()).format("MM-DD-YYYY"),
      fileid: "",
      islogo: false,
    });
    setBucket_Img_url("");
  }

  function handlePhotoFileSize(e) {
    console.log("🚀 ~ handlePhotoFileSize ~ e:", e.target.files[0])
    const validationStatus = validationData.find((x) => x.lable == "photosize");
    var _size = Math.floor(e.target.files[0].size / 1000000); //MB
    console.log("🚀 ~ handlePhotoFileSize ~ _size:", _size)
    console.log("🚀 ~ handlePhotoFileSize ~ validationStatus.number:", validationStatus.number)
    if (_size >= validationStatus.number) {
      setFileSizeError(
        `Please select Less than ${
          validationStatus.number + validationStatus.type
        } file`
      );
      setPhoto({
        ...photo,
        photo: "",
      });
    } else {
      setPhoto({
        ...photo,
        photo: e.target.files[0],
      });
      
      setBucket_Img_url(URL.createObjectURL(e.target.files[0]));
      setFileSizeError("");
    }
  }

  async function getImage(param) {
    setIsLoader(true);
    const params = {
      fileName: param,
    };
    const { data } = await apiCall("POST", apiEndPoints.GETIMAGE, params);
    if (data.status == 200) {
      setBucket_Img_url(data.url);
      setTimeout(() => {
        setIsLoader(false);
      }, 2000);
    } else {
      setIsLoader(false);
    }
  }

  async function getImageModal(param, name) {
    setTitle(name);
    const params = {
      fileName: param,
    };
    const { data } = await apiCall("POST", apiEndPoints.GETIMAGE, params);
    if (data.status == 200) {
      setBucket_Img_url_Modal(data.url);
    } else {
      console.log("error");
    }
  }

  return (
    <>
      {/* -------------------------------------------------- view ------------------------------------- */}
      <div
        className="modal fade"
        id="viewFile"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {title}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="progress-bar" />
            </div>
            <div className="modal-body">
              <img
                src={bucket_Img_url_Modal}
                //height="600px"
                className="photoviewdesh"
                width="100%"
                scrolling="auto"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          {
            //(photoListData.length < photoNumberValidation.number) ?
            <>
              <div className="form-group container__img">
                {isLoader ? (
                  <img
                    src="images/loader_dark.gif"
                    style={{
                      width: "30px",
                    }}
                  />
                ) : (
                  <div className="text-center">
                    <img src={bucket_Img_url} className="img__content" />
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="file-upload" className="custom-file-upload">
                  <i className="fa fa-cloud-upload"></i> Upload photo
                </label>
                <input
                  id="file-upload"
                  type="file"
                  onChange={(e) => handlePhotoFileSize(e)}
                  accept="image/x-png,image/gif,image/jpeg"
                  style={{ display: "none" }}
                />
                <small className="text-danger">
                  {fileSizeError != ""
                    ? fileSizeError
                    : !editData?.photo ||
                      (formError?.photo && !photo.photo.name)
                    ? formError?.photo
                    : ""}
                </small>
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  placeholder="Title photo"
                  value={editData.title}
                  maxLength={100}
                  onChange={(e) =>
                    setEditData({
                      ...editData,
                      title: e.target.value.trimStart(),
                    })
                  }
                />
                <small className="text-danger">
                  {!editData.title || formError.title ? formError.title : ""}
                </small>
              </div>
              <div className="form-group">
                <button
                  type="button"
                  className="btn main-btn w-100"
                  /* onClick={() => {
                    handleSubmit();
                  }} */
                  onClick={ () => {
                    isLoader ? console.log('save') : handleSubmit();
                  }}
                >
                  {/* {editData?.fileid ? "Update Photo" : "Save Photo"} */}

                  {isLoader ? (
                    <img
                      src="images/loader_white.gif"
                      style={{ width: "30px" }}
                    />
                  ) :  editData?.fileid ? "Update Photo" : "Save Photo"
                 }

                </button>
              </div>
            </>
            /*  :
          <div className="limit-msg">{`You have uploaded ${photoNumberValidation.number} photos`}</div> */
          }
        </div>
        <div className="col-md-6">
          <div className="card border-8">
            <label className="card-header border-t-8 bg--smb text-white p-1">
              My list photo
              <i
                onClick={() => {
                  cleanSetEditData();
                }}
                className="fa fa-plus add-new-item"
                style={{ marginLeft: "80%" }}
              ></i>
            </label>

            <div className="card-body text-center">
              <div className="row align-items-center">
                {isListLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <img className="listloadergif" src="images/infinity.gif" />
                  </div>
                ) : photoListData && Object.keys(photoListData).length > 0 ? (
                  <>
                    <table className="table table-sm table-responsive">
                      <thead>
                        <tr className="tbl--row">
                          <th scope="col" className="font-size-14" width="1%">
                            #
                          </th>
                          <th scope="col" className="font-size-14">
                            Name
                          </th>
                          <th scope="col" className="font-size-14">
                            URL
                          </th>
                          <th scope="col" className="font-size-14">
                            Date
                          </th>
                          <th scope="col" className="font-size-14">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {photoListData.map((item, i) => (
                          <tr className="tbl--row" key={i}>
                            <td className="font-size-14">{i + 1}</td>
                            <td className="font-size-14">{item.title}</td>
                            <td className="font-size-14">{item.filefile}</td>
                            <td className="font-size-14">
                              {moment(item.createddate._i).format("MM-DD-YYYY")}
                            </td>
                            <td
                              className="font-size-14"
                              style={{ display: "contents" }}
                            >
                              <a
                                onClick={() => {
                                  getImageModal(item.filefile, item.title);
                                }}
                                title="View"
                                className="cursor-pointer color__light mr-1"
                                data-toggle="modal"
                                data-target="#viewFile"
                              >
                                <i className="fa fa-play fa__icons"></i>
                              </a>
                              <a
                                onClick={() => {
                                  setEditData(item);
                                  getImage(item.filefile);
                                }}
                                title="Edit"
                                className="color__light mr-1"
                              >
                                <i className="fa fa-edit fa__icons"></i>
                              </a>
                              <a
                                onClick={() => {
                                  deletePhoto(item.fileid, item.filetype);
                                }}
                                title="Delete"
                                className="color__light"
                              >
                                <i className="fa fa-trash fa__icons"></i>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {photoListData.length > 10 ? (
                      <>
                        <div className="btm-pagination mt-4">
                          <div className="d-flex justify-content-end">
                            <ReactPaginate
                              // previousLabel={'previous'}
                              // nextLabel={'next'}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={pageCount}
                              marginPagesDisplayed={4}
                              pageRangeDisplayed={2}
                              onPageChange={handlePageClick}
                              containerClassName={
                                "pagination pagination-style-02 justify-content-center"
                              }
                              subContainerClassName={"pages pagination"}
                              activeClassName={"current active active_pg"}
                              activeLinkClassName={"current active active_pg"}
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link bg-gray"}
                              previousClassName={"page-item"}
                              nextClassName={"page-item"}
                              previousLinkClassName={"page-link bg-gray"}
                              nextLinkClassName={"page-link bg-gray"}
                              previousLabel={
                                <i className="fal fa-chevron-left"></i>
                              }
                              nextLabel={
                                <i className="fal fa-chevron-right"></i>
                              }
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                  </>
                ) : (
                  <div className="text-center">
                    <img
                      className="no-foundimage"
                      src="images/nodatafound.png"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PhotoList;
