import React, { useState, useEffect, useRef } from "react";
import { apiCall } from "utils/httpClient";
import apiEndPoints from "utils/apiEndPoints";
import { successToast } from "utils/httpClient";
import ReactPaginate from "react-paginate";
import { errorToast } from "utils/httpClient";
import moment from "moment";
import Swal from "sweetalert2";

function OfferList() {
  // const [offerfile, setOfferFile] = useState({
  //   offerfile: "",
  // });
  const [offerfile, setOfferFile] = useState();
  const [editData, setEditData] = useState({
    name: "",
    expirationdate: "",
    createddate: "",
    description: "",
    offerid: "",
  });

  const [oldData, setOldData] = useState({});
  const [offerListData, setOfferListData] = useState([]);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isLoader, setIsLoader] = useState(false);
  const [baseUrl, setBaseUrl] = useState();
  const todaydate = moment().format("YYYY-MM-DD");
  const [fileSizeError, setFileSizeError] = useState("");
  const [bucket_Img_url, setBucket_Img_url] = useState();
  const [isListLoading, setIsListLoading] = useState(false);
  const currentdate = moment().format("YYYY-MM-DD");
  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    getOfferList(newOffset);
  };

  useEffect(() => {
    getOfferList(itemOffset);
    getValidationList();
  }, []);

  const [validationData, setValidationData] = useState([]);
  const [offerNumberValidation, setOfferNumberValidation] = useState(0);
  async function getValidationList() {
    const { data } = await apiCall("GET", apiEndPoints.GETVALIDATIONLIST);
    if (data.status == 200) {
      setValidationData(data.data);
      setOfferNumberValidation(data.data.find((x) => x.lable == "addoffer"));
    } else {
      setValidationData([]);
    }
  }

  async function getOfferList(offset) {
    setIsListLoading(true);
    // const header = { "Authorization": `Bearer ${localStorage.getItem('authToken')}` }
    const params = {
      limit: itemsPerPage,
      offset: offset,
    };
    const { data } = await apiCall(
      "POST",
      apiEndPoints.GETBUSINESSOFFERlIST,
      params
    );
    if (data.status == 200) {
      setIsListLoading(false);
      setOfferListData(data.data);
      setBaseUrl(data.base_url);
      const pageCount = data.total_data / itemsPerPage;
      setPageCount(pageCount);
    } else {
      setOfferListData([]);
      setIsListLoading(false);
    }
  }

  const [formError, setFormError] = useState({});
  function formValidation() {
    let error = {};
    let formErrorData = false;

    if (!editData.name) {
      formErrorData = true;
      error["name"] = "Name is required";
    } else if (editData.name.length < 5) {
      formErrorData = true;
      error["name"] = "Name Length more then 5!";
    }
    if (!offerfile) {
      formErrorData = true;
      error["offerfile"] = "Image is required";
    }

    if (!editData.description) {
      formErrorData = true;
      error["description"] = "Description is required";
    }

    if (!editData.createddate) {
      formErrorData = true;
      error["createddate"] = "Effective date is required";
    } else if (editData.createddate < currentdate) {
      formErrorData = true;
      error["createddate"] = "Effective date should not be before today";
    }

    if (!editData.expirationdate) {
      formErrorData = true;
      error["expirationdate"] = "Expiration date is required";
    } else if (editData.expirationdate < currentdate) {
      formErrorData = true;
      error["expirationdate"] = "Expiration date should not be before today";
    }
    setFormError(error);
    return formErrorData;
  }

  const checkcountvalidation = () => {
    if (offerListData.length < offerNumberValidation.number) {
      return true;
    } else {
      Swal.fire({
        title: "Oops...",
        text: `You have already Added ${offerNumberValidation.number} Offers`,
        icon: "info",
        //showCancelButton: true,
        //confirmButtonColor: "#6258D3",
        cancelButtonColor: "#000",
        cancelButtonText: "OK",
        //confirmButtonText: "Yes, delete it!",
      });
    }
  };

  async function handleAddOffer(e) {
    const offerData = new FormData();
    if (!formValidation() && checkcountvalidation()) {
      setIsLoader(true);

      offerData.append("name", editData?.name);
      offerData.append("offerfile", offerfile);
      offerData.append("expirationdate", editData?.expirationdate);
      offerData.append("createddate", editData?.createddate);
      offerData.append("description", editData?.description);

      const { data } = await apiCall("POST", apiEndPoints.ADDOFFER, offerData);

      if (data.status == 200) {
        setIsLoader(false);
        successToast(data.message);
        getOfferList(itemOffset);
        cleanSetEditData();
        setBucket_Img_url("");
        setOfferFile();
      } else {
        errorToast(data.message);
        setIsLoader(false);
      }
    }
  }

  function editFormValidation() {
    let error = {};
    let formErrorData = false;

    if (!editData.name) {
      formErrorData = true;
      error["name"] = "Name is required";
    } else if (editData.name.length < 5) {
      formErrorData = true;
      error["name"] = "Name Length more then 5!";
    }
    if (!editData.description) {
      formErrorData = true;
      error["description"] = "Description is required";
    }
    
    if (!editData.createddate) {
      formErrorData = true;
      error["createddate"] = "Effective date is required";
    } else if (
      oldData.createddate != editData.createddate &&
      editData.createddate < currentdate
    ) {
      formErrorData = true;
      error["createddate"] = "Effective date should not be before today";
    }

    if (!editData.expirationdate) {
      formErrorData = true;
      error["expirationdate"] = "Expiration date is required";
    } else if (
      oldData.expirationdate != editData.expirationdate &&
      editData.expirationdate < currentdate
    ) {
      formErrorData = true;
      error["expirationdate"] = "Expiration date should not be before today";
    }
    if (fileSizeError != "") {
      formErrorData = true;
    }
    setFormError(error);
    return formErrorData;
  }

  function handleSubmit() {
    if (editData?.offerid == "") {
      handleAddOffer();
    } else {
      updateOffer();
    }
  }

  async function updateOffer() {
    if (!editFormValidation()) {
      setIsLoader(true);
      const updateData = new FormData();
      updateData.append("name", editData?.name);
      updateData.append("offerfile", offerfile);
      updateData.append(
        "expirationdate",
        moment(editData?.expirationdate).format("YYYY-MM-DD")
      );
      updateData.append(
        "createddate",
        moment(editData?.createddate).format("YYYY-MM-DD")
      );
      updateData.append("description", editData?.description);
      updateData.append("offerid", editData.offerid);

     

      const { data } = await apiCall(
        "POST",
        apiEndPoints.UPDATEOFFER,
        updateData
      );
      if (data.status == 200) {
        setIsLoader(false);
        successToast(data.message);
        getOfferList(itemOffset);
        cleanSetEditData();
        setBucket_Img_url("");
        setOfferFile();
      } else {
        errorToast(data.message);
        setIsLoader(false);
      }
    }
  }

  async function deleteOffer(offerid) {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    };
    const params = {
      offerid: offerid,
    };

    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this offer ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#6258D3",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { data } = await apiCall(
          "POST",
          apiEndPoints.OFFERDELETE,
          params,
          header
        );
        if (data.status == 200) {
          getOfferList(itemOffset);
          successToast(data.message);
          cleanSetEditData();
          setBucket_Img_url("");
        } else {
          errorToast(data.message);
          setIsLoader(false);
        }
      }
    });
  }

  function cleanSetEditData() {
    setEditData({
      name: "",
      expirationdate: "",
      createddate: "",
      description: "",
      offerid: "",
    });
    setBucket_Img_url("");
  }

  function handleDocumentFileSize(e) {
    const validationStatus = validationData.find(
      (x) => x.lable == "offerimagesize"
    );
    console.log("🚀 ~ handleDocumentFileSize ~ validationStatus:", validationStatus)

    var _size = Math.floor(e.target.files[0].size / 1000000); //MB

    if (_size >= validationStatus.number) {
      setFileSizeError(
        `Please select Less than ${
          validationStatus.number + validationStatus.type
        } file`
      );
      setOfferFile("");
    } else {
      setOfferFile(e.target.files[0]);
      setBucket_Img_url(URL.createObjectURL(e.target.files[0]));
      setFileSizeError("");
    }
  }

  async function getImage(param) {
    setIsLoader(true);
    const params = {
      fileName: param,
    };
    const { data } = await apiCall("POST", apiEndPoints.GETIMAGE, params);
    if (data.status == 200) {
      setBucket_Img_url(data.url);
      setTimeout(() => {
        setIsLoader(false);
      }, 500);
    } else {
      setIsLoader(false);
    }
  }

  return (
    <>
      {/* ----------------------------------------- add new ----------------------------------------- */}

      <div className="row">
        <div className="col-md-6">
          <div className="form-group container__img">
            {isLoader ? (
              <img
                src="images/loader_dark.gif"
                style={{
                  width: "30px",
                }}
              />
            ) : (
              <div className="text-center">
                <img src={bucket_Img_url} className="img__content" />
              </div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="file-upload" className="custom-file-upload">
              <i className="fa fa-cloud-upload"></i> Upload image
            </label>
            <input
              id="file-upload"
              type="file"
              onChange={(e) => handleDocumentFileSize(e)}
              accept="image/x-png,image/gif,image/jpeg"
              style={{ display: "none" }}
            />
            <small className="text-danger">
              {fileSizeError != ""
                ? fileSizeError
                : !offerfile && formError?.offerfile
                ? formError?.offerfile
                : ""}
            </small>
          </div>
          
          <div className="form-group">
            <label className="d-block mb-0">Offer Title</label>
            <input
              type="text"
              className="form-control form-control-md"
              placeholder="Offer Title"
              value={editData.name}
              maxLength={100}
              onChange={(e) =>
                setEditData({
                  ...editData,
                  name: e.target.value.trimStart(),
                })
              }
            />
            <small className="text-danger">
              {!editData.name || formError.name ? formError.name : ""}
            </small>
          </div>
           
          <div className="form-group">
            <label className="d-block mb-0">Offer Description</label>
            <textarea
                  className="form-control form-control-md"
                  placeholder="Description"
                  value={editData?.description}
                  onChange={(e) =>
                    setEditData({
                      ...editData,
                      description: e.target.value,
                    })
                  }
                />
                <small className="text-danger">
                  {!editData.description && formError.description
                    ? formError.description
                    : ""}
                </small>
          </div>

          <div className="form-group">
            <label className="d-block mb-0">Effective Date</label>
            <input
              type="date"
              className="form-control form-control-md"
              placeholder="Name"
              min={moment(new Date()).format("YYYY-MM-DD")}
              max={moment(editData.expirationdate).format("YYYY-MM-DD")}
              value={moment(editData.createddate)
                .format("YYYY-MM-DD")
                .toString()}
              onChange={(e) =>
                setEditData({
                  ...editData,
                  createddate: e.target.value,
                })
              }
            />
            <small className="text-danger">
              {!editData.createddate || formError.createddate
                ? formError.createddate
                : ""}
            </small>
          </div>
          <div className="form-group">
            <label className="d-block mb-0">Expiration Date</label>
            <input
              type="date"
              className="form-control form-control-md"
              placeholder="Name"
              // min={moment(new Date()).format("YYYY-MM-DD")}
              min={moment(editData.createddate).format("YYYY-MM-DD")}
              value={moment(editData.expirationdate)
                .format("YYYY-MM-DD")
                .toString()}
              onChange={(e) =>
                setEditData({
                  ...editData,
                  expirationdate: e.target.value,
                })
              }
            />
            <small className="text-danger">
              {!editData.expirationdate || formError.expirationdate
                ? formError.expirationdate
                : ""}
            </small>
          </div>
          <div className="form-group date text-right">
            <button
              type="button"
              className="btn main-btn w-100"
             /*  onClick={() => {
                handleSubmit();
              }} */
              onClick={() => {
                isLoader ? console.log('save') : handleSubmit();
              }}
            >

              {isLoader ? (
                    <img
                      src="images/loader_white.gif"
                      style={{ width: "30px" }}
                    />
                  ) : ( editData?.offerid ? "Update Offer" : "Save Offer" )
                
                }
            </button>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card border-8">
            <label className="card-header border-t-8 bg--smb text-white p-1">
              My list offer
              <i
                onClick={() => {
                  cleanSetEditData();
                }}
                className="fa fa-plus add-new-item"
                style={{ marginLeft: "80%" }}
              ></i>
            </label>
            <div className="card-body text-center">
              <div className="row align-items-center">
                {isListLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <img className="listloadergif" src="images/infinity.gif" />
                  </div>
                ) : offerListData && offerListData.length > 0 ? (
                  <>
                    <table className="table table-sm table-responsive">
                      <thead>
                        <tr className="tbl--row">
                          <th scope="col" className="font-size-14" width="1%">
                            #
                          </th>
                          <th scope="col" className="font-size-14" width="50%">
                            Name
                          </th>
                          {/* <th scope="col" className="font-size-14">
                            URL
                          </th> */}
                          <th scope="col" className="font-size-14">
                            Eff. Date
                          </th>
                          <th scope="col" className="font-size-14">
                            Exp. Date
                          </th>
                          <th scope="col" className="font-size-14">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {offerListData.map((item, i) => (
                          <tr key={i} className="tbl--row">
                            <td className="font-size-14">{i + 1}</td>
                            <td className="font-size-14">{item.title}</td>
                            {/* <td className="font-size-14">{item.offerfile}</td> */}
                            <td className="font-size-14">
                              {moment(item.createddate).format("MM-DD-YYYY")}
                            </td>
                            <td className="font-size-14">
                              {moment(item.expirationdate).format("MM-DD-YYYY")}
                            </td>
                            <td
                              className="font-size-14"
                              style={{ display: "contents" }}
                            >
                              {/* <a
                                onClick={() => {
                                  setEditData(item);
                                  getImage(item.offerfile);
                                }}
                                title="View"
                                className="cursor-pointer color__light mr-1"
                                data-toggle="modal"
                                data-target="#viewOffer"
                              >
                                <i className="fa fa-play fa__icons"></i>
                              </a> */}
                              <a
                                onClick={(e) => {
                                  setOldData(item);
                                  setEditData(item);
                                  getImage(item.offerfile);
                                }}
                                title="Edit"
                                className="color__light mr-1"
                              >
                                <i className="fa fa-edit fa__icons"></i>
                              </a>
                              <a
                                onClick={(e) => {
                                  deleteOffer(item.offerid);
                                }}
                                title="Delete"
                                className="color__light"
                              >
                                <i className="fa fa-trash fa__icons"></i>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {offerListData.length > 10 ? (
                      <>
                        <div className="btm-pagination mt-4">
                          <div className="d-flex justify-content-end">
                            <ReactPaginate
                              // previousLabel={'previous'}
                              // nextLabel={'next'}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={pageCount}
                              marginPagesDisplayed={4}
                              pageRangeDisplayed={2}
                              onPageChange={handlePageClick}
                              containerClassName={
                                "pagination pagination-style-02 justify-content-center"
                              }
                              subContainerClassName={"pages pagination"}
                              activeClassName={"current active active_pg"}
                              activeLinkClassName={"current active active_pg"}
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link bg-gray"}
                              previousClassName={"page-item"}
                              nextClassName={"page-item"}
                              previousLinkClassName={"page-link bg-gray"}
                              nextLinkClassName={"page-link bg-gray"}
                              previousLabel={
                                <i className="fal fa-chevron-left"></i>
                              }
                              nextLabel={
                                <i className="fal fa-chevron-right"></i>
                              }
                            />
                            {/* <ul className="pagin--menu">
              <li className="pagin-link">
                <a className="pre-nxt-btn">Previous</a>
              </li>
              <li class  Name="pagin-link">
                <a className="number-active">01</a>
              </li>
              <li className="pagin-link">
                <a className="number">02</a>
              </li>
              <li className="pagin-link">
                <a className="number">03</a>
              </li>
              <li className="pagin-link">
                <a>Next</a>
              </li>
            </ul> */}
                          </div>
                        </div>
                      </>
                    ) : null}
                  </>
                ) : (
                  <div className="text-center" style={{"backgroundColor" :"white","height":"450px"}}>
                  <img src="images/nodatafound.png" />
                  <span className="not-found-content">Ooops, no offer found</span>
                </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OfferList;
