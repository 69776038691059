import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from "moment";
import base64 from "base-64";
import BusinessList from "./businesslist"
import UserList from "./userlist"
import InvesmentRequest from "./invesmentrequest"

export default function Admindashboard(props) {
  // const {handlePageClick, itemOffset, itemsPerPage, pageCount}= props;
  let history = useHistory();
  const [pageShow, setPageShow] = useState('businesslist');
  const [searchkey, setSearchkey] = useState('');

  const alignth = {
    color: "white",
    backgroundColor: "#877cf2",
    padding: "10px",
    fontFamily: "Arial",
    textAlign: "left",
  };
  const aligntd = {
    textAlign: "left",
  };

  return (
    <>
      <div style={{ marginBottom: "2rem" }}></div>
      <div className="container">

        <div className="srvc-dt-menu">
          <div class="row">
            <div class="col-md-8" style={{"marginBottom": "15px"}}>
              <ul className="srvc--menu " style={{ marginTop: '17px' }}>
                <li
                  style={{ 'marginLeft': '0' }}
                  className={`srvc--gallary-menu ${(pageShow === `businesslist`) &&
                    "active-link"
                    }`}
                >
                  <span onClick={() => setPageShow('businesslist')}>Business List</span>
                </li>

                <li
                  className={`srvc--gallary-menu ${pageShow === `userlist` && "active-link"
                    }`}
                ><span onClick={() => setPageShow('userlist')}>Users List</span>
                </li>
                <li
                  className={`srvc--gallary-menu ${pageShow === `invesmentrequest` && "active-link"
                    }`}
                ><span onClick={() => setPageShow('invesmentrequest')}>Invesment Requests</span>
                </li>

              </ul>
            </div>
            {
              pageShow === "businesslist" ?

              <div class="col-md-4">
              <div className="form-group date">
                <label className="d-block mb-0">
                  Search:
                </label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  placeholder={pageShow === 'userlist'? "Search by user name" :"Search by business name / email"}
                  value={searchkey}
                  onChange={(e) =>
                    setSearchkey(e.target.value)
                  }

                />
              </div>
            </div>
              
              :null
            }
            
          </div>




        </div>

        {
          pageShow === 'invesmentrequest' ?
            <InvesmentRequest /> :
            pageShow === 'userlist' ?
              <UserList searchkey={searchkey} /> :
              <BusinessList  searchkey={searchkey}/>
        }


      </div>
    </>
  );
}
