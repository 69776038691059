// import OtpInput from 'react-otp-input';
import React, { useState, useEffect, useRef } from "react";
import OtpInput from "react18-input-otp";
import { apiCall, successToast, errorToast } from "utils/httpClient";
import ApiEndPoint from "utils/apiEndPoints";
import { useSelector, useDispatch } from "react-redux";
import { setBusinessRegisterDetail } from "../../../../reduxStore/action/usersActions";
import Select from "react-select";
import Loader from "common/loader";
import Multiselect from "multiselect-react-dropdown";
import { AutocompletePlace } from "common/autoCompleteAddress";
import { Link } from "react-router-dom";

function ValidatePopup(props) {
  const businessData = useSelector((store) => store.businessData);
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);
  const { otpShow, formVaildateStatus } = props;
  const countrycodeOptions = [
    { value: "+91", label: "+91" },
    { value: "+1", label: "+1" },
  ];
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [mobileOtp, setMobileOtp] = useState(null);
  const [otpmail, setOtpmail] = useState(null);
  const [resntMobLoader, setResntMobLoader] = useState(false);
  const [mobileForgotError, setMobileForgotError] = useState({});
  const [resntEmailLoader, setResntEmailLoader] = useState(false);
  const [otpForgotError, setOtpForgotError] = useState({});
  const [otpError, setOtpError] = useState({});

  // useEffect(()=>{
  //   setTimeout(() => {
  //     setOtpError({})
  //     setMobileForgotError({})
  //     setOtpForgotError({})
  //   }, 100000);

  // },[otpError, otpForgotError, mobileForgotError])

  // ----------------------- otp ------------------------

  const nullerrormsg = () => {
    setOtpError({});
    setMobileForgotError({});
    setOtpForgotError({});
  };

  function formValidation() {
    let error = {};
    let formErrorData = false;

    // var fff = "'"+otpmail+"'";
    // if (!otpmail || fff.length != 4) {
    if (!otpmail) {
      formErrorData = true;
      error["otpmail"] = "Email OTP is required";
    }
    // var fffmm = "'"+mobileOtp+"'";
    // if (!mobileOtp || fffmm.length!=4) {
    // if (!mobileOtp) {
    //   formErrorData = true;
    //   error["mobileOtp"] = "Mobile OTP is required";
    // }
    setOtpError(error);
    return formErrorData;
  }
  async function handleOtpVerify() {
    // props.otpVarify();

    nullerrormsg();
    if (!formValidation()) {
      setIsLoader(true);
      const header = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const params = {
        email: props.formVaildate.email,
        //mobile: props.formVaildate.mobile,
        email_otp: otpmail,
        //mobile_otp: mobileOtp,
      };
      const { data } = await apiCall(
        "POST",
        ApiEndPoint.VERIFYEMAILMOBILE,
        params,
        header
      );

      if (data.status == 200) {
        props.otpVarify();
        setIsLoader(false);
        dispatch(setBusinessRegisterDetail(data.data));
        successToast(data.messageTost);
      } else {
        setIsLoader(false);
        setOtpError(data.message);
        errorToast(data.message.messageTost);
      }
    }
  }

  async function resendmobileotp() {
    nullerrormsg();
    setResntMobLoader(true);
    const header = { Authorization: `Bearer ${localStorage.getItem("token")}` };
    const params = {
      mobile: props.formVaildate.mobile,
      countrycode: props.formVaildate.countrycode,
    };
    const { data } = await apiCall(
      "POST",
      ApiEndPoint.RESENDMOBILEOTP,
      params,
      header
    );
    if (data.status == 200) {
      successToast(data.message?.messageTost);
      setResntMobLoader(false);
      setMobileForgotError(data.message);
    } else {
      setMobileForgotError(data.message);
      setResntMobLoader(false);
      errorToast(data.message?.messageTost);
    }
  }

  async function resendEmailOtp() {
    nullerrormsg();
    setResntEmailLoader(true);
    const header = { Authorization: `Bearer ${localStorage.getItem("token")}` };
    const params = { email: props.formVaildate.email };
    const { data } = await apiCall(
      "POST",
      ApiEndPoint.RESENTOTP,
      params,
      header
    );
    if (data.status == 200) {
      successToast(data.message?.messageTost);
      setOtpForgotError(data.message);
      setResntEmailLoader(false);
    } else {
      setResntEmailLoader(false);
      setOtpForgotError(data.message);
      errorToast(data.message?.messageTost);
    }
  }

  // -----------------------------------------------

  return (
    <>
      <div
        className="modal fade vaildateId--modal"
        id="vaildateId"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Validate Your Identity
              </h5>
              <button
                id="vaildateIdentityModel"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div
              className="modal-content-doticon text-center mt-4 mb-3"
              style={{ margin: "10px" }}
            >
              {!otpShow ? (
                <img
                  src="images/gray-circle.png"
                  className="modal-content-doticon-img"
                  style={{ width: "30px", marginRight: "40%" }}
                />
              ) : (
                <img
                  src="images/blue-circle.png"
                  className="modal-content-doticon-img"
                  style={{ width: "30px", marginRight: "40%" }}
                />
              )}
              <img
                src="images/gray-circle.png"
                className="modal-content-doticon-img"
                style={{ width: "30px", marginRight: "40%" }}
              />
              <img src="images/gray-circle.png" style={{ width: "30px" }} />
            </div>

            {!otpShow ? (
              <>
                <div className="modal-body">
                  <form>
                    {/* <div className="form-group mb-4">
                      <label className="d-block mb-0" />
                      <div className="inputsel">
                        <input
                          autoComplete="off"
                          id="myTextBox"
                          type="text"
                          className="form-control form-control-md "
                          placeholder="Your Mobile Number"
                          onChange={(e) => {
                            props.setFormVaildate({
                              ...props.formVaildate,
                              mobile: e.target.value,
                            });
                            props.setFormError({
                              ...props.formError,
                              mobile: "",
                            });
                          }}
                          disabled={otpShow ? true : false}
                          max="10"
                          onKeyPress={(event) => {
                            if (
                              !/[0-99]/.test(event.key) ||
                              event.target.value.length > 9
                            ) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </div>
                      <small className="text-danger">
                        {props.formError.mobile ? props.formError.mobile : ""}
                      </small>
                    </div> */}
                    <div className="form-group date">
                      <label className="d-block mb-0" />
                      <input
                        type="text"
                        className="form-control form-control-md"
                        placeholder="Your Email Address"
                        value={props.formVaildate.email}
                        maxLength={50}
                        onChange={(e) => {
                          props.setFormVaildate({
                            ...props.formVaildate,
                            email: e.target.value.trimStart(),
                          });
                          props.setFormError({
                            ...props.formError,
                            email: "",
                          });
                        }}
                        disabled={otpShow ? true : false}
                      />
                      <small className="text-danger">
                        {props.formError.email ? props.formError.email : ""}
                      </small>
                    </div>
                  </form>
                </div>

                <div
                  className="text-center"
                  style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                >
                  <p className="text-success">
                    {props.formError?.messageSuccess
                      ? props?.formError.messageSuccess
                      : ""}
                  </p>
                  <p className="text-danger">
                    {otpError.messageError ? otpError.messageError : ""}
                  </p>
                </div>

                <div className="col-lg-12" style={{ marginTop: "-40px" }}>
                  <div className="form-group text-center">
                    {!otpShow ? (
                      <a
                        type="button"
                        className="btn main-btn mt-4 w-100"
                        onClick={() => {
                          props.handleVaildateIdentity();
                        }}
                      >
                        {props.isLoader ? (
                          <img
                            src="images/loader_white.gif"
                            style={{ width: "30px" }}
                          />
                        ) : (
                          "Submit"
                        )}
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </>
            ) : null}

            {!formVaildateStatus ? (
              <div
                className={`otp_area ${!otpShow ? "displayNone" : ""}`}
                id="otpForm"
              >
                <div className="modal-body">
                  {otpShow ? (
                    <div>
                      <div>
                        {" "}
                        <small className="text-danger">
                          {mobileForgotError.messageError
                            ? mobileForgotError.messageError
                            : ""}
                        </small>{" "}
                        <small className="text-success">
                          {mobileForgotError.messageSuccess
                            ? mobileForgotError.messageSuccess
                            : ""}
                        </small>
                      </div>
                      {/* <div>
                        Enter Your Mobile OTP {""}
                        <span
                          onClick={() => resendmobileotp()}
                          className="text-default"
                          style={{ cursor: "pointer" }}
                        >
                          {resntMobLoader ? (
                            <img
                              src="images/loader_dark.gif"
                              style={{ width: "25px" }}
                            />
                          ) : (
                            "(Resend Mobile One Time Password)"
                          )}{" "}
                        </span>
                      </div>
                      <div className="otp-container  mt-2">
                        <OtpInput
                          value={mobileOtp}
                          onChange={(e) => {
                            setMobileOtp(e);
                            setMobileForgotError({});
                            setOtpError({});
                          }}
                          numInputs={4}
                          separator={<span>&#160;</span>}
                        />
                        <small className="text-danger">
                          {otpError.mobileOtp ? otpError.mobileOtp : ""}
                        </small>
                      </div>
                      <br /> */}
                      <div>
                        {" "}
                        <small className="text-danger">
                          {otpForgotError.messageError
                            ? otpForgotError.messageError
                            : ""}
                        </small>{" "}
                        <small className="text-success">
                          {otpForgotError.messageSuccess
                            ? otpForgotError.messageSuccess
                            : ""}
                        </small>
                      </div>
                      <div>
                        Enter Your Email OTP {""}
                        <span
                          onClick={() => resendEmailOtp()}
                          className="text-default"
                          style={{ cursor: "pointer" }}
                        >
                          {resntEmailLoader ? (
                            <img
                              src="images/loader_dark.gif"
                              style={{ width: "25px" }}
                            />
                          ) : (
                            "(Resend Email One Time Password)"
                          )}
                        </span>
                      </div>
                      <div className="otp-container mt-2">
                        <OtpInput
                          value={otpmail}
                          onChange={(e) => {
                            setOtpmail(e);
                            setOtpForgotError({});
                            setOtpError({});
                          }}
                          numInputs={4}
                          separator={<span>&#160;</span>}
                        />
                        <small className="text-danger">
                          {otpError.otpmail ? otpError.otpmail : ""}
                        </small><br />
                        <small className="check-email-inbox">
                          <span>We've sent you a 4 digit code to your Inbox, please enter it to validate your email. Check also your Junk, Spam or Trash folder.</span>
                        </small>
                      </div>
                      
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}

            <div
              className={`modal-footer ${!otpShow ? "displayNone" : ""}`}
              style={{ borderTop: "0px solid #dee2e6" }}
            >
              {otpShow ? (
                <a
                  type="button"
                  className="btn main-btn mt-2 w-100"
                  onClick={() => handleOtpVerify()}
                >
                  {isLoader ? (
                    <img
                      src="images/loader_white.gif"
                      style={{ width: "30px" }}
                    />
                  ) : (
                    "Verify"
                  )}
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function RegisterPopup(props) {
  const wrapperRefCity = useRef(null);
  const wrapperRef = useRef(null);
  const [webUrlMsg, setWebUrlMsg] = useState("");
  const [serviceList, setServiceList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [serviceSocList, setServiceSocList] = useState([]);
  const [searchCityList, setSearchCityList] = useState([]);
  const [cityListShow, setCityListShow] = useState(false);
  const { setFormsubmit } = props;

  const [usernamemessage, setUsernamemessage] = useState({ error: true });
  const [certificateName, setCertificateName] = useState("");

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRefCity.current &&
        !wrapperRefCity.current.contains(event.target)
      ) {
        setCityListShow(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
  }, [wrapperRefCity]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        //alert("You clicked outside of me!");
        setIndustryListShow(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
  }, [wrapperRef]);

  const serviceOptions =
    serviceList &&
    serviceList.length > 0 &&
    serviceList.map((item, i) => {
      return { value: item.naicsid, label: item.title };
    });

  const optionsService =
    serviceList && serviceList.length > 0
      ? serviceList.map((item, i) => {
        return { cat: item.naicsid, key: item.title };
      })
      : [];
  const serviceSocOptions =
    serviceSocList &&
    serviceSocList?.length > 0 &&
    serviceSocList.map((item, i) => {
      return { value: item.socid, label: item.title };
    });
  // console.log('citiesList: dddd ', citiesList);

  // const cityOptions = citiesList && citiesList?.length > 0 && citiesList.map((item, i) => {
  //   return { value: item.id, label: item.city }
  // })

  function isUrlCheck(item) {
    let url;
    try {
      if (item != "") {
        url = new URL(item);
        props.setBusinessRegiData({
          ...props.businessRegiData,
          website: item,
        });
        setWebUrlMsg("");
      } else {
        props.setBusinessRegiData({
          ...props.businessRegiData,
          website: "",
        });
      }
    } catch (_) {
      setWebUrlMsg("Enter Proper Web URL");
    }
    // var hhh = url.protocol === "http:" || url.protocol === "https:";
  }

  useEffect(() => {
    // getServiceList();
    // getCityList()
    // getServiceSocList();
    // getIndustryListData()
  }, []);

  const [industryList, setIndustryList] = useState([]);
  // const industryOptions =
  //   industryList &&
  //   industryList.length > 0 &&
  //   industryList.map((item, i) => {
  //     return { value: item.naicsid, label: item.title };
  //   });
  // async function getIndustryListData() {
  //   const { data } = await apiCall(
  //     "GET",
  //     ApiEndPoint.GETINDUSTRYLIST
  //   );
  //   // console.log('data: GETSERVICELIST ==>', data);
  //   if (data.status == 200) {
  //     setIndustryList(data.data);
  //   } else {
  //     setIndustryList([]);
  //   }
  // }

  const searchServiceListData = async (item) => {
    if (item.length >= 3) {
      const params = { servicename: item };
      const { data } = await apiCall(
        "POST",
        ApiEndPoint.GETSERVICELIST,
        params
      );
      if (data.status == 200) {
        setServiceList(data.data);
      } else {
        setServiceList([]);
      }
    } else {
      setServiceList([]);
    }
  };

  // async function getServiceList() {
  //   const header = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  //   const { data } = await apiCall(
  //     "GET",
  //     ApiEndPoint.GETSERVICELIST,
  //     {},
  //     header
  //   );
  //   // console.log('data: GETSERVICELIST ==>', data);
  //   if (data.status == 200) {
  //     setServiceList(data.data);
  //   }
  // }
  // async function getCityList() {
  //   const header = { "Authorization": `Bearer ${localStorage.getItem('token')}` }
  //   const { data } = await apiCall('GET', ApiEndPoint.GETCITIESLIST, {}, header);
  //   // console.log('data: GETCITIESLIST ==>', data);
  //   if (data.status == 200) {
  //     // setCitiesList(data.data)
  //     const arr = [];
  //     data.data.map((item, i) => {
  //       if (i < 300) {
  //         arr.push(item)
  //       }
  //     })
  //     setCitiesList(arr)
  //   }
  // }
  async function getServiceSocList() {
    const header = { Authorization: `Bearer ${localStorage.getItem("token")}` };
    const { data } = await apiCall(
      "GET",
      ApiEndPoint.GETSERVICESOCLIST,
      {},
      header
    );
    // console.log('data: GETSERVICESOCLIST ==>', data);
    if (data.status == 200) {
      setServiceSocList(data.data);
    } else {
      setServiceSocList([]);
    }
  }

  async function handleCitySearch(city) {
    if (city != "" && city.length >= 3) {
      const params = { cityname: city };
      const { data } = await apiCall("POST", ApiEndPoint.GETCITIESLIST, params);
      if (data.status == 200) {
        setSearchCityList(data.data);
      } else {
        setSearchCityList([]);
      }
      setCityListShow(true);
    } else {
      setSearchCityList([]);
      setCityListShow(false);
    }
  }

  function handleSelectCity(cityname, state_id) {
    document.getElementById("citySearch").value = cityname + ", " + state_id;
    setCityListShow(false);
    setSearchCityList([]);
  }
  function manageCitySearch(item) {
    if (item == " ") {
      setCityListShow(false);
      setSearchCityList([]);
      props.setBusinessRegiData({
        ...props.businessRegiData,
        cityid: "",
        cityname: "",
      });
    }
    // console.log("item =veer city===>", item);
  }

  const [industryListShow, setIndustryListShow] = useState(false);
  // const [industryList, setIndustryList] = useState([])
  async function handleIndustryServiceSearch(service) {
    if (service != "" && service.length >= 3) {
      const params = { servicename: service };
      const { data } = await apiCall(
        "POST",
        ApiEndPoint.GETINDUSTRYLIST,
        params
      );
      if (data.status == 200) {
        setIndustryList(data.data);
        setIndustryListShow(true);
      } else {
        // errorToast(data.message);
        setIndustryList([]);
        setIndustryListShow(false);
      }
    } else {
      props.setBusinessRegiData({
        ...props.businessRegiData,
        industry: "",
      });
      setServiceList([]);
      setIndustryListShow(false);
    }
  }
  function handleSelectIndustry(item) {
    document.getElementById("industryId").value = item;
    setIndustryListShow(false);
  }

  function onlyLettersAndNumbers(str) {
    return /^[A-Za-z0-9]*$/.test(str);
  }
  const onSelect = (latLng, area) => {
    const formDataState = {
      ...props.businessRegiData,
      address: area,
      latitude: latLng.lat,
      longitude: latLng.lng,
    };
    props.setBusinessRegiData(formDataState);
  };

  const handleInputArea = (e) => {
    const formDataState = {
      ...props.businessRegiData,
      address: e,
      latitude: "",
      longitude: "",
    };
    props.setBusinessRegiData(formDataState);
  };

  const checkusername = async (username) => {
    const error = {};

    props.setBusinessRegiData({
      ...props.businessRegiData,
      business_username: username,
    });

    if (username.length >= 6) {
      if (onlyLettersAndNumbers(username)) {
        const params = { username: username };
        const { data } = await apiCall(
          "POST",
          ApiEndPoint.CHECKUSERNAME,
          params
        );
        if (data.status == 200) {
          error["message"] = data?.message?.message;
          error["error"] = false;
          setFormsubmit(false);
        } else {
          error["message"] = data?.message?.message;
          error["error"] = true;
          setFormsubmit(true);
        }
      } else {
        error["message"] =
          "Invalid username format. Please ensure that your username consists only of letters and numbers.";
        error["error"] = true;
        setFormsubmit(true);
      }
      setUsernamemessage(error);
    } else {
      error["message"] =
        "Invalid username format. Please ensure that your username contains at least 6 characters and consists only of letters and numbers.";
      setFormsubmit(true);
      error["error"] = true;
      setUsernamemessage(error);
    }
  };

  return (
    <>
      <div
        // id="businessRegiModel"
        className="modal fade businessRegister--modal"
        id="businessRegister"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Register Your Business
              </h5>
              <button
                id="businessRegiMdlClose"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="progress-bar" />
            </div>

            <div
              className="modal-content-doticon text-center mt-4 mb-3"
              style={{ margin: "10px" }}
            >
              <img
                src="images/blue-circle.png"
                className="modal-content-doticon-img"
                style={{ width: "30px", marginRight: "40%" }}
              />

              <img
                src="images/blue-circle.png"
                className="modal-content-doticon-img"
                style={{ width: "30px", marginRight: "40%" }}
              />
              <img src="images/gray-circle.png" style={{ width: "30px" }} />
            </div>

            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      {/* <label className="d-block mb-0">
                          Business Username{" "}
                          <span style={{ color: "red" }}>*</span>(Username must
                          contains at least 6 characters and consists only of
                          letters and numbers)
                        </label> */}
                      <input
                        type="text"
                        className="form-control form-control-md"
                        placeholder="Business User Name"
                        minLength="6"
                        maxLength="50"
                        value={props.businessRegiData.business_username}
                        onChange={
                          /* (e) =>
                            props.setBusinessRegiData({
                              ...props.businessRegiData,
                              business_username: e.target.value,
                            }), */
                          (e) => checkusername(e.target.value.trimStart())
                        }
                      />
                      <small
                        className={
                          !props.businessRegiData?.business_username &&
                            props.formError?.business_username
                            ? "text-danger"
                            : usernamemessage?.error
                              ? "text-danger"
                              : "text-success"
                        }
                      >
                        {!props.businessRegiData?.business_username &&
                          props.formError?.business_username
                          ? props.formError?.business_username
                          : usernamemessage?.message
                            ? usernamemessage?.message
                            : ""}
                      </small>
                    </div>

                    <div className="form-group">
                      {/* <label className="d-block mb-0">
                          Business Name <span style={{ color: "red" }}>*</span>
                        </label> */}
                      <input
                        type="text"
                        className="form-control form-control-md"
                        placeholder="Business Name"
                        disabled={usernamemessage?.error ? true : false}
                        maxLength={80}
                        onChange={(e) =>
                          props.setBusinessRegiData({
                            ...props.businessRegiData,
                            business_name: e.target.value.trimStart(),
                          })
                        }
                      />
                      <small className="text-danger">
                        {!props.businessRegiData?.business_name &&
                          props.formError?.business_name
                          ? props.formError?.business_name
                          : ""}
                      </small>
                      <small className="text-danger">
                        {props.formError?.business_name_check
                          ? props.formError?.business_name_check
                          : ""}
                      </small>
                    </div>
                    {/* <div className="form-group date">
                       <label className="d-block mb-0">
                          Address <span style={{ color: "red" }}>*</span>
                        </label> 
                      <input
                        type="text"
                        className="form-control form-control-md"
                        placeholder="Address"
                        value={props.businessRegiData.address}
                        onChange={(e) =>
                          props.setBusinessRegiData({
                            ...props.businessRegiData,
                            address: e.target.value.trimStart(),
                          })
                        }
                      />
                      
                      <small className="text-danger">
                        {(!props.businessRegiData?.address ||
                          (props.businessRegiData?.address &&
                            props.businessRegiData?.address.trim().length ===
                            0)) &&
                          props.formError?.address
                          ? props.formError?.address
                          : ""}
                      </small>
                    </div> */}
                    <div className="form-group date">
                      {/* <label className="d-block mb-0">
                          City <span style={{ color: "red" }}>*</span>
                        </label> */}
                      <div ref={wrapperRefCity}>
                        <input
                          type="text"
                          className="form-control"
                          id="citySearch"
                          onChange={(e) => {
                            handleCitySearch(e.target.value);
                          }}
                          onBlur={(e) => manageCitySearch(e.target.value)}
                          placeholder="City"
                          autoComplete="off"
                        />
                        <div>
                          <div
                            className={`city_list_box ${cityListShow ? "show" : "hide"
                              }`}
                            style={{ width: "90%" }}
                          >
                            {searchCityList && searchCityList.length > 0 ? (
                              <ul className="city_list_menu">
                                {searchCityList.map((item, i) => {
                                  return (
                                    <li
                                      onClick={() => {
                                        props.setBusinessRegiData({
                                          ...props.businessRegiData,
                                          cityid: item.id,
                                        });
                                        setCityListShow(false);
                                        handleSelectCity(
                                          item.city,
                                          item.state_id
                                        );
                                      }}
                                      className={`city_list ${props.businessRegiData?.cityid ==
                                          item.id
                                          ? "active"
                                          : ""
                                        }`}
                                    >
                                      {item.city}, {item.state_id}
                                    </li>
                                  );
                                }, [])}
                              </ul>
                            ) : (
                              <p
                                style={{
                                  textAlign: "center",
                                  marginTop: "2px",
                                  color: "gray",
                                }}
                              >
                                No data
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <small className="text-danger">
                        {!props.businessRegiData?.cityid &&
                          props.formError.cityid
                          ? props.formError.cityid
                          : ""}
                      </small>
                    </div>

                   {/*  <div className="form-group date">
                      <div className="form-group date">
                        
                        <div ref={wrapperRef}>
                          <input
                            type="text"
                            className="form-control"
                            id="industryId"
                            onChange={(e) =>
                              handleIndustryServiceSearch(e.target.value)
                            }
                            placeholder="Industry"
                            autoComplete="off"
                          />
                          <div>
                            <div
                              className={`city_list_box ${industryListShow ? "show" : "hide"
                                }`}
                            >
                              {industryList.length > 0 ? (
                                <ul className="city_list_menu">
                                  {industryList.map((item, i) => {
                                    return (
                                      <li
                                        key={i}
                                        onClick={() => {
                                          props.setBusinessRegiData({
                                            ...props.businessRegiData,
                                            industry: item.naicsid,
                                          });
                                          handleSelectIndustry(item.title);
                                        }}
                                        className={`city_list ${props.businessRegiData?.industry ==
                                            item.naicsid
                                            ? "active"
                                            : ""
                                          }`}
                                      >
                                        <a> {item.title}</a>{" "}
                                      </li>
                                    );
                                  })}
                                </ul>
                              ) : (
                                <p
                                  style={{
                                    textAlign: "center",
                                    marginTop: "2px",
                                    color: "gray",
                                  }}
                                >
                                  No data
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <small className="text-danger">
                          {props.businessRegiData?.industry == "" &&
                            props.formError.industry
                            ? props.formError.industry
                            : ""}
                        </small>
                      </div>
                    </div> */}

                    <div className="form-group date">
                      {/* <label className="d-block mb-0">
                          Service <span style={{ color: "red" }}>*</span>
                        </label> */}
                      <Multiselect
                        displayValue="key"
                        onKeyPressFn={function noRefCheck() { }}
                        onRemove={(e) => props.setSelectedOption(e)}
                        onSearch={(e) => searchServiceListData(e)}
                        onSelect={(e) => props.setSelectedOption(e)}
                        options={optionsService && optionsService}
                        value={props.selectedOption}
                        selectionLimit="1"
                        placeholder="Occupation"
                      />
                     
                      <small className="text-danger">
                        {props.selectedOption == 0 && props.formError.service
                          ? props.formError.service
                          : ""}
                      </small>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="file-upload"
                        className="custom-file-upload"
                      >
                        <i className="fa fa-cloud-upload"></i> Upload business
                        License
                      </label>
                      <input
                        id="file-upload"
                        type="file"
                        onChange={(e) => {
                          props.setBusinessRegiData({
                            ...props.businessRegiData,
                            certificate: e.target.files[0],
                          });
                          setCertificateName(e.target.files[0].name);
                        }}
                        accept="application/pdf"
                        style={{ display: "none" }}
                      />
                      <small>{certificateName}</small>

                    
                      <small className="text-danger">
                        {!props.businessRegiData?.certificate &&
                          props.formError.certificate
                          ? props.formError.certificate
                          : ""}
                      </small>
                    </div>

                   
                  </div>
                
                </div>
              </form>
            </div>
            <div className="col-lg-12">
              <div className="form-group text-center">
                <button
                  type="button"
                  className="btn main-btn w-100"
                  onClick={() => props.submitBusinessForm()}
                >
                  {" "}
                  {props.isLoader ? (
                    <img
                      src="images/loader_white.gif"
                      style={{ width: "30px" }}
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function CertifyPopup(props) {
  const businessData = useSelector((store) => store.businessData);
  const dispatch = useDispatch();

  const [isLoader, setIsLoader] = useState(false);
  const [selectFile, setSelectFile] = useState("");
  const [electroniCarticles, setElectroniCarticles] = useState();

  const [fileError, setFileError] = useState({});
  function formValidation() {
    let error = {};
    let formErrorData = false;
    if (!selectFile) {
      formErrorData = true;
      error["selectFile"] = "W9 form File is required.";
    }
    if (selectFile && selectFile?.name.split(".").pop() != "pdf") {
      formErrorData = true;
      error["selectFile"] = "Select only pdf file.";
    }
    if (!electroniCarticles) {
      formErrorData = true;
      error["electronicarticles"] =
        "Article of Incorporation File is required.";
    }
    if (
      electroniCarticles &&
      electroniCarticles?.name.split(".").pop() != "pdf"
    ) {
      formErrorData = true;
      error["electronicarticles"] = "Select only pdf file.";
    }
    setFileError(error);
    return formErrorData;
  }

  async function formsubmit() {
    if (!formValidation()) {
      setIsLoader(true);
      const frmData = new FormData();
      frmData.append(
        "businessid",
        businessData?.businessRegisterDetail.businessid
      );
      frmData.append(
        "profileid",
        businessData?.businessRegisterDetail.profileid
      );
      frmData.append("w9form", selectFile);
      frmData.append("electronicarticles", electroniCarticles);

      const header = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const { data } = await apiCall(
        "POST",
        ApiEndPoint.BUSINESSCERTIFICATEUPDATE,
        frmData,
        header
      );
      // console.log('data: BUSINESSCERTIFICATEUPDATE ==>', data);

      if (data.status == 200) {
        dispatch(setBusinessRegisterDetail(data.data));
        successToast(data.message);
        setIsLoader(false);
        props.setCertityBusinessStatus(true);
        // props.handleCertityBusiness();
        document.getElementById("certifyBusiness").click();
        document.getElementById("sucessModelBtn").click();
      } else {
        setIsLoader(false);
        errorToast(data.message);
      }
    }
  }

  return (
    <>
      <div
        className="modal fade certifyBusiness--modal"
        id="certifyBusiness"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="container">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Certify Your Business
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <div className="progress-bar" />
              </div>
              <div className="modal-body">
                <form>
                  {/* <div className="form-group">
                                        <label className="d-block">

                                            Upload your current IRS W9 Form
                                        </label>
                                    </div>
                                    <a onClick={() => document.getElementById('selectPdfFile').click()} className="main-btn rounded">
                                        browser
                                    </a>

                                    <input type='file' onChange={(e) => handleSelectFile(e.target.value)} style={{ display: 'none' }} id='selectPdfFile' accept="application/pdf" />
                                    <br />
                                    <span>{selectFile}</span> */}

                  <div className="form-group date">
                    <label className="d-block mb-0">W9 form</label>
                    <input
                      type="file"
                      className="form-control form-control-md"
                      onChange={(e) => setSelectFile(e.target.files[0])}
                      accept="application/pdf"
                    />
                    <small className="text-danger">
                      {!selectFile && fileError.selectFile
                        ? fileError.selectFile
                        : ""}
                    </small>
                  </div>

                  <div className="form-group date">
                    <label className="d-block mb-0">
                      Article of Incorporation
                    </label>
                    <input
                      type="file"
                      className="form-control form-control-md"
                      placeholder="Article of Incorporation"
                      onChange={(e) => setElectroniCarticles(e.target.files[0])}
                      accept="application/pdf"
                    />
                    <small className="text-danger">
                      {!electroniCarticles && fileError?.electronicarticles
                        ? fileError.electronicarticles
                        : ""}
                    </small>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="main-btn rounded"
                  style={{ minWidth: 160 }}
                  onClick={() => formsubmit()}
                >
                  {" "}
                  {isLoader ? (
                    <img
                      src="images/loader_white.gif"
                      style={{ width: "30px" }}
                    />
                  ) : (
                    "Check Status"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function FormSucessPopup(props) {
  return (
    <>
      <div
        className="modal fade success--modal"
        id="sucessModel"
        tabIndex={-1}
        data-backdrop="static"
        data-keyboard="false"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header" style={{ borderBottom: "0px" }}>
              <a
                href="/login"
                id="vaildateIdentityModel"
                type="button"
                className="close"
                // data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </a>
            </div>
            <div className="modal-body">
              <div className="form-group text-center">
                <img
                  src="images/papel-picado.png"
                  className="mb-4"
                  style={{ width: "100px" }}
                />
                <div
                  className="col-md-9 mb-5"
                  style={{ margin: "auto", fontSize: "20px" }}
                >
                  <p className="d-block">
                    Our team is{" "}
                    <span className="text-default fw-bold">
                      {" "}
                      processing your registration
                    </span>
                    {" "}
                    which should be completed within 24 hours.
                    <span className="text-default fw-bold">
                      {" "}
                      Please check your email address to confirm your registration.
                    </span>
                  </p>
                </div>
              </div>
              {/* <div className="form-group text-center" style={{ fontSize: "20px" }}>
                <p>Go to e-mail</p>
              </div>
              <div className="row mb-5">
                <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                  <div className="form-group text-center join-us--form-box">
                    <img
                      src="images/logo-yahoo.svg"
                      style={{ width: "100px" }}
                    ></img>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                  <div className="form-group text-center join-us--form-box">
                    <img
                      src="images/logo-yahoo.svg"
                      style={{ width: "100px" }}
                    ></img>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                  <div className="form-group text-center join-us--form-box">
                    <img
                      src="images/logo-yahoo.svg"
                      style={{ width: "100px" }}
                    ></img>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function SubscriptionPopup(props) {
  return (
    <>
      <div
        className="modal fade success--modal"
        id="subscriptionPopup"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="container">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body mt-4 pt-3 pb-4">
                <div className="form-group text-center">
                  <img src="images/right_img.png" style={{ width: "85px" }} />
                  <p className="sucess_msg_title">
                    Thank For Register with us.
                  </p>
                  <p className="d-block">
                    Our back end team verify you information and update you
                    shortly for login your business account.
                  </p>
                </div>
              </div>
              <div className="modal-footer" style={{ borderTop: "0px" }}>
                <a
                  href="/login"
                  type="button"
                  className="main-btn rounded"
                  style={{ minWidth: 150 }}
                >
                  Continue
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export {
  ValidatePopup,
  RegisterPopup,
  CertifyPopup,
  FormSucessPopup,
  SubscriptionPopup,
};
