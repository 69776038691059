import React, { useState } from 'react';

function NewSubscription() {
    return (
        <section className="about-us-area pb-10 bg-light hero--mrgn-top">
            <div className="about__mainpage">
                <div className="container ">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="">
                                <div className="heading1">
                                    <h2>Select your Subscription Plan</h2>
                                </div>
                               
                                <div className="row justify-content-center">

                                    <div className="col-lg-4 col-md-6 col-sm-9">
                                        <div className="join-item join--smb-item mt-30">
                                            <div className="text-center join--itemnum-box">

                                                <span className={'join--item-num-active-plan'}>$10/Month</span>
                                            </div>

                                            <div className="join--idnty-top-plan text-plan">
                                                <span className="plan-name"> Business Owner </span>
                                            </div>
                                            <div className="join--idnty text-plan">
                                                <span>
                                                    <img className='plan-image-icon' src="images/right_img.png" /> Profile
    </span>
                                            </div>
                                            <div className="join--idnty text-plan">
                                                <span>
                                                    <img className='plan-image-icon' src="images/right_img.png" /> Video
        </span>
                                            </div>
                                            <div className="join--idnty text-plan">
                                                <span>
                                                    <img className='plan-image-icon' src="images/right_img.png" />Photo
        </span>
                                            </div>
                                            <div className="join--idnty text-plan">
                                                <span>
                                                    <img className='plan-image-icon' src="images/right_img.png" /> Document
        </span>
                                            </div>
                                            <div className="join--idnty text-plan">
                                                <span>
                                                    <img className='plan-image-icon' src="images/right_img.png" />Job
        </span>
                                            </div>
                                            <div className="join--idnty text-plan">
                                                <span>
                                                    <img className='plan-image-icon' src="images/right_img.png" />Offer
        </span>
                                            </div>
                                            <div className="join--idnty text-plan">
                                                <span>
                                                    <img className='plan-image-icon' src="images/right_img.png" />Priority search result
        </span>
                                            </div>
                                            <div className="join--idnty text-plan">
                                                <span>
                                                    <img className='plan-image-icon' src="images/crose-icon-image.png" />Invesment Opportunities
        <span className='sub-heading-plan'> (Business, Real Estate,FinTech,etc...)</span>
                                                </span>
                                            </div>
                                            <div className="join--idnty-content-plan">
                                                {/* <span
                                                    //onClick={(plantype)=>purchaseplan(10,2,'P-2KH790366X450330SMO4B5DY')}
                                                    className='main-btn join--begin-btn rounded'>Buy Now</span> */}

                                                <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                                                    <input type="hidden" name="cmd" value="_s-xclick" />
                                                    <input type="hidden" name="hosted_button_id" value="QE8YFDEEKU43U" />
                                                    <table style={{display:'none'}}>
                                                        <tr><td><input type="hidden" name="on0" value="Scope Options" />Scope Options</td></tr><tr><td>
                                                            <select name="os0">
                                                                <option value="City">$10.00 USD - monthly</option>
                                                               
                                                            </select> </td></tr>
                                                    </table>
                                                    <input type="hidden" name="currency_code" value="USD" />
                                                    <input
                                                    style={{width: "172px"}}  
                                                    type="image" 
                                                    src="images/BuyNow.png" 
                                                    border="0" 
                                                    name="submit" 
                                                    alt="PayPal - The safer, easier way to pay online!" 
                                                     />
                                                    <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1"/>
                                                </form>



                                            </div>


                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-9">
                                        <div className="join-item join--smb-item mt-30">
                                            <div className="text-center join--itemnum-box">

                                                <span className={'join--item-num-active-plan'}>$100/month</span>
                                            </div>
                                            <div className="join--idnty-top-plan text-plan">
                                                <span className="plan-name"> Active Investor </span>
                                            </div>
                                            <div className="join--idnty text-plan">
                                                <span>
                                                    <img className='plan-image-icon' src="images/right_img.png" /> Profile
    </span>
                                            </div>
                                            <div className="join--idnty text-plan">
                                                <span>
                                                    <img className='plan-image-icon' src="images/right_img.png" /> Video
        </span>
                                            </div>
                                            <div className="join--idnty text-plan">
                                                <span>
                                                    <img className='plan-image-icon' src="images/right_img.png" />Photo
        </span>
                                            </div>
                                            <div className="join--idnty text-plan">
                                                <span>
                                                    <img className='plan-image-icon' src="images/right_img.png" />Document
        </span>
                                            </div>
                                            <div className="join--idnty text-plan">
                                                <span>
                                                    <img className='plan-image-icon' src="images/right_img.png" />Job
        </span>
                                            </div>
                                            <div className="join--idnty text-plan">
                                                <span>
                                                    <img className='plan-image-icon' src="images/right_img.png" />Offer
        </span>
                                            </div>
                                            <div className="join--idnty text-plan">
                                                <span>
                                                    <img className='plan-image-icon' src="images/right_img.png" />Priority search result
        </span>
                                            </div>
                                            <div className="join--idnty text-plan">
                                                <span>
                                                    <img className='plan-image-icon' src="images/right_img.png" />Invesment Opportunities
        <span className='sub-heading-plan'> (Business, Real Estate,FinTech,etc...)</span>
                                                </span>
                                            </div>
                                            <div className="join--idnty-content-plan">
                                               {/*  <span
                                                    //onClick={(plantype)=>purchaseplan(100,3,'P-70967648FS892891LMO4B65I')}
                                                    className='main-btn join--begin-btn rounded'>Buy Now</span> */}


<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
<input type="hidden" name="cmd" value="_s-xclick"/>
<input type="hidden" name="hosted_button_id" value="NCWGFWSVVF87L"/>
<input style={{width: "172px"}} type="image" src="images/BuyNow.png" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!"/>
<img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1"/>
</form>



                                            </div>

                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default NewSubscription;
