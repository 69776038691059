import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import apiEndPoints from "utils/apiEndPoints";
import { apiCall } from "utils/httpClient";
import { useSelector } from "react-redux";
import Select from "react-select";
import { successToast, errorToast } from "utils/httpClient";
import Loader from "common/loader";
import base64 from "base-64";
import Multiselect from "multiselect-react-dropdown";
import moment from "moment";

function PhotoList() {
  const wrapperRefCity = useRef(null);
  const wrapperRef = useRef(null);
  let history = useHistory();
  const userData = useSelector((store) => store.userData);
  const [isLoader, setIsLoader] = useState(false);
  const [proIsLoader, setProIsLoader] = useState(false);
  const [businessDetails, setBusinessDetail] = useState({});
  const [paymentmethods, setPaymentmethods] = useState({});
  const [selectImage, setSelectImage] = useState("");
  const [bucket_Img_url, setBucket_Img_url] = useState();
  const [bucket_cer_url, setBucket_cer_url] = useState();
  const [isImgLoader, setIsImgLoader] = useState(false);
  const [photofile, setPhotofile] = useState();
  const [currentstatus, setCurrentstatus] = useState(0);
  const [planstartdate, setPlanstartdate] = useState(moment().format("YYYY-MM-DD"));
  const [planenddate, setPlanenddate] = useState(moment().format("YYYY-MM-DD"));

  const { businessid, profileid } = useParams();
  const [baseUrl, setBaseUrl] = useState();
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        //alert("You clicked outside of me!");
        setIndustryListShow(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
  }, [wrapperRef]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRefCity.current &&
        !wrapperRefCity.current.contains(event.target)
      ) {
        setCityListShow(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
  }, [wrapperRefCity]);

  useEffect(() => {
    getBusinessData();
    getServiceDetails();
    // getIndustryList();
  }, []);

  useEffect(() => {
    getImage(businessDetails?.photofile);
  }, [businessDetails && businessDetails?.photofile]);

  const getBusinessData = async () => {
    setIsLoader(true);

    const param = {
      profileid: base64.decode(profileid),
      businessid: base64.decode(businessid),
    };

    const { data } = await apiCall(
      "POST",
      apiEndPoints.GETBUSINESSDETAILS,
      param
    );
    if (data.status == 200) {
      console.log("🚀 ~ getBusinessData ~ data:", data.data)
      setBusinessDetail(data.data);
      setPlanstartdate(moment(data.data.plan_start_date).format("YYYY-MM-DD"));
      setPlanenddate(moment(data.data.plan_expiry_date).format("YYYY-MM-DD"));
      setBaseUrl(data.base_url);
      var selArr = [];
      data.data?.naics.length > 0 &&
        data.data.naics.map((x) => {

          // const arr = { value: x.naicsid, label: x.title };
          const arr = { cat: x.naicsid, key: x.title };
          selArr.push(arr);
        });
      setSelectedService([...selArr]);
      setIsLoader(false);
      getW9Certificate(data.data?.certificate)
      setCurrentstatus(data.data?.status);
      setPaymentmethods(data.data?.payments !== '' ? JSON.parse(data.data?.payments) : null);


    } else {
      setIsLoader(false);
    }
  };

  const clickHaveKidsLabel = (id) => {
    document.getElementById(id).click()
  }
  const setdatesubscription = (valueselect) => {
    console.log("🚀 ~ setdatesubscription ~ value:", valueselect)
    const today = moment();
    const nextWeek = today.add(364, 'days');
    const planexpirydate = nextWeek.format('YYYY-MM-DD');
    const planstartdate = moment().format('YYYY-MM-DD');
    setPlanstartdate(planstartdate)
    setPlanenddate(planexpirydate)
    if (valueselect == 1) {

      /*  setBusinessDetail({
         ...businessDetails,
         plan_option: "1"
       }); */
    }
  }


  // const getBusinessData = async () => {
  //   // setIsLoader(true);
  //   const header = {
  //     Authorization: `Bearer ${localStorage.getItem("authToken")}`,
  //   };
  //   const param = {
  //     profileid: userData.userData.profileid,
  //     businessid: userData.userData.businessid,
  //   };

  //   const { data } = await apiCall(
  //     "POST",
  //     apiEndPoints.GETBUSINESSDETAILS,
  //     param,
  //     header
  //   );

  //   if (data.status == 200) {
  //     setBusinessDetail(data.data);
  //     setBaseUrl(data.base_url);

  //     var selArr = [];
  //     data.data?.naics.length > 0 &&
  //       data.data.naics.map((x) => {
  //         // const arr = { value: x.naicsid, label: x.title };
  //         const arr = { cat: x.naicsid, key: x.title };
  //         selArr.push(arr);
  //       });
  //     setSelectedService([...selArr]);
  //     setIsLoader(false);
  //   } else {
  //     setIsLoader(false);
  //   }
  // };

  //   --------------------------------------------- Get service List ----------------------------

  const [serviceList, setServiceList] = useState({});

  const getServiceDetails = async () => {
    const { data } = await apiCall("POST", apiEndPoints.GETSERVICELIST);
    if (data.status == 200) {
      setServiceList(data.data);
    }
  };
  const [updatedServices, setUpdatedServices] = useState();

  // const options =
  //   serviceList.length > 0 &&
  //   serviceList.map((curE) => {
  //     return { value: curE.naicsid, label: curE.title };
  //   });

  // const selectedService =
  //   businessDetails &&
  //   businessDetails?.naics &&
  //   businessDetails?.naics.length > 0
  //     ? businessDetails.naics.map((x) => {
  //         return { label: x.title };
  //       })
  //     : {};

  // console.log("selectedService selectedService", selectedService);

  const [formError, setFormError] = useState({});

  function validation() {
    let error = {};
    let validError = false;
    if (!businessDetails?.fullname) {
      validError = true;
      error["fullname"] = "Business name is required!";
    }
    if (businessDetails?.plan_type == "0") {
      validError = true;
      error["plan_type"] = "Subscription Type is required!";
    }
    if (businessDetails?.plan_type == "2" && planstartdate == "") {
      validError = true;
      error["plan_start_date"] = "Subscription Start Date is required!";
    }
    if (businessDetails?.plan_type == "2" && planenddate == "") {
      validError = true;
      error["plan_expiry_date"] = "Subscription End Date is required!";
    }
    /* if (!businessDetails?.address) {
      validError = true;
      error["address"] = "Address is required!";
    } */
    if (!businessDetails.cityid) {
      validError = true;
      error["cityid"] = "City is required!";
    }
    if (!businessDetails.city_name) {
      validError = true;
      error["cityid"] = "City is required!";
    }
    if (selectedService.length == 0) {
      validError = true;
      error["naics"] = "Occupation is required!";
    }
    /* if (!businessDetails?.industry) {
      validError = true;
      error["industry"] = "Industry is required!";
    } */

    /*   if (!businessDetails?.payments) {
        validError = true;
        error["payments"] = "Payment method is required!";
      }
      if (!businessDetails?.phone) {
        validError = true;
        error["phone"] = "Phone is required!";
      } else if (businessDetails?.phone.toString().length < 10) {
        validError = true;
        error["phone"] = "Enter 10 digits Mobile number!";
      }
      if (!businessDetails?.websiteurl) {
        validError = true;
        error["websiteurl"] = "Website_url is required!";
      }
      if (!businessDetails?.about) {
        validError = true;
        error["about"] = "Overview/Introduction is required!";
      }
       if (!businessDetails?.service_area) {
        validError = true;
        error["service_area"] = "Servicing_areas is required!";
      }
      if (!businessDetails?.service_offer) {
        validError = true;
        error["service_offer"] = "Servicing_offer is required!";
      }
      if (!businessDetails?.year_revenue) {
        validError = true;
        error["year_revenue"] = "Year Revenue is required!";
      }
      if (!businessDetails?.hours) {
        validError = true;
        error["hours"] = "Hours Operation is required!";
      }
      if (!businessDetails?.pricehour) {
        validError = true;
        error["pricehour"] = "Price/hour is required!";
      }
      if (!businessDetails?.numemps) {
        validError = true;
        error["numemps"] = "Head Count is required!";
      } */

    setFormError(error);
    return validError;
  }

  // -------------------------------------------------- new code ------------------------
  const [cityListShow, setCityListShow] = useState(false);
  const [searchCityList, setSearchCityList] = useState([]);
  const [industryListShow, setIndustryListShow] = useState(false);
  const [selectedService, setSelectedService] = useState([]);
  const [industryList, setIndustryList] = useState({});
  const [notifyLoader, setNotifyLoader] = useState(false)
  const [errorPlanSub, setErrorPlanSub] = useState(false)

  function manageCityList() {
    const status = cityListShow ? false : true;
    setCityListShow(status);
  }

  function manageCitySearch(item) {
    if (item == "") {
      setCityListShow(false);
      setBusinessDetail({
        ...businessDetails,
        cityid: "",
        city_name: "",
      });
    }
  }
  async function handleCitySearch(city = "") {
    if (city.length >= 3) {
      const params = { cityname: city };
      const { data } = await apiCall(
        "POST",
        apiEndPoints.GETCITIESLIST,
        params
      );
      if (data.status == 200) {
        setSearchCityList(data.data);
      } else {
        setSearchCityList([]);
      }
      setCityListShow(true);
    } else {
      setSearchCityList([]);
      setBusinessDetail({
        ...businessDetails,
        cityid: "",
        city_name: "",
      });
    }
  }

  function handleSelectCity(cityname) {
    document.getElementById("citySearch").value = cityname;
    setCityListShow(false);
    setSearchCityList([]);
  }
  // if(isLoader){
  //   return(<Loader />)
  // }

  const options =
    serviceList.length > 0
      ? serviceList.map((curE) => {
        return { cat: curE.naicsid, key: curE.title };
      })
      : [];

  function handleSelectIndustry(item) {
    document.getElementById("industryId").value = item;
    setIndustryListShow(false);
  }
  // function handleIndustrySearch(){
  //   const status = industryListShow?false:true
  //   setIndustryListShow(status);
  // }

  const searchServiceListData = async (item) => {
    if (item.length >= 3) {
      const params = { servicename: item };
      const { data } = await apiCall(
        "POST",
        apiEndPoints.GETSERVICELIST,
        params
      );
      if (data.status == 200) {
        setServiceList(data.data);
      } else {
        setServiceList([]);
      }
    } else {
      setServiceList([]);
    }
  };

  // --------------------------------------new code close-----------------------------

  const [webUrlMsg, setWebUrlMsg] = useState("");
  function isUrlCheck(item) {
    let url;
    try {
      url = new URL(item);
      setBusinessDetail({
        ...businessDetails,
        websiteurl: item,
      });
      setWebUrlMsg("");
    } catch (_) {
      setWebUrlMsg("Enter Proper Web URL. Example http://");
      setBusinessDetail({
        ...businessDetails,
        websiteurl: "",
      });
    }
  }

  const submitBusinessForm = async () => {
    var servId =
      businessDetails &&
        businessDetails?.naics &&
        businessDetails?.naics.length > 0
        ? businessDetails.naics.map((x) => x.naicsid)
        : "";
    // if(businessDetails.plan_type == 0){
    //   setErrorPlanSub(true);
    //   return;
    // }
    // setErrorPlanSub(false);

    if (!validation()) {
      // setIsLoader(true)
      setProIsLoader(true);
      let businessData = new FormData();

      businessData.append("businessid", businessDetails.businessid);
      businessData.append("profileid", businessDetails.profileid);
      businessData.append("fullname", businessDetails.fullname);
      businessData.append("address", businessDetails.address);
      businessData.append("cityid", businessDetails.cityid);
      businessData.append("numemps", businessDetails.numemps);
      businessData.append(
        "annualgrossrevenue",
        businessDetails.annualgrossrevenue
      );
      businessData.append("about", businessDetails.about);
      businessData.append("pricemodel", businessDetails.pricemodel);
      businessData.append("hours", businessDetails.hours);
      businessData.append("payments", JSON.stringify(paymentmethods));
      businessData.append("phone", businessDetails.phone);
      businessData.append("email", businessDetails.email);
      businessData.append("websiteurl", businessDetails.websiteurl);
      businessData.append("showcall", businessDetails.showcall);
      businessData.append("showtext", businessDetails.showtext);
      businessData.append("showemail", businessDetails.showemail);
      businessData.append("service_offer", businessDetails.service_offer);
      businessData.append("service_area", businessDetails.service_area);
      businessData.append("county", "1");
      businessData.append("state", "1");
      businessData.append("industry", businessDetails.industry);
      businessData.append("industry_name", businessDetails.industry_name);
      businessData.append("year_revenue", businessDetails.year_revenue);
      //businessData.append("plan_type", 2);//el tipo 2 es el perfil de Business Owner
      businessData.append("plan_type", businessDetails.plan_type);//linea comentada del plan_type
      businessData.append("plan_option", businessDetails.plan_type == "2" ? businessDetails.plan_option : "0");
      // businessData.append("head_count", businessDetails.head_count);
      businessData.append(
        "business_validation",
        businessDetails.business_validation
      );
      // businessData.append("naicsid", servId.toString());
      businessData.append(
        "naicsid",
        selectedService.length > 0 &&
        selectedService.map((item) => item.cat).join(", ")
      );
      businessData.append("naics", businessDetails.naics);
      businessData.append("facebookurl", businessDetails.facebookurl);
      businessData.append("linkedInurl", businessDetails.linkedInurl);
      businessData.append("twitterurl", businessDetails.twitterurl);
      businessData.append("youtubeurl", businessDetails.youtubeurl);
      businessData.append("photofile", businessDetails.photofile);
      businessData.append("is_nonprofit", businessDetails.is_nonprofit);
      businessData.append("status", businessDetails.status);
      businessData.append("is_minority", businessDetails.is_minority);
      businessData.append("allinformation", businessDetails.allinformation);
      businessData.append("certificate", businessDetails.certificate);
      businessData.append("plan_start_date", planstartdate);
      businessData.append("plan_expiry_date", planenddate);
      const header = {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      };
      const { data } = await apiCall(
        "POST",
        apiEndPoints.BUSINESSDETAILUPDATE,
        businessData,
        header
      );
      if (data.status == 200) {
        setIsLoader(false);
        setProIsLoader(false);
        successToast(data.message);
        setCurrentstatus(1);
      } else {
        setProIsLoader(false);
        setIsLoader(false);
        errorToast(data.message);
      }
    }
  };

  async function handleSendNotification(profileid) {
    // setIsLoader(true);
    setNotifyLoader(true);
    const header = {
      Authorization: `Bearer ${localStorage.getItem("userToken")}`,
    };
    const params = { profileid: profileid };
    var { data } = await apiCall(
      "POST",
      apiEndPoints.SENDBUSINESSNOTIFICATION,
      params,
      header
    );
    if (data.status == 200) {
      getBusinessData();
      successToast(data.message);
      setIsLoader(false);
      setNotifyLoader(false);
    } else {
      errorToast(data.message);
      setIsLoader(false);
      setNotifyLoader(false);
    }
  }
  const [showFilePath, setShowFilePath] = useState();

  async function searchIndustryListData(item) {
    if (item != "" && item.length >= 3) {
      const params = { servicename: item };
      const { data } = await apiCall(
        "POST",
        apiEndPoints.GETINDUSTRYLIST,
        params
      );
      if (data.status == 200) {
        setIndustryList(data.data);
        setIndustryListShow(true);
      } else {
        setIndustryListShow(false);
        // errorToast(data.message);
        setIndustryList([]);
        setBusinessDetail({
          ...businessDetails,
          industry: 0,
          industry_name: "",
        });
      }
    } else {
      setIndustryListShow(false);
      setIndustryList([]);
      setBusinessDetail({
        ...businessDetails,
        industry: 0,
        industry_name: "",
      });
    }
  }

  async function getImage(param) {
    setIsImgLoader(true);
    const params = {
      fileName: param,
    };
    const { data } = await apiCall("POST", apiEndPoints.GETIMAGE, params);
    if (data.status == 200) {
      setBucket_Img_url(data.url);
      setTimeout(() => {
        setIsImgLoader(false);
      }, 500);
    } else {
      setIsImgLoader(false);
    }
  }

  function isSelectPlan(item) {
    let url;
    console.log('como', item);
    // try {
    //   url = new URL(item);
    //   setBusinessDetail({
    //     ...businessDetails,
    //     websiteurl: item,
    //   });
    //   setWebUrlMsg("");
    // } catch (_) {
    //   setWebUrlMsg("Enter Proper Web URL. Example http://");
    //   setBusinessDetail({
    //     ...businessDetails,
    //     websiteurl: "",
    //   });
    // }
  }

  async function getW9Certificate(param) {
    console.log("functiongetW9Certificate -> param", param)
    const params = {
      fileName: param,
    };
    const { data } = await apiCall("POST", apiEndPoints.GETIMAGE, params);
    if (data.status == 200) {
      console.log("functiongetW9Certificate -> data.url", data.url)
      setBucket_cer_url(data.url);
      setShowFilePath(data.url);
      //document.getElementById('modal_cls').click() // for closing pdf view modal
    } else {
    }
  }

  async function getAOICertificate(param) {
    const params = {
      fileName: param,
    };
    const { data } = await apiCall("POST", apiEndPoints.GETIMAGE, params);
    if (data.status == 200) {
      setBucket_cer_url(data.url);
      setShowFilePath(
        businessDetails.electronicarticles != null
          ? data.url
          : ""
      );
    } else {
    }
  }

  /*   if (isLoader) {
      return <Loader />;
    } */

  return (
    <>
      <div className="container">

        {isLoader ? (
          <div style={{ textAlign: 'center' }}>
            <img className="listloadergif-admin" src="images/infinity.gif" />

          </div>
        ) :
          <>
            <div
              className="modal showVideo--modal"
              id="showCertificate"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="container">
                <div
                  className="modal-dialog  video--modal"
                  style={{ maxWidth: "550px" }}
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel"></h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                      <div className="progress-bar" />
                    </div>
                    <div
                      className="modal-body"
                      style={{ minHeight: "500px", width: "100%" }}
                    >
                      <div className="swiper-slide">
                        {showFilePath ? (
                          <iframe
                            style={{
                              display: "block",
                              width: "100%",
                              height: "700px",
                            }}
                            className="embed-responsive-item"
                            frameBorder="0"
                            // src="https://www.orimi.com/pdf-test.pdf"
                            // src={showFilePath}
                            src={showFilePath}
                            allowFullScreen
                          ></iframe>
                        ) : (
                          <img src="images/nodatafound.png" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {businessDetails && (
                <form>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="d-block mb-0">Business Name:</label>
                          <input
                            type="text"
                            className="form-control form-control-md"
                            placeholder="Business Name"
                            value={businessDetails.fullname}
                            onChange={(e) =>
                              setBusinessDetail({
                                ...businessDetails,
                                fullname: e.target.value,
                              })
                            }
                          />
                          <small className="text-danger">
                            {!businessDetails?.fullname && formError.fullname
                              ? formError.fullname
                              : ""}
                          </small>
                        </div>
                        <div className="form-group date">
                          <label className="d-block mb-0">Address:</label>
                          <input
                            type="text"
                            className="form-control form-control-md"
                            placeholder="Address"
                            value={businessDetails.address}
                            onChange={(e) =>
                              setBusinessDetail({
                                ...businessDetails,
                                address: e.target.value,
                              })
                            }
                          />
                          <small className="text-danger">
                            {!businessDetails?.address && formError.address
                              ? formError.address
                              : ""}
                          </small>
                        </div>
                        <div className="form-group date">
                          <label className="d-block mb-0">City:</label>
                          <div ref={wrapperRefCity}>
                            <input
                              type="text"
                              className="form-control"
                              id="citySearch"
                              onChange={(e) => {
                                handleCitySearch(e.target.value);
                              }}
                              // onClick={() => manageCityList()}
                              onBlur={(e) => manageCitySearch(e.target.value)}
                              placeholder="City"
                              defaultValue={businessDetails.city_name + ', ' + businessDetails.statecode}
                              autoComplete="off"
                              disabled="true"
                            />
                            <div>
                              <div
                                className={`city_list_box ${cityListShow ? "show" : "hide"
                                  }`}
                              >
                                {searchCityList && searchCityList.length > 0 ? (
                                  <ul className="city_list_menu">
                                    {searchCityList.map((item, i) => {
                                      return (
                                        <li
                                          onClick={() => {
                                            setBusinessDetail({
                                              ...businessDetails,
                                              cityid: item.id,
                                              city_name: item.city,
                                            });
                                            setCityListShow(false);
                                            handleSelectCity(item.city);
                                          }}
                                          className={`city_list ${businessDetails?.cityid == item.id
                                            ? "active"
                                            : ""
                                            }`}
                                        >
                                          {item.city}
                                        </li>
                                      );
                                    }, [])}
                                  </ul>
                                ) : (
                                  <p
                                    style={{
                                      textAlign: "center",
                                      marginTop: "2px",
                                      color: "gray",
                                    }}
                                  >
                                    No data
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <small className="text-danger">
                            {!businessDetails?.cityid && formError.cityid
                              ? formError.cityid
                              : ""}
                          </small>
                        </div>
                        <div className="form-group date">
                          <label className="d-block mb-0">
                            Overview/introduction:
                          </label>
                          <textarea
                            className="form-control"
                            placeholder="Overview/introduction"
                            value={businessDetails.about}
                            onChange={(e) =>
                              setBusinessDetail({
                                ...businessDetails,
                                about: e.target.value,
                              })
                            }
                          ></textarea>
                          <small className="text-danger">
                            {!businessDetails?.about && formError.about
                              ? formError.about
                              : ""}
                          </small>
                        </div>
                        {/* <div className="form-group date">
                  <label className="d-block mb-0">Servicing areas:</label>
                  <textarea
                    className="form-control"
                    placeholder="Servicing areas:cities,counties,states"
                    value={businessDetails.service_area}
                    onChange={(e) =>
                      setBusinessDetail({
                        ...businessDetails,
                        service_area: e.target.value,
                      })
                    }
                  ></textarea>
                  <small className="text-danger">
                    {!businessDetails?.service_area &&
                    formError.service_area
                      ? formError.service_area
                      : ""}
                  </small>
                </div>
                <div className="form-group date">
                  <label className="d-block mb-0">Services offered:</label>
                  <textarea
                    className="form-control"
                    placeholder="Services offered within your main service"
                    value={businessDetails.service_offer}
                    onChange={(e) =>
                      setBusinessDetail({
                        ...businessDetails,
                        service_offer: e.target.value,
                      })
                    }
                  ></textarea>

                  <small className="text-danger">
                    {!businessDetails?.service_offer &&
                    formError.service_offer
                      ? formError.service_offer
                      : ""}
                  </small>
                </div>
*/}
                        {/* <div className="form-group date">
                          <label className="d-block mb-0">Price/hour:</label>
                          <input
                            type="text"
                            className="form-control form-control-md"
                            placeholder="Price/hour"
                            value={businessDetails.pricehour}
                            onChange={(e) =>
                              setBusinessDetail({
                                ...businessDetails,
                                pricehour: e.target.value,
                              })
                            }
                            onKeyPress={(event) => {
                              if (!/[0-99]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          <small className="text-danger">
                            {!businessDetails?.pricehour && formError.pricehour
                              ? formError.pricehour
                              : ""}
                          </small>
                        </div> */}

                        <div className="form-group date">
                          <label className="d-block mb-0">Price Model:</label>
                          <select
                            placeholder="Price/hour"
                            className="form-control form-control-md form-control-city"
                            value={businessDetails.pricemodel}
                            onChange={(e) =>
                              setBusinessDetail({
                                ...businessDetails,
                                pricemodel: e.target.value,
                              })
                            }
                          >
                            <option defaultValue={null}>
                              Select Price Model
                            </option>
                            <option value={'Hourly Rate'}>Hourly Rate</option>
                            <option value={'Commission'}>Commission</option>
                            <option value={'Fixed Price'}>Fixed Price</option>
                            <option value={'Contact Us'}>Contact Us</option>
                          </select>

                          <small className="text-danger">
                            {!businessDetails?.pricemodel && formError.pricemodel
                              ? formError.pricemodel
                              : ""}
                          </small>
                        </div>

                        <div className="form-group date">
                          <label className="d-block mb-0">Business Phone:</label>
                          <input
                            type="text"
                            className="form-control form-control-md"
                            placeholder="Business Phone"
                            value={businessDetails.phone}
                            onChange={(e) =>
                              setBusinessDetail({
                                ...businessDetails,
                                phone: e.target.value,
                              })
                            }
                            max="10"
                            onKeyPress={(event) => {
                              if (
                                !/[0-99]/.test(event.key) ||
                                event.target.value.length > 9
                              ) {
                                event.preventDefault();
                              }
                            }}
                          />
                          <small className="text-danger">
                            {!businessDetails?.phone ||
                              (businessDetails?.phone.length != 10 && formError.phone)
                              ? formError.phone
                              : ""}
                          </small>
                        </div>

                        <div className="form-group" style={{ "width": "100%", "float": "left" }}>
                          <label className="d-block mb-0">Payment Methods:</label>

                          <div className="col-md-12">
                            <div className="payment-check col-md-3">
                              {/*  {paymentmethods?.cash == 1 ? (
                          <input
                            className="form-check-input-payment"
                            type="checkbox"
                            onClick={(e) =>
                              setPaymentmethods({
                                ...paymentmethods,
                                cash: 1,
                              })
                            }
                            checked={
                              paymentmethods?.cash == 0 ? false : true
                            }
                          />
                        ) : (
                            <input
                              className="form-check-input-payment"
                              type="checkbox"
                              onClick={(e) =>
                                setPaymentmethods({
                                  ...paymentmethods,
                                  cash: 0,
                                })
                              }
                              checked={
                                paymentmethods?.cash == 0 ? false : true
                              }
                            />
                          )} */}
                              <input
                                className="form-check-input payment-check"
                                type="checkbox"
                                onClick={(e) =>
                                  setPaymentmethods({
                                    ...paymentmethods,
                                    cash: paymentmethods?.cash && paymentmethods?.cash == 1 ? 0 : 1,
                                  })
                                }
                                checked={
                                  paymentmethods?.cash && paymentmethods?.cash == 1 ? true : false

                                }
                              />


                              <label
                                className="form-check-label-payment"
                                htmlFor="flexCheckDefault"
                              >
                                Cash
                              </label>
                            </div>

                            <div className="payment-check col-md-3">
                              <input
                                className="form-check-input payment-check"
                                type="checkbox"
                                onClick={(e) =>
                                  setPaymentmethods({
                                    ...paymentmethods,
                                    cashapp: paymentmethods?.cashapp && paymentmethods?.cashapp == 1 ? 0 : 1,
                                  })
                                }
                                checked={
                                  paymentmethods?.cashapp && paymentmethods?.cashapp == 1 ? true : false

                                }
                              />
                              <label
                                className="form-check-label-payment"
                                htmlFor="flexCheckDefault"
                              >
                                Check
                              </label>
                            </div>

                            <div className="payment-check col-md-3">
                              <input
                                className="form-check-input payment-check"
                                type="checkbox"
                                onClick={(e) =>
                                  setPaymentmethods({
                                    ...paymentmethods,
                                    creditcard: paymentmethods?.creditcard && paymentmethods?.creditcard == 1 ? 0 : 1,
                                  })
                                }
                                checked={
                                  paymentmethods?.creditcard && paymentmethods?.creditcard == 1 ? true : false

                                }
                              />
                              <label
                                className="form-check-label-payment"
                                htmlFor="flexCheckDefault"
                              >
                                Card
                              </label>
                            </div>
                            <div className="payment-check col-md-3">
                              <input
                                className="form-check-input payment-check"
                                type="checkbox"
                                onClick={(e) =>
                                  setPaymentmethods({
                                    ...paymentmethods,
                                    paypal: paymentmethods?.paypal && paymentmethods?.paypal == 1 ? 0 : 1,
                                  })
                                }
                                checked={
                                  paymentmethods?.paypal && paymentmethods?.paypal == 1 ? true : false

                                }
                              />
                              <label
                                className="form-check-label-payment"
                                htmlFor="flexCheckDefault"
                              >
                                Paypal
                              </label>
                            </div>
                            <div className="payment-check col-md-3">
                              <input
                                className="form-check-input payment-check"
                                type="checkbox"
                                onClick={(e) =>
                                  setPaymentmethods({
                                    ...paymentmethods,
                                    zelle: paymentmethods?.zelle && paymentmethods?.zelle == 1 ? 0 : 1,
                                  })
                                }
                                checked={
                                  paymentmethods?.zelle && paymentmethods?.zelle == 1 ? true : false

                                }
                              />
                              <label
                                className="form-check-label-payment"
                                htmlFor="flexCheckDefault"
                              >
                                Zelle
                              </label>
                            </div>

                            <small className="text-danger">
                              {!businessDetails?.payments && formError.payments
                                ? formError.payments
                                : ""}
                            </small>
                          </div>
                        </div>


                        <div className="form-group date">
                          <label className="d-block mb-0">Business Email:</label>
                          <input
                            type="text"
                            className="form-control form-control-md"
                            placeholder="Business Email"
                            value={businessDetails.email}
                            onChange={(e) =>
                              setBusinessDetail({
                                ...businessDetails,
                                email: e.target.value,
                              })
                            }

                          />
                          <small className="text-danger">
                            {!businessDetails?.email ||
                              formError.email
                              ? formError.email
                              : ""}
                          </small>
                        </div>
                        <div className="form-group date">
                          <label className="d-block mb-0">
                            Hours of operation:
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-md"
                            placeholder="Hours of operation"
                            value={businessDetails.hours}
                            onChange={(e) =>
                              setBusinessDetail({
                                ...businessDetails,
                                hours: e.target.value,
                              })
                            }
                          /* onKeyPress={(event) => {
                            if (!/[0-99]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }} */
                          />
                          <div>
                            <p className="paymentmethodstypes1___">
                              Mon to Fri 10 AM to 8 PM
                            </p>
                          </div>
                          <small className="text-danger">
                            {!businessDetails?.hours && formError.hours
                              ? formError.hours
                              : ""}
                          </small>
                        </div>



                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="col-md-10">
                        <div className="form-group date">
                          <label className="d-block mb-0">Occupation:</label>
                          <Multiselect
                            displayValue="key"
                            onKeyPressFn={function noRefCheck() { }}
                            onRemove={(e) => setSelectedService(e)}
                            onSearch={(e) => searchServiceListData(e)}
                            onSelect={(e) => setSelectedService(e)}
                            selectionLimit={businessDetails?.plan_type == '2' ? "3" :"1"}
                            options={options}
                            selectedValues={selectedService}
                            style={{ backgroundColor: "red" }}
                          />
                          <small className="text-danger">
                            {selectedService.length == 0 && formError.naics
                              ? formError.naics
                              : ""}
                          </small>
                        </div>

                        <div className="form-group date">
                          <label className="d-block mb-0">Industry:</label>
                           <div ref={wrapperRef}>
                            <input
                              type="text"
                              className="form-control"
                              id="industryId"
                              // onFocus={() => setIndustryListShow(true)}
                              onChange={(e) => searchIndustryListData(e.target.value)}
                              // onBlur={()=>handleIndustrySearch()}
                              placeholder="Industry"
                              defaultValue={businessDetails?.industry_name}
                              //disabled={businessDetails?.industry == "0" ? false : true }
                              
                            />
                            <div>
                              <div
                                className={`city_list_box ${industryListShow ? "show" : "hide"
                                  }`}
                              >
                                {industryList.length > 0 ? (
                                  <ul className="city_list_menu">
                                    {industryList.map((item, i) => {
                                      return (
                                        <li
                                          key={i}
                                          onClick={() => {
                                            setBusinessDetail({
                                              ...businessDetails,
                                              industry: item.naicsid,
                                            });
                                            handleSelectIndustry(item.title);
                                          }}
                                          className={`city_list ${businessDetails?.industry ==
                                            item.naicsid
                                            ? "active"
                                            : ""
                                            }`}
                                        >
                                          <a> {item.title}</a>{" "}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                ) : (
                                  <p
                                    style={{
                                      textAlign: "center",
                                      marginTop: "2px",
                                      color: "gray",
                                    }}
                                  >
                                    No data
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>


                          <small className="text-danger">
                            {!businessDetails?.industry && formError.industry
                              ? formError.industry
                              : ""}
                          </small>
                        </div>

                        <div className="form-group date">
                          <label className="d-block mb-0">Year Revenue:</label>


                          <select
                            name="maritalstatus"
                            id="maritalstatus"
                            placeholder="Marital Status"
                            className="form-control form-control-md form-control-city"
                            value={businessDetails.year_revenue}
                            onChange={(e) =>
                              setBusinessDetail({
                                ...businessDetails,
                                year_revenue: e.target.value,
                              })
                            }
                          >
                            <option value="">Select Year Revenue</option>
                            <option value="0 - $100,000">0 - $100,000</option>
                            <option value="$100,001 - $250,000">$100,001 - $250,000</option>
                            <option value="$250,001 - $500,000">$250,001 - $500,000</option>
                            <option value="$500,001 - $1,000,000">$500,001 - $1,000,000</option>
                            <option value="$1,000,001 - $10,000,000">$1,000,001 - $10,000,000</option>
                            <option value="$10,000,001 or More">$10,000,001 or More</option>
                          </select>


                          <small className="text-danger">
                            {!businessDetails?.year_revenue &&
                              formError.year_revenue
                              ? formError.year_revenue
                              : ""}
                          </small>
                        </div>

                        <div className="form-group date">
                          <label className="d-block mb-0">Head Count:</label>

                          <select
                            //name="maritalstatus"
                            //id="maritalstatus"
                            placeholder="Head Count"
                            className="form-control form-control-md form-control-city"
                            value={businessDetails?.numemps}
                            onChange={(e) =>
                              setBusinessDetail({
                                ...businessDetails,
                                numemps: e.target.value,
                              })
                            }
                          >
                            <option value="">Select Head Count</option>
                            <option value="0 - 1">0 - 1</option>
                            <option value="2 - 10">2 - 10</option>
                            <option value="11 - 50">11 - 50</option>
                            <option value="51 - 200">51 - 200</option>
                            <option value="201 - 500">201 - 500</option>
                            <option value="501 - 1,000">501 - 1,000</option>
                            <option value="1,001 - 5,000">1,001 - 5,000</option>
                            <option value="5,001 - 10,000">5,001 - 10,000</option>
                            <option value="10,001 or More">10,001 or More</option>
                          </select>

                          <small className="text-danger">
                            {!businessDetails?.numemps && formError.numemps
                              ? formError.numemps
                              : ""}
                          </small>
                        </div>


                        <div className="form-group date">
                          <label className="d-block mb-0">Website URL:</label>
                          <input
                            className="form-control form-control-md"
                            placeholder="http://www.dominio.com"
                            defaultValue={businessDetails.websiteurl}
                            onBlur={(e) => isUrlCheck(e.target.value)}
                            // onChange={(e) => setFormData({
                            //     ...formData,
                            //     website: e.target.value
                            // })}
                            onChange={() => setWebUrlMsg("")}
                          />
                          <small
                            className="text-danger"
                            style={{ display: "block" }}
                          >
                            {webUrlMsg != ""
                              ? webUrlMsg
                              : formError.websiteurl
                                ? formError.websiteurl
                                : ""}
                          </small>
                          {/* <small className="text-danger">{webUrlMsg}</small> */}
                        </div>






                        <div className="form-group date">
                          <label className="d-block mb-0">Subscription Type:</label>

                          <select
                            //name="maritalstatus"
                            //id="maritalstatus"
                            placeholder="Subscription"
                            className="form-control form-control-md form-control-city"
                            value={businessDetails?.plan_type}
                            onChange={(e) => {
                              setBusinessDetail({
                                ...businessDetails,
                                plan_type: e.target.value,
                              }),
                                setdatesubscription(e.target.value)
                            }
                            }
                          >
                            <option value="0">Select Subscription</option>
                            <option value="1">Free</option>
                            <option value="2">Paid</option>
                            {/* <option value="3">Active Investor</option> */}

                          </select>
                          <small className="text-danger">
                            {!businessDetails?.plan_type || formError.plan_type
                              ? formError.plan_type
                              : ""}
                          </small>

                        </div>
                        {
                          console.log("🚀 ~ PhotoList ~ businessDetails?.plan_type:", businessDetails?.plan_type)
                        }
                        {


                          (businessDetails?.plan_type == '2') ? <>


                            <div className="form-group date">
                              <label className="d-block mb-0">Subscription Options:</label>

                              <select
                                placeholder="Subscription Options"
                                className="form-control form-control-md form-control-city"
                                value={businessDetails?.plan_option}
                                onChange={(e) => {
                                  setBusinessDetail({
                                    ...businessDetails,
                                    plan_option: e.target.value,
                                  })
                                }
                                }
                              >
                                {/* <option value="0">Select Option</option> */}
                                <option value="1">City</option>
                                <option value="2">County</option>
                                <option value="3">State</option>
                                <option value="4">Country</option>
                                {/* <option value="3">Active Investor</option> */}

                              </select>
                              <small className="text-danger">
                                {!businessDetails?.plan_option || formError.plan_option
                                  ? formError.plan_option
                                  : ""}
                              </small>

                            </div>

                            <div className="form-group date">
                              <label className="d-block mb-0">Subscription Effective Date:</label>
                              <input
                                type="date"
                                className="form-control form-control-md"
                                placeholder="Business Email"
                                value={planstartdate}
                                min={moment(new Date()).format("YYYY-MM-DD")}
                                onChange={(e) =>
                                  setPlanstartdate(e.target.value)
                                }
                              //disabled={true}
                              />
                              <small className="text-danger">
                                {!planstartdate || formError.plan_start_date
                                  ? formError.plan_start_date
                                  : ""}
                              </small>
                            </div>

                            <div className="form-group date">
                              <label className="d-block mb-0">Subscription Expiration date:</label>
                              <input
                                type="date"
                                className="form-control form-control-md"
                                placeholder="Business Email"
                                value={planenddate}
                                onChange={(e) =>
                                  setPlanenddate(e.target.value)
                                }
                              //disabled={true}
                              />
                              <small className="text-danger">
                                {!planenddate || formError.plan_expiry_date
                                  ? formError.plan_expiry_date
                                  : ""}
                              </small>
                            </div>
                          </>
                            : null
                        }





                      </div>
                    </div>

                    <div className="col-md-3" style={{ "padding-left": "0%" }}>
                      <div className="col-md-12">
                        <div className="morebgstyle" style={{ "borderBottom": "2px solid", "borderRadius": 0 }}>
                          <div className="form-check">
                            {businessDetails?.showcall == 0 ? (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                onClick={(e) =>
                                  setBusinessDetail({
                                    ...businessDetails,
                                    showcall: 1,
                                  })
                                }
                                checked={
                                  businessDetails.showcall == 0 ? false : true
                                }
                              />
                            ) : (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                onClick={(e) =>
                                  setBusinessDetail({
                                    ...businessDetails,
                                    showcall: 0,
                                  })
                                }
                                checked={true}
                              />
                            )}
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Show Call Button
                            </label>
                          </div>
                          <div className="form-check">
                            {businessDetails?.showtext == 0 ? (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                onClick={(e) =>
                                  setBusinessDetail({
                                    ...businessDetails,
                                    showtext: 1,
                                  })
                                }
                                checked={
                                  businessDetails.showtext == 0 ? false : true
                                }
                              />
                            ) : (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                onClick={(e) =>
                                  setBusinessDetail({
                                    ...businessDetails,
                                    showtext: 0,
                                  })
                                }
                                checked={
                                  businessDetails.showtext == 0 ? false : true
                                }
                              />
                            )}
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Show Text Button
                            </label>
                          </div>
                          <div className="form-check">
                            {businessDetails?.showemail == 0 ? (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                onClick={(e) =>
                                  setBusinessDetail({
                                    ...businessDetails,
                                    showemail: 1,
                                  })
                                }
                                checked={
                                  businessDetails.showemail == 0 ? false : true
                                }
                              />
                            ) : (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                onClick={(e) =>
                                  setBusinessDetail({
                                    ...businessDetails,
                                    showemail: 0,
                                  })
                                }
                                checked={
                                  businessDetails.showemail == 0 ? false : true
                                }
                              />
                            )}
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Show Email Button
                            </label>
                          </div>

                          <div className="form-check">
                            {businessDetails?.is_nonprofit == 0 ? (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                onClick={(e) =>
                                  setBusinessDetail({
                                    ...businessDetails,
                                    is_nonprofit: 1,
                                  })
                                }
                                checked={
                                  businessDetails.is_nonprofit == 0 ? false : true
                                }
                              />
                            ) : (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                onClick={(e) =>
                                  setBusinessDetail({
                                    ...businessDetails,
                                    is_nonprofit: 0,
                                  })
                                }
                                checked={
                                  businessDetails.is_nonprofit == 0 ? false : true
                                }
                              />
                            )}
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Non-Profit
                            </label>
                          </div>

                          <div className="form-check">
                            {businessDetails?.is_minority == 0 ? (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                onClick={(e) =>
                                  setBusinessDetail({
                                    ...businessDetails,
                                    is_minority: 1,
                                  })
                                }
                                checked={
                                  businessDetails.is_minority == 0 ? false : true
                                }
                              />
                            ) : (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                onClick={(e) =>
                                  setBusinessDetail({
                                    ...businessDetails,
                                    is_minority: 0,
                                  })
                                }
                                checked={
                                  businessDetails.is_minority == 0 ? false : true
                                }
                              />
                            )}
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Minority
                            </label>
                          </div>


                        </div>
                        <div className="business-vaildation-style">
                          <div className="form-check">
                            {businessDetails?.verified == 0 ? (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  businessDetails.verified == 0
                                    ? false
                                    : true
                                }
                              />
                            ) : (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  businessDetails.verified == 0
                                    ? false
                                    : true
                                }
                              />
                            )}
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Business verified
                            </label>
                          </div>


                        </div>
                      </div>

                      <div className="form-group col-md-6">
                        <form name="myForm">
                          <label style={{ fontWeight: "bold" }}>
                            Status:
                          </label><br />
                          <input
                            className="ml-1 form-check-input"
                            type="checkbox"
                            id="active"
                            name="myRadios"
                            onChange={(e) =>
                              setBusinessDetail({
                                ...businessDetails,
                                status: e.target.value,
                              })
                            }
                            value="1"
                            checked={businessDetails.status == 1 ? true : false}
                          />
                          <label style={{ "margin-right": "55px" }}
                            for="html" className="ml-4"
                            onClick={() => clickHaveKidsLabel("active")}
                          >
                            Active
                          </label>

                          <input
                            className="ml-1 form-check-input"
                            type="checkbox"
                            id="inactive"
                            name="myRadios"
                            value="0"
                            onChange={(e) =>
                              setBusinessDetail({
                                ...businessDetails,
                                status: e.target.value,
                              })
                            }
                            checked={businessDetails.status == 0 ? true : false}
                          />
                          <label for="css"
                            className="ml-4"
                            onClick={() => clickHaveKidsLabel("inactive")}
                          >
                            Inactive
                          </label>
                          <br />
                        </form>
                        <small className="text-danger">
                          {/*  {!userDetails.havekids && profileFormError.havekids
                            ? profileFormError.havekids
                            : ""} */}
                        </small>
                      </div>

                      <div className="savechangesbtn__ mt-4 pl-2">
                        <button
                          id="submit"
                          type="button"
                          className="main-btn rounded"
                          style={{ minWidth: 160 }}
                          onClick={submitBusinessForm}
                        >
                          {proIsLoader ? (
                            <img
                              src="images/loader_white.gif"
                              style={{ width: "28px" }}
                            />
                          ) : (
                            "Update Status"
                          )}
                        </button>
                      </div>
                      <div className="savechangesbtn__ mt-4 pl-2">
                        <button
                          id="submit"
                          type="button"
                          className="main-btn rounded"
                          data-toggle="modal"
                          data-target="#showCertificate"
                          style={{ minWidth: 160 }}
                        //onClick={submitBusinessForm}
                        >View Business Licence</button>
                      </div>

                      <div className="savechangesbtn__ mt-4 pl-2">
                        {businessDetails.verified == 1 ? (
                          /*  <button
                             id="submit"
                             type="button"
                             className="main-btn rounded"
                             style={{ minWidth: 160 }}
                           >
                             Verified
                           </button> */
                          null
                        ) : businessDetails?.status == 1 && currentstatus == 1 ? (
                          <button
                            id="submit"
                            type="button"
                            className="main-btn rounded"
                            style={{ minWidth: 160 }}
                            onClick={() =>
                              handleSendNotification(businessDetails.profileid)
                            }
                          >
                            {notifyLoader ? (
                              <img
                                src="images/loader_white.gif"
                                style={{ width: "28px" }}
                              />
                            ) : (
                              "Send notification"
                            )}
                          </button>
                        ) : (
                          ""
                        )}
                      </div>






                    </div>
                  </div>
                </form>
              )}
            </div>
          </>}


      </div>
    </>
  );
}

export default PhotoList;
