import { Link, useLocation } from "react-router-dom";
import { useAuth, useAuthData } from "component/context/UserContext";
import { useSelector, useDispatch } from "react-redux";
import {
  setUserAuthData,
  setUserAuthDataImg,
  setBusinessRegisterDetail,
  handleLoginStatus,
} from "../reduxStore/action/usersActions";
import { useState, useEffect } from "react";
import HeaderMenuPrincipal from "./headerMenuPrincipal";
import HeaderMenuBusiness from "./headerMenuBusiness";
import HeaderMenuAdmin from "./headerMenuAdmin";

function Header() {
  const userLoginStatus = useSelector((store) => store.userLoginStatus);
  const userAuthData = useSelector((store) => store.userData);
  const [menushow, setMenushow] = useState(false);

  const dispatch = useDispatch();

  var allinformation = localStorage.getItem("allinformation");
  const { getUser, signOut } = useAuth();
  const location = useLocation();
  const { pathname } = location;

  const userAuth = localStorage.getItem("userAuth");

  const showmodelmenu = () => {
    setMenushow(!menushow);
  };

  async function userSignOut(userType) {
    // await localStorage.setItem('userAuth', null);
    await localStorage.removeItem("userAuth");
    await localStorage.removeItem("token");
    signOut(userType);
    dispatch(setUserAuthData({}));
    dispatch(setBusinessRegisterDetail({}));
    dispatch(handleLoginStatus({ username: "", userType: "user", token: "" }));
  }

  return (
    <>
      <div className="navbar navbar-light navbar-expand-lg justify-content-between bg-white shadow">
        <span className="navbar-brand" style={{ marginLeft: 0 }}>
          <Link to="/home" className="navbar-brand">
            <img
              src="images/logo_name.png"
              alt="header-imagen-logo"
              height="55px"
            />
          </Link>
        </span>
        <span className="navbar-brand-sm">
          <button
            className="navbar-toggler border-white"
            type="button"
            onClick={() => showmodelmenu()}
           /*  data-toggle="collapse"
            data-target="#navbarSupportedContentZ"
            aria-controls="navbarSupportedContentZ"
            aria-expanded={menushow}
            aria-label="Toggle navigation" */
          >
            <img
              src="images/icons/menu.png"
              className="srvc--icon-img"
              height="23px"
            ></img>
          </button>
        </span>
        { console.log("🚀 ~ Header ~ menushow:", menushow)
        //${menushow ? "" : "shomenumobile"}
        }
        <div className={`${menushow ? "shomenumobile" : "hidemenumobile"}` }>
          {userLoginStatus.userType === "business" &&
           
            userLoginStatus.userToken != null ? (
            <div
              className={"navbar-collapse"}
              id="navbarSupportedContentZ"
            >
              <HeaderMenuBusiness
                pathname={pathname}
                allinformation={allinformation}
                userAuthData={userAuthData}
                userSignOut={userSignOut}
                userLoginStatus={userLoginStatus}
                setMenushow={setMenushow} 
                menushow={menushow}
              />
            </div>
          ) : userLoginStatus.userType === "admin" &&
            userLoginStatus.userToken != null ? (
            <div
              className={"navbar-collapse"}
              id="navbarSupportedContentZ"
            >
              <HeaderMenuAdmin
                userLoginStatus={userLoginStatus}
                userSignOut={userSignOut}
              />
            </div>
          ) : (
            <div
              className={"navbar-collapse"}
              id="navbarSupportedContentZ"
            >
              <HeaderMenuPrincipal pathname={pathname} setMenushow={setMenushow} menushow={menushow} />
            </div>
          )}
        </div>
        <div
          className="collapse navbar-collapse principal-menu"
          id="navbar-collapse"
        >
          {userLoginStatus.userType === "business" &&
            userLoginStatus.userToken != null ? (
            <ul className="navbar-nav ml-auto">
              <li className="nav-item mr-4">
                {allinformation == 1 ? (
                  <>
                    <Link
                      style={{ padding: "0px 20px" }}
                      to="/change_password"
                      className={`nav-link ${pathname == "/change_password" && "active"
                        }`}
                    >
                      <img
                        src={
                          userAuthData.userData.img
                            ? userAuthData.userData.img
                            : userAuthData.userData.photofile ==
                              "undefineddefault.png"
                              ? "./images/user.png"
                              : userAuthData.userData.photofile
                                ? userAuthData.userData.photofile
                                : "./images/user.png"
                        }
                        className="user--icon"
                        style={{
                          marginTop: "-4px",
                          width: "30px",
                          height: "30px",
                          borderRadius: "50px",
                        }}
                      />
                      <span className="name-user">
                        {" "}
                        {userAuthData?.userData?.firstname != null
                          ? userAuthData?.userData?.firstname
                          : "" + " " + userAuthData?.userData?.lastname != null
                            ? userAuthData?.userData?.lastname
                            : ""}
                      </span>{" "}
                    </Link>
                  </>
                ) : null}
              </li>
              <li className="nav-item log-out-btn mr-4">
                <a
                  onClick={() => userSignOut(userLoginStatus.userType)}
                  style={{ borderBottom: "0", padding: "0px 10px" }}
                  className="nav-link log--out-btn cursor-pointer"
                >
                  <i className="fa fa-power-off" />
                </a>
              </li>
            </ul>
          ) : userLoginStatus.userType === "admin" &&
            userLoginStatus.userToken != null ? (
            <ul className="navbar-nav ml-auto">
              <li className="nav-item mr-4">
                <span className="nav-link cursor-pointer">
                  <img
                    src="images/user.png"
                    className="user--icon"
                    style={{
                      marginTop: "-4px",
                      width: "36px",
                      height: "30px",
                      borderRadius: "50px",
                    }}
                  />{" "}
                  <span>{userLoginStatus.userName}</span>
                </span>
              </li>
              <li className="nav-item log-out-btn mr-4">
                <a
                  onClick={() => userSignOut()}
                  style={{ borderBottom: "0", padding: "0px 10px" }}
                  className="nav-link log--out-btn cursor-pointer"
                >
                  <i className="fa fa-power-off" />
                </a>
              </li>
            </ul>
          ) : (
            <ul className="nav navbar-nav ml-auto">

              <li className="nav-item mr-4">
                <Link
                  to="/job_list"
                  onClick={() => {
                    //setMenushow(!menushow), 
                    pathname == "/job_list" ? window.location.reload() : null 
                  }}
                  className={`nav-link ${pathname == "/job_list" && "active"}`}
                >
                  Jobs
                </Link>
              </li>


              <li className="nav-item mr-4">
                <Link
                 
                  to="/offer_list"
                  onClick={() => {
                    pathname == "/offer_list" ? window.location.reload() : null 
                  }}
                  className={`nav-link ${pathname == "/offer_list" && "active"}`}
                >
                  Offers
                </Link>
              </li>

            
              {/*<li className="nav-item mr-4">
                <a href="/home#benefits" className="nav-link">Benefits</a>
              </li>
               <li className="nav-item mr-4">
                <a href="/home#testimonials" className="nav-link">Why MAXBIZ</a>
              </li> */}
              <li className={"nav-item mr-4"}>
                <Link
                  to="/join_us"
                  //onClick={() => setMenushow(!menushow)}
                  className={`nav-link ${pathname == "/join_us" && "active"}`}
                >
                  Join
                </Link>
              </li>
             {/*  <li className={"nav-item mr-4"}>
                <Link
                  to="/contact"
                  onClick={() => setMenushow(!menushow)}
                  className={`nav-link ${pathname === "/contact" && "active"}`}
                >
                  Contact
                </Link>
              </li> */}
              <li className={"login__box mr-4"}>
                <Link
                  to="/login"
                  className={"text-white"}
                  // className={`nav-link ${pathname == "/login" && "active"}`}
                  //onClick={() => setMenushow(!menushow)}
                >
                  Login
                </Link>
              </li>
            </ul>
          )}
        </div>
      </div>
    </>
  );
}

export default Header;
