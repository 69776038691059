//Dashboard Screen
import DashboardScreen from 'screen/dashboard';

//Profile Screen
import ChangePasswordScreen from 'screen/profile/changePassword';

import Admindashboard from 'screen/admin/';  

import BusinessDetail from 'screen/admin/businessDetail';
export const routes = [
    { path: '/', exact: true, name: 'dashboard', component: DashboardScreen },
    { path: '/change_password', exact: true, name: 'change_password', component: ChangePasswordScreen },
    // { path: '/adminhome', exact: true, name: 'adminhome', component: Admindashboard },
]

export const adminRoster = [
    { path: '/', exact: true, name: 'adminhome', component: Admindashboard },
    { path: '/businessdetails/:businessid/:profileid', exact: true, name: 'businessDetails', component: BusinessDetail },
]