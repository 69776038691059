import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import base64 from "base-64";
import Loader from "common/loader";
import moment from "moment";
import { Rating } from "react-simple-star-rating";
import LogoSlider from "screen/service/serviceDetail/component/logoSlider";
function jobsList(props) {
  const [showmore, setShowmore] = useState("");
  const moreshow = (jobid) => {
    setShowmore(jobid);
  };
  const [bucket_Img_url, setBucket_Img_url] = useState();
  const [openModal, setOpenModal] = useState(false)
  const [popuptitle, setPopuptitle] = useState("");

  const correctjobdec = (description) => {
    const datahhh = description ? description : null;
    return datahhh.replace(/(\r\n|\n|\r)/g, '<br />');
  }

  const fullviewimage = (imagepath, title) => {
    setOpenModal(!openModal)
    setBucket_Img_url(imagepath)
    setPopuptitle(title)
  }

  const SponsoredCount = props.socListData.length > 0 ? props.socListData.filter(item => item.plan_type === 2) : [];

  const freeCount = props.socListData.length > 0 ? props.socListData.filter(item => item.plan_type === 1) : [];


  
  return (
    <section className="default-smb-area bg--light">
      <div className="container">
        <div className="mb-4">
          <div className="row">
            <div className="col-md-4 mar-top">
              <div className="">
                <label className="Form--lbl">Occupation</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    id="serviceSearch"
                    defaultValue={props.reset != 1 ? props.serviceName : ""}
                    onChange={(e) => {
                      props.handlesocSearch(e.target.value.trimStart());
                    }}
                    onBlur={(e) =>
                      props.manageSearchSoc(e.target.value.trimStart())
                    }
                    placeholder="Electricians, Plumbers, Lawyers"
                    autoComplete="off"
                  />
                  <div>
                    <div
                      className={`city_list_box ${props.socListShow ? "show" : "hide"
                        }`}
                    >
                      {props.searchSocList && props.searchSocList.length > 0 ? (
                        <ul className="city_list_menu">
                          {props.searchSocList.map((item, i) => {
                            return (
                              <li
                                onClick={() => {
                                  props.setSelectedSoc(item.socid);
                                  props.handleSelectSoc(item.title);
                                  props.setFormError({
                                    ...props.formError,
                                    service: "",
                                  });
                                }}
                                className={`city_list ${props.serviceData?.service == item.socid
                                    ? "active"
                                    : ""
                                  }`}
                              >
                                {item.title}
                              </li>
                            );
                          }, [])}
                        </ul>
                      ) : (
                        <p
                          style={{
                            textAlign: "center",
                            marginTop: "2px",
                            color: "gray",
                          }}
                        ></p>
                      )}
                    </div>
                  </div>
                  <small className="text-danger">
                    {props.formError?.service ? props.formError?.service : ""}
                  </small>
                </div>
              </div>
            </div>
            <div className="col-md-4 mar-top">
              <div className="">
                <label className="Form--lbl">City</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    id="citySearch"
                    defaultValue={props.reset != 1 ? props.cityName : ""}
                    onChange={(e) => {
                      props.handleCitySearch(e.target.value);
                    }}
                    onBlur={(e) => props.manageSearchCity(e.target.value)}
                    placeholder="Boca Raton, Miami, Orlando"
                    autoComplete="off"
                  />
                  <div>
                    <div
                      className={`city_list_box ${props.cityListShow ? "show" : "hide"
                        }`}
                    >
                      {props.searchCityList &&
                        props.searchCityList.length > 0 ? (
                        <ul className="city_list_menu">
                          {props.searchCityList.map((item, i) => {
                            return (
                              <li
                                onClick={() => {
                                  props.setSelectedCity(item.id);
                                  props.setCityListShow(false);
                                  props.handleSelectCity(
                                    item.city,
                                    item.state_id
                                  );
                                  props.setFormError({
                                    ...props.formError,
                                    city: "",
                                  });
                                }}
                                className={`city_list ${props.serviceData?.city == item.id
                                    ? "active"
                                    : ""
                                  }`}
                              >
                                {item.city}, {item.state_id}
                              </li>
                            );
                          }, [])}
                        </ul>
                      ) : (
                        <p
                          style={{
                            textAlign: "center",
                            marginTop: "2px",
                            color: "gray",
                          }}
                        ></p>
                      )}
                    </div>
                  </div>
                  <small className="text-danger">
                    {props.formError?.city ? props.formError?.city : ""}
                  </small>
                </div>
              </div>
            </div>
            <div className="col-md-2 mar-top">
              <button
                style={{ "marginTop": "22px" }}
                type="button"
                className="btn main-btn w-100"
                onClick={() => props.searchSocDataList()}
              >
                Search Jobs
              </button>
            </div>
            {/*   <div className="col-md-2 mar-top">
              <button
                type="button"
                className="btn main-btn w-100"
                onClick={() => props.resetDetail()}
              >
                Reset
              </button>
            </div> */}
            {/* <div className="col-md-2">
              <button
                type="button"
                className="btn main-btn w-100"
                onClick={() => props.resetDetail()}
              >
                Reset
              </button>
            </div> */}
          </div>
        </div>
        {openModal ? <LogoSlider
          bucket_Img_url={bucket_Img_url}
          setOpenModal={setOpenModal}
          popuptitle={popuptitle}
        /> : ""}
        <div className="">
          {!props.resetIsLoading ? (
            <div>
              <div className="page-container border-bottom-0">
                <div className="row">
                  {SponsoredCount.length > 0 ? <h5 style={{ "color": "#6258d3", "fontSize": "12pt" }}>Sponsored</h5> : null}

                  {props.socListData && props.socListData.length > 0 ? (

                    (SponsoredCount.length > 0) ?
                      SponsoredCount.map((item, i) => (
                        <div
                          className="page-content col-xl-6 mb-6 mb-xl-0"
                          key={i}
                        >
                          <div className="widget mb-8">
                            <div className="rm-listings">
                              <div className="card border-8 shadow mb-3">
                                <div
                                  className="card-header bg-white border-t-8"
                                  style={{
                                    borderBottom: "none",
                                    marginBottom: "-10px",
                                  }}
                                >
                                  <div
                                    className="bg--smb w-100 p-2"
                                    style={{ borderRadius: "10px" }}
                                  >
                                    <div className="d-flex justify-content-between row">
                                      <div className="col-md-9">
                                        <h5 className="m-0 text-white">
                                          {item.business_name}
                                        </h5>
                                      </div>

                                      <div className="col-md-3">
                                        <span
                                          className="badge bg-white p-1"
                                          style={{ borderRadius: "30px" }}
                                        >
                                          <small className="srvc--text">
                                            {/*  Rating ({Math.round(item.ranting)}) */}
                                            <div
                                              className="d-flex"
                                              style={{
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Rating initialValue={Math.round(item.ranting)} readonly="true" size="18" />
                                            
                                            </div>
                                          </small>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-md-3">
                                      <div className="ser_img_lst">
                                        <img
                                          
                                          src={
                                            item.aws_url
                                              ? item.aws_url
                                              : "images/no_image.png"
                                          }
                                          className="card-img rounded pointer-show"
                                          alt="Business Image"
                                          onClick={() => fullviewimage(item.aws_url
                                            ? item.aws_url
                                            : "images/no_image.png", item?.title)}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-9">
                                      <div className="srvc--containt card-body p-0">
                                        <small className="srvc--text serice--list-text">
                                          {item.brand_text}
                                        </small>
                                        <small>
                                          {item?.title != null ? (
                                            <span className="service--amunt-rigt">
                                              Job Title: {item?.title}
                                            </span>
                                          ) : null}
                                        </small>
                                        <small className="srvc--text serice--list-text">
                                          <b>Occupation:</b>&nbsp;{" "}
                                          {item?.occtitle}
                                        </small>
                                        <small className="srvc--text serice--list-text">
                                          <b>Industry:</b>&nbsp;{" "}
                                          {item?.industryname}
                                        </small>
                                        <small className="srvc--text serice--list-text">
                                          <b>Start/End Dates:</b>&nbsp;{" "}
                                          {moment(item?.jobstartdate).format(
                                            "MM-DD-YYYY"
                                          )}
                                          &nbsp;/&nbsp;
                                          {moment(item?.expirationdate).format(
                                            "MM-DD-YYYY"
                                          )}
                                        </small>

                                        {/* <small className="srvc--text serice--list-text">
                                       <b>Expiration Date:</b>&nbsp;{item?.expirationdate}
                                       </small> */}

                                        <small className="srvc--text serice--list-text">
                                          <b>City:</b>&nbsp;{item?.city},&nbsp;
                                          {item?.state_name}
                                        </small>
                                        <small
                                          className={
                                            "srvc--text serice--list-text" +
                                            (item?.jobid === showmore
                                              ? " more-desc"
                                              : " lim-desc")
                                          }
                                        >
                                          {/* <b>Service:</b>&nbsp;{item.naics_name} */}
                                          <b>Description:</b>&nbsp;{" "}
                                          <div dangerouslySetInnerHTML={{ __html: correctjobdec(item?.description) }}></div>
                                          {/* {item?.description} */}

                                        </small>

                                        <small className="srvc--text serice--list-text more-lable">
                                          {/* <span onClick={(jobid) => moreshow(item?.jobid)}>More</span> */}

                                          {item?.jobid === showmore ? (
                                            <b onClick={(jobid) => moreshow(0)}>
                                              {"Less >>"}
                                            </b>
                                          ) : (
                                            <b
                                              onClick={(jobid) =>
                                                moreshow(item?.jobid)
                                              }
                                            >
                                              {"Show More >>"}
                                            </b>
                                          )}
                                        </small>
                                        <small className="srvc--text serice--list-text">
                                          <b>Note:</b>&nbsp; <p style={{ display: "inline", color: "#6258d3" }}> To apply for this job
                                            simply click on Email and attach your
                                            resume </p>
                                        </small>
                                      
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer text-muted bg-white border-b-8">
                                  <div className="d-flex justify-content-between">
                                    <div>
                                      
                                    <label
                                        className="business__card-badge mr-1"
                                        style={{
                                          backgroundColor: "#6258d3",
                                        }}
                                      >
                                        <Link
                                          to={`/${item?.businessUrlName
                                            .replace(/\s+/g, "")
                                            .toLowerCase()}`}
                                          className="business__card-badge--p"
                                          style={{
                                            color: "#ffff"}}
                                        >
                                          <i class="fa fa-eye"></i>{" "}
                                          <small
                                            className="p__text"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Detail
                                          </small>
                                        </Link>
                                      </label>

                                      {item.showtext > 0 ? (
                                        <label className="business__card-badge mr-1">
                                          <Link
                                            to="#"
                                            onClick={(e) => {
                                              window.location = `sms:${item.phone}?&body=<<Type here your message for this job>>`;
                                              e.preventDefault();
                                            }}
                                          >
                                            <p className="business__card-badge--p">
                                              <i className="fa fa-comments"></i>{" "}
                                              <small
                                                className="p__text"
                                                style={{ fontSize: "14px" }}
                                              >
                                                Text
                                              </small>
                                            </p>
                                          </Link>
                                        </label>
                                      ) : (
                                        ""
                                      )}

                                      {item.showcall > 0 ? (
                                        <label className="business__card-badge mr-1">
                                          <Link
                                            to="#"
                                            onClick={(e) => {
                                              window.location = `tel:${item.phone}`;
                                              e.preventDefault();
                                            }}
                                          >
                                            <p className="business__card-badge--p">
                                              <i className="fa fa-phone"></i>{" "}
                                              <small
                                                className="p__text"
                                                style={{ fontSize: "14px" }}
                                              >
                                                Call
                                              </small>
                                            </p>
                                          </Link>
                                        </label>
                                      ) : (
                                        ""
                                      )}

                                      {item.showemail > 0 ? (
                                        <label className="business__card-badge mr-1">
                                          <a
                                            target="_blank"
                                            href={`mailto:${item.email}?&subject=Application for JOB&body=<<Type here the information you want to Apply for this job>>`}
                                          >
                                            <p className="business__card-badge--p">
                                              <i className="fa fa-envelope"></i>{" "}
                                              <small
                                                className="p__text"
                                                style={{ fontSize: "14px" }}
                                              >
                                                Email
                                              </small>
                                            </p>
                                          </a>
                                        </label>
                                      ) : (
                                        ""
                                      )}

                                      <label className="business__card-badge mr-1">
                                        <a target="_blank" href={item.websiteurl}>
                                          <p className="business__card-badge--p">
                                            <i class="fa fa-globe"></i>{" "}
                                            <small
                                              className="p__text"
                                              style={{ fontSize: "14px" }}
                                            >
                                              Web
                                            </small>
                                          </p>
                                        </a>
                                      </label>

                                      <label className="business__card-badge mr-1">
                                        <a
                                          target="_blank"
                                          href={`https://www.google.com/maps/search/${item.address}`}
                                        >
                                          <p className="business__card-badge--p">
                                            <i class="fa fa-map"></i>{" "}
                                            <small
                                              className="p__text"
                                              style={{ fontSize: "14px" }}
                                            >
                                              Map
                                            </small>
                                          </p>
                                        </a>
                                      </label>

                                      {/*<label
                                        className="business__card-badge mr-1"
                                        style={{
                                          backgroundColor: "#6258d3",
                                        }}
                                      >
                                         <Link
                                          to={`/${item?.businessUrlName
                                            .replace(/\s+/g, "")
                                            .toLowerCase()}`}
                                          className="business__card-badge--p"
                                          style={{
                                            color: "#ffff"}}
                                        >
                                          <i class="fa fa-eye"></i>{" "}
                                          <small
                                            className="p__text"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Detail
                                          </small>
                                        </Link>
                                      </label> */}

                                       <label className="business__card-badge mr-1">
                                        <a target="_blank" href={`mailto:mbt@maxelit.com?&subject=Suspicious Content&body=Dear MAXBIZ Team:%0D%0A%0D%0APlease review the following suspicious content: <<INCLUDE PAGE, SUSPICIOUS CONTENT AND ANY OTHER RELEVANT DETAILS>>.%0D%0A%0D%0ARegards,%0D%0A%0D%0A<<YOUR FULL NAME>>%0D%0A<<YOUR PHONE NUMBER>>`}>
                                          <p className="business__card-badge--p">
                                            <i class="fa fa-flag"></i> <small className="p__text" style={{ fontSize: "14px" }}>Report</small>
                                          </p>
                                        </a>
                                      </label> 
                                      
                                    </div>

                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* </Link> */}
                        </div>
                      )) : null
                    ) : (
                    <div className="text-center" style={{ "backgroundColor": "white", "height": "450px" }}>
                      <img src="images/nodatafound.png" />
                      <span className="not-found-content">Ooops, no jobs found</span>
                    </div>
                  )}
                </div>
                { //for Free Business Job
                }
                <div className="row">
                  {freeCount && freeCount.length > 0 ? <div className="borderfree"></div> : null}

                  {
                    props.socListData && props.socListData.length > 0 ? (

                      (freeCount.length > 0) ?
                        freeCount.map((item, i) => (
                          <div
                            className="page-content col-xl-6 mb-6 mb-xl-0"
                            key={i}
                          >
                            <div className="widget mb-8">
                              <div className="rm-listings">
                                <div className="card border-8 shadow mb-3">
                                  <div
                                    className="card-header bg-white border-t-8"
                                    style={{
                                      borderBottom: "none",
                                      marginBottom: "-10px",
                                    }}
                                  >
                                    <div
                                      className="bg--smb w-100 p-2"
                                      style={{ borderRadius: "10px" }}
                                    >
                                      <div className="d-flex justify-content-between row">
                                        <div className="col-md-9">
                                          <h5 className="m-0 text-white">
                                            {item.business_name}
                                          </h5>
                                        </div>

                                        <div className="col-md-3">
                                          <span
                                            className="badge bg-white p-1"
                                            style={{ borderRadius: "30px" }}
                                          >
                                            <small className="srvc--text">
                                              {/*  Rating ({Math.round(item.ranting)}) */}
                                              <div
                                                className="d-flex"
                                                style={{
                                                  justifyContent: "space-between",
                                                }}
                                              >
                                                <Rating initialValue={Math.round(item.ranting)} readonly="true" size="18" />
                                               
                                              </div>
                                            </small>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col-md-3">
                                        <div className="ser_img_lst">
                                          <img
                                            
                                            src={
                                              item.aws_url
                                                ? item.aws_url
                                                : "images/no_image.png"
                                            }
                                            className="card-img rounded pointer-show"
                                            alt="Business Image"
                                            onClick={() => fullviewimage(item.aws_url
                                              ? item.aws_url
                                              : "images/no_image.png", item?.title)}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-9">
                                        <div className="srvc--containt card-body p-0">
                                          <small className="srvc--text serice--list-text">
                                            {item.brand_text}
                                          </small>
                                          <small>
                                            {item?.title != null ? (
                                              <span className="service--amunt-rigt">
                                                Job Title: {item?.title}
                                              </span>
                                            ) : null}
                                          </small>
                                          <small className="srvc--text serice--list-text">
                                            <b>Occupation:</b>&nbsp;{" "}
                                            {item?.occtitle}
                                          </small>
                                          <small className="srvc--text serice--list-text">
                                            <b>Industry:</b>&nbsp;{" "}
                                            {item?.industryname}
                                          </small>
                                          <small className="srvc--text serice--list-text">
                                            {/* <b>Service:</b>&nbsp;{item.naics_name} */}
                                            <b>Start/End Dates:</b>&nbsp;{" "}
                                            {moment(item?.jobstartdate).format(
                                              "MM-DD-YYYY"
                                            )}
                                            &nbsp;/&nbsp;
                                            {moment(item?.expirationdate).format(
                                              "MM-DD-YYYY"
                                            )}
                                          </small>

                                         <small className="srvc--text serice--list-text">
                                            <b>City:</b>&nbsp;{item?.city},&nbsp;
                                            {item?.state_name}
                                          </small>
                                          <small
                                            className={
                                              "srvc--text serice--list-text" +
                                              (item?.jobid === showmore
                                                ? " more-desc"
                                                : " lim-desc")
                                            }
                                          >
                                            {/* <b>Service:</b>&nbsp;{item.naics_name} */}
                                            <b>Description:</b>&nbsp;{" "}
                                            <div dangerouslySetInnerHTML={{ __html: correctjobdec(item?.description) }}></div>
                                            {/* {item?.description} */}

                                          </small>

                                          <small className="srvc--text serice--list-text more-lable">
                                            {/* <span onClick={(jobid) => moreshow(item?.jobid)}>More</span> */}

                                            {item?.jobid === showmore ? (
                                              <b onClick={(jobid) => moreshow(0)}>
                                                {"Less >>"}
                                              </b>
                                            ) : (
                                              <b
                                                onClick={(jobid) =>
                                                  moreshow(item?.jobid)
                                                }
                                              >
                                                {"Show More >>"}
                                              </b>
                                            )}
                                          </small>
                                          <small className="srvc--text serice--list-text">
                                            <b>Note:</b>&nbsp; <p style={{ display: "inline", color: "#6258d3" }}> To apply for this job
                                              simply click on Email and attach your
                                              resume </p>
                                          </small>
                                        
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card-footer text-muted bg-white border-b-8">
                                    <div className="d-flex justify-content-between">
                                      <div>
                                     {/*  <label
                                          className="business__card-badge"
                                          style={{
                                            backgroundColor: "#6258d3",
                                          }}
                                        >
                                          <Link
                                            to={`/${item?.businessUrlName
                                              .replace(/\s+/g, "")
                                              .toLowerCase()}`}
                                            className="business__card-badge--p"
                                            style={{
                                              color: "#ffff",
                                             }}
                                          >
                                            <i class="fa fa-eye"></i>{" "}
                                            <small
                                              className="p__text"
                                              style={{ fontSize: "14px" }}
                                            >
                                              Detail
                                            </small>
                                          </Link>
                                        </label> */}
                                        {item.showtext > 0 ? (
                                          <label className="business__card-badge mr-1">
                                            <Link
                                              to="#"
                                              onClick={(e) => {
                                                window.location = `sms:${item.phone}?&body=<<Type here your message for this job>>`;
                                                e.preventDefault();
                                              }}
                                            >
                                              <p className="business__card-badge--p">
                                                <i className="fa fa-comments"></i>{" "}
                                                <small
                                                  className="p__text"
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  Text
                                                </small>
                                              </p>
                                            </Link>
                                          </label>
                                        ) : (
                                          ""
                                        )}

                                        {item.showcall > 0 ? (
                                          <label className="business__card-badge mr-1">
                                            <Link
                                              to="#"
                                              onClick={(e) => {
                                                window.location = `tel:${item.phone}`;
                                                e.preventDefault();
                                              }}
                                            >
                                              <p className="business__card-badge--p">
                                                <i className="fa fa-phone"></i>{" "}
                                                <small
                                                  className="p__text"
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  Call
                                                </small>
                                              </p>
                                            </Link>
                                          </label>
                                        ) : (
                                          ""
                                        )}

                                        {item.showemail > 0 ? (
                                          <label className="business__card-badge mr-1">
                                            <a
                                              target="_blank"
                                              href={`mailto:${item.email}?&subject=Application for JOB&body=<<Type here the information you want to Apply for this job>>`}
                                            >
                                              <p className="business__card-badge--p">
                                                <i className="fa fa-envelope"></i>{" "}
                                                <small
                                                  className="p__text"
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  Email
                                                </small>
                                              </p>
                                            </a>
                                          </label>
                                        ) : (
                                          ""
                                        )}

                                        <label className="business__card-badge mr-1">
                                          <a target="_blank" href={item.websiteurl}>
                                            <p className="business__card-badge--p">
                                              <i class="fa fa-globe"></i>{" "}
                                              <small
                                                className="p__text"
                                                style={{ fontSize: "14px" }}
                                              >
                                                Web
                                              </small>
                                            </p>
                                          </a>
                                        </label>

                                        <label className="business__card-badge mr-1">
                                          <a
                                            target="_blank"
                                            href={`https://www.google.com/maps/search/${item.address}`}
                                          >
                                            <p className="business__card-badge--p">
                                              <i class="fa fa-map"></i>{" "}
                                              <small
                                                className="p__text"
                                                style={{ fontSize: "14px" }}
                                              >
                                                Map
                                              </small>
                                            </p>
                                          </a>
                                        </label>
                                        <label
                                          className="business__card-badge"
                                          style={{
                                            backgroundColor: "#6258d3",
                                          }}
                                        >
                                          <Link
                                            to={`/${item?.businessUrlName
                                              .replace(/\s+/g, "")
                                              .toLowerCase()}`}
                                            className="business__card-badge--p"
                                            style={{
                                              color: "#ffff",
                                             }}
                                          >
                                            <i class="fa fa-eye"></i>{" "}
                                            <small
                                              className="p__text"
                                              style={{ fontSize: "14px" }}
                                            >
                                              Detail
                                            </small>
                                          </Link>
                                        </label>

                                       {/*  <label className="business__card-badge mr-1">
                                        <a target="_blank" href={`mailto:mbt@maxelit.com?&subject=Suspicious Content&body=Dear MAXBIZ Team:%0D%0A%0D%0APlease review the following suspicious content: <<INCLUDE PAGE, SUSPICIOUS CONTENT AND ANY OTHER RELEVANT DETAILS>>.%0D%0A%0D%0ARegards,%0D%0A%0D%0A<<YOUR FULL NAME>>%0D%0A<<YOUR PHONE NUMBER>>`}>
                                          <p className="business__card-badge--p">
                                            <i class="fa fa-flag"></i> <small className="p__text" style={{ fontSize: "14px" }}>Report</small>
                                          </p>
                                        </a>
                                      </label> */}
                                       
                                      </div>

                                      {/*  <Link
                                    to={`/${item?.businessUrlName
                                      .replace(/\s+/g, "")
                                      .toLowerCase()}/JOB`}
                                    style={{
                                      border: "1px solid #6258d3",
                                      borderRadius: "4px",
                                      color: "white",
                                      padding: "3px 12px",
                                      backgroundColor: "#6258d3"
                                    }}
                                  >
                                    View Details
                                  </Link> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* </Link> */}
                          </div>

                        )) :
                        null
                    ) : null}

                </div>
              </div>
            </div>
          ) : (
            <div className="text-center">
              {" "}
              <div className="" style={{ height: "300px" }}>
                <div className="lds-ellipsis">
                  <span />
                  <span />
                  <span />
                </div>
              </div>{" "}
            </div>
          )}

          {((props.socListData && props.socListData.length > 9) || props.itemOffset > 0) && (
            <div className="btm-pagination">
              <div className="d-flex justify-content-end">
                <ReactPaginate
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={props.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={4}
                  onPageChange={props.handlePageClick}
                  containerClassName={
                    "pagination pagination-style-02 justify-content-center"
                  }
                  subContainerClassName={"pages pagination"}
                  activeClassName={"current active active_pg"}
                  activeLinkClassName={"current active active_pg"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link bg-gray"}
                  previousClassName={"page-item"}
                  nextClassName={"page-item"}
                  previousLinkClassName={"page-link bg-gray"}
                  nextLinkClassName={"page-link bg-gray"}
                  previousLabel={<i className="fal fa-chevron-left"></i>}
                  nextLabel={<i className="fal fa-chevron-right"></i>}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
export default jobsList;
