import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import ServiceListScreen from "./component/serviceList";
import { apiCall } from "../../../utils/httpClient";
import ENDPOINTS from "../../../utils/apiEndPoints";
import Loader from "common/loader";
import { useDispatch, useSelector } from "react-redux";
import base64 from "base-64";
import apiEndPoints from "../../../utils/apiEndPoints";
import { serchServiceCitySelect } from "../../../reduxStore/action/usersActions";

function ServiceList() {
  const resetRef = useRef(0)
  const serviceRef = useRef([])
  const dispatch = useDispatch();

  const [seachServiceData, setSeachServiceData] = useState({
    city_id: "",
    service_id: "",
    city_name: "",
  });

  //const searchServic = useSelector((store) => store.searchService);
  const serviceRoute = useSelector((store) => store.serviceDetail);

  const { service_id, city_id, city_name, service_name } = useParams();

  const [serviceListData, setServiceListData] = useState([]);

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [itemOffset, setItemOffset] = useState("0");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [baseUrl, setBaseUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [resetIsLoading, setResetIsLoading] = useState(false);
  const [citiesList, setCitiesList] = useState([]);
  const [serviceList, setServiceList] = useState(serviceRef.current);

  const [selectedCity, setSelectedCity] = useState("");
  const [selectedService, setSelectedService] = useState("");

  const [searchCityList, setSearchCityList] = useState([]);
  const [cityListShow, setCityListShow] = useState(false);
  const [cityName, setCityName] = useState("");
  const [serviceName, setServiceName] = useState("");

  const [searchServiceList, setSearchServiceList] = useState([]);
  const [serviceListShow, setServiceListShow] = useState(false);
  const [selectServiceName, setSelectServiceName] = useState("");
  const [formError, setFormError] = useState();
  // const [bucket_Img_url, setBucket_Img_url] = useState();
  useEffect(() => {
    // setCityName(base64.decode(serviceRoute.serviceDetail.cityname))
    // setServiceName(base64.decode(serviceRoute.serviceDetail.servicename))
    setSelectedCity(serviceRoute.serviceDetail.city);
    setSelectedService(serviceRoute.serviceDetail.service);
    setServiceName(serviceRoute.serviceDetail.servicename);
    setCityName(serviceRoute.serviceDetail.cityname);
    getServiceDataList(itemOffset);
  }, []);

  async function getServiceDataList(offset) {

    if (resetRef.current != 1) {
      dispatch(
        serchServiceCitySelect({
          service:
            selectedService != ""
              ? selectedService
              : serviceRoute.serviceDetail.service,
          servicename:
            selectServiceName != ""
              ? selectServiceName
              : serviceRoute.serviceDetail.servicename,
          city:
            selectedCity != "" ? selectedCity : serviceRoute.serviceDetail.city,
          cityname:
            cityName != "" ? cityName : serviceRoute.serviceDetail.cityname,
        })
      );
      var params = {
        serviceid:
          selectedService != ""
            ? selectedService
            : serviceRoute.serviceDetail.service,
        cityid:
          selectedCity != "" ? selectedCity : serviceRoute.serviceDetail.city,
        limit: itemsPerPage,
        offset: String(offset),
      };
    } else {

      var params = {
        serviceid: "",
        cityid: "",
        limit: itemsPerPage,
        offset: offset,
      };
      setSelectedService("");
      setSelectedCity("");
    }
    setIsLoading(true);
    setResetIsLoading(true);
    const { data } = await apiCall(
      "POST",
      ENDPOINTS.GETLISTSERVICESSEARCH,
      params
    );
    if (data.status == 200) {
      setServiceListData(data.data.services);
      // dispatch(setServiceBusinessData(data.data));
      // setBaseUrl(data.base_url);//eliminar linea
      setPageCount(Math.ceil(data.data.total_data / itemsPerPage));
      setIsLoading(false);
      setResetIsLoading(false)
    } else {
      setServiceListData([]);
      setIsLoading(false);
      setResetIsLoading(false)
    }
  }
  const handlePageClick = (event) => {
    // const newOffset = (event.selected * itemsPerPage) % serviceListData.length;
    const newOffset = event.selected * itemsPerPage;
    setItemOffset(newOffset);
    getServiceDataList(newOffset);
  };



  // -------------------------------------------- city ----------------------------------

  function handleSelectCity(cityname, state_id) {

    document.getElementById("citySearch").value = cityname + ', ' + state_id;
    setCityName(cityname + ', ' + state_id);
    setCityListShow(false);
    setSearchCityList([]);
  }

  async function handleCitySearch(city = "") {
    if (city != "" && city.length >= 3) {
      // console.log('search ==>', city)
      const params = { cityname: city };
      const { data } = await apiCall(
        "POST",
        apiEndPoints.GETCITIESLIST,
        params
      );
      if (data.status == 200) {
        setSearchCityList(data.data);
      } else {
        setSearchCityList([]);
      }
      setCityListShow(true);
    } else {
      setSearchCityList([]);
      setSelectedCity("");
      setCityListShow(false);
    }
  }

  async function handleServiceSearch(item) {
    if (item != '' && item.length >= 3) {
      const params = { servicename: item };
      const { data } = await apiCall(
        "POST",
        apiEndPoints.GETSERVICELIST,
        params
      );
      if (data.status == 200) {
        setSearchServiceList(data.data);
      } else {
        setSearchServiceList([]);
      }
      setServiceListShow(true);
    } else {
      serviceRef.current = []
      setServiceListShow(false);
      setSearchServiceList([]);
      setSelectedService('');
    }
  }

  function handleSelectService(item) {
    setSelectServiceName(item);
    document.getElementById("serviceSearch").value = item;
    setServiceListShow(false);
    setSearchServiceList([]);
  }

  function resetDetail() {
    resetRef.current = 1
    dispatch(
      serchServiceCitySelect({
        service: "",
        servicename: "",
        city: "",
        cityname: "",
      })
    );
    setCityName('')
    setServiceName("")
    document.getElementById('citySearch').value = ''
    document.getElementById('serviceSearch').value = ''
    setFormError({})
    getServiceDataList(itemOffset);
  }


  function searchValidation() {
    let error = {};
    let formErrorData = false;

    if (!selectedService) {
      formErrorData = true;
      error["service"] = "Occupation is required";
    }
    if (!selectedCity) {
      formErrorData = true;
      error["city"] = "City is required";
    }
    // if (!serviceRoute.serviceDetail.service && !selectedService) {
    //   formErrorData = true;
    //   error["service"] = "Service is required";
    // }
    // if (!serviceRoute.serviceDetail.city && !selectedCity) {
    //   formErrorData = true;
    //   error["city"] = "City is required";
    // }
    setFormError(error);
    return formErrorData;
  }
  function searchServiceDataList() {
    if (!searchValidation()) {
      resetRef.current = 0
      getServiceDataList('0')
    }
  }


  function manageSearchService(item) {
    if (item == '') {
      setSelectedService('')
      setServiceListShow(false);
      setSearchServiceList([]);
    }
  }

  function manageSearchCity(item) {
    if (item == '') {
      setSelectedCity('')
      setSearchCityList([]);
      setCityListShow(false);
    }
  }

  return (
    <ServiceListScreen
      resetDetail={resetDetail}
      serviceRoute={serviceRoute}
      setCityListShow={setCityListShow}
      cityListShow={cityListShow}
      handleSelectCity={handleSelectCity}
      handleCitySearch={handleCitySearch}
      setSearchCityList={setSearchCityList}
      searchCityList={searchCityList}
      getServiceDataList={getServiceDataList}
      serviceListData={serviceListData}
      handlePageClick={handlePageClick}
      pageCount={pageCount}
      seachServiceData={seachServiceData}
      setSeachServiceData={setSeachServiceData}
      baseUrl={baseUrl}
      citiesList={citiesList}
      serviceList={serviceList}
      setSelectedCity={setSelectedCity}
      selectedCity={selectedCity}
      setSelectedService={setSelectedService}
      selectedService={selectedService}
      cityName={cityName}
      serviceName={serviceName}
      handleServiceSearch={handleServiceSearch}
      searchServiceList={searchServiceList}
      serviceListShow={serviceListShow}
      handleSelectService={handleSelectService}
      isLoading={isLoading}
      resetIsLoading={resetIsLoading}
      searchServiceDataList={searchServiceDataList}
      formError={formError}
      setFormError={setFormError}
      manageSearchService={manageSearchService}
      itemOffset={itemOffset}
      manageSearchCity={manageSearchCity}
    />
  );
}
export default ServiceList;
