import React, { useState, useEffect } from "react";
import HomeScreen from "./component/home";
import { useHistory } from "react-router-dom";
import { useSearch } from "component/context/SearchContext";
import { apiCall } from "../../utils/httpClient";
import apiEndPoints from "utils/apiEndPoints";
import base64 from "base-64";
import { useDispatch } from "react-redux";
import { serchServiceCitySelect } from "../../reduxStore/action/usersActions";
function Home() {
  const dispatch = useDispatch();
  let history = useHistory();
  const { searchService, searchData } = useSearch();
  const [baseUrl, setBaseUrl] = useState("");
  const [topService, setTopService] = useState([]);
  const [formError, setFormError] = useState({});
  const [selectedCity, setSelectedCity] = useState({});
  const [selectedService, setSelectedService] = useState('');
  const [citiesList, setCitiesList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [searchCityList, setSearchCityList] = useState([]);
  const [cityListShow, setCityListShow] = useState(false);
  const [searchServiceList, setSearchServiceList] = useState([]);
  const [serviceListShow, setServiceListShow] = useState(false);
  const [serviceData, setServiceData] = useState({
    service: "",
    servicename: "",
    city: "",
    cityname: "",
  });

  const [searchServices, setSearchServices] = useState('')

  useEffect(() => {
    //getTopServices();
    getServiceList();
  }, []);

  const getTopServices = async () => {
    const { data } = await apiCall(
      "GET",
      apiEndPoints.GETTOPSERVICEBUSINESSLIST
    );
    if (data.status == 200) {
      setTopService(data.data);
      setBaseUrl(data.base_url);
    }
  };

  const getServiceList = async () => {
    try {
      const params = { servicename: '' };
      const { data } = await apiCall("POST", apiEndPoints.GETSERVICELIST,params);
      

      if (data.status == 200) {
        setServiceList(data.data);
      } else {
        setServiceList([]);
      }
    } catch (error) { }
  };

  function validation() {
    let error = {};
    let formErrorData = false;

    if (!serviceData?.service) {
      formErrorData = true;
      error["service"] = "Occupation is required";
    }
    if (!serviceData?.city) {
      formErrorData = true;
      error["city"] = "City is required";
    }
    setFormError(error);
    return formErrorData;
  }
  async function navToList() {
    if (!validation()) {
      dispatch(serchServiceCitySelect(serviceData));
      history.push(`/service_list`);
      //searchService();
    }
  }


  async function handleCitySearch(city) {
    if (city != '' && city.length >= 3) {
      const params = { cityname: city };
      const { data } = await apiCall(
        "POST",
        apiEndPoints.GETCITIESLIST,
        params
      );
      if (data.status == 200) {
        setSearchCityList(data.data);
      } else {
        setSearchCityList([]);
      }
      setCityListShow(true);
    } else {
      setCityListShow(false);
      setSearchCityList([]);
      setServiceData({
        ...serviceData,
        city: '',
        cityname: '',
      });
    }
  }

  function handleSelectCity(cityname,state_id) {
    document.getElementById("citySearch").value = cityname+', '+state_id;
    setCityListShow(false);
    setSearchCityList([]);
  }

  async function handleServiceSearch(item) {
    // if (searchServices != '') {

      if (item != '' && item.length >= 3) {
        const params = { servicename: item };
        const { data } = await apiCall(
          "POST",
          apiEndPoints.GETSERVICELIST,
          params
        );
        if (data.status == 200) {
          setSearchServiceList(data.data);
        } else {
          setSearchServiceList([]);
        }
        setServiceListShow(true);
      } else {
        setServiceListShow(false);
        setSearchServiceList([]);
        setServiceData({
          ...serviceData,
          service: '',
          servicename: ''
        });
      }
    // } else {
    //   setServiceListShow(false);
    //     setSearchServiceList([]);
    //     setServiceData({
    //       ...serviceData,
    //       service: '',
    //       servicename: ''
    //     });
    // }
  }
  function handleSelectService(item) {
    document.getElementById("serviceSearch").value = item;
    setServiceListShow(false);
    setSearchServiceList([]);
  }
  return (
    <HomeScreen
      navToList={navToList}
      topService={topService}
      setServiceData={setServiceData}
      serviceData={serviceData}
      formError={formError}
      citiesList={citiesList}
      serviceList={serviceList}
      baseUrl={baseUrl}
      setSelectedCity={setSelectedCity}
      selectedCity={selectedCity}
      setSelectedService={setSelectedService}
      selectedService={selectedService}
      handleCitySearch={handleCitySearch}
      handleSelectCity={handleSelectCity}
      cityListShow={cityListShow}
      searchCityList={searchCityList}
      setCityListShow={setCityListShow}
      handleServiceSearch={handleServiceSearch}
      serviceListShow={serviceListShow}
      searchServiceList={searchServiceList}
      handleSelectService={handleSelectService}
      setServiceListShow={setServiceListShow}
      setSearchServices={setSearchServices}
    />
  );
}

export default Home;