import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import OfferListScreen from "./component/offerList";
import { apiCall } from "../../../utils/httpClient";
import ENDPOINTS from "../../../utils/apiEndPoints";
import Loader from "common/loader";
import { useDispatch, useSelector } from "react-redux";
import base64 from "base-64";
import apiEndPoints from "../../../utils/apiEndPoints";
import { serchServiceCitySelect } from "../../../reduxStore/action/usersActions";

function OfferList() {
  const resetRef = useRef(0)
  const serviceRef = useRef([])
  const serviceRoute = useSelector((store) => store.serviceDetail);
  const [IndustryListData, setIndustryListData] = useState([]);

  const [pageCount, setPageCount] = useState(1);
  const [itemOffset, setItemOffset] = useState("0");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [baseUrl, setBaseUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [resetIsLoading, setResetIsLoading] = useState(false);
  const [citiesList, setCitiesList] = useState([]);

  const [selectedCity, setSelectedCity] = useState("");
  const [selectedIndustry, setSelectedIndustry] = useState("");

  const [searchCityList, setSearchCityList] = useState([]);
  const [cityListShow, setCityListShow] = useState(false);
  const [cityName, setCityName] = useState("");
  const [serviceName, setServiceName] = useState("");

  const [searchIndustryList, setSearchIndustryList] = useState([]);
  const [industryListShow, setIndustryListShow] = useState(false);
  const [formError, setFormError] = useState();
  useEffect(() => {

    getOffersDataList(itemOffset);
  }, []);

  async function getOffersDataList(offset) {

    if (resetRef.current != 1) {

      var params = {
        serviceid: selectedIndustry != ""
          ? selectedIndustry : "",
        cityid: selectedCity != "" ? selectedCity : "",
        limit: itemsPerPage,
        offset: String(offset),
      };
    } else {

      var params = {
        serviceid: "",
        cityid: "",
        limit: itemsPerPage,
        offset: offset,
      };
      setSelectedIndustry("");
      setSelectedCity("");
    }
    setIsLoading(true);
    setResetIsLoading(true);
    const { data } = await apiCall(
      "POST",
      ENDPOINTS.GETLISTOFFERSEARCH,
      params
    );
    if (data.status == 200) {

      //setIndustryListData([]);

      setIndustryListData(data.data.services);
      setPageCount(Math.ceil(data.data.total_data / itemsPerPage));
      setIsLoading(false);
      setResetIsLoading(false)
    } else {
      setIndustryListData([]);
      setIsLoading(false);
      setResetIsLoading(false)
    }
  }
  const handlePageClick = (event) => {
    // const newOffset = (event.selected * itemsPerPage) % IndustryListData.length;
    const newOffset = event.selected * itemsPerPage;
    setItemOffset(newOffset);
    getOffersDataList(newOffset);
  };



  // -------------------------------------------- city ----------------------------------

  function handleSelectCity(cityname, state_id) {

    document.getElementById("citySearch").value = cityname + ', ' + state_id;
    setCityName(cityname + ', ' + state_id);
    setCityListShow(false);
    setSearchCityList([]);
  }

  async function handleCitySearch(city = "") {
    if (city != "" && city.length >= 3) {
      // console.log('search ==>', city)
      const params = { cityname: city };
      const { data } = await apiCall(
        "POST",
        apiEndPoints.GETCITIESLIST,
        params
      );
      if (data.status == 200) {
        setSearchCityList(data.data);
      } else {
        setSearchCityList([]);
      }
      setCityListShow(true);
    } else {
      setSearchCityList([]);
      setSelectedCity("");
      setCityListShow(false);
    }
  }

  async function handleIndustrySearch(item) {
    if (item != '' && item.length >= 3) {
      const params = { servicename: item };
      const { data } = await apiCall(
        "POST",
        apiEndPoints.GETINDUSTRYLIST,
        params
      );
      if (data.status == 200) {
        setSearchIndustryList(data.data);
      } else {
        setSearchIndustryList([]);
      }
      setIndustryListShow(true);
    } else {
      serviceRef.current = []
      setIndustryListShow(false);
      setSearchIndustryList([]);
      setSelectedIndustry('');
    }
  }

  function handleSelectIndustry(item) {
    document.getElementById("serviceSearch").value = item;
    setIndustryListShow(false);
    setSearchIndustryList([]);
  }

  function resetDetail() {
    resetRef.current = 1

    setCityName('')
    setServiceName("")
    document.getElementById('citySearch').value = ''
    document.getElementById('serviceSearch').value = ''
    setFormError({})
    getOffersDataList(itemOffset);
  }


  function searchValidation() {
    let error = {};
    let formErrorData = false;
    setFormError(error);
    return formErrorData;
  }
  function searchServiceDataList() {
    if (!searchValidation()) {
      resetRef.current = 0
      getOffersDataList('0')
    }
  }


  function manageSearchService(item) {
    if (item == '') {
      setSelectedIndustry('')
      setIndustryListShow(false);
      setSearchIndustryList([]);
    }
  }

  function manageSearchCity(item) {
    if (item == '') {
      setSelectedCity('')
      setSearchCityList([]);
      setCityListShow(false);
    }
  }


  return (
    <OfferListScreen
      resetDetail={resetDetail}
      serviceRoute={serviceRoute}
      setCityListShow={setCityListShow}
      cityListShow={cityListShow}
      handleSelectCity={handleSelectCity}
      handleCitySearch={handleCitySearch}
      setSearchCityList={setSearchCityList}
      searchCityList={searchCityList}
      getOffersDataList={getOffersDataList}
      IndustryListData={IndustryListData}
      handlePageClick={handlePageClick}
      pageCount={pageCount}
      baseUrl={baseUrl}
      citiesList={citiesList}
      setSelectedCity={setSelectedCity}
      selectedCity={selectedCity}
      setSelectedIndustry={setSelectedIndustry}
      selectedIndustry={selectedIndustry}
      cityName={cityName}
      serviceName={serviceName}
      handleIndustrySearch={handleIndustrySearch}
      searchIndustryList={searchIndustryList}
      industryListShow={industryListShow}
      handleSelectIndustry={handleSelectIndustry}
      isLoading={isLoading}
      resetIsLoading={resetIsLoading}
      searchServiceDataList={searchServiceDataList}
      formError={formError}
      setFormError={setFormError}
      manageSearchService={manageSearchService}
      itemOffset={itemOffset}
      manageSearchCity={manageSearchCity}
    />
  );
}
export default OfferList;
