import Slider from "common/slider";
import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { apiCall } from "../../../../utils/httpClient";
import apiEndPoints from "utils/apiEndPoints";
import moment from "moment";
function ChangePasswordcreen(props) {
  const wrapperRefoccup = useRef(null);
  const wrapperRefCity = useRef(null);
  const inputFile = useRef(null);
  const todayDate = new Date().toISOString().split("T")[0];

  const futureyear = moment().add(-18, "Y").format("YYYY-MM-DD");

  let history = useHistory();
  const {
    setCityListShow,
    cityListShow,
    handleSelectCity,
    handleCitySearch,
    searchCityList,
    userDetails,
    accountDetailUpdate,
    setUserDetails,
    userPassword,
    setUserPassword,
    profileImage,
    setProfileImage,
    selectProfile,
    setSelectProfile,
    profileFormError,
    cityList,
    updatetwofactor,
    factorIsLoader,
  } = props;

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [bucket_Img_url, setBucket_Img_url] = useState();
  const [imgLoad, setImgLoad] = useState(false);

  useEffect(() => {
    selectProfile != undefined && getImage(selectProfile?.name);
  }, [selectProfile != undefined && selectProfile?.name]);

  function handleConfirmPassword() {
    if (confirmPassword == userPassword.new_password) {
      // console.log(': vir' );
      setConfirmPasswordError("");
      props.passwordUpdate();
    } else {
      setConfirmPasswordError("password not match!");
    }
  }

  const profile_photo_picker = () => {
    const getImage = inputFile.current.click();
  };
  function changeProfileImage(event) {
    if (event.target.files && event.target.files[0]) {
      setProfileImage(URL.createObjectURL(event.target.files[0]));
      setSelectProfile(event.target.files[0]);
      props.setAwsImage(URL.createObjectURL(event.target.files[0]));
    }
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRefCity.current &&
        !wrapperRefCity.current.contains(event.target)
      ) {
        props.setCityListShow(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
  }, [wrapperRefCity]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRefoccup.current &&
        !wrapperRefoccup.current.contains(event.target)
      ) {
        props.setOccupationListShow(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
  }, [wrapperRefoccup]);
  // ---------------------- select city ----------------------------------------------------------

  // const options =
  // cityList.length > 0 &&
  // cityList.map((curE, i) => {
  //   if(i<100){
  //     return { value:curE.id, label: curE.city };
  //   }
  // });

  // console.log("city options", options)

  // const selectedService =
  // userDetails &&
  // userDetails?.cityid &&
  // cityList?.length > 0
  //   ? cityList.map((x) => {
  //       return { label: x.city };
  //     })
  //   : {};

  async function getImage(param) {
    // setIsLoader(true);
    const params = {
      fileName: param,
    };
    const { data } = await apiCall("POST", apiEndPoints.GETIMAGE, params);
    if (data.status == 200) {
      setBucket_Img_url(data.url);
      // setTimeout(() => {
      //   setIsLoader(false);
      // }, 2000);
    } else {
      // setIsLoader(false);
    }
  }

  const imageLoading = (e) => {
    setImgLoad(true);
    setTimeout(() => {
      setImgLoad(false);
    }, 1000);
  };

  useEffect(() => {
    var input = document.getElementById("myInput");
    input.addEventListener("keypress", function (event) {
      if (event.key === "Enter") {
        // event.preventDefault();
        document.getElementById("myBtn").click();
      }
    });
  }, [userPassword?.confirmPassword]);

  const clickHaveKidsLabel = (id) => {
    document.getElementById(id).click();
  };

  return (
    <section className="default-smb-area bg--light">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="card border-8 shadow p-2">
              <div className="card-body">
                <div className="row">
                  {/* <div className="col-md-12">
                    <div className="season_tabs">
                      <div className="season_tab">
                        <input
                          type="radio"
                          id="tab-1"
                          name="tab-group-1"
                          checked
                        />
                        <label for="tab-1">tab 1</label>

                        <div className="season_content">
                          <span>content 1</span>
                        </div>
                      </div>

                      <div className="season_tab">
                        <input type="radio" id="tab-2" name="tab-group-1" />
                        <label for="tab-2">tab 2</label>

                        <div className="season_content">
                          <span>content 2</span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-md-12 botum-border">
                    <ul className="nav nav-tabs " id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#home"
                          type="button"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                          <i className="fa fa-id-card"></i> Data
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#profile"
                          type="button"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="false"
                        >
                          <i className="fa fa-lock"></i> Security
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-12">
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div
                          className="row"
                          style={{ justifyContent: "center" }}
                        >
                          <div className="col-md-12">
                            <div className="form--group">
                              <div className="row">
                                <div className="form--head text-center mt-5 mb-5">
                                  <h3>
                                    <i className="fa fa-user"></i> Update
                                    Profile
                                  </h3>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <div
                                      className="text-center"
                                      style={{
                                        marginTop: "28px",
                                        marginBottom: "27px",
                                      }}
                                    >
                                      <div className="col-md-12">
                                        <img
                                          onClick={profile_photo_picker}
                                          src={
                                            props.awsImage
                                              ? props.awsImage
                                              : "./images/user.png"
                                          }
                                          height="100px"
                                          width="100px"
                                          style={{
                                            borderRadius: "50px",
                                          }}
                                          onLoad={(e) => imageLoading(e)}
                                          className="prof-img"
                                        />
                                        <img
                                          src="./images/preview.gif"
                                          width="50px"
                                          height="50px"
                                          className={`${
                                            imgLoad ? "load" : "displayNone"
                                          }`}
                                        />
                                      </div>
                                      <div className="col-md-12">
                                        <i
                                          onClick={profile_photo_picker}
                                          className="fa fa-edit editprofile-icon"
                                        ></i>
                                      </div>
                                    </div>

                                    <input
                                      type="file"
                                      id="addImage"
                                      style={{ display: "none" }}
                                      ref={inputFile}
                                      accept="image/*"
                                      onChange={(e) => changeProfileImage(e)}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label style={{ fontWeight: "bold" }}>
                                      First Name:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control form-control-md"
                                      placeholder="First Name"
                                      maxLength={50}
                                      value={userDetails.firstname || ""}
                                      onChange={(e) =>
                                        setUserDetails({
                                          ...userDetails,
                                          firstname: e.target.value.trimStart(),
                                        })
                                      }
                                    />
                                    <small className="text-danger">
                                      {!userDetails.firstname &&
                                      profileFormError.firstname
                                        ? profileFormError.firstname
                                        : ""}
                                    </small>
                                  </div>
                                  <div className="form-group">
                                    <label style={{ fontWeight: "bold" }}>
                                      Last Name:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control form-control-md"
                                      placeholder="Last Name"
                                      maxLength={50}
                                      value={userDetails.lastname}
                                      onChange={(e) =>
                                        setUserDetails({
                                          ...userDetails,
                                          lastname: e.target.value.trimStart(),
                                        })
                                      }
                                    />
                                    <small className="text-danger">
                                      {!userDetails.lastname &&
                                      profileFormError.lastname
                                        ? profileFormError.lastname
                                        : ""}
                                    </small>
                                  </div>
                                  <div className="form-group">
                                    <label style={{ fontWeight: "bold" }}>
                                      Email:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control form-control-md"
                                      placeholder="Email"
                                      maxLength={50}
                                      value={userDetails.email}
                                      onChange={(e) =>
                                        setUserDetails({
                                          ...userDetails,
                                          email: e.target.value,
                                        })
                                      }
                                      disabled
                                    />
                                    <small className="text-danger">
                                      {!userDetails.email &&
                                      profileFormError.email
                                        ? profileFormError.email
                                        : ""}
                                    </small>
                                  </div>
                                  <div className="form-group">
                                    <label style={{ fontWeight: "bold" }}>
                                      Phone Number:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control form-control-md"
                                      placeholder="Phone Number"
                                      value={
                                        userDetails.mobile == "999999999"
                                          ? ""
                                          : userDetails.mobile
                                      }
                                      onChange={(e) =>
                                        setUserDetails({
                                          ...userDetails,
                                          mobile: e.target.value,
                                        })
                                      }
                                      maxLength={15}
                                      onKeyPress={(event) => {
                                        if (
                                          !/^[0-9+]+$/.test(event.key) ||
                                          event.target.value.length > 14
                                        ) {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                    {/* <small className="text-danger">
                          {!userDetails.mobile && profileFormError.mobile
                            ? profileFormError.mobile : userDetails?.mobile?.length<10 ? "Enter 10 digits mobile number"
                            : ""}
                        </small> */}
                                  </div>
                                  <div className="form-group">
                                    <label style={{ fontWeight: "bold" }}>
                                      Date of Birth:
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control form-control-md"
                                      placeholder="Date of Birth"
                                      value={userDetails.dob}
                                      onChange={(e) =>
                                        setUserDetails({
                                          ...userDetails,
                                          dob: e.target.value,
                                        })
                                      }
                                      //max={todayDate}
                                      max={futureyear}
                                    />
                                    {/* <input type="date" id="birthday" name="birthday" /> */}
                                    <small className="text-danger">
                                      {!userDetails.dob && profileFormError.dob
                                        ? profileFormError.dob
                                        : ""}
                                    </small>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <form name="myForm">
                                      <label style={{ fontWeight: "bold" }}>
                                        Gender:
                                      </label>
                                      <br />
                                      <input
                                        className="ml-1 form-check-input form-check-input-radio"
                                        type="checkbox"
                                        id="male"
                                        name="myRadios"
                                        onChange={(e) =>
                                          setUserDetails({
                                            ...userDetails,
                                            gender: 1,
                                          })
                                        }
                                        value="1"
                                        checked={
                                          userDetails.gender == 1 ? true : false
                                        }
                                      />
                                      <label
                                        style={{ marginRight: "55px" }}
                                        className="ml-4"
                                        onClick={() =>
                                          clickHaveKidsLabel("male")
                                        }
                                      >
                                        Male
                                      </label>

                                      <input
                                        className="ml-1 form-check-input form-check-input-radio"
                                        type="checkbox"
                                        id="female"
                                        name="myRadios"
                                        value="2"
                                        onChange={(e) =>
                                          setUserDetails({
                                            ...userDetails,
                                            gender: 2,
                                          })
                                        }
                                        checked={
                                          userDetails.gender == 2 ? true : false
                                        }
                                      />
                                      <label
                                        className="ml-4"
                                        onClick={() =>
                                          clickHaveKidsLabel("female")
                                        }
                                      >
                                        Female
                                      </label>
                                      <br />
                                    </form>
                                   {/*  <small className="text-danger">
                                      {!userDetails.havekids &&
                                      profileFormError.havekids
                                        ? profileFormError.havekids
                                        : ""}
                                    </small> */}
                                  </div>
                                  <div className="form-group">
                                    <form name="myForm">
                                      <label style={{ fontWeight: "bold" }}>
                                        Have Kids:
                                      </label>
                                      <br />
                                      <input
                                        className="ml-1 form-check-input form-check-input-radio"
                                        type="checkbox"
                                        id="kids_yes"
                                        name="myRadios"
                                        onChange={(e) =>
                                          setUserDetails({
                                            ...userDetails,
                                            havekids: 1,
                                          })
                                        }
                                        value="1"
                                        checked={
                                          userDetails.havekids == 1
                                            ? true
                                            : false
                                        }
                                      />
                                      <label
                                        style={{ marginRight: "66px" }}
                                        className="ml-4"
                                        onClick={() =>
                                          clickHaveKidsLabel("kids_yes")
                                        }
                                      >
                                        Yes
                                      </label>

                                      <input
                                        className="ml-1 form-check-input form-check-input-radio"
                                        type="checkbox"
                                        id="kids_no"
                                        name="myRadios"
                                        value="2"
                                        onChange={(e) =>
                                          setUserDetails({
                                            ...userDetails,
                                            havekids: 2,
                                          })
                                        }
                                        checked={
                                          userDetails.havekids == 2
                                            ? true
                                            : false
                                        }
                                      />
                                      <label
                                        className="ml-4"
                                        onClick={() =>
                                          clickHaveKidsLabel("kids_no")
                                        }
                                      >
                                        No
                                      </label>
                                      <br />
                                    </form>
                                    <small className="text-danger">
                                      {!userDetails.havekids &&
                                      profileFormError.havekids
                                        ? profileFormError.havekids
                                        : ""}
                                    </small>
                                  </div>
                                  <div className="form-group">
                                    <label style={{ fontWeight: "bold" }}>
                                      City:
                                    </label>

                                    <div ref={wrapperRefCity}>
                                      <input
                                        autoComplete="off"
                                        type="text"
                                        className="form-control"
                                        id="citySearch"
                                        onChange={(e) => {
                                          handleCitySearch(e.target.value);
                                        }}
                                        placeholder="City"
                                        defaultValue={userDetails.city_name}
                                        onClick={() => setCityListShow(false)}
                                        onBlur={(e) =>
                                          props.manageCitySearch(e.target.value)
                                        }
                                      />
                                      <div>
                                        <div
                                          className={`city_list_box ${
                                            cityListShow ? "show" : "hide"
                                          }`}
                                          style={{ width: "89%" }}
                                        >
                                          {searchCityList &&
                                          searchCityList.length > 0 ? (
                                            <ul className="city_list_menu">
                                              {searchCityList.map((item, i) => {
                                                return (
                                                  <li
                                                    onClick={() => {
                                                      setUserDetails({
                                                        ...userDetails,
                                                        cityid: item.id,
                                                        // cityid: item.city,
                                                      });
                                                      setCityListShow(false);
                                                      handleSelectCity(
                                                        item.city
                                                      );
                                                    }}
                                                    className={`city_list ${
                                                      userDetails?.cityid ==
                                                      item.id
                                                        ? "active"
                                                        : ""
                                                    }`}
                                                  >
                                                    {item.city}
                                                  </li>
                                                );
                                              }, [])}
                                            </ul>
                                          ) : (
                                            <p
                                              style={{
                                                textAlign: "center",
                                                marginTop: "2px",
                                                color: "gray",
                                              }}
                                            >
                                              No data
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    {/* <input
                          type="text"
                          className="form-control form-control-md"
                          placeholder="City"
                          value={userDetails.cityid}
                          onChange={(e) =>
                            setUserDetails({
                              ...userDetails,
                              cityid: e.target.value,
                            })
                          }
                        /> */}
                                    {/* <Select
                          options={cityList}
                          placeholder="Select City"
                          onChange={(e) =>props.setSelectedCity(e)
                          }
                          // onChange={(e) => console.log(e.value)}
                          defaultValue={props.selectedCity}
                        /> */}
                                    {/* <select
                          name="cityid"
                          id="cityid"
                          placeholder="Select City"
                          className="form-control form-control-md"
                          value={userDetails.cityid}
                          onChange={(e) =>
                            setUserDetails({
                              ...userDetails,
                              cityid: e.target.value,
                            })
                          }
                        > 
                          {cityList.length > 0 &&
                            cityList.map((x) => {
                              return <option value={x.id}>{x.city}</option>;
                            })}
                        </select>*/}
                                    <small className="text-danger">
                                      {!userDetails.cityid &&
                                      profileFormError.cityid
                                        ? profileFormError.cityid
                                        : ""}
                                    </small>
                                  </div>
                                  <div className="form-group">
                                    <label style={{ fontWeight: "bold" }}>
                                      Occupation:
                                    </label>
                                    <div ref={wrapperRefoccup}>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="occupationId"
                                        onChange={(e) =>
                                          props.handleClickOcupation(
                                            e.target.value
                                          )
                                        }
                                        placeholder="Occupation"
                                        defaultValue={
                                          userDetails?.occupation_name
                                        }
                                        onBlur={(e) =>
                                          props.manageOccupationSearch(
                                            e.target.value
                                          )
                                        }
                                      />
                                      <div>
                                        <div
                                          className={`city_list_box ${
                                            props.occupationListShow
                                              ? "show"
                                              : "hide"
                                          }`}
                                          style={{ width: "91%" }}
                                        >
                                          {props.serviceSocList &&
                                          props.serviceSocList.length > 0 ? (
                                            <ul className="city_list_menu">
                                              {props.serviceSocList.map(
                                                (item, i) => {
                                                  return (
                                                    <li
                                                      key={i}
                                                      onClick={() => {
                                                        setUserDetails({
                                                          ...userDetails,
                                                          occupation:
                                                            item.socid,
                                                          occupation_name:
                                                            item.title,
                                                        });
                                                        props.handleSelectOccupation(
                                                          item.title
                                                        );
                                                      }}
                                                      className={`city_list ${
                                                        userDetails?.occupation ==
                                                        item.socid
                                                          ? "active"
                                                          : ""
                                                      }`}
                                                    >
                                                      <a> {item.title}</a>{" "}
                                                    </li>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          ) : (
                                            <p
                                              style={{
                                                textAlign: "center",
                                                marginTop: "2px",
                                                color: "gray",
                                              }}
                                            >
                                              No data
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <small className="text-danger">
                                      {!userDetails.occupation &&
                                      profileFormError.occupation
                                        ? profileFormError.occupation
                                        : ""}
                                    </small>
                                  </div>
                                  <div className="form-group">
                                    <label style={{ fontWeight: "bold" }}>
                                      Education:
                                    </label>

                                    <select
                                      placeholder="Yearly Income"
                                      className="form-control form-control-md form-control-city"
                                      value={userDetails?.education}
                                      onChange={(e) =>
                                        setUserDetails({
                                          ...userDetails,
                                          education: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="">Select Education</option>
                                      <option value="High School">
                                        High School
                                      </option>
                                      <option value="Associate">
                                        Associate
                                      </option>
                                      <option value="Bachelor">Bachelor</option>
                                      <option value="Masters">Masters</option>
                                      <option value="Doctorate">
                                        Doctorate
                                      </option>
                                    </select>

                                    {/*  <input
                          type="text"
                          className="form-control form-control-md form-control-city"
                          placeholder="Income"
                          value={userDetails.income}
                          onChange={(e) =>
                            setUserDetails({
                              ...userDetails,
                              income: e.target.value,
                            })
                          }
                          onKeyPress={(event) => {
                            if (!/[0-99]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        /> */}
                                    <small className="text-danger">
                                      {!userDetails.education &&
                                      profileFormError.education
                                        ? profileFormError.education
                                        : ""}
                                    </small>
                                  </div>
                                  <div className="form-group">
                                    <label style={{ fontWeight: "bold" }}>
                                      Yearly Income:
                                    </label>

                                    <select
                                      placeholder="Yearly Income"
                                      className="form-control form-control-md form-control-city"
                                      value={userDetails.income}
                                      onChange={(e) =>
                                        setUserDetails({
                                          ...userDetails,
                                          income: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="">
                                        Select Yearly Income
                                      </option>
                                      <option value="0 - $50,000">
                                        0 - $50,000
                                      </option>
                                      <option value="$50,001 - $100,000">
                                        $50,001 - $100,000
                                      </option>
                                      <option value="$100,001 - $250,000">
                                        $100,001 - $250,000
                                      </option>
                                      <option value="$250,001 - $500,000">
                                        $250,001 - $500,000
                                      </option>
                                      <option value="$500,001 - $1,000,000">
                                        $500,001 - $1,000,000
                                      </option>
                                      <option value="$1,000,001 or More">
                                        $1,000,001 or More
                                      </option>
                                    </select>

                                    <small className="text-danger">
                                      {!userDetails.income &&
                                      profileFormError.income
                                        ? profileFormError.income
                                        : ""}
                                    </small>
                                  </div>
                                  <div className="form-group">
                                    <label style={{ fontWeight: "bold" }}>
                                      Marital Status:
                                    </label>

                                    <select
                                      name="maritalstatus"
                                      id="maritalstatus"
                                      placeholder="Marital Status"
                                      className="form-control form-control-md form-control-city"
                                      value={userDetails.maritalstatus}
                                      onChange={(e) =>
                                        setUserDetails({
                                          ...userDetails,
                                          maritalstatus: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="">
                                        Select Marital status
                                      </option>
                                      <option value="1">Married</option>
                                      <option value="0">Single</option>
                                      <option value="2">Widowed</option>
                                    </select>
                                    <small className="text-danger">
                                      {!userDetails.maritalstatus &&
                                      profileFormError.maritalstatus
                                        ? profileFormError.maritalstatus
                                        : ""}
                                    </small>
                                  </div>
                                </div>

                                <div className="form-group col-md-12">
                                  <div className="row">
                                    <div className="col-md-6"></div>
                                    <div className="col-md-6">
                                      <div className="text-center">
                                        <button
                                          type="button"
                                          className="btn main-btn w-100"
                                          onClick={accountDetailUpdate}
                                        >
                                          {props.isLoader ? (
                                            <img
                                              src="images/loader_white.gif"
                                              style={{ width: "28px" }}
                                            />
                                          ) : (
                                            "Save Change"
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="profile"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                      >
                        <div
                          className="row"
                          style={{ justifyContent: "center" }}
                        >
                          <div className="col-md-6">
                            <div className="form--group">
                              <div className="form--head text-center mt-5 mb-5">
                                <h3>
                                  <i className="fa fa-lock-open"></i> Change
                                  Password
                                </h3>
                              </div>
                              <form id="passwordForm">
                                <div className="row">
                                  <div className="form-group col-md-12">
                                    <label style={{ fontWeight: "bold" }}>
                                      Old Password:
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control form-control-md"
                                      placeholder="Old Password"
                                      onChange={(e) => {
                                        setUserPassword({
                                          ...userPassword,
                                          old_password: e.target.value,
                                        });
                                        props.setFormError({
                                          ...props.formError,
                                          old_password: "",
                                        });
                                      }}
                                    />
                                    <small className="text-danger">
                                      {props.formError.old_password
                                        ? props.formError.old_password
                                        : ""}
                                    </small>
                                  </div>
                                  <div className="form-group col-md-12">
                                    <label style={{ fontWeight: "bold" }}>
                                      New Password:
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control form-control-md"
                                      placeholder="New Password"
                                      onChange={(e) =>
                                        setUserPassword({
                                          ...userPassword,
                                          new_password: e.target.value,
                                        })
                                      }
                                    />
                                    <small className="text-danger">
                                      {!userPassword.new_password &&
                                      props.formError.new_password
                                        ? props.formError.new_password
                                        : ""}
                                      {props.formError.new_password_length
                                        ? props.formError.new_password_length
                                        : ""}
                                    </small>
                                  </div>
                                  <div className="form-group col-md-12">
                                    <label style={{ fontWeight: "bold" }}>
                                      Repeat Password:
                                    </label>
                                    <input
                                      id="myInput"
                                      type="password"
                                      className="form-control form-control-md"
                                      placeholder="Repeat Password"
                                      onChange={(e) => {
                                        setUserPassword({
                                          ...userPassword,
                                          confirm_password: e.target.value,
                                        });
                                        props.setFormError({
                                          ...props.formError,
                                          confirm_password: "",
                                        });
                                      }}
                                    />
                                    <small className="text-danger">
                                      {props.formError.confirm_password
                                        ? props.formError.confirm_password
                                        : ""}
                                    </small>
                                    {/* <input
                          type="password"
                          className="form-control form-control-md"
                          placeholder="Repeat Password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        /> */}
                                    {/* <small className="text-danger">
                          {confirmPasswordError}
                        </small> */}
                                  </div>
                                  <div className="form-group col-md-12">
                                    <div className="text-center">
                                      <button
                                        id="myBtn"
                                        type="button"
                                        className="btn main-btn w-100"
                                        // onClick={() => handleConfirmPassword()}
                                        onClick={() => props.passwordUpdate()}
                                      >
                                        {props.passIsLoader ? (
                                          <img
                                            src="images/loader_white.gif"
                                            style={{ width: "28px" }}
                                          />
                                        ) : (
                                          "Save Change"
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div
                          className="form--card noti--form-card mt-4 mb-3"
                          style={{ maxWidth: "100%" }}
                        >
                          <div className="form--box">
                            <div className="form--group text-center">
                              <div className="form--head">
                                <h3>Two Factor Authentication</h3>
                              </div>
                              {/* <div className="text-center">
                    <div
                      className="text-center mb-4"
                      style={{ display: "flex" }}
                    > */}

                              <button
                                onClick={() =>
                                  userDetails.two_factor_auth == 1
                                    ? updatetwofactor(0)
                                    : null
                                }
                                type="button"
                                className={`${
                                  userDetails.two_factor_auth == 0
                                    ? "main-btn"
                                    : "main-gray-btn"
                                } rounded  mdl--btn`}
                              >
                                {userDetails.two_factor_auth == 1 &&
                                factorIsLoader ? (
                                  <img
                                    src="images/loader_white.gif"
                                    style={{ width: "28px" }}
                                  />
                                ) : (
                                  "Off"
                                )}
                              </button>
                              <button
                                style={{ marginLeft: "24px" }}
                                type="button"
                                onClick={() =>
                                  userDetails.two_factor_auth == 0
                                    ? updatetwofactor(1)
                                    : null
                                }
                                className={`${
                                  userDetails.two_factor_auth == 1
                                    ? "main-btn"
                                    : "main-gray-btn"
                                } rounded  mdl--btn`}
                              >
                                {" "}
                                {userDetails.two_factor_auth == 0 &&
                                factorIsLoader ? (
                                  <img
                                    src="images/loader_white.gif"
                                    style={{ width: "28px" }}
                                  />
                                ) : (
                                  "On"
                                )}
                              </button>
                              {/* </div>
                  </div> */}
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-md-12"> */}
                        <div
                          className="form--card noti--form-card mt-4 mb-3"
                          style={{ maxWidth: "100%", display: "none" }}
                        >
                          <div className="form--box">
                            <div className="form--group text-center">
                              <div className="form--head">
                                <h3>Notification Setting</h3>
                              </div>
                              {/* <div className="text-center">
                    <div
                      className="text-center mb-4"
                      style={{ display: "flex" }}
                    > */}
                              <button
                                type="button"
                                className="main-btn rounded  mdl--btn "
                              >
                                Email
                              </button>
                              <button
                                type="button"
                                className="main-gray-btn rounded ml-1 mdl--btn"
                              >
                                Sms
                              </button>
                              {/* </div>
                  </div> */}
                            </div>
                          </div>
                        </div>

                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ChangePasswordcreen;
