import React, { useState } from "react";
import OtpInput from 'react18-input-otp';
import { apiCall, errorToast } from "utils/httpClient";
import ApiEndPoint from "utils/apiEndPoints";
import { useHistory } from "react-router-dom";
import HomeVideo from "../../../../common/homevideo";
function ForgotPassword(props) {
  let history = useHistory();
  const [formData, setFormData] = useState({
    email: "",
    type: "FORGOT",
  });
  const [passwordData, setPasswordData] = useState({
    newpassword: "",
    confirmpassword: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [resntLoding, setResntLoding] = useState(false);
  const [formError, setFormError] = useState({});
  const [passwordError, setPasswordError] = useState({});
  const [enterotp, setEnterotp] = useState(0);
  const [otpmail, setOtpmail] = useState(null);
  const [otpError, setOtpError] = useState({});

  function validation() {
    let error = {};
    let validError = false;
    if (!formData?.email) {
      validError = true;
      error["email"] = "Email is required!";
    }
    setFormError(error);
    return validError;
  }

  function validationotp() {
    let error = {};
    let validError = false;
    if (!otpmail) {
      validError = true;
      error["otpmail"] = "Email OTP is required";
    }

    setFormError(error);
    return validError;
  }

  function validationpassword() {
    let error = {};
    let validError = false;
    if (!passwordData?.newpassword) {
      validError = true;
      error["newpassword"] = "New Password is required!";
    } else if (passwordData?.newpassword.length < 6 || passwordData?.newpassword.length > 15) {
      validError = true;
      error["newpassword"] = "New Password Shuld be between 6 to 15 Charactor!";
    } else if (!passwordData?.confirmpassword) {
      validError = true;
      error["confirmpassword"] = "Confirm Password is required!";
    } else if (passwordData?.newpassword !== passwordData?.confirmpassword) {
      validError = true;
      error["passwordnotmatch"] = "Confirm Password not match !";
    } else {
      validError = false;
    }
    setPasswordError(error);
    return validError;
  }

  async function submitforgotform() {
    if (!validation()) {
      setIsLoading(true);
      const { data } = await apiCall(
        "POST",
        ApiEndPoint.FORGOTPASSWORD,
        formData
      );

      if (data.status == 200) {
        setIsLoading(false);
        setEnterotp(1);
      } else {
        setIsLoading(false);
        setFormError(data.message);
        errorToast(data.message?.messageTost);
      }
    }
  }
  async function Verifyotp() {
    if (!validationotp()) {
      setIsLoading(true);
      const params = { otp: otpmail, email: formData?.email };
      const { data } = await apiCall("POST", ApiEndPoint.VERIFYEDOTP, params);

      if (data.status == 200) {
        setIsLoading(false);
        setEnterotp(2);
      } else {
        setIsLoading(false);
        setFormError(data.message);
        errorToast(data.message?.messageTost);
      }
    }
  }

  const changepassword = async () => {
    if (!validationpassword()) {
      setIsLoading(true);
      const params = {
        password: passwordData?.newpassword,
        email: formData?.email,
      };
      const { data } = await apiCall(
        "POST",
        ApiEndPoint.PASSWORDUPDATE,
        params
      );

      if (data.status == 200) {
        setIsLoading(false);
        history.push("/login");
      } else {
        setIsLoading(false);
        errorToast(data.message);
      }
    }
  };
  async function handleResentOtp() {
    setResntLoding(true);
    const params = { email: formData?.email };
    const { data } = await apiCall("POST", ApiEndPoint.RESENTOTP, params);
    if (data.status == 200) {
      setResntLoding(false);
      setFormError(data.message);
      setOtpmail(null);
    } else {
      setResntLoding(false);
      setFormError(data.message);
      errorToast(data.message?.messageTost);
    }
  }

  return (
    <>
      <section className="default-smb-area bg--light">
        <div className="container ">
          <div className="row justify-content-center">
            {enterotp == 2 ? (
              <div className="col-lg-6">
                <div className="login--form-box Larger shadow bg-white">
                  <div className="heading1">
                    <h2>Change Password</h2>
                  </div>
                  <div className="formdiv">
                    <form className="forminline">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group setform">
                            <div>New Password</div>
                            <input
                              type="password"
                              className="form-control input--email"
                              id="formGroupExampleInput"
                              placeholder="New Password"
                              onChange={(e) => {
                                setPasswordData({
                                  ...passwordData,
                                  newpassword: e.target.value,
                                });
                              }}
                            />
                            <small className="text-danger">
                              {passwordError.newpassword
                                ? passwordError.newpassword
                                : ""}
                            </small>
                          </div>

                          <div className="form-group setform">
                            <div>Confirm Password</div>
                            <input
                              type="password"
                              className="form-control input--email"
                              id="formGroupExampleInput"
                              placeholder="Confirm Password"
                              onChange={(e) => {
                                setPasswordData({
                                  ...passwordData,
                                  confirmpassword: e.target.value,
                                });
                                setPasswordError({
                                  ...passwordError,
                                  passwordnotmatch: "",
                                  confirmpassword: "",
                                });
                              }}
                            />
                            <small className="text-danger">
                              {!passwordData?.confirmpassword &&
                                passwordError.confirmpassword
                                ? passwordError.confirmpassword
                                : ""}
                            </small>
                            <small className="text-danger">
                              {passwordError.passwordnotmatch
                                ? passwordError.passwordnotmatch
                                : ""}
                            </small>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group text-center ">
                            <button
                              onClick={() => changepassword()}
                              type="button"
                              className="btn btn-lg main-btn mt-4"
                              style={{ width: 150 }}
                            >
                              {isLoading ? (
                                <img
                                  src="images/loader_white.gif"
                                  style={{ width: "30px" }}
                                />
                              ) : (
                                "Change"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-lg-6">
                <div className="login--form-box border-8 shadow bg-white">
                  <div className="heading1 mb-3">
                    <h2>
                      {enterotp == 0
                        ? "Forgot Password"
                        : "Enter your One Time Password"}
                    </h2>
                  </div>
                  <div className="formdiv">
                    <form className="forminline">
                      <div className="row">
                        <div className="col-lg-12">
                          {enterotp == 0 ? (
                            <div className="form-group setform">
                              <input
                                type="text"
                                className="form-control input--email"
                                id="formGroupExampleInput"
                                placeholder="Email"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    email: e.target.value,
                                  });
                                  setFormError({});
                                }}
                              />
                              <small className="text-danger">
                                {formError.email ? formError.email : ""}
                              </small>
                            </div>
                          ) : (
                            <>
                              <div className="otp-container mt-2">
                                <OtpInput
                                  value={otpmail}
                                  onChange={(e) => {
                                    setOtpmail(e);
                                    setFormError({});
                                  }}
                                  numInputs={4}
                                  separator={<span>&#160;</span>}
                                />
                                <small className="text-danger">
                                  {formError.otpmail ? formError.otpmail : ""}
                                  {formError?.messageError &&
                                    formError.messageError}
                                </small>
                                <small className="text-success">
                                  {formError?.messageSuccess &&
                                    formError.messageSuccess}
                                </small>

                                <div style={{ marginTop: "0.5rem" }}>
                                  <span
                                    onClick={() => handleResentOtp()}
                                    style={{
                                      color: "#877cf2",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {" "}
                                    {resntLoding ? (
                                      <img
                                        src="images/loader_dark.gif"
                                        style={{ width: "30px" }}
                                      />
                                    ) : (
                                      "Resend One Time Password"
                                    )}
                                  </span><br />
                                  <div className="check-email-inbox">
                                    <span>We've sent you a 4 digit code to your Inbox, please enter it to validate your email. Check also your Junk, Spam or Trash folder.</span>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group text-center ">
                            <button
                              onClick={() =>
                                enterotp == 0 ? submitforgotform() : Verifyotp()
                              }
                              type="button"
                              className="btn main-btn mt-4 w-100"
                            >
                              {isLoading ? (
                                <img
                                  src="images/loader_white.gif"
                                  style={{ width: "30px" }}
                                />
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default ForgotPassword;
