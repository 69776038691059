
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { apiCall } from "utils/httpClient";
import apiEndPoints from "utils/apiEndPoints";
import { successToast } from "utils/httpClient";
import { errorToast } from "utils/httpClient";
import moment from "moment";
import base64 from "base-64";
import { Document, Page } from "react-pdf";
function InvestorRequestList() {
  const formRef = useRef();
 
  const [investorListData, setInvestorListData] = useState([]);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isLoader, setIsLoader] = useState(false);
  const [editDocument, setEditDocument] = useState("");
  const [base_url, setBase_url] = useState();
  const [fileSizeError, setFileSizeError] = useState("");
  const todaydate = moment().format("YYYY-MM-DD");
  const [bucket_Img_url, setBucket_Img_url] = useState();
  const [numPages, setNumPages] = useState(null); 
  const [pageNumber, setPageNumber] = useState(1);
  const [isListLoading, setIsListLoading] = useState(false)
  const [showMore, setShowMore] = useState(0)
  const [totalData, setTotalData] = useState(0)





  useEffect(() => {
    myInvestorRequestList(itemOffset);
    
  }, []);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage);
    //setItemOffset(newOffset)

    /*  !showloader ?
         setIsListLoading(true)
         : null */

         myInvestorRequestList(newOffset);
};

  const showmore = (id) =>{
    setShowMore(id)
   }
  const showless = (id) =>{
     setShowMore(id)
  }

  async function getImage(param) {
    //setIsLoader(true);
    const params = {
      fileName: param,
    };
    const { data } = await apiCall("POST", apiEndPoints.GETIMAGE, params);

    if (data.status == 200) {
      setBucket_Img_url(data.url);
      setTimeout(() => {
        //setIsLoader(true);
      }, 1000);
    } else {
      //setIsLoader(false);
    }
  }
  
  
  
  async function myInvestorRequestList(offset) {
   
    setIsListLoading(true)
    const params = {
      limit: itemsPerPage,
      offset: offset,
    };
    const { data } = await apiCall(
      "POST",
      apiEndPoints.GETINVESTORREQUESTLIST,
      params
    );
    if (data.status == 200) {
        
     /*  setIsListLoading(false)
      setInvestorListData(data.data);

      const total_data = data?.totalData
      const parPage = (total_data / itemsPerPage);
      setPageCount(parPage) */

      setIsListLoading(false)
      setInvestorListData([]);


     
      /* const pageCount = data.total_data / itemsPerPage;
      setPageCount(pageCount); */
    } else {
      setIsListLoading(false)
      setInvestorListData([]);
    }
  }
 
 return (
   

        <div className="container">
          {!isListLoading ?
            <div>
              <div className="page-container border-bottom-0" style={{marginTop: 23}}>
                <div className="row">
                  {investorListData && investorListData.length > 0 ? (

                    investorListData.map((item, i) => (
                      <div className="page-content col-xl-6 mb-6 mb-xl-0">
                        <div className="widget mb-8">
                          <div className="rm-listings">
                            <div className="card mb-3 p-3 shadow-sm">
                              <div className="row">
                                <div className="col-md-3">
                                  <div className='ser_img_lst'>
                                     <img
                                      
                                      src={ item.aws_url
                                        ? item.aws_url
                                        : "images/no_image.png"}
                                      className="card-img rounded "
                                      alt="Business Image"
                                    /> 
                                  </div>
                                </div>
                                <div className="col-md-9">
                                  <div className="srvc--containt card-body p-0">
                                    <div className="d-flex justify-content-between">
                                      <h5 className="card-title mb-0">
                                        {item.business_name}
                                      </h5>
                                      <span className="service--amunt-rigt">
                                        ${item.required_amount.toLocaleString('en-US')}
                                      </span>
                                    </div>
                                    <small className="srvc--text">
                                     {/*  Rating ({Math.round(item.ranting)}) */}

                                      <div className="usr--head-rating-list">
                                        <span
                                          className={`fa fa-star ${
                                            item.ranting >= 1
                                              ? "checked"
                                              : ""
                                            }`}
                                        />
                                        <span
                                          className={`fa fa-star ${
                                            item.ranting >= 2
                                              ? "checked"
                                              : ""
                                            }`}
                                        />
                                        <span
                                          className={`fa fa-star ${
                                            item.ranting >= 3
                                              ? "checked"
                                              : ""
                                            }`}
                                        />
                                        <span
                                          className={`fa fa-star ${
                                            item.ranting >= 4
                                              ? "checked"
                                              : ""
                                            }`}
                                        />
                                        <span
                                          className={`fa fa-star ${
                                            item.ranting >= 5
                                              ? "checked"
                                              : ""
                                            }`}
                                        />
                                      </div>
                                
                                    </small>
                                    <small className="srvc--text serice--list-text">
                                      <b>Mobile:</b>&nbsp;{item.phone}
                                     </small>
                                    <small className="srvc--text serice--list-text">
                                      <b>Email:</b>&nbsp;{item.email}

                                    </small>
                                   {/*  <small className="srvc--text serice--list-text">
                                      <b>Location:</b> {item.address}

                                    </small> */}
                                    <small className="srvc--text serice--list-text">
                                    <b>Title:</b>&nbsp;{item.title}

                                    </small>
                                     <small className="srvc--text serice--list-text">
                                     
                                     
                                     <span className={showMore === item.request_id ? '' : "ellipsis"}
                                     ><b>Summary:</b>&nbsp;{item.summary}</span>
                                     
                                     {showMore === item.request_id ?
                                     <p className="moreless" onClick={() => setShowMore(0)}>Show Less</p>
                                     :
                                     <p className="moreless" onClick={() => showmore(item.request_id)}>Show More</p>
                                    }


                                    </small>
                                    <hr />
                                    <div className="d-flex justify-content-between">
                                      
                                      <ul className="srvc--botm-icon">
                                        <li className="srvc--icon">
                                          {item.showcall >
                                            0 ? (
                                              <Link
                                                to="#"
                                                onClick={(e) => {
                                                  window.location = `tel:${item.phone}`;
                                                  e.preventDefault();
                                                }}
                                              >
                                                <img
                                                  src="images/icons/call.png"
                                                  className="srvc--icon-img"
                                                />
                                              </Link>
                                            ) : (
                                              ""
                                            )}
                                        </li>
                                        <li className="srvc--icon">
                                          {item.showemail >
                                            0 ? (
                                              <a
                                                target="_blank"
                                                href={`mailto:${item.email}?&subject=Investor Information Request&body=<<Type here the information you want to request from the business>>`}
                                              >
                                                <img
                                                  src="images/icons/mail.png"
                                                  className="srvc--icon-img"
                                                />
                                              </a>
                                            ) : (
                                              ""
                                            )}
                                        </li>
                                        <li className="srvc--icon">
                                          {item.showtext >
                                            0 ? (
                                              <Link
                                                to="#"
                                                onClick={(e) => {
                                                  window.location = `sms:${item.phone}?&body=<<Type here the information you want to request from the business>>`;
                                                  e.preventDefault();
                                                }}
                                              >
                                                <img
                                                  src="images/icons/link.png"
                                                  className="srvc--icon-img"
                                                />
                                              </Link>
                                            ) : (
                                              ""
                                            )}



                                        </li>
                                      </ul>
                                
                                     
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                    ))
                  ) : (
                      <div className="text-center">
                        <img src="images/nodatafound.png" />
                      </div>
                    )}
                </div>
              </div>
            </div>
            : <div className='text-center'> 
               <div className="" style={{ height: '300px' }}>
              <div className="lds-ellipsis">
                <span />
                <span />
                <span />
              </div>
            </div> 
           </div>}

          {(investorListData && investorListData.length > 0 &&
              <div className="btm-pagination mt-4">
              <div className="d-flex justify-content-end">
                  {/* <div className="pag-lable-show">Show Result {itemsPerPage} from {showTotalSaleRow}</div> */}
                  <ReactPaginate
                      // previousLabel={'previous'}
                      // nextLabel={'next'}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      marginPagesDisplayed={4}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageClick}
                      containerClassName={
                          "pagination pagination-style-02 justify-content-center"
                      }
                      subContainerClassName={"pages pagination"}
                      activeClassName={"current active active_pg"}
                      activeLinkClassName={"current active active_pg"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link bg-gray"}
                      previousClassName={"page-item"}
                      nextClassName={"page-item"}
                      previousLinkClassName={"page-link bg-gray"}
                      nextLinkClassName={"page-link bg-gray"}
                      previousLabel={<i className="fal fa-chevron-left"></i>}
                      nextLabel={<i className="fal fa-chevron-right"></i>}
                  />
              </div>
          </div>
          )}
        </div>
      
      
   
  );
}

export default InvestorRequestList;
