import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { AuthContext } from "component/context/UserContext";
import { PrivateRoute, PublicRoute, ScrollToTop } from "./routes";
import { setDefaultHeader, apiCall } from "../utils/httpClient";
import ApiEndPoint from "../utils/apiEndPoints";
import axios from "axios";
//Screen
import HomeScreen from "screen/home";

// Auth Screen
import JoinusScreen from "screen/auth/joinus";
import LoginScreen from "screen/auth/login";
import ForgotPasswordScreen from "screen/auth/forgotPassword";
import SubscriptionPlanScreen from "screen/auth/subscriptionplan";
import RedirectPlanScreen from "screen/auth/redirectplan";
import PayalReturnScreeen from "screen/auth/paypalreturn";
import PayalCancelScreeen from "screen/auth/paypalcancel";
import NewSubscription from "screen/auth/newsubscription";
//import PaypalredirectScreen from "screen/auth/paypalredirect";

//Service Screen
import ServiceListScreen from "screen/service/serviceList";
import JobListScreen from "screen/service/jobList";
import OffferListScreen from "screen/service/offerList";
import ServiceDetailScreen from "screen/service/serviceDetail";

//Dashboard Screen
import DashboardScreen from "screen/dashboard";

//Profile Screen
import ChangePasswordScreen from "screen/profile/changePassword";
import ContactScreen from "screen/contact";
import PrivacyScreen from "screen/privacy";

//Navigation screen
import navigation from "./navigation";
import { routes, adminRoster } from "./navigation";

//common
import Header from "common/header";
import Footer from "common/footer";
import Loader from "common/loader";

//Admin
import AdminDashboard from "screen/admin";

import { useDispatch, useSelector } from "react-redux";
import adminLogin from "screen/adminLogin";
// import {handleLoginStatus} from '../reduxStore/action/usersActions'
function App() {
  // const dispatchs = useDispatch();
  const loginStatus = useSelector((store) => store.userLoginStatus);
  // const userBusinessDataStore = useSelector(store => store.userBusinessDataStore);
  // console.log('userBusinessDataStore: ', userBusinessDataStore);
  // console.log('loginStatus:  veer1233 rdx nav => ', loginStatus);
  // const userData = useSelector(store => store.userData);
  // console.log('userData: ==auth=>', userData);
  const [showFooter, setShowFooter] = useState(false);

  const initialLoginState = {
    isLoading: true,
    userName: null,
    userToken: null,
    userType: "",
  };
  const loginReducer = (prevState, action) => {
    switch (action.type) {
      case "RETRIEVE_TOKEN":
        return {
          ...prevState,
          userType: action.userType,
          userToken: action.token,
          isLoading: false,
        };
      case "LOGIN":
        return {
          ...prevState,
          userName: action.id,
          userType: action.userType,
          userToken: action.token,
          isLoading: false,
        };
      case "LOGOUT":
        return {
          ...prevState,
          userType: "",
          userName: null,
          userToken: null,
          isLoading: false,
        };
      case "REGISTER":
        return {
          ...prevState,
          userName: action.id,
          userType: action.userType,
          userToken: action.token,
          isLoading: false,
        };
    }
  };

  const [loginState, dispatch] = React.useReducer(
    loginReducer,
    initialLoginState
  );

  const authContext = React.useMemo(
    () => ({
      signIn: async (foundUser, token, userType) => {
        try {
          await localStorage.setItem("userToken", token);
        } catch (e) {
          console.log(e);
        }
        dispatch({
          type: "LOGIN",
          id: foundUser,
          token: token,
          userType: userType,
        });
      },
      signOut: async (user_id) => {
        //console.log("🚀 ~ file: mainNavigation.jsx:117 ~ signOut: ~ user_id:", user_id)
        try {
          await localStorage.removeItem("userToken");
          user_id === "business"
            ? window.location.assign("/login")
            : window.location.assign("/adminlogin");
        } catch (e) {
          console.log(e);
        }
        dispatch({ type: "LOGOUT" });
      },
      signUp: () => {},
      getUser: () => {
        let userToken = localStorage.getItem("userToken");
        try {
          return userToken;
        } catch (e) {
          console.log(e);
        }
      },
    }),
    []
  );

  React.useEffect(() => {
    setTimeout(async () => {
      let userToken;
      userToken = null;
      try {
        userToken = await localStorage.getItem("userToken");
      } catch (e) {
        console.log(e);
      }
      dispatch({ type: "RETRIEVE_TOKEN", token: userToken });
    }, 1000);

    getToken();
  }, []);

  React.useEffect(() => {
    setShowFooter(window.location.pathname != "/home");
    console.log(window.location.pathname);
  }, [window.location]);

  async function getToken() {
    try {
      const Auth = await localStorage.getItem("userToken");
      //console.log('Auth: ', Auth);
      if (Auth) {
        // dispatch({ type: "LOGIN", token: Auth,  });
        await localStorage.setItem("token", Auth);
        await setDefaultHeader("token", Auth);
      } else {
        var { data } = await apiCall("GET", ApiEndPoint.JWTTOKEN);
        //console.log('data: JWTTOKEN ==>', data);
        await setDefaultHeader("token", data.token);
        await localStorage.setItem("token", data.token);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const loading = <Loader />;
  if (loginState.isLoading) {
    return loading;
  }

  return (
    <AuthContext.Provider value={authContext}>
      <BrowserRouter>
        <ScrollToTop>
          <Header />
          <Switch>
            <PublicRoute
              restricted={true}
              loginState={loginState}
              component={HomeScreen}
              path="/home"
              exact
            />
            <PublicRoute
              restricted={true}
              loginState={loginState}
              component={JoinusScreen}
              path="/join_us"
              exact
            />
            <PublicRoute
              restricted={true}
              loginState={loginState}
              component={LoginScreen}
              path="/login"
              exact
            />
            <PublicRoute
              restricted={false}
              loginState={loginState}
              component={ServiceListScreen}
              // path="/service_list/:service_id/:city_id"
              path="/service_list"
              exact
            />
            <PublicRoute
              restricted={false}
              loginState={loginState}
              component={JobListScreen}
              // path="/service_list/:service_id/:city_id"
              path="/job_list"
              exact
            />
            <PublicRoute
              restricted={false}
              loginState={loginState}
              component={OffferListScreen}
              // path="/service_list/:service_id/:city_id"
              path="/offer_list"
              exact
            />
            <PublicRoute
              restricted={true}
              loginState={loginState}
              component={ServiceDetailScreen}
              path="/service_detail/:business_id"
              // path="/service_detail"
              exact
            />
            <PublicRoute
              restricted={true}
              loginState={loginState}
              component={ForgotPasswordScreen}
              path="/forgot_password"
              exact
            />
            <PublicRoute
              restricted={true}
              loginState={loginState}
              component={adminLogin}
              path="/adminlogin"
              exact
            />
            <PublicRoute
              restricted={true}
              loginState={loginState}
              component={SubscriptionPlanScreen}
              path="/subscriptionplan"
              exact
            />
            <PublicRoute
              restricted={true}
              loginState={loginState}
              component={RedirectPlanScreen}
              path="/redirectplan"
              exact
            />
            <PublicRoute
              restricted={true}
              loginState={loginState}
              component={PayalReturnScreeen}
              path="/paypalreturn"
              exact
            />
            <PublicRoute
              restricted={true}
              loginState={loginState}
              component={PayalCancelScreeen}
              path="/paypalcancel"
              exact
            />
            <PublicRoute
              restricted={true}
              loginState={loginState}
              component={NewSubscription}
              path="/newsubscription"
              exact
            />

            <PublicRoute
              restricted={false}
              loginState={loginState}
              component={ChangePasswordScreen}
              path="/change_password"
              exact
            />
            
            <PublicRoute
              restricted={false}
              loginState={loginState}
              component={ContactScreen}
              path="/contact"
              exact
            />
            
            <PublicRoute
              restricted={false}
              loginState={loginState}
              component={PrivacyScreen}
              path="/privacy_policy"
              exact
            />

            <PublicRoute
              restricted={true}
              loginState={loginState}
              component={ServiceDetailScreen}
              path="/:business_name"
              // path="/service_detail"
              exact
            />

            {/* <PublicRoute
              restricted={false}
              loginState={loginState}
              component={DashboardScreen}
              path="/dashboard"
            />
            <PublicRoute
              restricted={false}
              loginState={loginState}
              component={ChangePasswordScreen}
              path="/change_password"
              exact
            />
            <PublicRoute
              restricted={true}
              loginState={loginState}
              component={AdminDashboard}
              path="/admin"
              exact
            />   */}

            {/* <PrivateRoute path="/" loginState={loginState}>
            <Switch>
               {routes.map((route, idx) => {
                 return (
                   route.component && (
                     <Route
                       key={idx}
                       path={route.path}
                       exact={route.exact}
                       name={route.name}
                       render={(props) => (
                         <div>
                           <route.component {...props} />
                         </div>
                       )}
                     />
                   )
                 );
               })}
               </Switch>
            </PrivateRoute> */}

            {/* <PrivateRoute path="/" loginState={loginState}>
              
              
               {(loginState.loginType == 'business')?
              <Switch>
                {routes.map((route, idx) => {
                console.log('route: ', route);
                  return (
                    route.component && (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={(props) => (
                          <div>
                            <route.component {...props} />
                          </div>
                        )}
                      />
                    )
                  );
                })}
                </Switch>: <>{(loginState.loginType == "admin")?<Switch>{
                adminRoster.map((route, idx) => {
                console.log('route: ', route);
                    return (
                      route.component && (
                        <Route
                          key={idx}
                          path={route.path}
                          exact={route.exact}
                          name={route.name}
                          render={(props) => (
                            <div>
                              <route.component {...props} />
                            </div>
                          )}
                        />
                      )
                    );
                  })}</Switch>:<Redirect from="/" to="/" />}
                  </>
              }
            </PrivateRoute>  */}

            {loginStatus.userType == "business" ? (
              <PrivateRoute path="/" loginState={loginState}>
                <Switch>
                  {routes.map((route, idx) => {
                    return (
                      route.component && (
                        <Route
                          key={idx}
                          path={route.path}
                          exact={route.exact}
                          name={route.name}
                          render={(props) => (
                            <div>
                              <route.component {...props} />
                            </div>
                          )}
                        />
                      )
                    );
                  })}
                </Switch>
              </PrivateRoute>
            ) : loginStatus.userType == "admin" ? (
              <PrivateRoute path="/" loginState={loginState}>
                <Switch>
                  {adminRoster.map((route, idx) => {
                    return (
                      route.component && (
                        <Route
                          key={idx}
                          path={route.path}
                          exact={route.exact}
                          name={route.name}
                          render={(props) => (
                            <div>
                              <route.component {...props} />
                            </div>
                          )}
                        />
                      )
                    );
                  })}
                </Switch>
              </PrivateRoute>
            ) : (
              <Redirect from="/" to="/home" />
            )}
          </Switch>

          
          <Footer />
        </ScrollToTop>
      </BrowserRouter>
    </AuthContext.Provider>
  );
}
export default App;
